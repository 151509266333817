<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Informe de cortesias</h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: #1976d2 !important"
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Informe de cortesias</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 mb-3>
          <v-dialog
            ref="dialogDesde"
            v-model="modalDesde"
            :return-value.sync="desde"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="desde"
                label="Desde *"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="desde"
              scrollable
              locale="es-ES"
              :max="hoy"
              :min="primer_fecha_venta"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modalDesde = false"
                >Cancelar</v-btn
              >
              <v-btn flat color="primary" @click="$refs.dialogDesde.save(desde)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 mb-3>
          <v-dialog
            ref="dialogHasta"
            v-model="modalHasta"
            :return-value.sync="hasta"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="hasta"
                label="Hasta *"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="hasta"
              scrollable
              locale="es-ES"
              :max="hoy"
              :min="primer_fecha_venta"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modalHasta = false"
                >Cancelar</v-btn
              >
              <v-btn flat color="primary" @click="$refs.dialogHasta.save(hasta)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 mb-3 v-if="show_list_eventos">
          <v-select
            v-model="evento_filter"
            item-value="id"
            item-text="nombre"
            :items="eventos"
            label="Seleccionar un evento *"
            prepend-icon="pageview"
            required
          ></v-select>
        </v-flex>
      </v-layout>
      <div class="text-right">
        <v-btn color="info" @click="searchInformeCortesias"
          ><v-icon>search</v-icon> BUSCAR</v-btn
        >
      </div>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        must-sort
        :items="exportCortesias"
        hide-default-footer
        :length="pages"
        :search="search"
        :total-items="totalItemCount"
        :itemsPerPage="exportCortesias.length"
        class="elevation-1 table-ventas"
        :loading="loading"
        :no-data-text="'No se han encontrado cortesias...'"
      >
        <template v-slot:items="props">
          <td class="font-weight-bold">#{{ props.item.id }}</td>
          <td class="text-xs-center">
            {{ formatFechaHumans(props.item.fecha) }}<br /><small>{{
              props.item.fecha
            }}</small>
          </td>
          <td class="text-xs-center">{{ props.item.usuario.nombre }}</td>
          <td class="text-xs-center">{{ props.item.cliente }}</td>
        </template>
        <template v-slot:item.acciones="item">
          <td>
            <v-icon
              title="Visualizar la factura"
              medium
              center
              @click="searchFactura(item.item.id)"
            >
              receipt
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      desde: null,
      hasta: null,
      modalDesde: false,
      modalHasta: false,
      evento_filter: null,
      show_list_eventos: false,
      show_button_cierre_diario: false,
      modalCierreDiario: false,
      headers: [
        {
          text: "N° VENTA",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "fecha", align: "center", sortable: false },
        {
          text: "RESPONSABLE",
          value: "email_usuario",
          align: "center",
          sortable: false,
        },
        {
          text: "OBSERVACIÓN",
          value: "observacion",
          align: "center",
          sortable: false,
        },
        { text: "CLIENTE", value: "cliente", align: "center", sortable: false },
        { text: "ACCIONES", value: "acciones", sortable: false },
      ],
      json_fields: {
        "N° VENTA": "id",
        FECHA: "fecha",
        "PUNTO VENTA": "punto_ventum.nombre_razon",
        CLIENTE: "cliente",
        "TIPO DE PAGO": "pago_parcial",
        "FORMA DE PAGO": "forma_pago",
        "COD VOUCHER": "code_voucher",
        "TOTAL PAGADO": "total",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  async beforeCreate() {
    document.title = "Informe de cortesias - TicketShop";
    await this.$store.dispatch("evento/listMisEventos");
    await this.$store.dispatch("carrito/informeCortesias", {
      page: 1,
      desde: null,
      hasta: null,
      id_evento: this.evento_filter,
    });
    var self = this;
    setTimeout(function () {
      self.show_list_eventos = true;
    }, 300);
  },
  watch: {
    pagination: {
      async handler() {
        this.$store.dispatch("carrito/informeCortesias", {
          page: this.pagination.page,
          desde: this.desde,
          hasta: this.hasta,
          id_evento: this.evento_filter,
        });
      },
      deep: true,
    },
  },
  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    factura() {
      return this.$store.getters["carrito/factura"];
    },
    pagination() {
      return this.$store.getters["carrito/paginationCortesias"];
    },
    pages() {
      return this.$store.getters["carrito/pagesCortesias"];
    },
    totalItemCount() {
      return this.$store.getters["carrito/totalItemCount"];
    },
    loading() {
      return this.$store.getters["carrito/loading"];
    },
    eventos() {
      return this.$store.getters["evento/listEventos"];
    },
    exportCortesias() {
      return this.$store.getters["carrito/exportCortesias"];
    },
    hoy() {
      return moment().format("YYYY-MM-DD");
    },
    primer_fecha_venta() {
      return moment(
        this.$store.getters["carrito/primer_fecha_venta_pdv"]
      ).format("YYYY-MM-DD");
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    printFactura() {
      // Pass the element id here
      this.$htmlToPaper("printFactura");
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    formatFechaHumans(fecha) {
      return moment(fecha).fromNow();
    },
    searchInformeCortesias() {
      if (
        this.desde != null &&
        this.hasta != null &&
        this.evento_filter != null
      ) {
        this.$store.dispatch("carrito/informeCortesias", {
          page: 1,
          desde: this.desde,
          hasta: this.hasta,
          id_evento: this.evento_filter,
        });
      } else {
        swal(
          "Error!",
          "Completa los campos para realizar la busqueda!",
          "error"
        );
      }
    },
    searchFactura(id) {
      this.$store.dispatch("carrito/searchFactura", id);
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 16px;
}
</style>