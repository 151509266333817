<template>
  <div>
    <nav class="navbar nav-public navbar-dark navbar-expand-md bg-dark-other justify-content-center">
      <router-link :to="{path: '/'}" class="navbar-brand" title="TicketShop" tag="a">
        <img class="logo" src="../../../public/images/logo-ticketshop.png" style="max-width: 150px;">        
      </router-link>
      <b style="color:white; width: 190px; font-size: 14px;" >
        &nbsp;&nbsp;&nbsp;&nbsp;Llámanos:
        <a class="nav-link" href="tel:04124122415">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          04124122415</a>        
        <a class="nav-link" href="https://api.whatsapp.com/send?phone=5804124122415" target="_blank">
          Escríbenos: <br>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
          </svg>
          04124122415
        </a>        
      </b>
      <button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
        <ul class="navbar-nav mx-auto text-center menu-public">
          <li class="nav-item">
            <router-link to="/puntosVenta" title="Puntos de venta autorizados" class="nav-link text-white item-menu-public"><i class="material-icons">location_on</i> Puntos de venta</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/comunicados" title="Ver los comunicados" class="nav-link text-white item-menu-public"><i class="material-icons">forum</i> Comunicados</router-link>
          </li>
          <li class="nav-item">
            <a href="https://centrodeayuda.ticketshopve.com/" title="Preguntas, Quejas y Reclamos" class="nav-link text-white item-menu-public"><i class="material-icons">contact_support</i> PQR</a>
          </li>
          <!--<li class="nav-item">
            <router-link to="/contacto" title="Contactanos" class="nav-link text-white item-menu-public"><i class="material-icons">contact_mail</i> Contacto</router-link>
          </li>-->
        </ul>
        <ul class="nav navbar-nav flex-row justify-content-center flex-nowrap">

          <li class="nav-item pr-2" v-if="!$store.state.user.accessToken">
            <a class="nav-link" href="#" @click.prevent="showModalLogin"><i class="material-icons">person</i></a>
            <!--<router-link :to="{ name: 'login' }" class="nav-link" title="Iniciar sesion" exact><i class="material-icons">person</i></router-link>-->
          </li>
          <li class="nav-item dropdown pr-2" v-if="$store.state.user.accessToken">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false" title="Mis opciones">
              <img src="/images/user.png" class="img-logged">
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" :to="{name: 'perfil'}">Mi perfil</router-link>
              <router-link class="dropdown-item" :to="{name: 'perfil', params: {optPerfil: 3}}" v-if="usuario.id_rol==2">Mis compras</router-link>
              <!-- <router-link class="dropdown-item" :to="{}">Configurar alertas</router-link> -->
              <router-link class="dropdown-item" :to="{name: 'contacto'}">Soporte</router-link>

              <router-link class="dropdown-item" :to="{ name: 'misEventos' }"
                           v-if="usuario.id_rol==3 || usuario.id_rol==4 || usuario.id_rol==8 || usuario.id_rol==9 || usuario.id_rol==5 || usuario.id_rol==7">
                Mis eventos
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'PuntosVentaJefePunto' }"
                           v-if="usuario.id_rol==7">
                Puntos de venta
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'pagosParciales' }"
                           v-if="usuario.id_rol==5">
                Pagos parciales
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'eventosAprobacion' }" v-if="usuario.id_rol==6">Eventos
                aprobacion
              </router-link>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click.prevent="logout">Cerrar sesion</a>
            </div>
          </li>
          <!-- <li class="nav-item pr-2">
            <a class="nav-link" href="#"><i class="material-icons">shopping_cart</i></a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="tel:04124122415"><i class="material-icons">phone</i></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    showModalLogin() {
      this.$store.commit('showModalLogin', true);
    },
  },
  computed: {
    usuario() {
      return this.$store.getters['user/dataUserLogged'];
    },
    modalLogin() {
      return this.$store.getters['modalLogin'];
    },
  },
};
</script>
<style>
.cont-input-search span {
  background-color: white;
}

.cont-input-search input {
  border-left: 0px;
}

.bg-dark-other {
  background-color: #313131 !important;
}

.nav-link {
  color: white !important;
}
.nav-link:hover{
  color: #ffffffa4 !important;
}
.search .v-input__control {
  min-height: auto !important;
}

.search .v-input__slot {
  border-radius: 100px !important;
}

/*.router-link-exact-active{
    color: #fff !important;
}*/
.img-logged {
  max-width: 30px;
  box-shadow: 0px 3px 7px 2px rgb(0 0 0 / 60%);
    border-radius: 100%;
}
.img-logged:hover{
  opacity: .9;
}
.dropdown-menu{
  position: absolute !important;
}
.dropdown-menu.show {
  top: 42px;
  left: -28px;
}

.dropdown-toggle::after {
  display: none !important;
}
.item-menu-public{
  font-size: 16px ;
  font-weight: bold;
}
.item-menu-public:hover{
  color: white !important;
  opacity: .7;
}
.nav-public{
  background-color: rgb(49 43 17) !important;
    background: url('/images/fondo-menu.jpg');
    background-blend-mode: overlay;
    background-attachment: fixed;
    border-bottom: 1px black solid;
}
</style>