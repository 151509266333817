import {configApi} from '../api/configApi';
import {router} from '../main.js';

export const getters = {
		tokenResetPassword(state){
			return state.tokenResetPassword;
		},
		tokenActiveAccount(state){
			return state.tokenActiveAccount;
		},
		sha(state){
			return state.sha;
		},
		obtener_refventa(state){
			return state.obtener_refventa;
		},
		get_init_point(state){
			return state.init_point;
		},
		modalFactura(state){
			return state.modalFactura;
		},
		modalLogin(state){
			return state.modalLogin;
		},
};