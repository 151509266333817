import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       auditoriaeventos: [],
       auditoriaevento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listAuditoriaeventos: (state,getters,rootState) => {
          return state.auditoriaeventos;
    },
    auditoriaevento: (state,getters,rootState) => {
        return state.auditoriaevento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listAuditoriaeventos: (state,auditoriaeventos) => {
          state.auditoriaeventos = auditoriaeventos;
          state.pagination.totalItems = auditoriaeventos.total;
          state.pagination.page = auditoriaeventos.current_page;
          state.pagination.rowsPerPage = auditoriaeventos.per_page;
       },
      listAuditoriaeventosSearch: (state,auditoriaeventos) => {
            if(auditoriaeventos != null){ 
                state.auditoriaeventos = auditoriaeventos;
            }else{
              state.auditoriaeventos = null;
            }
       },
      viewAuditoriaevento(state,id){
        let auxAuditoriaevento = state.auditoriaeventos.filter((item) => item.id == id);
        state.auditoriaevento = auxAuditoriaevento[0];
      },
      setAuditoriaevento(state,auditoriaevento){
          state.auditoriaevento = auditoriaevento;
      },
      clearAuditoriaevento(state){
        state.auditoriaevento = null;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listAuditoriaeventos({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}auditoria_evento`,null,{headers: headers})
      .then(response => {
        commit('listAuditoriaeventos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener la auditoria del evento!", "error");
        console.error(error);
      });
    },
      listAuditoriaeventosEvento({state,getters,commit,rootState},key){
      axios.get(`${configApi.apiUrl}auditoria_evento/${key}`,null,{headers: headers})
      .then(response => {
        commit('listAuditoriaeventos',response.data.data);
      })
      .catch(error => {
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener la auditoria del evento!", "error");
        }
      });

    },
  newAuditoriaevento({state,getters,commit,rootState},payload){
      // id_evento
      // observacion
      // status_1
      // status_2
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}auditoria_evento`,payload,{headers: headers})
      .then(response => {
        store.dispatch('auditoriaevento/listAuditoriaeventos',state.pagination.page);
        commit('setAuditoriaevento',response.data.data);
        /*swal("Exito!", "Se creo la auditoria del eventocorrectamente!", "success");*/
      })
      .catch(error => {
        swal("Error!", "Error al crear la auditoria del evento", "error");
        console.error(error);
      });
  },
  updateAuditoriaevento({state,getters,commit,rootState},payload){
      // id_evento
      // observacion
      // status_1
      // status_2
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}auditoria_evento/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el auditoriaevento correctamente!", "success");
        store.dispatch('auditoriaevento/listAuditoriaeventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la auditoria del evento", "error");
        console.error(error);
      });
  },
  deleteAuditoriaevento({state,getters,commit,rootState},auditoriaevento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}auditoria_evento/${auditoriaevento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el auditoriaevento correctamente!", "success");
        store.dispatch('auditoriaevento/listAuditoriaeventos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la auditoria del evento","error");
     });
  },
  },
}