import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers, headersMultipart} from '../../api/headers';
import {store} from '../index';

export default {
    namespaced: true,
	state: {
        comunicados: [],
        loading: false,
        facturas: [],
        pagination: {
            descending: true,
            page: 1,
            rowsPerPage: 0,
            sortBy: '',
            totalItems: 0,
            rowsPerPageItems: [1, 2, 4, 8, 16],
        },
        pages: 0,
	},
	getters: {
        getComunicados(state) {
            return state.comunicados;
        },
        pagination(state,getters,rootState){
          return state.pagination;
        },
        pages(state,getters,rootState){
          return state.pages;
        },
        totalItemCount(state,getters,rootState){
            return state.pagination.totalItems;
        },
        loading(state,getters,rootState){
            return state.loading;
        },

	},
	mutations: {
        comunicados: (state,comunicados) => {
          state.comunicados = comunicados.data;
          state.pagination.totalItems = comunicados.total;
          state.pagination.page = comunicados.current_page;
          state.pagination.rowsPerPage = comunicados.per_page;
        },
      pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
	},
	actions: {
     loadComunicados({state,getters,commit,rootState},payload){
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}comunicados?page=${payload.page}`,null,{headers: headers})
      .then(response => {
        commit('comunicados',response.data.data);
        commit('pages');
        state.loading = false;
        store.commit('changeLoader', false);
      })
      .catch(error => {
        state.loading = false;
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los comunicados!", "error");
      });
        },
	}
}