<template>  
  <div id="pgr-payment-container"  ></div>
</template>
 
<script>
import moment from "moment"; 
moment.locale("es");
export default {
  data() {
    return {
      cargarWidgetSeguro:0,  
      valorapagar:0,
    }
  },
  props: {
    valortotalcompra:0,  
    fechaevento: ''  ,
     valorseguro:0,
  },
  created() { 
           this.cargarScript();       
  },
watch: {
   
        valortotalcompra(){
          this.actualizardatos();
          
        },
       
       
  },
  mounted() {
         //  console.log( this.$store.state.protectionValue )
  },
  methods: {

      llenar(){
        this.$emit("resultado", this.valorseguro);
      },
      cargarScript() {   
           
            if (typeof window._pgr === 'undefined') {
                  (function (P, R, O, t, E, C, T) {
                    P[t] = P[t] || function () { (P[t].q = P[t].q || []).push(arguments) };
                    C = R.createElement(O), T = R.getElementsByTagName(O)[0];
                    C.id = t; C.src = E; C.async = 1; T.parentNode.insertBefore(C, T);
                  }(window, document, 'script', '_pgr', 'https://test.widget.protectgroup.com/dynamic-widget.js'));
                  _pgr('init', {
                                  environment: 'test',
                                  debug: true,
                                  vendorCode: 'ven_local_82416aeac71e4580a07163ac7d56fcd5',
                                  currencyCode: 'COP',
                                  languageCode: 'es',
                                  eventDateFormat: 'DD/MM/YYYY',
                                  containerSize: 'small' ,
                                  useSaleAction: false
                                }); 
                   }
        }, 
        async  actualizardatos(){ 
                  let cantidadDinero = this.valortotalcompra  ;
                  cantidadDinero =cantidadDinero.replace(/[^0-9,]/g, '').replace(',', '.');
                  let valor = parseFloat(cantidadDinero);

                  let fecha=   this.formatofecha( this.fechaevento );
                  let resultado = 0;
                  resultado =  await this.ejecutapgw(valor, fecha);
                  setTimeout(() => {   
                  this.$store.state.protectionValue=resultado;
                }, 70);       
             
        }  ,

        async ejecutapgw(valor=0, fecha){
          let resultado = [];
          if(valor > 0){


            window._pgr('action', 'updateQuoteData',  {totalValue: valor, numberOfTickets: '1', eventTravelDateTime: fecha} );
             
              var self = this; 
               document.addEventListener('PgWidgetProtectionChange', function (event) {
                                 const { detail: { formattedTotalValue, formattedProtectionValue, formattedBookingCost, quoteId }, detail } = event 
                                 self.$store.state.protectionValue=  event.detail.protectionValue;
                                 self.$store.state.totalValue=  event.detail.totalValue;
                                 self.$store.state.pgwidget=detail.quoteId;
                                 self.$store.state.productId=event.detail.products[0].productId;
                                 self.$store.state.sold=event.detail.products[0].sold;
                                  
                                // console.log(event.detail);
                        }, false); 
                 

                        
              }
                return   resultado;
        },
        guardardatos(dato){
                        this.$store.state.protectionValue= dato;
                     //   console.log(dato); 
        },
        formatofecha( fecha ) { 
            const partes = fecha.split(' ');  
            const fechaPartes = partes[0].split('-'); 
            const dia = fechaPartes[2];
            const mes = fechaPartes[1];
            const anio = fechaPartes[0]; 
            let fechaFormateada = `${dia}/${mes}/${anio}`;
            return   fechaFormateada;
          },  
  },
};
</script>