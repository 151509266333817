var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.evento)?_c('v-app',[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('div',{staticClass:"mb-3 mt-3"},[_c('h2',[_vm._v("Informe de ventas")])]),_c('div',[_c('span',[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":{ path: '/mis-eventos' },"title":"Ver todos mis eventos"}},[_c('u',[_vm._v("Volver a Mis Eventos")])])],1),_c('span',[_vm._v(" > ")]),_c('span',[_vm._v("Informe de ventas")])]),_c('div',{staticClass:"mt-3 mb-4"},[_c('h2',{staticClass:"bg-white p-3"},[_vm._v("Evento "),_c('b',[_vm._v(_vm._s(_vm.evento.nombre))])])])])],1),_c('v-divider')],1),(_vm.ventasEventoEmpresario)?_c('div',{staticClass:"pl-3 pr-3"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg8":"","md8":"","sm8":"","xs12":"","mb-3":""}},[_c('h4',[_vm._v("\n            Cantidad total de boletas vendidas:\n            "),_c('b',[_vm._v(_vm._s(_vm.getTotalBoletasVendidas))])]),_c('h4',[_vm._v("\n            Venta total de boletas:\n            "),_c('b',[_vm._v(_vm._s(_vm.ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                  .simbolo)+_vm._s(_vm.formatNumber(_vm.getTotalVentasRealizadas)))])])]),_c('v-flex',{attrs:{"lg4":"","md4":"","sm4":"","xs12":""}},[_c('export-excel',{staticStyle:{"width":"fit-content"},attrs:{"data":_vm.exportVentasEventoEmpresario,"export-fields":_vm.json_fields,"worksheet":"Informe ventas","name":_vm.evento.nombre + '.xls'}},[_c('v-btn',{attrs:{"color":"success"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("cloud_download")]),_vm._v(" EXPORT A EXCEL")],1)],1)],1)],1),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":"","mb-3":"","mt-3":""}},[_c('v-flex',{attrs:{"lg3":"","md3":"","sm4":"","xs12":""}},[_c('v-chip',{attrs:{"color":"primary","text-color":"white"}},[_vm._v("Puestos Vendidos")])],1),_c('v-flex',{attrs:{"lg3":"","md3":"","sm4":"","xs12":""}},[_c('v-chip',{attrs:{"color":"warning","text-color":"white"}},[_vm._v("Puestos de cortesia")])],1)],1),_c('br'),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"elevation-1 table-ventas table-striped table table-hover"},[_c('thead',{staticClass:"thead-dark"},[_c('th',[_vm._v("LOCALIDAD")]),_c('th',[_vm._v("TIPO")]),_c('th',[_vm._v("SILLETERIA")]),_c('th',[_vm._v("PUESTOS VENDIDOS")]),_c('th',[_vm._v("CANTIDAD EN P.D.V")]),_c('th',[_vm._v("CANTIDAD EN LINEA")]),_c('th',[_vm._v("TOTAL CANTIDAD")]),_c('th',[_vm._v("VALOR UNITARIO")]),_c('th',[_vm._v("TOTAL EN P.D.V")]),_c('th',[_vm._v("TOTAL EN LINEA")]),_c('th',[_vm._v("TOTAL")])]),_c('tbody',[_vm._l((_vm.ventasEventoEmpresario.localidades_evento),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("\n                "+_vm._s(item.localidad.nombre)+"\n              ")]),_c('td',{},[_vm._v("\n                "+_vm._s(item.localidad.palco ? "PALCO" : "BOLETERIA")+"\n              ")]),_c('td',{},[_vm._v(_vm._s(item.localidad.silleteria ? "SI" : "NO"))]),_c('td',{},[(item.localidad.silleteria)?_c('div',[_vm._l((item.localidad.ventas.puestos_vendidos.filter(
                      (item) =>
                        item.detalle_vents[0].vent.forma_pago != 'cortesia'
                    )),function(puesto,index){return _c('v-chip',{key:index,attrs:{"color":"primary","text-color":"white"}},[_vm._v(_vm._s(puesto.palco.puestos[0].numero))])}),_vm._l((item.localidad.ventas.puestos_vendidos.filter(
                      (item) =>
                        item.detalle_vents[0].vent.forma_pago === 'cortesia'
                    )),function(puesto,index){return _c('v-chip',{key:index,attrs:{"color":"warning","text-color":"white"}},[_vm._v(_vm._s(puesto.palco.puestos[0].numero))])})],2):_c('div',[(
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago != 'cortesia'
                      ).length > 0
                    )?_c('v-chip',{attrs:{"x-large":"","color":"primary","text-color":"white"}},[_c('div',{staticClass:"blue darken-4 mr-2 py-1 px-2",staticStyle:{"border-radius":"50px"}},[_vm._v(_vm._s(item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago != "cortesia"
                      ).length))]),_vm._v("\n                    Vendidos")]):_vm._e(),(
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago == 'cortesia'
                      ).length > 0
                    )?_c('v-chip',{attrs:{"x-large":"","color":"warning","text-color":"white"}},[_c('v-avatar',{staticClass:"orange darken-4"},[_vm._v(_vm._s(item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago == "cortesia"
                      ).length))]),_vm._v("\n                    Cortesias")],1):_vm._e()],1)]),_c('td',{staticClass:"font-weight-bold text-center"},[(
                    _vm.getPuestosVendidosFisicos(
                      item.localidad.ventas.puestos_vendidos
                    ) > 0
                  )?_c('span',[_vm._v(_vm._s(_vm.getPuestosVendidosFisicos(
                      item.localidad.ventas.puestos_vendidos
                    )))]):_vm._e()]),_c('td',{staticClass:"font-weight-bold text-center"},[(
                    _vm.getPuestosVendidosEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    ) > 0
                  )?_c('span',[_vm._v(_vm._s(_vm.getPuestosVendidosEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    )))]):_vm._e()]),_c('td',{staticClass:"font-weight-bold text-center"},[_c('span',[_vm._v(_vm._s(_vm.getPuestosVendidosEnLinea(
                    item.localidad.ventas.puestos_vendidos
                  ) +
                  _vm.getPuestosVendidosFisicos(
                    item.localidad.ventas.puestos_vendidos
                  )))])]),_c('td',{staticClass:"font-weight-bold"},[_vm._v("\n                "+_vm._s(item.codigo_moneda.simbolo)+_vm._s(_vm.formatNumber(
                    item.precio_venta + item.precio_venta * item.impuesto
                  ))+"\n              ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v("\n                "+_vm._s(item.codigo_moneda.simbolo)+_vm._s(_vm.formatNumber(
                    _vm.getTotalVentasFisicas(
                      item.localidad.ventas.puestos_vendidos
                    )
                  ))+"\n              ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v("\n                "+_vm._s(item.codigo_moneda.simbolo)+_vm._s(_vm.formatNumber(
                    _vm.getTotalVentasEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    )
                  ))+"\n              ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v("\n                "+_vm._s(item.codigo_moneda.simbolo)+_vm._s(_vm.formatNumber(
                    _vm.getTotalVentasEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    ) +
                      _vm.getTotalVentasFisicas(
                        item.localidad.ventas.puestos_vendidos
                      )
                  ))+"\n              ")])])}),_c('tr',[_c('td',{staticClass:"font-weight-bold bg-dark text-white"},[_vm._v("TOTAL")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white"}),_c('td',{staticClass:"font-weight-bold bg-dark text-white"}),_c('td',{staticClass:"font-weight-bold bg-dark text-white"}),_c('td',{staticClass:"font-weight-bold bg-dark text-white text-center"},[_vm._v("\n                "+_vm._s(_vm.getTotalBoletasVendidasFisicas)+"\n              ")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white text-center"},[_vm._v("\n                "+_vm._s(_vm.getTotalBoletasVendidasEnLinea)+"\n              ")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white text-center"},[_vm._v("\n                "+_vm._s(_vm.getTotalBoletasVendidas)+"\n              ")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white"}),_c('td',{staticClass:"font-weight-bold bg-dark text-white"},[_vm._v("\n                "+_vm._s(_vm.ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo)+_vm._s(_vm.formatNumber(_vm.getTotalVentasRealizadasFisicas))+"\n              ")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white"},[_vm._v("\n                "+_vm._s(_vm.ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo)+_vm._s(_vm.formatNumber(_vm.getTotalVentasRealizadasEnLinea))+"\n              ")]),_c('td',{staticClass:"font-weight-bold bg-dark text-white"},[_vm._v("\n                "+_vm._s(_vm.ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo)+_vm._s(_vm.formatNumber(_vm.getTotalVentasRealizadas))+"\n              ")])])],2)])]),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":"","mb-3":""}},[_c('h4',[_vm._v("\n            Cantidad total de boletas vendidas:\n            "),_c('b',[_vm._v(_vm._s(_vm.getTotalBoletasVendidas))])]),_c('h4',[_vm._v("\n            Venta total de boletas:\n            "),_c('b',[_vm._v(_vm._s(_vm.ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                  .simbolo)+_vm._s(_vm.formatNumber(_vm.getTotalVentasRealizadas)))])])])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }