<template>
  <v-app>
    <v-layout row justify-center>
      <v-dialog v-model="$store.state.modalRegister" max-width="600px">
        <v-card>
          <v-card-title>
            <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <img
                src="/images/logo-ticketshop_color.png"
                style="max-width: 120px; width: 120px"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <form @submit.prevent="doRegister" autocomplete="off">
                <p class="mb-0 mt-0">
                  <v-icon small color="warning">info</v-icon> Los campos
                  marcados con <b>*</b> son obligatorios.
                </p>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      label="Nombres (*)"
                      required
                      v-model="user.nombre"
                      prepend-icon="person"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      label="Apellidos (*)"
                      v-model="user.apellidos"
                      prepend-icon="person"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg4 md4 sm4>
                    <v-select
                      v-model="user.tipo_identificacion"
                      item-value="id"
                      item-text="code"
                      :items="tipoIdentificaciones"
                      label="Tipo de identificación (*)"
                      persistent-hint
                      :hint="descripcionTipoIdentificacion"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 lg8 md8 sm8>
                    <v-text-field
                      label="Número de identificación (*)"
                      required
                      v-model="user.identificacion"
                      @blur="validarNumeroId"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      label="Correo electrónico (*)"
                      type="email"
                      required
                      v-model="user.email"
                      :rules="emailRules"
                      @blur="validarCorreo"
                    ></v-text-field>
                    <div  style="background: rgb(235 234 234);border-width: 1px;border-color: #e68800;border-style:solid;border-radius: 1.5rem;" v-if="user.activarmodalvalidacion">
                      <div class="text-center mb-5 pb-5">
                        <br>
                        <b>Ingrese el código que hemos enviado a su correo para validar el email</b> <br>
                      </div>
                      <div class="container" id="validarcodigo">
                        <div class="row justify-content-center" >
                          <div class="col-md-2">
                            <div class="form-group vertical-center">
                              <v-text-field
                                                    type="text"
                                                    ref="field1"
                                                    v-model="fieldValues[0]"
                                                    v-on:keyup="checkKey(2, $event)"
                                                    autocomplete="nope"
                                                    required
                                                    maxlength="1"
                                                    class="form-control text-center no-bottom-line" 
                                                    style="height:80px; padding-top:25px; font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group vertical-center">
                              <v-text-field
                                                    type="text" 
                                                    ref="field2"
                                                    v-model="fieldValues[1]"
                                                    v-on:keyup="checkKey(3, $event)"
                                                    autocomplete="nope"
                                                    required
                                                    maxlength="1"
                                                    class="form-control text-center  no-bottom-line" 
                                                    style="  height:80px; padding-top:25px;  font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;" 
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group vertical-center">
                              <v-text-field
                                                    type="text"
                                                    ref="field3"
                                                    v-model="fieldValues[2]"
                                                    v-on:keyup="checkKey(4, $event)"
                                                    autocomplete="nope"
                                                    required
                                                    maxlength="1"
                                                    class="form-control text-center  no-bottom-line" 
                                                    style=" height:80px; padding-top:25px;   font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;" 
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group vertical-center">
                              <v-text-field
                                                    type="text"
                                                    ref="field4"
                                                    v-model="fieldValues[3]"
                                                    v-on:keyup="checkKey(5, $event)"
                                                    autocomplete="nope" 
                                                    required
                                                    maxlength="1"
                                                    class="form-control text-center  no-bottom-line" 
                                                    style="  height:80px; padding-top:25px;  font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;"
                                ></v-text-field>
                            </div>
                          </div>
                        </div>   
                      </div>
                      <div class="text-center  mb-5 pb-5  "  >
                        <v-btn color="warning" class="btn-finalizar-compra"  @click="validarCodigo()"  >
                          Validar Código 
                        </v-btn>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-select
                      v-model="user.departamento"
                      item-value="id"
                      item-text="descripcion"
                      :items="departamentos"
                      label="Estado (*)"
                      prepend-icon="location_on"
                      persistent-hint
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-select
                      v-model="user.ciudad"
                      item-value="id"
                      item-text="descripcion"
                      :items="ciudadesSelect"
                      label="Ciudad (*)"
                      prepend-icon="location_on"
                      persistent-hint
                      required
                      :disabled="user.departamento == null"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      label="Dirección (*)"
                      type="text"
                      minlength="8"
                      v-model="user.direccion"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-text-field
                      label="Teléfono (*)"
                      type="number"
                      v-model="user.telefono"
                      min="0"
                      minlength="10"
                      autocomplete="nope"
                      @blur="validarTelefono"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      label="Contraseña (*)"
                      type="password"
                      required
                      v-model="user.password"
                      autocomplete="nope"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      label="Confirmar contraseña (*)"
                      type="password"
                      required
                      v-model="user.c_password"
                      autocomplete="off"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 class="text-center">
                    <v-btn color="primary" type="submit" :disabled="!disabled">REGISTRARME</v-btn>
                  </v-flex>
                </v-layout>
              </form>
              <hr />
              <div class="text-center">
                <h5>¿Ya tienes una cuenta?</h5>
                <v-btn color="success" @click="showFormLogin"
                  >Iniciar Sesión</v-btn
                >
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              flat
              @click="closeModalRegisterAndLogin"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>
<script>
import axios from 'axios';
import { configApi } from '../api/configApi';
import { headers } from '../api/headers';
export default {
  async beforeCreate() {
    await this.$store.dispatch("user/listTipoIdentificaciones");
    await this.$store.dispatch("departamento/listDepartamentos");
    await this.$store.dispatch("ciudad/listCiudades");
  },
  created() {
    this.user.nombre = null;
    this.user.apellidos = null;
    this.user.email = null;
    this.user.password = null;
    this.user.c_password = null;
    this.user.identificacion = null;
    this.user.direccion = null;
    this.user.ciudad = null;
    this.user.departamento = null;
    this.user.telefono = null;
    this.user.activarmodalvalidacion=false;
  },
  data() {
    return {
      disabled: false,
      activeBoton: true,
      sum:0,
      userLogin: {
        email: null,
        password: null
      },
      user: {
        nombre: null,
        apellidos: null,
        email: null,
        password: null,
        c_password: null,
        identificacion: null,
        tipo_identificacion: 1,
        direccion: null,
        ciudad: null,
        departamento: null,
        telefono: null,
        id_rol: 2,
        codigoverificacion:"",
        activarmodalvalidacion:false,
        codigoenviado:"",
      },
      fieldValues: ['', '', '', ''],
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electrónico incorrecta",
      ],
    };
  },
  methods: {
    async validarTelefono(event){
        const numtelefono = this.user.telefono;
        if(numtelefono.length < 10){
          swal("El Número de teléfono debe tener 10 digitos", '', "warning"); 
          this.disabled = false
        }else{
          this.disabled = true
        }
    },
  	checkKey(opcion,event) {
      const valueToPreserve = event.key; 
      const keyCode = event.keyCode || event.which;
           event.preventDefault(); 
      if ( (keyCode >= 96 && keyCode <= 105) || (keyCode >= 48 && keyCode <= 57)  ){ 
           this.fieldValues[opcion-1] = valueToPreserve; 
            if(opcion<5){
            this.$refs['field' + opcion].focus(); 
            } 
      }else{
        this.fieldValues[opcion-1] = ''; 
      }
    },
    async validarCodigo(event){
      const codigo =this.fieldValues[0]+ this.fieldValues[1]+ this.fieldValues[2]+ this.fieldValues[3];
      if( codigo.length>1){
            if( this.user.codigoenviado==codigo){
                this.user.activarmodalvalidacion=false;
                this.disabled = true
                this.user.codigoverificacion = ''
                this.fieldValues = []
                swal("Correo Verificado Exitosamente - Puedes continuar realizando el registro", '', "success");
            }else{
              swal("Error", "Debes ingresar un código corrrecto - si no te llego el código, rectifica el correo si esta bien escrito", "error");
            }
      }else{
          swal("Error", "Debes ingresar un código corrrecto ", "error");
      }

    },
    async validarCorreo(event){
        const correo = this.user.email;
        this.user.activarmodalvalidacion=false;
        if( correo.length>1){
            axios.post(`${configApi.apiUrl}enviar_codigo_confirmacion`, this.user).then((res)=>{
              if(res.status == 200){
                this.user.activarmodalvalidacion=true;
                this.user.codigoenviado=  res.data.success.codigo_confirmacion;
              }else{
                swal("Correo Electrónico Duplicado", res.data.error, "warning"); 
              }
            });
        }else{
            swal("Campo Vacío", "Debe ingresar un correo válido", "warning");  
        }
    },
    async validarNumeroId(event) {
      const documento = this.user.identificacion;
      this.user.activarmodalvalidacion=false;
      if( documento.length>1){
          axios.post(`${configApi.apiUrl}validar_documento_usuario`, this.user).then((res)=>{
            if(res.status == 200){
              return;
            }else{
              swal("Número de Identificación Duplicado", res.data.error, "warning"); 
            }
          });
      }else{
          swal("Campo Vacío", "Debe ingresar un número de identificación", "warning");  
      }
    },
    doRegister() {
      if (
        this.user.nombre != null &&
        this.user.apellidos != null &&
        this.user.tipo_identificacion != null &&
        this.user.identificacion != null &&
        this.user.email != null &&
        this.user.direccion != null &&
        this.user.departamento != null &&
        this.user.ciudad != null
      ) {
        if (this.user.password == this.user.c_password) {                    
          
          this.userLogin.email = this.user.email
          this.userLogin.password = this.user.password

          if(this.$store.dispatch("doRegister", this.user)) {

            setTimeout(() => {
              this.$store.dispatch("doLogin", this.userLogin);          
            }, "3000");
            
          }          
          
        } else {
          swal("Error!", "Las contraseñas deben ser iguales!", "error");
        }
      } else {
        swal(
          "Error!",
          "Debe completar todos los campos obligatorios!",
          "error"
        );
      }
    },
    showFormLogin() {
      this.$store.state.modalRegister = false;
      this.$store.state.modalLogin = true;
    },
    closeModalRegisterAndLogin() {
      this.$store.state.modalRegister = false;
      this.$store.state.modalLogin = false;
    },
  },
  computed: {
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.user.tipo_identificacion
      )[0] ? this.tipoIdentificaciones.filter(
        (item) => item.id === this.user.tipo_identificacion
      )[0].descripcion : '';
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.user.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
  },
};
</script>
<style>
#validarcodigo input {
  text-align: center;     
}

.no-bottom-line .v-input__slot:before,
.no-bottom-line .v-input__slot:after {
  display: none !important; 
}

.no-bottom-line .v-text-field__slot input {
  border-bottom: none !important;
  box-shadow: none !important;  
}
</style>