<template>
  <div>
    <div v-if="$store.state.loader" id="loader">
      <div class="loader">
        <GridLoader :color="color" :size="size" :margin="margin" :radius="radius" width="2px" height="10px"></GridLoader>
      </div>
    </div>
    <MenuPublic></MenuPublic>
    <transition appear name="fade">
      <router-view style="min-height: calc(100vh - 170px)"></router-view>
    </transition>
    <Login v-if="!$store.state.user.accessToken && $store.state.modalLogin"></Login>
    <Register v-if="!$store.state.user.accessToken  && $store.state.modalRegister"></Register>
    <Factura v-if="$store.state.user.accessToken != null && $store.state.modalFactura"></Factura>
    <Footer></Footer>
    <!-- <whatsapp-icon/> -->
  </div>
</template>

<script>
import WhatsappIcon from './components/Templates/WhatsappIcon.vue';
import MenuPublic from './components/fragments/MenuPublic.vue';
import GridLoader from 'vue-spinner/src/GridLoader.vue';
import Footer from './components/fragments/Footer.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Factura from './components/utils/Factura.vue';

export default { 
  mounted(){
    var self = this;
    $("#app").ready(function(){
        self.$store.commit('changeLoader', false);
    });
  },
  components: {
    GridLoader,
    MenuPublic,
    Footer,
    Login,
    Register,
    Factura,
    WhatsappIcon,
  },
  data(){
    return{
        color: '#ffcb30',
          size: '30px',
          margin: '5px',
          radius: '100%',
    }
  },

};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
div#loader {
   position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999999999999;
    text-align: center;
    background-color: rgba(236, 236, 236, 0.541);
    top: 0;
}
#loader .loader{
      display: -webkit-inline-box;
    padding-top: 20%;
}
/*.v-table__overflow {
    padding: 0px 30px;
    max-height: 300px !important;
    overflow: scroll !important;
}*/
.application--wrap {
    min-height: 100% !important;
}
*{
  font-family: Raleway,sans-serif;
}
body{
      background-color: #ececec !important;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 7px !important;
    padding-left: 7px !important;
}
.application {
    font-family: inherit !important;
}
.cont-flotante{
  box-shadow: 0px 0px 10px #dedede;
    border-radius: 5px;
}
.v-btn--floating .v-icon {
    height: auto !important;
}
a:hover {
    color: inherit !important;
    text-decoration: none !important;
}
.theme--light.v-label {
    color: rgb(0 0 0) !important;
}
.v-application--wrap{
  min-height: auto !important;
}
.area_mapeadaAuditorio2 img{
  display: block;
}
</style>
