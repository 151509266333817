<template>
    <div class="container-evento">
        <br>
        <div class="card" style="width: 100%;">
            <div class="card-body">
                <h5 class="card-title">Pago Móvil C2P</h5>
                <p class="card-text">Por favor complete los campos requeridos (*)</p>

                <form @change="datos_c2p">

                    <div class='d-flex'>      
                        <div class='col-md-5 mb-3 me-3'>
                            <label for='telefono' class='form-label'>Número afiliado a pago móvil (*)</label>
                            <input type='number' min="0" class='form-control' id='telefono' v-model="data_c2p.telefono_beneficiario" required>
                        </div>                                                         
                        <div class='col-md-3 mb-3 me-3'>
                            <label for='cedula' class='form-label'>Tipo Documento (*)</label>
                                <select class="form-control" v-model="data_c2p.tipo_cedula" style="text-align: center" required>
                                    <option value="V" selected>V</option>
                                    <option value="E">E</option>
                                    <option value="P">P</option>                                    
                                </select>
                        </div>                        
                        <div class='col-md-4 mb-3 me-3'>
                            <label for='cedula' class='form-label'>Documento de identidad (*)</label>
                            <input type='number' min="0" class='form-control' id='cedula' v-model="data_c2p.cedula" required>
                        </div>
                    </div>
                    
                    <div class='d-flex'> 
                        <div class='col-md-12 mb-3'>
                            <label for='banco' class='form-label'>Banco (*)</label>
                            <select class="form-control" v-model="data_c2p.banco_beneficiario" required>
                                <option v-for="banco in bancoseleccion" v-bind:key="banco.id" :value="banco.Code">
                                {{ banco.Name }}
                                </option>
                            </select>
                        </div> 
                    </div>
                    <a class="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Ingresar Código C2P
                    </a>  
                    <br><br>
                    <div class="d-flex">
                        
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body text-justify">
                                Ingrese la clave o código de pago móvil C2P emitido por su Banco. <br><br>
                                <b>¿Cómo recibir su clave C2P en BNC?</b> <br>
                                Ingrese a la banca en línea del BNC, opción "Generador de Claves C2P". El banco le suministrará 5 claves. Introduzca una de ellas.
                                <div class='col-md-6 mb-3 me-2'>
                                    <label for='token' class='form-label'>Clave de Pago C2P / Token (*)</label>
                                    <input type='text' class='form-control' id='token' v-model="data_c2p.token" required>
                                </div> 
                            </div>                              
                        </div>
                                              
                    </div>

                </form>
                
            </div>
        </div>
        
         <div class="row row-clearfix">
               <div class="col-md-12 col-lg-12 col-sm-12">
                    
                    <div class='m-3 col-md-12'>

                        

                    </div>      

                </div>
         </div>
    </div>

</template>
<script>

import VCreditCard from 'v-credit-card';
import 'v-credit-card/dist/VCreditCard.css';

import moment from "moment";

import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/themes/material_blue.css'; 
moment.locale("es");

export default {
components: {
 VCreditCard,
},
data() {
 return {
    data_c2p: {
        telefono_beneficiario: '',
        cedula: '',
        banco_beneficiario: '0191',
        token: '',
        tipo_cedula: 'V'
    },
    bancos: [
        {
            "Name":"Banco Nacional de Crédito, C.A. Banco Universal",
            "Code":"0191",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Industrial de Venezuela",
            "Code":"0003",
            "Services":""
        },
        {
            "Name":"Banco Bicentenario (BanfoAndes)",
            "Code":"0007",
            "Services":"P2P"
        },
        {
            "Name":"Banco Guayana",
            "Code":"0008",
            "Services":"TRF"
        },
        {
            "Name":"Banco de Venezuela",
            "Code":"0102",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Venezolano de Crédito",
            "Code":"0104",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Mercantil",
            "Code":"0105",
            "Services":"TRF, P2P"
        },
        {
            "Name":"BBVA Banco Provincial",
            "Code":"0108",
            "Services":"TRF, P2P"
        },
        {
            "Name":"BanCaribe",
            "Code":"0114",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Exterior",
            "Code":"0115",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Occidental de Descuento (BOD)",
            "Code":"0116",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Caroní",
            "Code":"0128",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banesco",
            "Code":"0134",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Sofitasa",
            "Code":"0137",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Plaza",
            "Code":"0138",
            "Services":"TRF, P2P"
        },
        {
            "Name":"BanGente",
            "Code":"0146",
            "Services":"TRF"
        },
        {
            "Name":"Banco Fondo Común (BFC)",
            "Code":"0151",
            "Services":"TRF, P2P"
        },
        {
            "Name":"100% Banco",
            "Code":"0156",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Del Sur",
            "Code":"0157",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco del Tesoro",
            "Code":"0163",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Agrícola de Venezuela",
            "Code":"0166",
            "Services":"TRF, P2P"
        },
        {
            "Name":"BanCrecer, Banco de Desarrolo",
            "Code":"0168",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Mi banco, Banco Microfinanciero, C.A.",
            "Code":"0169",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Activo",
            "Code":"0171",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Bancamiga Banco Universal, C.A.",
            "Code":"0172",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Internacional de Desarrollo",
            "Code":"0173",
            "Services":"TRF"
        },
        {
            "Name":"BanPlus, Banco Comercial",
            "Code":"0174",
            "Services":"TRF, P2P"
        },
        {
            "Name":"Banco Bicentenario, del Pueblo, de la Clase Obrera, Mujer y Comunas, Banco Universal, C.A.",
            "Code":"0175",
            "Services":"TRF"
        },
        {
            "Name":"Novo Banco, S.A. Sucrusal Venezuela, Banco Universal",
            "Code":"0176",
            "Services":"TRF"
        },
        {
            "Name":"Banco de las Fuerzas Armadas",
            "Code":"0177",
            "Services":"TRF, P2P"
        },
        {
            "Name":"CitiBank",
            "Code":"0190",
            "Services":"TRF"
        },
        {
            "Name":"ABN-AMRO Bank",
            "Code":"0196",
            "Services":"TRF"
        },
        {
            "Name":"Instituto Municipal de Crédito Popular",
            "Code":"0601",
            "Services":"TRF"
        }
    ],
    bancoseleccion: [],
    valido : false
 };
},
watch: {
    'data_c2p.telefono_beneficiario'(value, oldvalue){
        if(this.data_c2p.telefono_beneficiario){
            this.validar()
        }
    },
    'data_c2p.cedula'(value, oldvalue){
        if(this.data_c2p.cedula){
            this.validar()
        }
    },
    'data_c2p.banco_beneficiario'(value, oldvalue){
        if(this.data_c2p.banco_beneficiario){
            this.validar()
        }
    },
    'data_c2p.token'(value, oldvalue){
        if(this.data_c2p.token){
            this.validar()
        }
    },
    
 
},
async mounted() {
    for (let i=0; i< this.bancos.length; i++)
    {
        var prueba = this.bancos[i].Services.split(", ")
        if(prueba[0] == 'P2P' || prueba[1] == 'P2P')
            this.bancoseleccion.push(this.bancos[i])

    }
},
beforeUpdate() {
 
},
updated() {
 
},
methods: {
    datos_c2p() { 
        this.$emit('datos_c2p', this.data_c2p)
    },
    validar(){
        this.valido = false

        if(this.data_c2p.telefono_beneficiario != "" && this.data_c2p.cedula != "" && this.data_c2p.banco_beneficiario != "" && this.data_c2p.token != "" ){
            this.valido = true
        }
        this.$emit("resultado", this.valido);
    }
},
};
</script>
<style>
h6,
li,
p {
font-size: 0.875rem;
}
.cont-desc-evento {
background-color: white;
text-align: center;
padding: 15px;
box-shadow: 0px 0px 10px #eaeaea;
border-radius: 4px;
border-top: 5px #c7a923 solid;
height: 100%;
}

.cont-mapa-asientos {
background-color: white;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
text-align: center;
box-shadow: 0px 0px 10px #dedede;
border-radius: 5px;
padding: 10px;
min-height: 700px;
background-size: contain;
}

@media (max-width: 1480px) {
.cont-mapa-asientos {
 min-height: 600px;
}
}
@media (max-width: 768px) {
.cont-mapa-asientos {
 min-height: 400px;
}
.container-tab {
 padding-right: 1rem !important;
 padding-left: 1rem !important;
}
}
.cont-mapa-asientos .sub {
padding: 0px;
}

.cont-preventa-evento {
background-color: white;
box-shadow: 0px 0px 10px #dedede;
border-radius: 5px;
}

.heading-cont-preventa-evento {
background-color: #1976d2;
color: white;
padding: 10px;
text-align: center;
margin-bottom: 0px;
}

.sub-cont-preventa-evento {
border: 1px #c5c5c5 solid;
}

.cont-descuento-evento {
position: absolute;
text-align: center;
top: 60px;
background-color: red;
color: white;
padding: 10px;
right: 1rem;
z-index: 2;
border-radius: 5px;
box-shadow: 2px 2px 3px 2px #a0a0a0;
}

.container-tab {
padding-left: 30px;
padding-right: 30px;
}

.cont-terminos-condiciones {
border: 1px #bfbfbf solid;
padding: 10px;
max-height: 235px;
overflow-y: scroll;
background-color: white;
}

.cont-evento {
background-color: white;
border: 1px gray solid;
text-align: right;
aspect-ratio: 5/6;
width: 100%;
margin-top: 40px;
margin-bottom: 20px;
}
.cont-fecha {
/*padding:10px;*/
}

.cont-fecha-activo {
background-color: green;
position: relative;
right: -12px;
padding: 4px 9px;
color: white;
border-radius: 3px;
box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
background-color: #ffff00;
position: relative;
right: -12px;
padding: 4px 9px;
color: #000000;
border-radius: 3px;
box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-cancelado {
background-color: red;
position: relative;
right: -12px;
padding: 4px 9px;
color: #000000;
border-radius: 3px;
box-shadow: -1px 3px 4px 0px #000000;
}

.list-condiciones-evento li {
text-transform: capitalize;
}
.carousel {
height: auto !important;
}
.mt-50 {
margin-top: 50px;
}
.color-slide-table {
color: black !important;
margin-left: auto;
margin-right: auto;
}
.container-evento {
padding-bottom: 4rem;
}
.btn-finalizar-compra {
background-color: #1867c0 !important;
color: white !important;
}
.skeleton-banner-principal-evento{
height: 100%;
aspect-ratio: 880/448;
}
.img-skeleton .v-skeleton-loader__image{
height: 500px;
}
.skeleton-banner-principal-evento .v-skeleton-loader .v-skeleton-loader__image, .skeleton-banner-principal-evento .v-skeleton-loader{
height: 100%;
}
.tag-img-evento{
position: absolute;
display: block;
right: 0;
top: .4rem;
width: 170px;
z-index: 1;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
}
.container-modal-resumen{
background-color: white;
padding: 1.5rem 1rem;
}
@media (max-width: 768px) {
.container-modal-resumen{padding: 1rem .5rem;}
}


.custom-calendar .flatpickr-months {
background-color: #f0f0f0;
border-bottom: 1px solid #ddd;
width: 600px;
height: 40px;
}

.custom-calendar .flatpickr-days .flatpickr-day {
background-color: #fff;
color: #333;
}

.custom-calendar .flatpickr-days .flatpickr-day:hover {
background-color: #eee;
}

.custom-calendar .flatpickr-weekdays .flatpickr-weekday {
color: #999;
}

.custom-calendar .flatpickr-calendar.open {
border-color: #ccc;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.flatpickr-input {
display: none;
}
.disabled-date {
background-color: #696565;
color: #111010;
}
.custom-flatpickr {

}

.btn-active {
background-color: #1E0102 !important; 
color: white;
}


</style>