import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puntoventas: [],
       puntoventa: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
    eventosSinAsignar: [],
  },
  getters: {
    listPuntoventas: (state,getters,rootState) => {
          return state.puntoventas;
    },
    puntoventa: (state,getters,rootState) => {
        return state.puntoventa;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
    eventosSinAsignar(state,getters,rootState){
        return state.eventosSinAsignar;
    },
  },
  mutations: {
        listPuntoventas: (state,puntoventas) => {
          state.puntoventas = puntoventas;
          state.pagination.totalItems = puntoventas.total;
          state.pagination.page = puntoventas.current_page;
          state.pagination.rowsPerPage = puntoventas.per_page;
       },
      listPuntoventasSearch: (state,puntoventas) => {
            if(puntoventas != null){ 
                state.puntoventas = puntoventas;
            }else{
              state.puntoventas = null;
            }
       },
        listPuntoventasSearch: (state,puntoventas) => {
            if(puntoventas != null){ 
                state.puntoventas = puntoventas;
            }else{
              state.puntoventas = null;
            }
       },
      viewPuntoventa(state,id){
        let auxPuntoventa = state.puntoventas.filter((item) => item.id == id);
        state.puntoventa = auxPuntoventa[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
    eventosSinAsignar(state,eventosSinAsignar){
      state.eventosSinAsignar = eventosSinAsignar;
    },
    puntoventa(state,puntoventa){
      state.puntoventa = puntoventa;
    },
  },
  actions: {
    listPuntoventas({state,getters,commit,rootState}){
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}puntoventum_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuntoventas',response.data.data);
        commit('pages');
        state.loading = false;
        store.commit('changeLoader', false);
      })
      .catch(error => {
        state.loading = false;
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los puntos de venta!", "error");
        console.error(error);
      });

    },
  newPuntoventa({state,getters,commit,rootState},payload){
      // nombre_razon
      // identificacion
      // tipo_identificacion
      // direccion
      // telefono
      // responsable
      // zona
      // email
      // id_ciudad
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}puntoventum`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el punto de venta correctamente!", "success");
        store.dispatch('puntoventa/listPuntoventas',state.pagination.page);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!", "Error al crear el punto de venta!", "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
  },
  updatePuntoventa({state,getters,commit,rootState},payload){
      // nombre_razon
      // identificacion
      // tipo_identificacion
      // direccion
      // telefono
      // responsable
      // zona
      // email
      // id_ciudad
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puntoventum/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el punto de venta correctamente!", "success");
        store.dispatch('puntoventa/listPuntoventas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el punto de venta!", "error");
        console.error(error);
      });
  },
  deletePuntoventa({state,getters,commit,rootState},puntoventa){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puntoventum/${puntoventa}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el puntoventa correctamente!", "success");
        store.dispatch('puntoventa/listPuntoventas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el punto de venta!","error");
        //console.log(error);
     });
  },
    searchPuntoventas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPuntoventa?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPuntoventasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los punto de ventas!", "error");
        console.error(error);
      });

    },
      deleteEventoPorPuntoVenta({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}delete_punto_venta_x_evento`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "El evento ha sido removido del punto de venta con exito!", "success");
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!", "Error al remover el evento del punto de venta!", "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
  },
    eventosSinAsignar({state,getters,commit,rootState},id_puntoventa){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}eventos_sin_asignar/${id_puntoventa}`,null,{headers: headers})
      .then(response => {
       
        commit('eventosSinAsignar',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos sin asignar!", "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
  },
  eventosParaAsignar({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}eventos_para_asignar`,payload,{headers: headers})
      .then(response => {
       swal("Exito!", "La asignacion de eventos se realizo con exito!", "success");
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!", "Error al realizar la asignacion de eventos!", "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
  },
    eventosPuntoVenta({state,getters,commit,rootState},id_puntoventa){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}eventos_punto_venta/${id_puntoventa}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('puntoventa',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los eventos del punto de venta!", "error");
      });

    },
    newUsuarioPuntoVenta({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}nuevo_usuario_puntoventa`,payload,{headers: headers})
      .then(response => {
       swal("Exito!", "La creacion del punto de venta se realizo con exito!", "success");
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!", "Error al crear el punto de venta!", "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
  },
  },
}