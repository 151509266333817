import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headersMultipart} from '../../api/headers';
import {store} from '../index';

export default {
    namespaced: true,
	state: {
        //
	},
	getters: {
        //
	},
	mutations: {
        //
	},
	actions: {
        sendContact({state,getters,commit,rootState}, contacto){
            store.commit('changeLoader', true);

            axios.post(`${configApi.apiUrl}formulario_contacto`, contacto,{headers: headersMultipart})
                .then(response => {
                    store.commit('changeLoader', false);
                    swal('Los datos de contaco se enviaron correctamente', '', 'success')
                })
                .catch(error => {
                    let htmlErrors = ``;
                    let obj = error.response.data.errors;

                    for (const prop in obj) {
                        htmlErrors += `${obj[prop][0]}\n`;
                    }

                    if( error.response.data.status ) {
                        swal("Unauthenticated!", "", "error");
                    }

                    swal("Error!", htmlErrors , "error");
                    store.commit('changeLoader', false);
                });
        },
	}
}