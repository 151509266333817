export const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
 //   'Authorization': `Bearer  ${localStorage.getItem('accessTokenTicketClient')}`
};

export const headersMultipart = {
	"Accept": "application/json",
    "Content-Type" : "multipart/form-data"
//    'Authorization': `Bearer  ${localStorage.getItem('accessTokenTicketClient')}`
};