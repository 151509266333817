import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       auditorio_mapeado_tribunas: [],
       auditorio_mapeado_tribuna: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listAuditorio_mapeado_tribunas: (state,getters,rootState) => {
          return state.auditorio_mapeado_tribunas;
    },
    auditorio_mapeado_tribuna: (state,getters,rootState) => {
        return state.auditorio_mapeado_tribuna;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listAuditorio_mapeado_tribunas: (state,auditorio_mapeado_tribunas) => {
          state.auditorio_mapeado_tribunas = auditorio_mapeado_tribunas;
          state.pagination.totalItems = auditorio_mapeado_tribunas.total;
          state.pagination.page = auditorio_mapeado_tribunas.current_page;
          state.pagination.rowsPerPage = auditorio_mapeado_tribunas.per_page;
       },
      listAuditorio_mapeado_tribunasSearch: (state,auditorio_mapeado_tribunas) => {
            if(auditorio_mapeado_tribunas != null){ 
                state.auditorio_mapeado_tribunas = auditorio_mapeado_tribunas;
            }else{
              state.auditorio_mapeado_tribunas = null;
            }
       },
      viewAuditorio_mapeado_tribuna(state,id){
        let auxAuditorio_mapeado_tribuna = state.auditorio_mapeado_tribunas.filter((item) => item.id == id);
        state.auditorio_mapeado_tribuna = auxAuditorio_mapeado_tribuna[0];
      },
      setAuditorio_mapeado_tribuna(state,auditorio_mapeado_tribuna){
          state.auditorio_mapeado_tribuna = auditorio_mapeado_tribuna;
      },
      clearAuditorio_mapeado_tribuna(state){
        state.auditorio_mapeado_tribuna = null;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listAuditorio_mapeado_tribunas({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}auditorios_map_tribuna_all`,null,{headers: headers})
      .then(response => {
        commit('listAuditorio_mapeado_tribunas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los auditorio_mapeado_tribunas!", "error");
        console.error(error);
      });

    },
  newAuditorio_mapeado_tribuna({state,getters,commit,rootState},payload){
      // id_auditorio_mapeado
      // id_tribuna
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}auditorio_map_tribuna`,payload,{headers: headers})
      .then(response => {
        store.dispatch('auditorio_mapeado_tribuna/listAuditorio_mapeado_tribunas',state.pagination.page);
        commit('setAuditorio_mapeado_tribuna',response.data.data);
        /*swal("Exito!", "Se creo el auditorio_mapeado_tribuna correctamente!", "success");*/
      })
      .catch(error => {
        swal("Error!", "Error al crear el auditorio_mapeado_tribuna!", "error");
        console.error(error);
      });
  },
  updateAuditorio_mapeado_tribuna({state,getters,commit,rootState},payload){
      // id_auditorio_mapeado
      // id_tribuna
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}auditorio_map_tribuna/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el auditorio_mapeado_tribuna correctamente!", "success");
        store.dispatch('auditorio_mapeado_tribuna/listAuditorio_mapeado_tribunas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el auditorio_mapeado_tribuna!", "error");
        console.error(error);
      });
  },
  deleteAuditorio_mapeado_tribuna({state,getters,commit,rootState},auditorio_mapeado_tribuna){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}auditorio_mapeado_tribuna/${auditorio_mapeado_tribuna}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el auditorio_mapeado_tribuna correctamente!", "success");
        store.dispatch('auditorio_mapeado_tribuna/listAuditorio_mapeado_tribunas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el auditorio_mapeado_tribuna!","error");
     });
  },
    searchAuditorio_mapeado_tribunas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarAuditorio_mapeado_tribunas?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listAuditorio_mapeado_tribunasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los auditorio_mapeado_tribunas!", "error");
        console.error(error);
      });

    },
  },
}