<template>
  <v-app>
    <br />
    <br />
    <div v-if="tokenResetPassword" class="animated fadeInDown">
      <v-container grid-list-md class="col-md-4 col-md-offset-6">
        <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <img
            src="/images/logo-ticketshop.png"
            style="max-width: 150px; width: 150px"
          />
        </div>
        <br />
        <br />
        <div class="text-center">
          <h4><i class="material-icons">refresh</i> Restablecer contraseña.</h4>
          <h6>Ingrese los siguientes datos para continuar.</h6>
        </div>
        <form @submit.prevent="resetPasswordToken" autocomplete="off">
          <v-layout wrap>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                label="Correo electronico*"
                required
                v-model="security.email"
                :rules="emailRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                label="Contraseña"
                type="password"
                required
                v-model="security.password_new"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12>
              <v-text-field
                label="Confirmar contraseña"
                type="password"
                required
                v-model="security.password_confirmation"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 class="text-center">
              <v-btn color="primary" type="submit"
                >Restablecer contraseña</v-btn
              >
            </v-flex>
          </v-layout>
        </form>
        <br />
        <hr />
        <div class="text-center">
          <v-btn color="success" @click="$store.state.modalLogin = true"
            >Iniciar Sesión</v-btn
          >
        </div>
      </v-container>
    </div>
    <div
      v-if="tokenResetPassword != null && !tokenResetPassword"
      class="animated fadeInUp"
    >
      <v-container grid-list-md>
        <v-alert :value="true" type="error">
          <h2>
            Lo sentimos, no puedes restablecer tu contraseña... Parece que el
            token caducó o es incorrecto.
          </h2>
        </v-alert>
        <br />
        <br />
        <div class="text-center">
          <v-btn large @click="$router.push('/')">Ir al inicio</v-btn>
        </div>
      </v-container>
    </div>
  </v-app>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //vm.$store.dispatch('fetchAccessToken');
      if (vm.$store.state.user.accessToken != null) {
        next("/");
      }
    });
  },
  beforeCreate() {
    this.$store.dispatch("findTokenPasswordReset", this.$route.params.token);
  },
  created() {
    document.title = "Restablecer contraseña - TicketShop";
  },
  data() {
    return {
      security: {
        email: null,
        password_new: null,
        password_confirmation: null,
        token: this.$route.params.token,
      },
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electronico incorrecta",
      ],
    };
  },
  methods: {
    resetPasswordToken() {
      if (this.security.password_new == this.security.password_confirmation) {
        this.$store.dispatch("resetPasswordToken", this.security);
      } else {
        swal("Error!", "Las contraseñas deben ser iguales!", "error");
      }
    },
  },
  computed: {
    tokenResetPassword() {
      return this.$store.getters.tokenResetPassword;
    },
  },
};
</script>