<template>
  <v-app>
    <v-container>
      <h3>Politica y Tratamiento de Datos</h3>
      <div class="row row-clearfix">
        <div class="col-md col-lg col-sm">
          <p>
            En cumplimiento a lo dispuesto en la Ley estatutaria 1581 de 2012 y
            a su Decreto Reglamentario 1377 de 2013,
            <span>COMERCIALIZADORA DE FRANQUICIAS S.A.</span> informa la
            política aplicable a la entidad para el tratamiento de datos
            personales
            <span
              ><a
                href="https://ticketshop.com.co/site/politicotratamiento-de-datos/"
                >AQUÍ</a
              ></span
            >
          </p>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "politicatratamiento",
};
</script>