import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       filas: [],
       fila: null,
       filaslocalidad: [],
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listFilas: (state,getters,rootState) => {
          return state.filas;
    },
    listFilasLocalidad: (state,getters,rootState) => {
          return state.filaslocalidad;
    },
    fila: (state,getters,rootState) => {
        return state.fila;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listFilas: (state,filas) => {
          state.filas = filas;
          state.pagination.totalItems = filas.total;
          state.pagination.page = filas.current_page;
          state.pagination.rowsPerPage = filas.per_page;
       },
       listFilasLocalidad: (state,filaslocalidad) => {
          state.filaslocalidad = filaslocalidad;
       },
      listFilasSearch: (state,filas) => {
            if(filas != null){ 
                state.filas = filas;
            }else{
              state.filas = null;
            }
       },
      viewFila(state,id){
        let auxFila = state.filas.filter((item) => item.id == id);
        state.fila = auxFila[0];
      },
      setFila(state,fila){
          state.fila = fila;
      },
      clearFila(state){
        state.fila = null;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listFilas({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}fila_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listFilas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las filas!", "error");
        console.error(error);
      });

    },
    listFilasLocalidad({state,getters,commit,rootState},key){
      state.loading = true;
      store.commit('changeLoader', true);
      return axios.get(`${configApi.apiUrl}filas_localidad/${key}`,null,{headers: headers})
      .then(response => {
        store.state.filalocalidad = response.data.data
        commit('listFilasLocalidad',response.data.data);
        state.loading = false;
        store.commit('changeLoader', false);
      })
      .catch(error => {
        state.loading = false;
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener las localidades filas!", "error");
          console.error(error);
          store.commit('changeLoader', false);
        }
      });

    },
  newFila({state,getters,commit,rootState},payload){
      // id_localidad
      // nombre
      // numero
      // alineacion
      // orientacion
      //store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}fila`,payload,{headers: headers})
      .then(response => {
        /*store.dispatch('fila/listFilas',state.pagination.page);*/
        commit('setFila',response.data.data);
        /*swal("Exito!", "Se creo la fila correctamente!", "success");*/
      })
      .catch(error => {
        swal("Error!", "Error al crear la fila!", "error");
        console.error(error);
      });
  },
  newPuesto({state,getters,commit,rootState},payload){
      // id_localidad
      // numero
      // id_fila
      //store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}puesto`,payload,{headers: headers})
      .then(response => {
        /*swal("Exito!", "Se creo el fila correctamente!", "success");*/
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto!", "error");
        console.error(error);
      });
  },
  updateFila({state,getters,commit,rootState},payload){
      // id_localidad
      // nombre
      // numero
      // alineacion
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}fila/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la fila correctamente!", "success");
        store.dispatch('fila/listFilas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la fila!", "error");
        console.error(error);
      });
  },
  deleteFila({state,getters,commit,rootState},fila){
    store.dispatch('validateToken');
    return axios.delete(`${configApi.apiUrl}fila/${fila}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la fila correctamente!", "success");
        /*store.dispatch('fila/listFilas',state.pagination.page);*/
     })
     .catch(error => {
        swal("Error!","Error al eliminar la fila!","error");
        //console.log(error);
        return error;
     });
  },
    searchFilas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarFilas?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listFilasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las filas!", "error");
        console.error(error);
      });

    },
  },
}