<template>
  <v-app>
    <v-container>      
      <div class="row row-clearfix" style="background: #ƒff">
        <div class="col-md col-lg col-sm">
              <img :src="'/images/banner_marco.png'" :title="'MARCO ANTONIO SOLIS'" class="d-none d-md-flex w-100 pr-1">        
              <img :src="'/images/banner_marco.png'" :title="'MARCO ANTONIO SOLIS'" class="d-flex d-md-none w-100">          
              
            <Eventos
                :precioInicio="rangoPrecio[0]"
                :precioFin="rangoPrecio[1]"
                :artistasFilter="artistasFilter"
                :tiposEventosFilter="tiposEventosFilter"
                :fechaInicio="desde"
                :fechaFin="hasta"
            ></Eventos>

        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>

import Eventos from "./utils/Eventos.vue";

export default {
  name: "mision",
  components: {
    Eventos
  },
  data() {
    return {
        precioInicial: 1000,
        precioFinal: 10000000,
        rangoPrecio: [10000, 15000000],
        artistasFilter: [339],
        tiposEventosFilter: [],
        desde: null,
        hasta: null
    }
  },
  mounted(){
    this.filterEventos()
  },
  methods: {
    // filterEventos() {
    //   this.$store.dispatch("evento/filterEventos", {
    //     page: 1,
    //     precio_inicio: 10000,
    //     precio_fin: 10000000,
    //     artistas: [230],
    //     tiposEvento: [],
    //     fecha_inicio: null,
    //     fecha_fin: null,
    //   });
    // },
    filterEventos() {
        this.$store.dispatch("evento/filterEventos", {
        page: 1,
        precio_inicio: this.rangoPrecio[0],
        precio_fin: this.rangoPrecio[1],
        artistas: this.artistasFilter,
        tiposEvento: this.tiposEventosFilter,
        fecha_inicio: this.desde,
        fecha_fin: this.hasta,
        });
    },
  }
};
</script>