<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>MI PERFIL</h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: #1976d2 !important"
                :to="{ path: '/' }"
                title="Inicio"
                ><u>Inicio</u></router-link
              ></span
            ><span> > </span><span>Mi perfil</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <template>
        <v-card>
          <v-layout row wrap>
            <v-flex lg3 md3 sm4 xs12>
              <v-card class="no-shadow card-menu-dat-perfil">
                <v-navigation-drawer v-model="drawer" permanent absolute>
                  <v-toolbar flat class="transparent">
                    <v-list class="d-flex aling-items-center">
                      <div class="pr-3">
                        <v-icon large color="info">person_pin</v-icon>
                      </div>
                      <p class="my-auto">{{ user.nombre }}</p>
                    </v-list>
                  </v-toolbar>

                  <v-list class="pt-0" dense>
                    <v-divider></v-divider>

                    <div
                      class="item-list-menu-perfil"
                      v-for="item in items"
                      :key="item.title"
                      @click="optionSelect(item.option)"
                      :class="{ optionSelect: item.option == optionPerfil }"
                    >
                      <div class="pr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                      </div>
                      <p class="my-auto">{{ item.title }}</p>
                    </div>
                    <v-divider></v-divider>
                    <div @click="logout" class="item-list-menu-perfil">
                      <div class="pr-3">
                        <v-icon color="red">power_settings_new</v-icon>
                      </div>
                      <p class="my-auto">CERRAR SESION</p>
                    </div>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
            </v-flex>
            <v-flex lg9 md9 sm8 xs12>
              <template v-if="optionPerfil === 1">
                <v-container grid-list-md>
                  <form @submit.prevent="updateProfile" autocomplete="off">
                    <h3><v-icon>person</v-icon> INFORMACION PERSONAL</h3>
                    <p class="mb-0 mt-0">
                      <v-icon small color="warning">info</v-icon> Los campos
                      marcados con <b>*</b> son obligatorios.
                    </p>
                    <hr />
                    <v-layout wrap>
                      <v-flex xs12 lg6 md4 sm6 mb-3>
                        <v-text-field
                          label="Nombres (*)"
                          required
                          v-model="user.nombre"
                          prepend-icon="person"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 lg6 md4 sm6 mb-3>
                        <v-text-field
                          label="Apellidos (*)"
                          required
                          v-model="user.apellidos"
                          prepend-icon="person"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 lg4 md4 sm4 mb-3>
                        <v-select
                          v-model="user.tipo_identificacion"
                          item-value="id"
                          item-text="code"
                          :items="tipoIdentificaciones"
                          label="Tipo de identificacion (*)"
                          prepend-icon="person"
                          persistent-hint
                          :hint="descripcionTipoIdentificacion"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 lg8 md8 sm8 mb-3>
                        <v-text-field
                          label="Numero de identificacion (*)"
                          required
                          v-model="user.identificacion"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 mb-3>
                        <v-text-field
                          label="Correo electronico"
                          type="email"
                          readonly
                          v-model="user.email"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6 lg6 mb-3>
                        <v-select
                          v-if="show_list_departamentos"
                          v-model="user.departamento"
                          item-value="id"
                          item-text="descripcion"
                          :items="departamentos"
                          label="Departamento (*)"
                          prepend-icon="location_on"
                          persistent-hint
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 md6 lg6 mb-3>
                        <v-select
                          v-if="show_list_ciudades"
                          v-model="user.ciudad"
                          item-value="id"
                          item-text="descripcion"
                          :items="ciudadesSelect"
                          label="Ciudad (*)"
                          prepend-icon="location_on"
                          persistent-hint
                          required
                          :disabled="user.departamento == null"
                          no-data-text="Selecciona un departamento"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 md6 lg6 mb-3>
                        <v-text-field
                          label="Direccion (*)"
                          v-model="user.direccion"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6 lg6 mb-3>
                        <v-text-field
                          label="Telefono"
                          type="tel"
                          v-model="user.telefono"
                          autocomplete="nope"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 class="text-right">
                        <v-btn color="primary" type="submit"
                          >ACTUALIZAR MIS DATOS</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </form>
                </v-container>
              </template>
              <template v-if="optionPerfil === 2">
                <v-container grid-list-md>
                  <form @submit.prevent="updatePassword" autocomplete="off">
                    <h3>
                      <v-icon>security</v-icon> ACTUALIZACION DE CONTRASEÑA
                    </h3>
                    <p class="mb-0 mt-0">
                      <v-icon small color="warning">info</v-icon> Los campos
                      marcados con <b>*</b> son obligatorios.
                    </p>
                    <v-divider></v-divider>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          label="Contraseña actual (*)"
                          type="password"
                          required
                          v-model="user.security.mypassword"
                          autocomplete="nope"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          label="Nueva Contraseña (*)"
                          type="password"
                          required
                          v-model="user.security.password"
                          autocomplete="nope"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-text-field
                          label="Confirmar contraseña (*)"
                          type="password"
                          required
                          v-model="user.security.c_password"
                          autocomplete="off"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 class="text-right">
                        <v-btn color="primary" type="submit"
                          >ACTUALIZAR CONTRASEÑA</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </form>
                </v-container>
              </template>
              <template v-if="optionPerfil === 3">
                <v-container grid-list-md>
                  <h3><v-icon>shopping_cart</v-icon> MIS COMPRAS</h3>
                  <v-divider></v-divider>
                  <p class="mb-0 mt-0">
                    <v-icon small color="warning">info</v-icon> Filtrar compras
                    en un rango de fechas.
                  </p>
                  <v-layout row wrap>
                    <v-flex lg4 md4 sm4 xs12 mb-3>
                      <v-dialog
                        ref="dialogDesde"
                        v-model="modalDesde"
                        :return-value.sync="desde"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="desde"
                            label="Desde"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="desde"
                          scrollable
                          locale="es-ES"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            flat
                            color="primary"
                            @click="modalDesde = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            flat
                            color="primary"
                            @click="$refs.dialogDesde.save(desde)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg4 md4 sm4 xs12 mb-3>
                      <v-dialog
                        ref="dialogHasta"
                        v-model="modalHasta"
                        :return-value.sync="hasta"
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="hasta"
                            label="Hasta"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="hasta"
                          scrollable
                          locale="es-ES"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            flat
                            color="primary"
                            @click="modalHasta = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            flat
                            color="primary"
                            @click="$refs.dialogHasta.save(hasta)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg4 md4 sm4 xs12 mb-3>
                      <v-btn color="info" @click="searchHistorialCompras"
                        ><v-icon>search</v-icon> BUSCAR</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-data-table
                    :headers="headers"
                    must-sort
                    :items="compras"
                    :length="pages"
                    :search="search"
                    :server-items-length="totalItemCount"
                    class="elevation-1 table-ventas"
                    :loading="loading"
                    :no-data-text="'No se han encontrado compras...'"
                  >
                    <template v-slot:items="props">
                      <td class="font-weight-bold">#{{ props.item.id }}</td>
                      <td class="text-xs-center">
                        {{ formatFechaHumans(props.item.fecha) }}<br /><small>{{
                          props.item.fecha
                        }}</small>
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.tipo_venta }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.pago_parcial }}
                      </td>
                      <!--<td class="text-xs-center">{{ props.item.evento.nombre }}</td>-->
                      <td class="text-xs-center text-uppercase">
                        {{ props.item.forma_pago }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.moneda.simbolo
                        }}{{ formatoMoneda(props.item.total) }} ({{
                          props.item.moneda.codigo_moneda
                        }})
                      </td>
                    </template>
                    <template v-slot:item.active="data">
                      <td class="text-xs-center">
                        <v-chip
                          v-if="data.item.active == 0"
                          class="ma-2"
                          color="orange"
                          text-color="white"
                        >
                          <v-avatar left>
                            <v-icon>access_time</v-icon>
                          </v-avatar>
                          Pendiente
                        </v-chip>
                        <v-chip
                          v-if="data.item.active == 1"
                          class="ma-2"
                          color="teal"
                          text-color="white"
                        >
                          <v-avatar left>
                            <v-icon>check_circle</v-icon>
                          </v-avatar>
                          Aceptada
                        </v-chip>
                        <v-chip
                          v-if="data.item.active == 2"
                          class="ma-2"
                          color="error"
                          text-color="white"
                        >
                          <v-avatar left>
                            <v-icon>cancel</v-icon>
                          </v-avatar>
                          Rechazada
                        </v-chip>
                      </td>
                    </template>
                    <template v-slot:item.reimprimir="{ item }">
                      
                      <v-chip
                        title="Descargar"
                        @click="ImprimirCompra(item.id)"
                        style="padding: 20px;"
                        color="success"
                        v-if="item.tipo_venta == 'VIRTUAL' && item.active == 1 && item.status_evento == 1 && item.se_imprime == 1 && (item.dias <=0 && item.dias != null) "
                      >
                      <v-icon left style="padding: 5px;">cloud_download</v-icon>
                       Descarga aquí <br> tus boletas
                      </v-chip>
                      <v-chip
                        class="ma-3"
                        size="x-large"
                        style="padding: 25px;"
                        v-if="item.tipo_venta == 'VIRTUAL' && item.active == 1 && item.status_evento == 1 && item.se_imprime == 0 && item.dias >=1"
                      >
                        <v-icon left style="padding: 5px;">info</v-icon>
                           En {{ item.dias }} días se habilitará <br>
                        la descarga de boletas
                      </v-chip>
                      <v-chip
                        class="ma-3"
                        size="x-large"
                        style="padding: 25px;"
                        v-if="item.tipo_venta == 'VIRTUAL' && item.active == 1 && item.status_evento != 1"
                      >
                        <v-icon left style="padding: 5px;">block</v-icon>
                           No Disponible 
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        title="Detalles de la compra"
                        @click="searchCompra(item.id)"
                      >
                        search
                      </v-icon>
                    </template>
                  </v-data-table>
                  <div class="text-xs-center pt-2">
                    <v-pagination
                      v-model="pagination.page"
                      :length="pages"
                    ></v-pagination>
                  </div>
                </v-container>
              </template>
              <template v-if="optionPerfil === 4">
                <v-container grid-list-md>
                  <h6 class="text-dark">
                    <a class="text-dark" @click.prevent="optionSelect(3)"
                      ><i class="material-icons">keyboard_backspace</i> Volver a
                      mis compras</a
                    >
                  </h6>
                  <v-divider></v-divider>
                  <v-layout row wrap>
                    <v-flex lg8 md8 sm6 xs12 mb-3 mt-3>
                      <h5>
                        <v-icon>info</v-icon>
                        <b class="text-underline"
                          >DETALLE DE LA COMPRA #{{ compra.n_factura }}</b
                        >
                      </h5>
                    </v-flex>
                    <v-flex lg4 md4 sm6 xs12 mb-3 text-center>
                      <v-chip
                        v-if="compra.active === 0"
                        class="ma-2"
                        color="orange"
                        text-color="white"
                      >
                        <v-avatar left>
                          <v-icon>access_time</v-icon>
                        </v-avatar>
                        Pendiente
                      </v-chip>
                      <v-chip
                        v-if="compra.active === 1"
                        class="ma-2"
                        color="teal"
                        text-color="white"
                      >
                        <v-avatar left>
                          <v-icon>check_circle</v-icon>
                        </v-avatar>
                        Aceptada
                      </v-chip>
                      <v-chip
                        v-if="compra.active === 2"
                        class="ma-2"
                        color="error"
                        text-color="white"
                      >
                        <v-avatar left>
                          <v-icon>cancel</v-icon>
                        </v-avatar>
                        Rechazada
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <br />
                  <h6><b>N° FACTURA: </b> {{ compra.n_factura }}.</h6>
                  <h6>
                    <b>FECHA: </b> {{ compra.fecha }}
                    <b>({{ formatFechaHumans(compra.fecha) }})</b>
                  </h6>
                  <h6>
                    <b>TIPO VENTA: </b>
                    <span v-if="compra.tipo_venta == 1">FISICA</span
                    ><span v-if="compra.tipo_venta == 0">VIRTUAL</span>
                  </h6>
                  <h6>
                    <b>TIPO PAGO: </b>
                    <span v-if="compra.pago_parcial == 1">PARCIAL</span
                    ><span v-if="compra.pago_parcial == 0">COMPLETO</span>
                  </h6>
                  <div v-if="compra.tipo_venta == 1">
                    <h6 class="text-uppercase">
                      <b>FORMA PAGO: </b> {{ compra.datos_pago.formaPago }} ({{
                        compra.impuesto_venta.codigo_moneda
                      }})
                    </h6>
                    <h6
                      v-if="compra.datos_pago.formaPago != 'efectivo'"
                      class="text-uppercase"
                    >
                      <b>TIPO TARJETA:</b>
                      {{ compra.datos_pago.formaPagoTarjeta }}
                    </h6>
                    <h6 v-if="compra.datos_pago.formaPago != 'efectivo'">
                      <b>MONTO TARJETA:</b>
                      {{ compra.impuesto_venta.simboloMoneda
                      }}{{ formatNumber(compra.datos_pago.montoTarjeta) }} ({{
                        compra.impuesto_venta.codigo_moneda
                      }})
                    </h6>
                    <h6 v-if="compra.datos_pago.formaPago != 'tarjeta'">
                      <b>MONTO EFECTIVO:</b>
                      {{ compra.impuesto_venta.simboloMoneda
                      }}{{ formatNumber(compra.datos_pago.montoEfectivo) }} ({{
                        compra.impuesto_venta.codigo_moneda
                      }})
                    </h6>
                    <h6
                      v-if="compra.datos_pago.formaPago != 'efectivo'"
                      class="text-uppercase"
                    >
                      <b>COD. VOUCHER:</b> {{ compra.datos_pago.codeVoucher }}
                    </h6>
                    <h6>
                      <b>PUNTO DE VENTA:</b> {{ compra.punto_venta.nombre }}.
                    </h6>
                    <h6>
                      <b>DIR. PUNTO VENTA:</b>
                      {{ compra.punto_venta.direccion }}.
                    </h6>
                  </div>
                  <h5 class="mt-2 mb-2 font-weight-bold">
                    <v-icon>theaters</v-icon> ENTREGA BOLETAS:
                  </h5>
                  <div class="pl-4">
                    <h6>
                      <b>MEDIO: </b>
                      <span v-if="compra.entrega_boletas === 'digital'">
                        ENTREGA DIGITAL
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-icon color="gray" v-on="on"> info </v-icon>
                          </template>
                          Este medio enviara tus boletas y/o palcos a tu correo
                          electronico.
                        </v-tooltip>
                      </span>
                      <span v-if="compra.entrega_boletas === 'fisica'">
                        ENTREGA FISICA
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-icon color="gray" v-on="on"> info </v-icon>
                          </template>
                          Deberas acercarte a un punto de venta autorizado del
                          evento a reclamar tus boletas y/o palcos de manera
                          impresa.
                        </v-tooltip>
                      </span>
                      <span v-if="compra.entrega_boletas === 'domicilio'">
                        ENTREGA A DOMICILIO
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-icon color="gray" v-on="on"> info </v-icon>
                          </template>
                          Tus boletas y/o palcos seran entregados a tu domicilio
                          de manera impresa.
                        </v-tooltip>
                      </span>
                      <span v-if="compra.entrega_boletas === 'taquilla'">
                        ENTREGA EN TAQUILLA
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-icon color="gray" v-on="on"> info </v-icon>
                          </template>
                          Deberas reclamar tus boletas y/o palcos en la taquilla
                          del evento.
                        </v-tooltip>
                      </span>
                    </h6>
                    <h6>
                      <b>ESTADO: </b>
                      <v-chip
                        v-if="compra.estado_entrega === 'pendiente'"
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        small
                      >
                        <v-avatar left>
                          <v-icon>access_time</v-icon>
                        </v-avatar>
                        Pendiente
                      </v-chip>
                      <v-chip
                        v-if="compra.estado_entrega === 'finalizado'"
                        class="ma-2"
                        color="teal"
                        text-color="white"
                        small
                      >
                        <v-avatar left>
                          <v-icon>check_circle</v-icon>
                        </v-avatar>
                        Finalizado
                      </v-chip>
                    </h6>
                  </div>
                  <h6
                    class="text-secondary mt-5"
                    v-if="compra.abonos_venta_parcial.length > 0"
                  >
                    ABONOS REALIZADOS:
                  </h6>
                  <table
                    class="table"
                    v-if="compra.abonos_venta_parcial.length > 0"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">FORMA DE PAGO</th>
                        <th class="text-center">TIPO TARJETA</th>
                        <th class="text-center">MONTO EFECTIVO</th>
                        <th class="text-center">MONTO TARJETA</th>
                        <th class="text-center">COD VOUCHER</th>
                        <th class="text-center">FECHA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in compra.abonos_venta_parcial">
                        <td class="text-center text-uppercase">
                          {{ item.forma_pago }}
                        </td>
                        <td class="text-center text-uppercase">
                          {{
                            item.forma_pago != "efectivo"
                              ? item.tipo_tarjeta
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          {{ compra.impuesto_venta.simboloMoneda
                          }}{{ formatNumber(item.monto_efectivo) }} ({{
                            compra.impuesto_venta.codigo_moneda
                          }})
                        </td>
                        <td class="text-center">
                          <span v-if="item.forma_pago != 'efectivo'"
                            >{{ compra.impuesto_venta.simboloMoneda
                            }}{{ formatNumber(item.monto_tarjeta) }} ({{
                              compra.impuesto_venta.codigo_moneda
                            }})</span
                          >
                        </td>
                        <td class="text-center">{{ item.code_voucher }}</td>
                        <td class="text-center">{{ item.created_at }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 class="text-secondary mt-5">RESUMEN DE COMPRA:</h6>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">DESCRIPCION</th>
                        <th class="text-center">VALOR</th>
                        <th class="text-center">CANTIDAD</th>
                        <th class="text-center">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in compra.productosFactura">
                        <td class="text-center">{{ item.descripcion }}</td>
                        <td class="text-center">
                          {{ compra.impuesto_venta.simboloMoneda }}
                          <span
                            v-if="compra.datos_pago.formaPago != 'cortesia'"
                            >{{ formatNumber(item.valor) }}</span
                          ><span v-else>0</span> ({{
                            compra.impuesto_venta.codigo_moneda
                          }})
                        </td>
                        <td class="text-center">{{ item.cantidad }} un</td>
                        <td class="text-center">
                          {{ compra.impuesto_venta.simboloMoneda
                          }}<span
                            v-if="compra.datos_pago.formaPago != 'cortesia'"
                            >{{
                              formatNumber(item.cantidad * item.valor)
                            }}</span
                          ><span v-else>0</span> ({{
                            compra.impuesto_venta.codigo_moneda
                          }})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <hr />
                  <div class="text-right">
                    <br />
                    <h5>
                      <b
                        >SUB - TOTAL: {{ compra.impuesto_venta.simboloMoneda
                        }}<span
                          v-if="compra.datos_pago.formaPago != 'cortesia'"
                          >{{ formatNumber(compra.subtotal) }}</span
                        ><span v-else>0</span></b
                      >
                      ({{ compra.impuesto_venta.codigo_moneda }})
                    </h5>
                    <h5>
                      <b
                        >+{{ compra.impuesto_venta.descripcion }} ({{
                          compra.impuesto_venta.porcentaje * 100
                        }}%):
                      </b>
                      {{ compra.impuesto_venta.simboloMoneda
                      }}{{
                        formatNumber(compra.impuesto_venta.valorImpuesto)
                      }}
                      ({{ compra.impuesto_venta.codigo_moneda }})
                    </h5>
                    <hr />
                    <h5>
                      <b
                        >TOTAL: {{ compra.impuesto_venta.simboloMoneda
                        }}<span
                          v-if="compra.datos_pago.formaPago != 'cortesia'"
                          >{{ formatNumber(compra.total) }}</span
                        ><span v-else>0</span>
                      </b>
                      ({{ compra.impuesto_venta.codigo_moneda }})
                    </h5>
                  </div>
                  <br />
                  <br />
                  <br />
                </v-container>
              </template>
            </v-flex>
          </v-layout>
        </v-card> </template></v-container
  ></v-app>
</template>

</v-container>
</v-app>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import { configApi } from '../api/configApi';
import { headers } from '../api/headers';
moment.locale("es");
export default {
  data() {
    return {
      search: "",
      desde: null,
      hasta: null,
      modalDesde: false,
      modalHasta: false,
      headers: [
        {
          text: "N° VENTA",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "fecha", align: "center", sortable: false },
        {
          text: "TIPO VENTA",
          value: "tipo_venta",
          align: "center",
          sortable: false,
        },
        {
          text: "TIPO PAGO",
          value: "pago_parcial",
          align: "center",
          sortable: false,
        },
        {
          text: "FORMA DE PAGO",
          value: "forma_pago",
          align: "center",
          sortable: false,
        },
        { text: "TOTAL", value: "total", align: "center", sortable: false },
        { text: "ESTADO", value: "active", align: "center", sortable: false },
        { text: "DESCARGAR BOLETAS", value: "reimprimir",align: "center", sortable: false },
        { text: "ACCIONES", value: "actions", sortable: false },
      ],
      drawer: true,
      items: [
        { title: "MI PERFIL", icon: "person", option: 1 },
        { title: "CONTRASEÑA", icon: "security", option: 2 },
        { title: "MIS COMPRAS", icon: "shopping_cart", option: 3 },
      ],
      right: null,
      optionPerfil: 1,
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electronico incorrecta",
      ],
      user: {
        nombre: null,
        apellidos: null,
        email: null,
        security: {
          mypassword: null,
          password: null,
          c_password: null,
        },
        identificacion: null,
        tipo_identificacion: 1,
        direccion: null,
        ciudad: null,
        departamento: null,
        telefono: null,
        id_rol: 2,
      },
      show_list_departamentos: false,
      show_list_ciudades: false,
      arrayimprimir: {
        id:null,
        email:null,
        nro: null,
      },
      cant_boleta: null,
      fecha_impresion: null,
    };
  },
  async beforeCreate() {
    document.title = "Mi perfil - TicketShop";
    this.$store.dispatch("user/listTipoIdentificaciones");
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("user/historialCompras", {
      page: 1,
      desde: null,
      hasta: null,
    });
    var self = this;
    setTimeout(function () {
      self.show_list_departamentos = true;
    }, 500);
    setTimeout(function () {
      self.show_list_ciudades = true;
    }, 600);
  },
  watch: {
    pagination: {
      async handler() {
        this.$store.dispatch("user/historialCompras", {
          page: this.pagination.page,
          desde: this.desde,
          hasta: this.hasta,
        });
      },
      deep: true,
    },
  },
  created() {
    this.user.nombre = this.usuario.nombre;
    this.user.apellidos = this.usuario.apellidos;
    this.user.email = this.usuario.email;
    this.user.identificacion = this.usuario.identificacion;
    this.user.direccion = this.usuario.direccion;
    this.user.telefono = this.usuario.telefono;
    if (isNaN(this.usuario.ciudad)) {
      this.user.ciudad = null;
    } else {
      this.user.ciudad = parseInt(this.usuario.ciudad);
    }
    if (isNaN(this.usuario.departamento)) {
      this.user.departamento = null;
    } else {
      this.user.departamento = parseInt(this.usuario.departamento);
    }
    if (this.$route.params.optPerfil) {
      this.optionSelect(this.$route.params.optPerfil);
    }
  },
  methods: {
    optionSelect(option) {
      this.optionPerfil = option;
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    printFactura() {
      // Pass the element id here
      this.$htmlToPaper("printFactura");
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return 0;
      }
    },
    formatFechaHumans(fecha) {
      return moment(fecha).fromNow();
    },
    updateProfile() {
      if (
        this.user.nombre != null &&
        this.user.apellidos != null &&
        this.user.tipo_identificacion != null &&
        this.user.identificacion != null &&
        this.user.direccion != null &&
        this.user.departamento != null &&
        this.user.ciudad != null
      ) {
        this.$store.dispatch("user/updateProfile", this.user);
      } else {
        swal(
          "Error!",
          "Debe completar todos los campos obligatorios!",
          "error"
        );
      }
    },
    updatePassword() {
      if (
        this.user.security.password != null &&
        this.user.security.c_password != null &&
        this.user.security.mypassword != null
      ) {
        if (this.user.security.password === this.user.security.c_password) {
          this.$store
            .dispatch("user/updatePassword", this.user.security)
            .then(() => {
              this.user.security.mypassword = null;
              this.user.security.password = null;
              this.user.security.c_password = null;
            });
        } else {
          swal(
            "Error!",
            "La nueva contraseña no coincide con su campo de confirmacion!",
            "error"
          );
        }
      } else {
        swal(
          "Error!",
          "Debe completar todos los campos obligatorios!",
          "error"
        );
      }
    },
    searchHistorialCompras() {
      if (this.desde != null && this.hasta != null) {
        this.$store.dispatch("user/historialCompras", {
          page: 1,
          desde: this.desde,
          hasta: this.hasta,
        });
      } else {
        swal(
          "Error!",
          "Completa los campos para realizar la busqueda!",
          "error"
        );
      }
    },
    async searchCompra(id) {
      await this.$store.dispatch("user/searchCompra", id);
      this.optionPerfil = 4;
    },
    async ImprimirCompra(id) {
      this.$store.commit('changeLoader', true);
        await axios.get(`${configApi.apiUrl}buscar_detalles/${id}`,null,{headers: headers})
        .then(response => {
            this.cant_boleta = response.data.data.length
            if(response.status != 200){
              swal("Error!","no existe esta venta","error");
            }
        })
          
          this.arrayimprimir.id = id
          this.arrayimprimir.email = this.user.email
          for (let i = 0; i < this.cant_boleta; i++) {
              this.arrayimprimir.nro = i
              const response = await axios.post(`${configApi.apiUrl}imprimirboletas`, this.arrayimprimir, { responseType: 'blob' });
              if(response.data.size > 0){
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                let x =i+1
                link.download = 'Boleta '+x+'.pdf';
                link.click();
              }else{
                swal("Error!","No tiene permitido imprimir esta boleta","error");
              }
              
          }
          this.$store.commit('changeLoader', false);
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.user.tipo_identificacion
      )[0].descripcion;
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.user.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    compras() {
      return this.$store.getters["user/compras"];
    },
    compra() {
      return this.$store.getters["user/compra"];
    },
    pagination() {
      return this.$store.getters["user/paginationCompras"];
    },
    pages() {
      return this.$store.getters["user/pagesCompras"];
    },
    totalItemCount() {
      return this.$store.getters["user/totalItemCount"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
  },
};
</script>
<style>
.no-shadow {
  box-shadow: none !important;
}
.optionSelect {
  border-left: 2px #2196f3 solid;
}
.optionSelect a {
  font-weight: bold !important;
}
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 16px;
}
.item-list-menu-perfil {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}
.item-list-menu-perfil p {
  font-size: 0.75rem;
}
.item-list-menu-perfil:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.card-menu-dat-perfil {
  min-height: 700px;
}
@media (max-width: 768px) {
  .card-menu-dat-perfil {
    min-height: 400px;
  }
}
</style>