<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Puntos de venta</h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: #1976d2 !important"
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Puntos de venta</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg6 md6 sm4 xs12 mb-3> </v-flex>
        <v-flex lg3 md3 sm4 xs12 mb-3> </v-flex>
        <v-flex lg3 md3 sm4 xs12 mb-3 text-right>
          <v-btn class="mt-4" color="info" @click="modalNewPuntoVenta = true"
            ><i class="material-icons">add</i> CREAR PUNTO DE VENTA</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </v-container>
    <div class="pl-5 pr-5">
      <v-card>
        <v-card-title class="pr-5 pl-5">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="puntos_venta"
          :search="search"
          class="elevation-1 table-ventas pr-3 pl-3"
          :no-data-text="'No se han encontrado puntos de venta...'"
          must-sort
          rows-per-page-text="Resultados por pagina"
        >
          <template v-slot:items="props">
            <td class="text-xs-center font-weight-bold">
              {{ props.item.nombre_razon }}
            </td>
            <td class="text-xs-center">
              {{ getDepartamento(props.item.id_ciudad) }}
            </td>
            <td class="text-xs-center">
              {{
                ciudades.filter((item) => item.id == props.item.id_ciudad)[0]
                  .descripcion
              }}
            </td>
            <td class="text-xs-center">{{ props.item.direccion }}</td>
            <td class="text-xs-center">{{ props.item.responsable }}</td>
            <td class="text-xs-center">{{ props.item.identificacion }}</td>
            <td class="text-xs-center">{{ props.item.telefono }}</td>
            <td class="text-xs-center">{{ props.item.email }}</td>
          </template>
          <template v-slot:item.acciones="props">
            <td class="text-xs-center">
              <v-icon
                medium
                title="Ver eventos de este punto de venta"
                @click="eventosPuntoVenta(props.item.id)"
              >
                search
              </v-icon>
            </td>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              No se encontraron puntos de venta con "{{ search }}".
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog
      v-if="modalNewPuntoVenta"
      v-model="modalNewPuntoVenta"
      max-width="750px"
      persistent
    >
      <form @submit.prevent="nuevoPuntoVenta" action="#" autocomplete="off">
        <v-card>
          <v-card-title>
            <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <img
                src="/images/logo-ticketshop.png"
                style="max-width: 150px; width: 150px"
              />
            </div>
          </v-card-title>
          <v-container>
            <v-card-text>
              <h5 class="text-center"><b>NUEVO PUNTO DE VENTA</b></h5>
              <v-divider></v-divider>
              <p class="mb-0 mt-0">
                <v-icon small color="warning">info</v-icon> Los campos marcados
                con <b>*</b> son obligatorios.
              </p>
              <p class="mb-0 mt-0">
                <v-icon small color="warning">info</v-icon> Se asignara el
                numero de identificacion como contraseña para el usuario.
              </p>
              <p class="mb-0 mt-0">
                <v-icon small color="warning">info</v-icon> Se enviara un correo
                electronico al usuario informando la creacion de su cuenta en
                TicketShop.
              </p>
              <hr />
              <v-layout wrap row>
                <v-flex xs12 sm12 md12 lg12 mb-3>
                  <v-text-field
                    label="Nombres del responsable (*)"
                    required
                    v-model="user.nombre"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 mb-3>
                  <v-text-field
                    label="Nombres o razon social (*)"
                    required
                    v-model="user.puntoventa.nombre_razon"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg4 md4 sm4 pr-3 mb-3>
                  <v-select
                    v-model="user.tipo_identificacion"
                    item-value="id"
                    item-text="code"
                    :items="tipoIdentificaciones"
                    label="Tipo de identificacion (*)"
                    prepend-icon="person"
                    persistent-hint
                    :hint="descripcionTipoIdentificacion"
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12 lg8 md8 sm8 mb-3>
                  <v-text-field
                    label="Numero de identificacion (*)"
                    required
                    v-model="user.identificacion"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 mb-3>
                  <v-text-field
                    label="Correo electronico (*)"
                    type="email"
                    required
                    v-model="user.email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 pr-3 mb-3>
                  <v-select
                    v-model="user.departamento"
                    item-value="id"
                    item-text="descripcion"
                    :items="departamentos"
                    label="Departamento (*)"
                    prepend-icon="location_on"
                    persistent-hint
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 mb-3>
                  <v-select
                    v-model="user.ciudad"
                    item-value="id"
                    item-text="descripcion"
                    :items="ciudadesSelect"
                    label="Ciudad (*)"
                    prepend-icon="location_on"
                    persistent-hint
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 pr-3 mb-3>
                  <v-text-field
                    label="Direccion (*)"
                    v-model="user.direccion"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 mb-3>
                  <v-text-field
                    label="Telefono"
                    type="tel"
                    v-model="user.telefono"
                    autocomplete="nope"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-container>
          <hr />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success">GUARDAR</v-btn>
            <v-btn
              color="blue darken-1"
              flat
              @click="modalNewPuntoVenta = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      puntosVentaParaAsignar: [],
      modalNewPuntoVenta: false,
      headers: [
        { text: "NOMBRE RAZON", value: "nombre_razon" },
        { text: "DEPARTAMENTO", value: "id_ciudad" },
        { text: "CIUDAD", value: "id_ciudad" },
        { text: "DIRECCION", value: "direccion" },
        { text: "RESPONSABLE", value: "responsable" },
        { text: "IDENTIFICACION", value: "identificacion" },
        { text: "TELEFONO", value: "telefono" },
        { text: "EMAIL", value: "email" },
        { text: "ACCIONES", value: "acciones", sortable: false },
      ],
      user: {
        nombre: null,
        email: null,
        identificacion: null,
        tipo_identificacion: 1,
        direccion: null,
        ciudad: null,
        departamento: null,
        telefono: null,
        id_rol: 5,
        puntoventa: {
          nombre_razon: null,
        },
      },
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electronico incorrecta",
      ],
    };
  },
  async beforeCreate() {
    document.title = `Puntos de venta - TicketShop`;
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("user/listTipoIdentificaciones");
    await this.$store.dispatch("puntoventa/listPuntoventas");
  },
  computed: {
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.user.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    puntos_venta() {
      return this.$store.getters["puntoventa/listPuntoventas"];
    },
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.user.tipo_identificacion
      )[0].descripcion;
    },
  },
  methods: {
    deletePuntoVenta(id) {
      var self = this;
      swal({
        title: "¿Estas seguro de remover este punto de venta?",
        text: "",
        icon: "warning",
        buttons: ["No, cancelar", "Confirmar"],
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    },
    async nuevoPuntoVenta() {
      if (
        this.user.nombre != null &&
        this.user.puntoventa.nombre_razon != null &&
        this.user.tipo_identificacion != null &&
        this.user.identificacion != null &&
        this.user.email != null &&
        this.user.direccion != null &&
        this.user.departamento != null &&
        this.user.ciudad != null
      ) {
        await this.$store.dispatch(
          "puntoventa/newUsuarioPuntoVenta",
          this.user
        );
        this.puntosVentaParaAsignar = [];
        this.modalNewPuntoVenta = false;
        this.user.nombre = null;
        this.user.email = null;
        this.user.identificacion = null;
        this.user.tipo_identificacion = 1;
        this.user.direccion = null;
        this.user.ciudad = null;
        this.user.departamento = null;
        this.user.telefono = null;
        this.user.puntoventa.nombre_razon = null;
        await this.$store.dispatch("puntoventa/listPuntoventas");
      } else {
        swal(
          "Error!",
          "Debe completar todos los campos obligatorios!",
          "error"
        );
      }
    },
    getDepartamento(id_ciudad) {
      var id_departamento = this.ciudades.filter(
        (item) => item.id == id_ciudad
      )[0].id_departamento;
      return this.departamentos.filter((item) => item.id == id_departamento)[0]
        .descripcion;
    },
    eventosPuntoVenta(id_pdv) {
      this.$router.push({ name: "eventosPuntoVenta", params: { id: id_pdv } });
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
}
.check_asignar_pv .accent--text {
  color: #1969ed !important;
  caret-color: #1969ed !important;
}
</style>
