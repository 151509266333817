<template>
  <!--Comment-->
  <v-app>
    <div v-if="localidad" class="contenedor">
      <div class="tarima">
        <h3 align="center">
          {{ localidad.palco == 1 ? "Palcos " : "Puestos " }}de Localidad
          {{ idlocalidad + " " + localidad.nombre }}
        </h3>
      </div>

      <div class="puestos">
        <center>
          <v-container style="overflow: auto">
            <div v-if="filas.length > 0">
              <v-layout
                row
                wrap
                v-if="filas[0].puestos && filas[0].puestos.length > 0"
              >
                <v-flex md12 lg12 dm12 xs12 mb-3>
                  <v-btn small color="default" class="mr-3">DISPONIBLES</v-btn>
                  <v-btn small color="error">VACIOS</v-btn>
                </v-flex>
              </v-layout>
            </div>
            <div
              v-if="localidad.silleteria == 1"
              v-for="(fila, indexFila) in filas"
              :class="{
                'justify-start': fila.alineacion == 1,
                'justify-center': fila.alineacion == 2,
                'justify-end': fila.alineacion == 3,
              }"
              :style="{ display: 'flex', width: puestosmayor + 'px' }"
            >
              <div
                v-for="(puesto, index) in fila.puestos"
                :key="index"
                v-if="
                  (((localidad.palco == 1 && localidad.puestosxpalco != null) ||
                  index != 0
                    ? parseInt(index) % parseInt(localidad.puestosxpalco) == 0
                    : true) ||
                    localidad.palco != 1) &&
                  fila.orientacion == 1
                "
                class="sizePuestos"
                style="width: 90px"
              >
                <v-card
                  class="iconPuesto"
                  :color="puesto.status == 9 ? 'red' : ''"
                  @click="changeStatusPuesto(puesto, index, indexFila)"
                >
                  <v-icon>{{
                    localidad.palco == 1 ? "people" : "person"
                  }}</v-icon>
                  <br />{{ puesto.numero }}
                  <br />
                </v-card>
              </div>

              <div
                v-for="(puesto, index) in fila.puestos.slice().reverse()"
                :key="index"
                v-if="
                  (((localidad.palco == 1 && localidad.puestosxpalco != null) ||
                  index != 0
                    ? parseInt(index) % parseInt(localidad.puestosxpalco) == 0
                    : true) ||
                    localidad.palco != 1) &&
                  fila.orientacion == 2
                "
                class="sizePuestos"
                style="width: 90px"
              >
                <v-card
                  :color="puesto.status == 9 ? 'red' : ''"
                  class="iconPuesto"
                  @click="changeStatusPuesto(puesto, index, indexFila)"
                >
                  <v-icon>{{
                    localidad.palco == 1 ? "people" : "person"
                  }}</v-icon>
                  <br />{{ puesto.numero }}
                  <br />
                </v-card>
              </div>

              <div v-if="fila.puestos.length == 0">
                <v-card class="iconPuestoVacio red">
                  <br />
                </v-card>
                <br />
              </div>
            </div>
          </v-container>
        </center>
      </div>

      <div v-if="editable && editablexaforo" class="parametros">
        <v-btn
          @click="saveRow()"
          color="green darken-4 white--text"
          v-if="localidad.silleteria != 1"
          >Crear {{ localidad.palco == 1 ? "Palcos" : "Puestos" }} de Localidad
          sin Silleteria</v-btn
        >

        <v-form ref="ruleForm_add_puestos1" v-if="localidad.silleteria == 1">
          <h4 align="left" style="color: black">Añadir Filas</h4>
          <br />
          <ul>
            <li v-for="(fila, index) in filas">
              <v-layout row wrap>
                <v-flex lg1 md1 sm1 xs12 class="pr-4">
                  <v-text-field
                    type="number"
                    v-model="fila.numero"
                    readonly
                    label="Numero"
                    color="green darken-2"
                    :rules="[(v) => !!v || 'Numero es Requerido']"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex lg3 md4 sm4 xs12 class="pr-4">
                  <v-text-field
                    type="text"
                    v-model="fila.nombre"
                    label="Nombre"
                    color="green darken-2"
                    :rules="[(v) => !!v || 'Nombre es Requerido']"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex lg1 md1 sm1 xs12 class="pr-4">
                  <v-text-field
                    v-if="fila.id == null"
                    type="number"
                    v-model="puestosOpalcos[index]"
                    :label="localidad.palco == 1 ? 'Palcos' : 'Puestos'"
                    color="green darken-2"
                    @change="setPuestosOpalcos(index)"
                    :rules="[(v) => !!v || 'Requerido']"
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="fila.id != null"
                    readonly
                    type="number"
                    :value="
                      localidad.palco == 1
                        ? fila.puestos.length / localidad.puestosxpalco
                        : fila.puestos.length
                    "
                    :label="localidad.palco == 1 ? 'Palcos' : 'Puestos'"
                    color="green darken-2"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex lg1 md1 sm1 xs12 class="pr-4">
                  <v-text-field
                    v-if="fila.id == null"
                    type="number"
                    v-model="iniciaEn[index]"
                    label="Inicia En"
                    color="green darken-2"
                    @change="setPuestosOpalcos(index)"
                    :rules="[(v) => !!v || 'Requerido']"
                    :value="1"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex lg2 md2 sm2 xs12 class="pr-4">
                  <v-autocomplete
                    :items="alineacion"
                    item-text="descripcion"
                    item-value="id"
                    v-model="fila.alineacion"
                    label="Alineación"
                    color="green darken-2"
                    :rules="[(v) => !!v || 'Alineación es Requerido']"
                  ></v-autocomplete>
                </v-flex>

                <v-flex lg2 md2 sm2 xs12 class="pr-4">
                  <v-autocomplete
                    :items="orientacion"
                    item-text="descripcion"
                    item-value="id"
                    v-model="fila.orientacion"
                    label="Orientación"
                    color="green darken-2"
                    :rules="[(v) => !!v || 'Orientación es Requerido']"
                  ></v-autocomplete>
                </v-flex>

                <v-flex lg1 md1 sm1 xs12 class="pr-4">
                  <v-btn @click="deleteRow(index)" icon small color="red">
                    <v-icon small color="red">remove</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex lg1 md1 sm1 xs12 class="pr-4">
                  <v-btn @click="copyRow(index)" icon small color="green">
                    <v-icon small color="black">content_copy</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </li>
          </ul>

          <v-btn @click="addRow()" color="blue darken-4 white--text" class="mr-3"
            >Agregar nueva fila</v-btn
          >
          <v-btn @click="saveRow()" color="green darken-4 white--text"
            >Guardar filas</v-btn
          >

          <br />
        </v-form>
      </div>
    </div>
  </v-app>
</template>
<script>
export default {
  name: "add_puestos",
  props: ["idlocalidad", "editable"],
  async mounted() {
    await this.setLocalidad(this.idlocalidad);
    this.setFilaslocalidad(this.idlocalidad);
  },
  data() {
    return {
      editablexaforo: true,
      filas: [
        {
          id: null,
          id_localidad: this.idlocalidad,
          numero: 1,
          nombre: "",
          puestos: [
            {
              id: "",
              id_fila: "",
              id_localidad: "",
              numero: "",
              silla: "",
              status: 1,
            },
          ],
          alineacion: 2,
          orientacion: 1,
        },
      ],
      puestosOpalcos: [],
      iniciaEn: [],
      alineacion: [
        {
          descripcion: "Izquierda",
          id: 1,
        },
        {
          descripcion: "Centro",
          id: 2,
        },
        {
          descripcion: "Derecha",
          id: 3,
        },
      ],
      orientacion: [
        {
          descripcion: "Ascendente",
          id: 1,
        },
        {
          descripcion: "Descendente",
          id: 2,
        },
      ],
    };
  },
  methods: {
    addRow() {
      this.filas.push({
        id: null,
        id_localidad: this.idlocalidad,
        numero: "",
        nombre: "",
        puestos: [
          {
            id: "",
            id_fila: "",
            id_localidad: "",
            numero: "",
            silla: "",
            status: 1,
          },
        ],
        alineacion: "",
        orientacion: "",
      });

      for (var i = 0; i < this.filas.length; i++) {
        this.filas[i].numero = i + 1;
      }
    },
    setPuestosOpalcos(index) {
      this.filas[index].puestos = [];
      var puestos = this.puestosOpalcos[index];
      var iniciaen = this.iniciaEn[index];
      var puestospalcos = 0;

      if (this.localidad.palco == 1) {
        puestospalcos =
          parseInt(puestos) * parseInt(this.localidad.puestosxpalco);
      }
      if (puestospalcos > puestos) {
        puestos = puestospalcos;
      }

      var numpuestotemp = iniciaen;
      var cont = 0;

      for (var i = iniciaen; i < parseInt(puestos) + parseInt(iniciaen); i++) {
        if (this.localidad.palco != 1) {
          this.filas[index].puestos.push({
            id: "",
            id_fila: "",
            id_localidad: "",
            numero: i,
            silla: i,
            status: 1,
          });
        } else {
          this.filas[index].puestos.push({
            id: "",
            id_fila: "",
            id_localidad: "",
            numero: numpuestotemp,
            silla: numpuestotemp,
            status: 1,
          });
          cont = parseInt(cont) + 1;
          if (cont % this.localidad.puestosxpalco == 0) {
            numpuestotemp = parseInt(numpuestotemp) + 1;
          }
        }
      }
    },
    async deleteRow(index) {
      if (this.filas[index].id == null || this.filas[index].id == "") {
        this.filas.splice(index, 1);
        this.puestosOpalcos.splice(index, 1);
        this.iniciaEn.splice(index, 1);
      } else {
        await this.$store
          .dispatch("fila/deleteFila", this.filas[index].id)
          .then((response) => {
            if (response != null) {
              if (
                response.response.data.error !=
                "La Fila no se puedo eliminar, es usada en otra tabla"
              ) {
                this.filas.splice(index, 1);
                this.puestosOpalcos.splice(index, 1);
                this.iniciaEn.splice(index, 1);
              }
            } else {
              this.filas.splice(index, 1);
              this.puestosOpalcos.splice(index, 1);
              this.iniciaEn.splice(index, 1);
            }
          });
        this.$store.dispatch(
          "localidad/listLocalidadesAuditorio",
          this.idlocalidad
        );
      }

      for (var i = 0; i < this.filas.length; i++) {
        this.filas[i].numero = i + 1;
      }
    },
    async copyRow(index) {
      this.filas.push({
        id: this.filas[index].id,
        id_localidad: this.filas[index].id_localidad,
        numero: this.filas[index].numero + 1,
        nombre: this.filas[index].nombre,
        puestos: [
          {
            id: "",
            id_fila: this.filas[index].puestos[index].id_fila,
            id_localidad: this.filas[index].puestos[index].id_localidad,
            numero: this.filas[index].puestos[index].numero,
            silla: this.filas[index].puestos[index].silla,
            status: 1,
          },
        ],
        alineacion: this.filas[index].alineacion,
        orientacion:this.filas[index].orientacion,
      });

      
      this.puestosOpalcos[index + 1] = this.puestosOpalcos[index]
      
      for (var i = 0; i < this.filas.length; i++) {
        this.filas[i].numero = i + 1;
      }
    },
    async saveRow() {
      if (this.localidad.silleteria == 1) {
        if (this.$refs.ruleForm_add_puestos1.validate()) {
          this.$store.commit("changeLoader", true);

          var cantpuestos = 0;
          for (var i = 0; i < this.puestosOpalcos.length; i++) {
            if (this.localidad.palco == 1) {
              cantpuestos =
                parseInt(cantpuestos) +
                parseInt(this.puestosOpalcos[i]) *
                  parseInt(this.localidad.puestosxpalco);
            } else {
              cantpuestos =
                parseInt(cantpuestos) + parseInt(this.puestosOpalcos[i]);
            }
          }

          var puestosVacios = 0;
          for (var j = this.filas.length - 1; j >= 0; j--) {
            for (var k = this.filas[j].puestos.length - 1; k >= 0; k--) {
              if (
                this.filas[j].puestos[k].numero == "" ||
                this.filas[j].puestos[k].numero == null
              ) {
                puestosVacios++;
              }
            }
          }
          cantpuestos = cantpuestos + puestosVacios;
          if (cantpuestos == this.localidad.aforo + puestosVacios) {
            for (var j = 0; j < this.filas.length; j++) {
              if (this.filas[j].id == null || this.filas[j].id == "") {
                await this.newRow(j);
              }
            }
            this.editablexaforo = false;
            this.$store.commit("changeLoader", false);
            swal("Confirmacion!", "Puestos Guardados.", "success");
          } else {
            this.$store.commit("changeLoader", false);
            swal("Error!", "Puestos no coinciden con el aforo.", "error");
          }
        }
      } else {
        this.$store.commit("changeLoader", true);
        await this.$store.dispatch("fila/newFila", {
          id_localidad: this.idlocalidad,
          nombre: "-",
          numero: 1,
          alineacion: 1,
          orientacion: 1,
        });
        await this.newPuestos(this.localidad.aforo, 1, 0);
        this.$store.commit("changeLoader", false);
        swal("Confirmacion!", "Puestos Guardados.", "success");
        this.editablexaforo = false;
      }
    },
    async newRow(index) {
      await this.$store.dispatch("fila/newFila", this.filas[index]);

      var puestos = this.puestosOpalcos[index];

      if (this.localidad.palco == 1) {
        puestos = parseInt(puestos) * parseInt(this.localidad.puestosxpalco);
      }

      await this.newPuestos(puestos, this.iniciaEn[index], index);
    },
    async newPuestos(puestos, iniciaen, indexFila) {
      var puestosxfila = [];
      var numpuestotemp = iniciaen;
      var cont = 0;
      if (this.filas[indexFila]) {
        for (var i = this.filas[indexFila].puestos.length - 1; i >= 0; i--) {
          if (this.localidad.palco != 1) {
            puestosxfila.unshift(this.filas[indexFila].puestos[i].numero);
          } else {
            puestosxfila.unshift(this.filas[indexFila].puestos[i].numero);
            cont = parseInt(cont) + 1;
            if (cont % this.localidad.puestosxpalco == 0) {
              numpuestotemp = parseInt(numpuestotemp) + 1;
            }
          }
        }
      } else {
        //
        for (
          var i = iniciaen;
          i < parseInt(puestos) + parseInt(iniciaen);
          i++
        ) {
          if (this.localidad.palco != 1) {
            puestosxfila.push(i);
          } else {
            puestosxfila.push(numpuestotemp);
            cont = parseInt(cont) + 1;
            if (cont % this.localidad.puestosxpalco == 0) {
              numpuestotemp = parseInt(numpuestotemp) + 1;
            }
          }
        }
      }
      if (this.filas[indexFila]) {
        var puestosTotalFila = this.filas[indexFila].puestos;
      } else {
        var puestosTotalFila = null;
      }

      var fila = await this.$store.getters["fila/fila"];
      await this.$store.dispatch("puesto/newPuestosxfila", {
        id_localidad: this.idlocalidad,
        numeros: puestosxfila,
        id_fila: fila.id,
        puestosTotalFila,
      });
    },
    async setLocalidad(id) {
      await this.$store.dispatch("localidad/getLocalidad", id);
    },
    async setFilaslocalidad(id) {
      this.puestosOpalcos = [];
      this.iniciaEn = [];
      await this.$store.dispatch("fila/listFilasLocalidad", id);
      this.filas = this.filaslocalidad; //para cuando no se han guardado los puestos pueda graficar de todas maneras
      var puestos = 0;
      for (var i = 0; i < this.filaslocalidad.length; i++) {
        puestos =
          parseInt(puestos) + parseInt(this.filaslocalidad[i].puestos.length);
      }
      if (puestos == this.localidad.aforo) {
        this.editablexaforo = false;
      } else {
        this.editablexaforo = true;
      }
    },
    changeStatusPuesto(puesto, indexPuesto, indexFila) {
      if (this.editable && this.editablexaforo) {
        if (
          this.filas[indexFila].puestos[indexPuesto].numero == "" ||
          this.filas[indexFila].puestos[indexPuesto].numero == null
        ) {
          var itemBorrar = this.filas[indexFila].puestos.indexOf(
            this.filas[indexFila].puestos[indexPuesto]
          );
          this.filas[indexFila].puestos.splice(itemBorrar, 1);
        } else {
          //
          if (indexPuesto != 0) {
            indexPuesto = indexPuesto + 1;
          }
          this.filas[indexFila].puestos.splice(indexPuesto, 0, {
            id: "",
            id_fila: "",
            id_localidad: "",
            numero: "",
            silla: "",
            status: 9,
          });
        }
        //this.filas[indexFila].puestos[indexPuesto].status = (puesto.status == 1) ? 9 : 1;
      }
    },
  },
  computed: {
    filaslocalidad() {
      return this.$store.getters["fila/listFilasLocalidad"];
    },
    localidad() {
      return this.$store.getters["localidad/localidad"];
    },
    puestosmayor() {
      var mayor = 0;
      var puestosmayor = 0;
      for (var i = 0; i < this.filas.length; i++) {
        if (mayor < this.filas[i].puestos.length) {
          mayor = this.filas[i].puestos.length;
        }
      }
      if (this.localidad.palco == 1 && this.localidad.puestosxpalco != null) {
        puestosmayor = (parseInt(mayor) / this.localidad.puestosxpalco) * 90;
      } else {
        puestosmayor = parseInt(mayor) * 90;
      }
      return puestosmayor;
    },
  },
  watch: {
    idlocalidad(valnew, valold) {
      this.setLocalidad(valnew);
      this.setFilaslocalidad(valnew);
    },
  },
};
</script>
<style>
.contenedor {
  width: 100%;
  margin: auto;
}

.columnaPlata {
  width: 10%;
  display: inline-block;
  padding: 10px;
  border: 1px solid #cfd8dc;
  text-align: center;
  background: #8a9597;
}

.columnaOro {
  width: 10%;
  display: inline-block;
  padding: 10px;
  border: 1px solid #cfd8dc;
  text-align: center;
  background: #cda434;
}

.columnaDiamante {
  width: 10%;
  display: inline-block;
  padding: 10px;
  border: 1px solid #cfd8dc;
  text-align: center;
  background: #3f888f;
}

.puesto {
  width: 5%;
  display: inline-block;
  margin: auto;
  float: left;
  text-align: center;
  padding: 5px;
  background: white;
  color: white;
}

.tarima {
  width: 100%;
  display: block;
  margin: auto;
  padding: 15px;
  background: #403607;
  color: white;
}

/*	.vip {
		width: 80%;
		display: block;
		margin: auto;
		padding: 15px;
		background: #ffbf00;
		color: white;

	}

	.general {
		width: 80%;
		display: block;
		margin: auto;
		padding: 15px;
		background: #03a9f4;
		color: white;
	}*/

.parametros {
  width: 100%;
  display: block;
  margin: auto;
  padding: 15px;
  background: #e6ee9c;
  color: white;
}

.texto {
  color: black;
}
</style>
