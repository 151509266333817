<template>
    <v-app>
        <Home></Home>
    </v-app>
</template>
<script>

    import Home from '../Home.vue';

    export default{
        components: {
            Home
        }
    };
</script>
