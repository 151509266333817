import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       preciomonedas: [],
       preciomoneda: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPreciomonedas: (state,getters,rootState) => {
          return state.preciomonedas;
    },
    preciomoneda: (state,getters,rootState) => {
        return state.preciomoneda;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPreciomonedas: (state,preciomonedas) => {
          state.preciomonedas = preciomonedas;
          state.pagination.totalItems = preciomonedas.total;
          state.pagination.page = preciomonedas.current_page;
          state.pagination.rowsPerPage = preciomonedas.per_page;
       },
      listPreciomonedasSearch: (state,preciomonedas) => {
            if(preciomonedas != null){ 
                state.preciomonedas = preciomonedas;
            }else{
              state.preciomonedas = null;
            }
       },
      viewPreciomoneda(state,id){
        let auxPreciomoneda = state.preciomonedas.filter((item) => item.id == id);
        state.preciomoneda = auxPreciomoneda[0];
      },
      setPreciomoneda(state,preciomoneda){
          state.preciomoneda = preciomoneda;
      },
      clearPreciomoneda(state){
        state.preciomoneda = null;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPreciomonedas({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}precios_monedas`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPreciomonedas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener el precio por moneda del evento!", "error");
        console.error(error);
      });
    },
      listPreciomonedasEvento({state,getters,commit,rootState},key){
      axios.get(`${configApi.apiUrl}precios_evento/${key}`,null,{headers: headers})
      .then(response => {
        commit('listPreciomonedas',response.data.data);
      })
      .catch(error => {
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener el precio por moneda del evento!", "error");
          //console.log(error)
        }
      });

    },
  newPreciomoneda({state,getters,commit,rootState},payload){
      // id_evento
      // id_palco_evento
      // id_boleta_evento
      // id_preventa
      // id_costo_evento
      // monto_minimo
      // precio_venta
      // precio_servicio
      // descuento_fijo_precio
      // descuento_fijo_servicio
      // valor
      // status
      // codigo_moneda
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}precios_monedas`,payload,{headers: headers})
      .then(response => {
        store.dispatch('preciomonedas/listPreciomonedas',state.pagination.page);
        commit('setPreciomoneda',response.data.data);
        /*swal("Exito!", "Se creo el precio por moneda del eventocorrectamente!", "success");*/
      })
      .catch(error => {
        swal("Error!", "Error al crear el precio por moneda del evento", "error");
        console.error(error);
      });
  },
  updatePreciomoneda({state,getters,commit,rootState},payload){
      // id_evento
      // id_palco_evento
      // id_boleta_evento
      // id_preventa
      // id_costo_evento
      // monto_minimo
      // precio_venta
      // precio_servicio
      // descuento_fijo_precio
      // descuento_fijo_servicio
      // valor
      // status
      // codigo_moneda
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}precios_monedas/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el preciomoneda correctamente!", "success");
        store.dispatch('preciomonedas/listPreciomonedas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el precio por moneda del evento", "error");
        console.error(error);
      });
  },
  // deletePreciomoneda({state,getters,commit,rootState},preciomoneda){
  //   store.dispatch('validateToken');
  //   axios.delete(`${configApi.apiUrl}auditoria_evento/${preciomoneda}`,null,{headers: headers})
  //    .then(response => {
  //       swal("Exito!", "Se elimino el preciomoneda correctamente!", "success");
  //       store.dispatch('preciomonedas/listPreciomonedas',state.pagination.page);
  //    })
  //    .catch(error => {
  //       swal("Error!","Error al eliminar el precio por moneda del evento","error");
  //       console.log(error);
  //    });
  // },
  },
}