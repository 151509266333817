import Vue from 'vue';
import {router} from '../../main.js';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
    selectedCondiciones: [],
    selectedPuntoventas: [],
    artistasCheck: [],
    artistasCheckTemp: [],
    tribunasShow2: [],
    preventasEvento: [],
    preventasmonedaEvento: [],
    eventoMonto_minimo: [],
    modalPlantilla: 'new',
    codigomonedaEvento: [],
    imagenMapeo:{
      id: '',
      id_auditorio: '',
      area_mapeada: '',
      nombre: '',
      imagen: '',
      status: '',
    },
    // localidades: [{
    //   id:'',
    //   id_tribuna:'',
    //   nombre:'',
    //   impuesto:'',
    //   precio_venta:'',
    //   precio_servicio:'',
    //   puerta_acceso:'',
    //   url_imagen:'',
    //   aforo:'',
    //   silleteria:'',
    //   palco: '',
    //   puestosxpalco: '',
    //   imagen: '',
    // }],
    localidades: [],
    localidadesevento: [{
      id_localidad:'',
      impuesto:'',
      precio_venta:'',
      precio_servicio:'',
      url_imagen:'',
      imagen: '',
    }],
    localidadesmonedaevento: [[]],
    costos: [],
    costosMoneda: [],
    auditorioDatos: {
      id: '',
      nombre: '',
      direccion: '',
      id_pais: '',
      id_departamento: '',
      id_ciudad: '',  
      latitud: 4.6699538,
      longitud: -74.1171362,
      aforo: '',     
      url_imagen: '',
      codigo_mapeado: '',          
    },
    datosGenerales: {
      nombreEvento: null,
      descripcionEvento: null,
      temporada: null,
      auditorioevento: null,
      fechaEvento: null,
      fechaFinEvento: null,
      eventoUnSoloDia: false,
      horaInicio: null,
      horaAperturaPuertas: null,
      horaFinalizacion: null,
      tipoEvento: null,
      tipocosto: null,
      codigoPulep: null,
      costotransaccion: null,
      entregaboletadigital: null,
      entregaboletafisica: null,
      codigo_moneda: null,
      monto_minimo: null,
      fecha_inicio_venta_puntos: null,
      hora_inicio_venta_puntos: null,
      fecha_inicio_venta_internet: null,
      hora_inicio_venta_internet: null,
      venta_linea: false,
      domicilios: false,
      cant_max_boletas: null,
    },
    responsableEvento: {
      responsable: null,
      nombreRazonSocial: null,
      tipoIdentificacion: null,
      Identificacion: null,
      direccion: null,
      pais: '',
      departamento: null,
      ciudad: null,
      tipoCliente: false,
      email: null,
      telefono: null,
    },
    imagenesEventoCarousel: [],
    imagenesEventoTwitter: [],
    imagenesEventoDisenoBoleta: [],
    imagenesEventoInstagStories: [],
    imagenesEventoFacebook: [],
    imagenesEventoVert: [],
    imagenesEventoMiniatura: [],
    imagenesEventoBanner: [],
    imagenesEventoLocalidad: [],
    imagenesEventoDesktop: [],
    imagenesEventoMovil: [],
    imagenesEventoPrecios: [],
  },
  getters: {
    selectedCondiciones: (state,getters,rootState) => {
      return state.selectedCondiciones;
    },
    selectedPuntoventas: (state,getters,rootState) => {
      return state.selectedPuntoventas;
    },
    artistasCheck: (state,getters,rootState) => {
      return state.artistasCheck;
    },
    artistasCheckTemp: (state,getters,rootState) => {
      return state.artistasCheckTemp;
    },
    tribunasShow2: (state,getters,rootState) => {
      return state.tribunasShow2;
    },
    modalPlantilla: (state,getters,rootState) => {
      return state.modalPlantilla;
    },
    codigomonedaEvento: (state,getters,rootState) => {
      return state.codigomonedaEvento;
    },
    imagenMapeo: (state,getters,rootState) => {
      return state.imagenMapeo;
    },
    localidades: (state,getters,rootState) => {
      return state.localidades;
    },
    localidadesevento: (state,getters,rootState) => {
      return state.localidadesevento;
    },
    localidadesmonedaevento: (state,getters,rootState) => {
      return state.localidadesmonedaevento;
    },
    costos: (state,getters,rootState) => {
      return state.costos;
    },
    costosMoneda: (state,getters,rootState) => {
      return state.costosMoneda;
    },
    auditorioDatos: (state,getters,rootState) => {
      return state.auditorioDatos;
    },
    auditorioDatosid: (state,getters,rootState) => {
      return state.auditorioDatos.id;
    },
    auditorioDatosnombre: (state,getters,rootState) => {
      return state.auditorioDatos.nombre;
    },
    auditorioDatosdireccion: (state,getters,rootState) => {
      return state.auditorioDatos.direccion;
    },
    auditorioDatosid_pais: (state,getters,rootState) => {
      return state.auditorioDatos.id_pais;
    },
    auditorioDatosid_departamento: (state,getters,rootState) => {
      return state.auditorioDatos.id_departamento;
    },
    auditorioDatosid_ciudad: (state,getters,rootState) => {
      return state.auditorioDatos.id_ciudad;
    },
    auditorioDatoslatitud: (state,getters,rootState) => {
      return state.auditorioDatos.latitud;
    },
    auditorioDatoslongitud: (state,getters,rootState) => {
      return state.auditorioDatos.longitud;
    },
    auditorioDatosaforo: (state,getters,rootState) => {
      return state.auditorioDatos.aforo;
    },
    auditorioDatosurl_imagen: (state,getters,rootState) => {
      return state.auditorioDatos.url_imagen;
    },
    auditorioDatoscodigo_mapeado: (state,getters,rootState) => {
      return state.auditorioDatos.codigo_mapeado;
    },
    datosGenerales: (state,getters,rootState) => {
      return state.datosGenerales;
    },
    datosGeneralesnombreEvento: (state,getters,rootState) => {
      return state.datosGenerales.nombreEvento;
    },
    datosGeneralesdescripcionEvento: (state,getters,rootState) => {
      return state.datosGenerales.descripcionEvento;
    },
    datosGeneralestemporada: (state,getters,rootState) => {
      return state.datosGenerales.temporada;
    },
    datosGeneralesauditorioevento: (state,getters,rootState) => {
      return state.datosGenerales.auditorioevento;
    },
    datosGeneralesfechaEvento: (state,getters,rootState) => {
      return state.datosGenerales.fechaEvento;
    },
    datosGeneralesfechaFinEvento: (state,getters,rootState) => {
      return state.datosGenerales.fechaFinEvento;
    },
    datosGeneraleseventoUnSoloDia: (state,getters,rootState) => {
      return state.datosGenerales.eventoUnSoloDia;
    },
    datosGeneraleshoraInicio: (state,getters,rootState) => {
      return state.datosGenerales.horaInicio;
    },
    datosGeneraleshoraAperturaPuertas: (state,getters,rootState) => {
      return state.datosGenerales.horaAperturaPuertas;
    },
    datosGeneraleshoraFinalizacion: (state,getters,rootState) => {
      return state.datosGenerales.horaFinalizacion;
    },
    datosGeneralestipoEvento: (state,getters,rootState) => {
      return state.datosGenerales.tipoEvento;
    },
    datosGeneralestipocosto: (state,getters,rootState) => {
      return state.datosGenerales.tipocosto;
    },
    datosGeneralescodigoPulep: (state,getters,rootState) => {
      return state.datosGenerales.codigoPulep;
    },
    datosGeneralescostotransaccion: (state,getters,rootState) => {
      return state.datosGenerales.costotransaccion;
    },
    datosGeneralesentregaboletadigital: (state,getters,rootState) => {
      return state.datosGenerales.entregaboletadigital;
    },
    datosGeneralesentregaboletafisica: (state,getters,rootState) => {
      return state.datosGenerales.entregaboletafisica;
    },
    datosGeneralescodigomoneda: (state,getters,rootState) => {
      return state.datosGenerales.codigo_moneda;
    },
    datosGeneralesmonto_minimo: (state,getters,rootState) => {
      return state.datosGenerales.monto_minimo;
    },
    datosGeneralesfecha_inicio_venta_puntos: (state,getters,rootState) => {
      return state.datosGenerales.fecha_inicio_venta_puntos;
    },
    datosGeneraleshora_inicio_venta_puntos: (state,getters,rootState) => {
      return state.datosGenerales.hora_inicio_venta_puntos;
    },
    datosGeneralesfecha_inicio_venta_internet: (state,getters,rootState) => {
      return state.datosGenerales.fecha_inicio_venta_internet;
    },
    datosGeneraleshora_inicio_venta_internet: (state,getters,rootState) => {
      return state.datosGenerales.hora_inicio_venta_internet;
    },
    datosGeneralesventa_linea: (state,getters,rootState) => {
      return state.datosGenerales.venta_linea;
    },
    datosGeneralesdomicilios: (state,getters,rootState) => {
      return state.datosGenerales.domicilios;
    },
    datosGeneralescant_max_boletas: (state,getters,rootState) => {
      return state.datosGenerales.cant_max_boletas;
    },
    responsableEvento: (state,getters,rootState) => {
      return state.responsableEvento;
    },
    responsableEventoresponsable: (state,getters,rootState) => {
      return state.responsableEvento.responsable;
    },
    responsableEventonombreRazonSocial: (state,getters,rootState) => {
      return state.responsableEvento.nombreRazonSocial;
    },
    responsableEventotipoIdentificacion: (state,getters,rootState) => {
      return state.responsableEvento.tipoIdentificacion;
    },
    responsableEventoIdentificacion: (state,getters,rootState) => {
      return state.responsableEvento.Identificacion;
    },
    responsableEventodireccion: (state,getters,rootState) => {
      return state.responsableEvento.direccion;
    },
    responsableEventopais: (state,getters,rootState) => {
      return state.responsableEvento.pais;
    },
    responsableEventodepartamento: (state,getters,rootState) => {
      return state.responsableEvento.departamento;
    },
    responsableEventociudad: (state,getters,rootState) => {
      return state.responsableEvento.ciudad;
    },
    responsableEventotipoCliente: (state,getters,rootState) => {
      return state.responsableEvento.tipoCliente;
    },
    responsableEventoemail: (state,getters,rootState) => {
      return state.responsableEvento.email;
    },
    responsableEventotelefono: (state,getters,rootState) => {
      return state.responsableEvento.telefono;
    },
    preventasEvento: (state,getters,rootState) => {
      return state.preventasEvento;
    },
    preventasmonedaEvento: (state,getters,rootState) => {
      return state.preventasmonedaEvento;
    },
    eventoMonto_minimo: (state,getters,rootState) => {
      return state.eventoMonto_minimo;
    },
    imagenesEventoCarousel: (state,getters,rootState) => {
      return state.imagenesEventoCarousel;
    },
    imagenesEventoTwitter: (state,getters,rootState) => {
      return state.imagenesEventoTwitter;
    },
    imagenesEventoDisenoBoleta: (state,getters,rootState) => {
      return state.imagenesEventoDisenoBoleta;
    },
    imagenesEventoLocalidad: (state,getters,rootState) => {
      return state.imagenesEventoLocalidad;
    },
    imagenesEventoFacebook: (state,getters,rootState) => {
      return state.imagenesEventoFacebook;
    },
    imagenesEventoVert: (state,getters,rootState) => {
      return state.imagenesEventoVert;
    },
    imagenesEventoMiniatura: (state,getters,rootState) => {
      return state.imagenesEventoMiniatura;
    },
    imagenesEventoBanner: (state,getters,rootState) => {
      return state.imagenesEventoBanner;
    },
    imagenesEventoDesktop: (state,getters,rootState) => {
      return state.imagenesEventoDesktop;
    },
    imagenesEventoMovil: (state,getters,rootState) => {
      return state.imagenesEventoMovil;
    },
    imagenesEventoPrecios: (state,getters,rootState) => {
      return state.imagenesEventoPrecios;
    },
  },
  mutations: {
    selectedCondiciones(state,selectedCondiciones){
      state.selectedCondiciones = selectedCondiciones;
    },
    selectedPuntoventas(state,selectedPuntoventas){
      state.selectedPuntoventas = selectedPuntoventas;
    },
    artistasCheck(state,artistasCheck){
      state.artistasCheck = artistasCheck;
    },
    artistasCheckTemp(state,artistasCheckTemp){
      state.artistasCheckTemp.push(artistasCheckTemp);
    },
    artistasCheckTemp2(state,artistasCheckTemp){
      state.artistasCheckTemp.splice(artistasCheckTemp, 1);
    },
    tribunasShow2(state,tribunasShow2){
      state.tribunasShow2.push(tribunasShow2);
    },
    tribunasShow22(state,tribunasShow2){
      state.tribunasShow2.splice(tribunasShow2, 1);
    },
    tribunasShow25(state,tribunasShow2){
      state.tribunasShow2 = tribunasShow2;
    },
    modalPlantilla(state,modalPlantilla){
      state.modalPlantilla = modalPlantilla;
    },
    codigomonedaEvento(state,codigomonedaEvento){
      state.codigomonedaEvento.push(codigomonedaEvento);
    },
    codigomonedaEvento2(state,codigomonedaEvento){
      state.codigomonedaEvento.splice(codigomonedaEvento,1);
    },
    imagenMapeo(state,imagenMapeo){
      state.imagenMapeo = imagenMapeo;
    },
    localidades(state,localidades){
      state.localidades.push(localidades);
    },
    localidades2(state,localidades){
      state.localidades.splice(localidades, 1);
    },
    localidades3(state,localidades){
        //no reactivo
        /*state.localidades[localidades.key].id = localidades.localidades.id;
        state.localidades[localidades.key].id_tribuna = localidades.localidades.id_tribuna;
        state.localidades[localidades.key].nombre = localidades.localidades.nombre;
        state.localidades[localidades.key].impuesto = localidades.localidades.impuesto;
        state.localidades[localidades.key].precio_venta = localidades.localidades.precio_venta;
        state.localidades[localidades.key].precio_servicio = localidades.localidades.precio_servicio;
        state.localidades[localidades.key].puerta_acceso = localidades.localidades.puerta_acceso;
        state.localidades[localidades.key].url_imagen = localidades.localidades.url_imagen;
        state.localidades[localidades.key].aforo = localidades.localidades.aforo;
        state.localidades[localidades.key].silleteria = localidades.localidades.silleteria;
        state.localidades[localidades.key].palco = localidades.localidades.palco;
        state.localidades[localidades.key].puestosxpalco = localidades.localidades.puestosxpalco;
        state.localidades[localidades.key].imagen = localidades.localidades.imagen;*/

        //reactivo
        Vue.set(state.localidades, localidades.key, localidades); 
    },
    localidades4(state,localidades){
        state.localidades[localidades.key].id = localidades.id;
    },
    localidades5(state,localidades){
        state.localidades = localidades;
    },
    //inicio sin multiples monedas
    localidadesevento(state,localidadesevento){
      state.localidadesevento.push(localidadesevento);
    },
    localidadesevento2(state,localidadesevento){
      state.localidadesevento.splice(localidadesevento, 1);
    },
    localidadesevento3(state,localidadesevento){
      //no reactivo
      /*state.localidadesevento[localidadesevento.key].id_localidad = localidadesevento.localidadesevento.id_localidad;
      state.localidadesevento[localidadesevento.key].impuesto = localidadesevento.localidadesevento.impuesto;
      state.localidadesevento[localidadesevento.key].precio_venta = localidadesevento.localidadesevento.precio_venta;
      state.localidadesevento[localidadesevento.key].precio_servicio = localidadesevento.localidadesevento.precio_servicio;
      state.localidadesevento[localidadesevento.key].url_imagen = localidadesevento.localidadesevento.url_imagen;
      state.localidadesevento[localidadesevento.key].imagen = localidadesevento.localidadesevento.imagen;*/

      //reactivo
      Vue.set(state.localidadesevento, localidadesevento.key, localidadesevento); 
    },
    localidadesevento4(state,localidadesevento){
      state.localidadesevento[localidadesevento.key].id_localidad = localidadesevento.id_localidad;
    },
    localidadesevento5(state,localidadesevento){
      state.localidadesevento = localidadesevento;
    },
    //fin sin multiples monedas

    //inicio con multiples monedas
    //inserta el valor de una localidad de último
    localidadesmonedaevento(state,localidadesmonedaevento){
      state.localidadesmonedaevento.push(localidadesmonedaevento);
    },
    //elimina la localidad con el indice del primer parametro del splice
    localidadesmonedaevento2(state,localidadesmonedaevento){
      state.localidadesmonedaevento.splice(localidadesmonedaevento, 1);
    },
    //actualiza el valor de una localidad por una moneda
    localidadesmonedaevento3(state,localidadesmonedaevento){
      //tercer parametro los valores de ese objeto moneda
      Vue.set(state.localidadesmonedaevento[localidadesmonedaevento.localidad], localidadesmonedaevento.key_codigo_moneda, localidadesmonedaevento.moneda); 
    },
    //asigna el id de la localidad al segundo array según el indice del código moneda
    localidadesmonedaevento4(state,localidadesmonedaevento){
      state.localidadesmonedaevento[localidadesmonedaevento.key][localidadesmonedaevento.key_codigo_moneda].id_localidad = localidadesmonedaevento.id_localidad;
    },
    //inserta todas las localidades por moneda al mismo tiempo
    localidadesmonedaevento5(state,localidadesmonedaevento){
      state.localidadesmonedaevento = localidadesmonedaevento;
    },
    localidadesmonedaevento6(state,localidadesmonedaevento){
      state.localidadesmonedaevento[localidadesmonedaevento.localidad].push(localidadesmonedaevento.localidadmonedaevento);
    },
    localidadesmonedaevento7(state,localidadesmonedaevento){
      state.localidadesmonedaevento[localidadesmonedaevento.localidad].splice(localidadesmonedaevento.localidadmonedaevento,1);
    },
    //fin con multiples monedas
    costos(state,costos){
      state.costos.push(costos);
    },
    costos2(state,costos){
      state.costos.splice(costos, 1);
    },
    costos3(state,costos){
      state.costos = costos;
    },
    costos4(state,costos){
      Vue.set(state.costos, costos.key, costos); 
    },
    costosMoneda(state,costosMoneda){
      state.costosMoneda.push(costosMoneda);
    },
    costosMoneda2(state,costosMoneda){
      state.costosMoneda.splice(costosMoneda, 1);
    },
    costosMoneda3(state,costosMoneda){
      state.costosMoneda[costosMoneda.costo].push(costosMoneda.costoMoneda);
    },
    costosMoneda4(state,costosMoneda){
      state.costosMoneda[costosMoneda.costo].splice(costosMoneda.costoMoneda,1);
    },
    auditorioDatos(state,auditorioDatos){
      state.auditorioDatos = auditorioDatos;
    },
    auditorioDatosid (state,id){
      state.auditorioDatos.id = id;
    },
    auditorioDatosnombre (state,nombre){
      state.auditorioDatos.nombre = nombre;
    },
    auditorioDatosdireccion (state,direccion){
      state.auditorioDatos.direccion = direccion;
    },
    auditorioDatosid_pais (state,id_pais){
      state.auditorioDatos.id_pais = id_pais;
    },
    auditorioDatosid_departamento (state,id_departamento){
      state.auditorioDatos.id_departamento = id_departamento;
    },
    auditorioDatosid_ciudad (state,id_ciudad){
      state.auditorioDatos.id_ciudad = id_ciudad;
    },
    auditorioDatoslatitud (state,latitud){
      state.auditorioDatos.latitud = latitud;
    },
    auditorioDatoslongitud (state,longitud){
      state.auditorioDatos.longitud = longitud;
    },
    auditorioDatosaforo (state,aforo){
      state.auditorioDatos.aforo = aforo;
    },
    auditorioDatosurl_imagen (state,url_imagen){
      state.auditorioDatos.url_imagen = url_imagen;
    },
    auditorioDatoscodigo_mapeado (state,codigo_mapeado){
      state.auditorioDatos.codigo_mapeado = codigo_mapeado;
    },
    datosGenerales(state,datosGenerales){
      state.datosGenerales = datosGenerales;
    },
    datosGeneralesnombreEvento (state,nombreEvento){
      state.datosGenerales.nombreEvento = nombreEvento;
    },
    datosGeneralesdescripcionEvento (state,descripcionEvento){
      state.datosGenerales.descripcionEvento = descripcionEvento;
    },
    datosGeneralestemporada (state,temporada){
      state.datosGenerales.temporada = temporada;
    },
    datosGeneralesauditorioevento (state,auditorioevento){
      state.datosGenerales.auditorioevento = auditorioevento;
    },
    datosGeneralesfechaEvento (state,fechaEvento){
      state.datosGenerales.fechaEvento = fechaEvento;
    },
    datosGeneralesfechaFinEvento (state,fechaFinEvento){
      state.datosGenerales.fechaFinEvento = fechaFinEvento;
    },
    datosGeneraleseventoUnSoloDia (state,eventoUnSoloDia){
      state.datosGenerales.eventoUnSoloDia = eventoUnSoloDia;
    },
    datosGeneraleshoraInicio (state,horaInicio){
      state.datosGenerales.horaInicio = horaInicio;
    },
    datosGeneraleshoraAperturaPuertas (state,horaAperturaPuertas){
      state.datosGenerales.horaAperturaPuertas = horaAperturaPuertas;
    },
    datosGeneraleshoraFinalizacion (state,horaFinalizacion){
      state.datosGenerales.horaFinalizacion = horaFinalizacion;
    },
    datosGeneralestipoEvento (state,tipoEvento){
      state.datosGenerales.tipoEvento = tipoEvento;
    },
    datosGeneralestipocosto (state,tipocosto){
      state.datosGenerales.tipocosto = tipocosto;
    },
    datosGeneralescodigoPulep (state,codigoPulep){
      state.datosGenerales.codigoPulep = codigoPulep;
    },
    datosGeneralescostotransaccion (state,costotransaccion){
      state.datosGenerales.costotransaccion = costotransaccion;
    },
    datosGeneralesentregaboletadigital (state,entregaboletadigital){
      state.datosGenerales.entregaboletadigital = entregaboletadigital;
    },
    datosGeneralesentregaboletafisica (state,entregaboletafisica){
      state.datosGenerales.entregaboletafisica = entregaboletafisica;
    },
    datosGeneralescodigomoneda (state,codigo_moneda){
      state.datosGenerales.codigo_moneda = codigo_moneda;
    },
    datosGeneralesmonto_minimo (state,monto_minimo){
      state.datosGenerales.monto_minimo = monto_minimo;
    },
    datosGeneralesfecha_inicio_venta_puntos (state,fecha_inicio_venta_puntos){
      state.datosGenerales.fecha_inicio_venta_puntos = fecha_inicio_venta_puntos;
    },
    datosGeneraleshora_inicio_venta_puntos (state,hora_inicio_venta_puntos){
      state.datosGenerales.hora_inicio_venta_puntos = hora_inicio_venta_puntos;
    },
    datosGeneralesfecha_inicio_venta_internet (state,fecha_inicio_venta_internet){
      state.datosGenerales.fecha_inicio_venta_internet = fecha_inicio_venta_internet;
    },
    datosGeneraleshora_inicio_venta_internet (state,hora_inicio_venta_internet){
      state.datosGenerales.hora_inicio_venta_internet = hora_inicio_venta_internet;
    },
    datosGeneralesventa_linea (state,venta_linea){
      state.datosGenerales.venta_linea = venta_linea;
    },
    datosGeneralesdomicilios (state,domicilios){
      state.datosGenerales.domicilios = domicilios;
    },
    datosGeneralescant_max_boletas (state,cant_max_boletas){
      state.datosGenerales.cant_max_boletas = cant_max_boletas;
    },
    responsableEvento(state,responsableEvento){
      state.responsableEvento = responsableEvento;
    },
    responsableEventoresponsable (state,responsable){
      state.responsableEvento.responsable = responsable;
    },
    responsableEventonombreRazonSocial (state,nombreRazonSocial){
      state.responsableEvento.nombreRazonSocial = nombreRazonSocial;
    },
    responsableEventotipoIdentificacion (state,tipoIdentificacion){
      state.responsableEvento.tipoIdentificacion = tipoIdentificacion;
    },
    responsableEventoIdentificacion (state,Identificacion){
      state.responsableEvento.Identificacion = Identificacion;
    },
    responsableEventodireccion (state,direccion){
      state.responsableEvento.direccion = direccion;
    },
    responsableEventopais (state,pais){
      state.responsableEvento.pais = pais;
    },
    responsableEventodepartamento (state,departamento){
      state.responsableEvento.departamento = departamento;
    },
    responsableEventociudad (state,ciudad){
      state.responsableEvento.ciudad = ciudad;
    },
    responsableEventotipoCliente (state,tipoCliente){
      state.responsableEvento.tipoCliente = tipoCliente;
    },
    responsableEventoemail (state,email){
      state.responsableEvento.email = email;
    },
    responsableEventotelefono (state,telefono){
      state.responsableEvento.telefono = telefono;
    },
    preventasEvento (state,preventa){
      state.preventasEvento.push(preventa);
    },
    preventasEvento2 (state,preventa){
      state.preventasEvento.splice(preventa, 1);
    },
    preventasmonedaEvento (state,preventamoneda){
      state.preventasmonedaEvento.push(preventamoneda);
    },
    preventasmonedaEvento2 (state,preventamoneda){
      state.preventasmonedaEvento.splice(preventamoneda, 1);
    },    
    preventasmonedaEvento3(state,preventamoneda){
      state.preventasmonedaEvento[preventamoneda.preventa].push(preventamoneda.preventamoneda);
    },
    preventasmonedaEvento4(state,preventamoneda){
      state.preventasmonedaEvento[preventamoneda.preventa].splice(preventamoneda.preventamoneda,1);
    },
    eventoMonto_minimo (state,montominimo){
      state.eventoMonto_minimo.push(montominimo);
    },
    eventoMonto_minimo2 (state,montominimo){
      state.eventoMonto_minimo.splice(montominimo, 1);
    },
    imagenesEventoCarousel (state,imagen){
      state.imagenesEventoCarousel.push(imagen);
    },
    imagenesEventoCarousel2 (state,imagen){
      state.imagenesEventoCarousel.splice(imagen, 1);
    },
    imagenesEventoTwitter (state,imagen){
      state.imagenesEventoTwitter.push(imagen);
    },
    imagenesEventoTwitter2 (state,imagen){
      state.imagenesEventoTwitter.splice(imagen, 1);
    },
    imagenesEventoDisenoBoleta (state,imagen){
      state.imagenesEventoDisenoBoleta.push(imagen);
    },
    imagenesEventoDisenoBoleta2 (state,imagen){
      state.imagenesEventoDisenoBoleta.splice(imagen, 1);
    },
    imagenesEventoLocalidad (state,imagen){
      state.imagenesEventoLocalidad.push(imagen);
    },
    imagenesEventoLocalidad2 (state,imagen){
      state.imagenesEventoLocalidad.splice(imagen, 1);
    },
    imagenesEventoFacebook (state,imagen){
      state.imagenesEventoFacebook.push(imagen);
    },
    imagenesEventoFacebook2 (state,imagen){
      state.imagenesEventoFacebook.splice(imagen, 1);
    },
    imagenesEventoVert (state,imagen){
      state.imagenesEventoVert.push(imagen);
    },
    imagenesEventoVert2 (state,imagen){
      state.imagenesEventoVert.splice(imagen, 1);
    },
    imagenesEventoMiniatura (state,imagen){
      state.imagenesEventoMiniatura.push(imagen);
    },
    imagenesEventoMiniatura2 (state,imagen){
      state.imagenesEventoMiniatura.splice(imagen, 1);
    },
    imagenesEventoBanner (state,imagen){
      state.imagenesEventoBanner.push(imagen);
    },
    imagenesEventoBanner2 (state,imagen){
      state.imagenesEventoBanner.splice(imagen, 1);
    },
    imagenesEventoDesktop (state,imagen){
      state.imagenesEventoDesktop.push(imagen);
    },
    imagenesEventoDesktop2 (state,imagen){
      state.imagenesEventoDesktop.splice(imagen, 1);
    },
    imagenesEventoMovil (state,imagen){
      state.imagenesEventoMovil.push(imagen);
    },
    imagenesEventoMovil2 (state,imagen){
      state.imagenesEventoMovil.splice(imagen, 1);
    },
    imagenesEventoPrecios (state,imagen){
      state.imagenesEventoPrecios.push(imagen);
    },
    imagenesEventoPrecios2 (state,imagen){
      state.imagenesEventoPrecios.splice(imagen, 1);
    },
    clear(state){
      state.selectedCondiciones = [];
      state.preventasEvento = [];
      state.preventasmonedaEvento = [];
      state.eventoMonto_minimo = []; 
      state.selectedPuntoventas = [];
      state.artistasCheck = [];
      state.artistasCheckTemp = [];
      state.tribunasShow2 = [];
      state.modalPlantilla = 'new';
      state.codigomonedaEvento = [];
      state.imagenMapeo ={
        id: '',
        id_auditorio: '',
        area_mapeada: '',
        nombre: '',
        imagen: '',
        status: '',
      };
      // state.localidades = [{
      //   id:'',
      //   id_tribuna:'',
      //   nombre:'',
      //   impuesto:'',
      //   precio_venta:'',
      //   precio_servicio:'',
      //   puerta_acceso:'',
      //   url_imagen:'',
      //   aforo:'',
      //   silleteria:'',
      //   palco:'',
      //   puestosxpalco:'',
      //   imagen:'',
      // }];
      state.localidades = [];
      state.localidadesevento = [{
        id_localidad:'',
        impuesto:'',
        precio_venta:'',
        precio_servicio:'',
        url_imagen:'',
        imagen: '',
      }];
      state.localidadesmonedaevento = [[]];
      state.costos = [];
      state.costosMoneda = [];
      state.auditorioDatos = {
        id: '',
        nombre: '',
        direccion: '',
        id_pais: '',
        id_departamento: '',
        id_ciudad: '', 
        latitud: 4.6699538,
        longitud: -74.1171362,
        aforo: '',     
        url_imagen: '',
        codigo_mapeado: '',          
      };
      state.datosGenerales = {
        nombreEvento: null,
        descripcionEvento: null,
        temporada: null,
        auditorioevento: null,
        fechaEvento: null,
        fechaFinEvento: null,
        eventoUnSoloDia: false,
        horaInicio: null,
        horaAperturaPuertas: null,
        horaFinalizacion: null,
        tipoEvento: null,
        tipocosto: null,
        codigoPulep: null,
        costotransaccion: null,
        entregaboletadigital: null,
        entregaboletafisica: null,
        monto_minimo: null,
        fecha_inicio_venta_puntos: null,
        hora_inicio_venta_puntos: null,
        fecha_inicio_venta_internet: null,
        hora_inicio_venta_internet: null,
        venta_linea: false,
        domicilios: false,
        datosGeneralescant_max_boletas: null,
      };
      state.responsableEvento = {
        responsable: null,
        nombreRazonSocial: null,
        tipoIdentificacion: null,
        Identificacion: null,
        direccion: null,
        pais: '',
        departamento: null,
        ciudad: null,
        tipoCliente: false,
        email: null,
        telefono: null,
      };
      state.imagenesEventoCarousel = [];
      state.imagenesEventoTwitter = [];
      state.imagenesEventoDisenoBoleta = [];
      state.imagenesEventoLocalidad = [];
      state.imagenesEventoFacebook = [];
      state.imagenesEventoVert = [];
      state.imagenesEventoMiniatura = [];
      state.imagenesEventoBanner = [];
      state.imagenesEventoDesktop = [];
      state.imagenesEventoMovil = [];
      state.imagenesEventoPrecios = [];
      return '';
    },
  },
  actions: {
    searchArtistas({state,getters,commit,rootState},searchArtistas){
      commit('searchArtistas',searchArtistas);
    },
    selectedCondiciones({state,getters,commit,rootState},selectedCondiciones){
      commit('selectedCondiciones',selectedCondiciones);
    },
    selectedPuntoventas({state,getters,commit,rootState},selectedPuntoventas){
      commit('selectedPuntoventas',selectedPuntoventas);
    },
    artistasCheck({state,getters,commit,rootState},artistasCheck){
      commit('artistasCheck',artistasCheck);
    },
    artistasCheckTemp({state,getters,commit,rootState},artistasCheckTemp){
      commit('artistasCheckTemp',artistasCheckTemp);
    },
    artistasCheckTemp2({state,getters,commit,rootState},artistasCheckTemp){
      commit('artistasCheckTemp2',artistasCheckTemp);
    },
    tribunasShow2({state,getters,commit,rootState},tribunasShow2){
      commit('tribunasShow2',tribunasShow2);
    },
    tribunasShow22({state,getters,commit,rootState},tribunasShow2){
      commit('tribunasShow22',tribunasShow2);
    },
    tribunasShow25({state,getters,commit,rootState},tribunasShow2){
      commit('tribunasShow25',tribunasShow2);
    },
    modalPlantilla({state,getters,commit,rootState},modalPlantilla){
      commit('modalPlantilla',modalPlantilla);
    },
    codigomonedaEvento({state,getters,commit,rootState},codigomonedaEvento){
      commit('codigomonedaEvento',codigomonedaEvento);
    },
    codigomonedaEvento2({state,getters,commit,rootState},codigomonedaEvento){
      commit('codigomonedaEvento2',codigomonedaEvento);
    },
    imagenMapeo({state,getters,commit,rootState},imagenMapeo){
      commit('imagenMapeo',imagenMapeo);
    },
    localidades({state,getters,commit,rootState},localidades){
      commit('localidades',localidades);
    },
    localidades2({state,getters,commit,rootState},localidades){
      commit('localidades2',localidades);
    },
    localidades3({state,getters,commit,rootState},localidades){
      commit('localidades3',localidades);
    },
    localidades4({state,getters,commit,rootState},localidades){
      commit('localidades4',localidades);
    },
    localidades5({state,getters,commit,rootState},localidades){
      commit('localidades5',localidades);
    },
    localidadesevento({state,getters,commit,rootState},localidadesevento){
      commit('localidadesevento',localidadesevento);
    },
    localidadesevento2({state,getters,commit,rootState},localidadesevento){
      commit('localidadesevento2',localidadesevento);
    },
    localidadesevento3({state,getters,commit,rootState},localidadesevento){
      commit('localidadesevento3',localidadesevento);
    },
    localidadesevento4({state,getters,commit,rootState},localidadesevento){
      commit('localidadesevento4',localidadesevento);
    },
    localidadesevento5({state,getters,commit,rootState},localidadesevento){
      commit('localidadesevento5',localidadesevento);
    },
    localidadesmonedaevento({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento',localidadesmonedaevento);
    },
    localidadesmonedaevento2({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento2',localidadesmonedaevento);
    },
    localidadesmonedaevento3({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento3',localidadesmonedaevento);
    },
    localidadesmonedaevento4({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento4',localidadesmonedaevento);
    },
    localidadesmonedaevento5({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento5',localidadesmonedaevento);
    },
    localidadesmonedaevento6({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento6',localidadesmonedaevento);
    },
    localidadesmonedaevento7({state,getters,commit,rootState},localidadesmonedaevento){
      commit('localidadesmonedaevento7',localidadesmonedaevento);
    },
    costos({state,getters,commit,rootState},costos){
      commit('costos',costos);
    },
    costos2({state,getters,commit,rootState},costos){
      commit('costos2',costos);
    },
    costos3({state,getters,commit,rootState},costos){
      commit('costos3',costos);
    },
    costos4({state,getters,commit,rootState},costos){
      commit('costos4',costos);
    },
    costosMoneda({state,getters,commit,rootState},costosMoneda){
      commit('costosMoneda',costosMoneda);
    },
    costosMoneda2({state,getters,commit,rootState},costosMoneda){
      commit('costosMoneda2',costosMoneda);
    },
    costosMoneda3({state,getters,commit,rootState},costosMoneda){
      commit('costosMoneda3',costosMoneda);
    },
    costosMoneda4({state,getters,commit,rootState},costosMoneda){
      commit('costosMoneda4',costosMoneda);
    },
    auditorioDatos({state,getters,commit,rootState},auditorioDatos){
      commit('auditorioDatos',auditorioDatos);
    },
    auditorioDatosid ({state,getters,commit,rootState},id){
      commit('auditorioDatosid',id);
    },
    auditorioDatosnombre ({state,getters,commit,rootState},nombre){
      commit('auditorioDatosnombre',nombre);
    },
    auditorioDatosdireccion ({state,getters,commit,rootState},direccion){
      commit('auditorioDatosdireccion',direccion);
    },
    auditorioDatosid_pais ({state,getters,commit,rootState},id_pais){
      commit('auditorioDatosid_pais',id_pais);
    },
    auditorioDatosid_departamento ({state,getters,commit,rootState},id_departamento){
      commit('auditorioDatosid_departamento',id_departamento);
    },
    auditorioDatosid_ciudad ({state,getters,commit,rootState},id_ciudad){
      commit('auditorioDatosid_ciudad',id_ciudad);
    },
    auditorioDatoslatitud ({state,getters,commit,rootState},latitud){
      commit('auditorioDatoslatitud',latitud);
    },
    auditorioDatoslongitud ({state,getters,commit,rootState},longitud){
      commit('auditorioDatoslongitud',longitud);
    },
    auditorioDatosaforo ({state,getters,commit,rootState},aforo){
      commit('auditorioDatosaforo',aforo);
    },
    auditorioDatosurl_imagen ({state,getters,commit,rootState},url_imagen){
      commit('auditorioDatosurl_imagen',url_imagen);
    },
    auditorioDatoscodigo_mapeado ({state,getters,commit,rootState},codigo_mapeado){
      commit('auditorioDatoscodigo_mapeado',codigo_mapeado);
    },
    datosGenerales({state,getters,commit,rootState},datosGenerales){
      commit('datosGenerales',datosGenerales);
    },
    datosGeneralesnombreEvento({state,getters,commit,rootState},datosGenerales){
      commit('datosGeneralesnombreEvento',datosGenerales);
    },
    datosGeneralesdescripcionEvento({state,getters,commit,rootState},datosGenerales){
      commit('datosGeneralesdescripcionEvento',datosGenerales);
    },
    datosGeneralestemporada ({state,getters,commit,rootState},temporada){
      commit('datosGeneralestemporada',temporada);
    },
    datosGeneralesauditorioevento ({state,getters,commit,rootState},auditorioevento){
      commit('datosGeneralesauditorioevento',auditorioevento);
    },
    datosGeneralesfechaEvento ({state,getters,commit,rootState},fechaEvento){
      commit('datosGeneralesfechaEvento',fechaEvento);
    },
    datosGeneralesfechaFinEvento ({state,getters,commit,rootState},fechaFinEvento){
      commit('datosGeneralesfechaFinEvento',fechaFinEvento);
    },
    datosGeneraleseventoUnSoloDia ({state,getters,commit,rootState},eventoUnSoloDia){
      commit('datosGeneraleseventoUnSoloDia',eventoUnSoloDia);
    },
    datosGeneraleshoraInicio ({state,getters,commit,rootState},horaInicio){
      commit('datosGeneraleshoraInicio',horaInicio);
    },
    datosGeneraleshoraAperturaPuertas ({state,getters,commit,rootState},horaAperturaPuertas){
      commit('datosGeneraleshoraAperturaPuertas',horaAperturaPuertas);
    },
    datosGeneraleshoraFinalizacion ({state,getters,commit,rootState},horaFinalizacion){
      commit('datosGeneraleshoraFinalizacion',horaFinalizacion);
    },
    datosGeneralestipoEvento ({state,getters,commit,rootState},tipoEvento){
      commit('datosGeneralestipoEvento',tipoEvento);
    },
    datosGeneralestipocosto ({state,getters,commit,rootState},tipocosto){
      commit('datosGeneralestipocosto',tipocosto);
    },
    datosGeneralescodigoPulep ({state,getters,commit,rootState},codigoPulep){
      commit('datosGeneralescodigoPulep',codigoPulep);
    },
    datosGeneralescostotransaccion ({state,getters,commit,rootState},costotransaccion){
      commit('datosGeneralescostotransaccion',costotransaccion);
    },
    datosGeneralesentregaboletadigital ({state,getters,commit,rootState},entregaboletadigital){
      commit('datosGeneralesentregaboletadigital',entregaboletadigital);
    },
    datosGeneralesentregaboletafisica ({state,getters,commit,rootState},entregaboletafisica){
      commit('datosGeneralesentregaboletafisica',entregaboletafisica);
    },
    datosGeneralescodigomoneda ({state,getters,commit,rootState},codigo_moneda){
      commit('datosGeneralescodigomoneda',codigo_moneda);
    },
    datosGeneralesmonto_minimo ({state,getters,commit,rootState},monto_minimo){
      commit('datosGeneralesmonto_minimo',monto_minimo);
    },
    datosGeneralesfecha_inicio_venta_puntos ({state,getters,commit,rootState},fecha_inicio_venta_puntos){
      commit('datosGeneralesfecha_inicio_venta_puntos',fecha_inicio_venta_puntos);
    },
    datosGeneraleshora_inicio_venta_puntos ({state,getters,commit,rootState},hora_inicio_venta_puntos){
      commit('datosGeneraleshora_inicio_venta_puntos',hora_inicio_venta_puntos);
    },
    datosGeneralesfecha_inicio_venta_internet ({state,getters,commit,rootState},fecha_inicio_venta_internet){
      commit('datosGeneralesfecha_inicio_venta_internet',fecha_inicio_venta_internet);
    },
    datosGeneraleshora_inicio_venta_internet ({state,getters,commit,rootState},hora_inicio_venta_internet){
      commit('datosGeneraleshora_inicio_venta_internet',hora_inicio_venta_internet);
    },
    datosGeneralesventa_linea ({state,getters,commit,rootState},venta_linea){
      commit('datosGeneralesventa_linea',venta_linea);
    },
    datosGeneralesdomicilios ({state,getters,commit,rootState},domicilios){
      commit('datosGeneralesdomicilios',domicilios);
    },
    datosGeneralescant_max_boletas ({state,getters,commit,rootState},cant_max_boletas){
      commit('datosGeneralescant_max_boletas',cant_max_boletas);
    },
    responsableEvento({state,getters,commit,rootState},responsableEvento){
      commit('responsableEvento',responsableEvento);
    },
    responsableEventoresponsable ({state,getters,commit,rootState},responsable){
      commit('responsableEventoresponsable',responsable);
    },
    responsableEventonombreRazonSocial ({state,getters,commit,rootState},nombreRazonSocial){
      commit('responsableEventonombreRazonSocial',nombreRazonSocial);
    },
    responsableEventotipoIdentificacion ({state,getters,commit,rootState},tipoIdentificacion){
      commit('responsableEventotipoIdentificacion',tipoIdentificacion);
    },
    responsableEventoIdentificacion ({state,getters,commit,rootState},Identificacion){
      commit('responsableEventoIdentificacion',Identificacion);
    },
    responsableEventodireccion ({state,getters,commit,rootState},direccion){
      commit('responsableEventodireccion',direccion);
    },
    responsableEventopais ({state,getters,commit,rootState},pais){
      commit('responsableEventopais',pais);
    },
    responsableEventodepartamento ({state,getters,commit,rootState},departamento){
      commit('responsableEventodepartamento',departamento);
    },
    responsableEventociudad ({state,getters,commit,rootState},ciudad){
      commit('responsableEventociudad',ciudad);
    },
    responsableEventotipoCliente ({state,getters,commit,rootState},tipoCliente){
      commit('responsableEventotipoCliente',tipoCliente);
    },
    responsableEventoemail ({state,getters,commit,rootState},email){
      commit('responsableEventoemail',email);
    },
    responsableEventotelefono ({state,getters,commit,rootState},telefono){
      commit('responsableEventotelefono',telefono);
    },
    preventasEvento ({state,getters,commit,rootState},preventa){
      commit('preventasEvento',preventa);
    },
    preventasEvento2 ({state,getters,commit,rootState},preventa){
      commit('preventasEvento2',preventa);
    },
    preventasmonedaEvento ({state,getters,commit,rootState},preventamoneda){
      commit('preventasmonedaEvento',preventamoneda);
    },
    preventasmonedaEvento2 ({state,getters,commit,rootState},preventamoneda){
      commit('preventasmonedaEvento2',preventamoneda);
    },
    preventasmonedaEvento3 ({state,getters,commit,rootState},preventamoneda){
      commit('preventasmonedaEvento3',preventamoneda);
    },
    preventasmonedaEvento4 ({state,getters,commit,rootState},preventamoneda){
      commit('preventasmonedaEvento4',preventamoneda);
    },
    eventoMonto_minimo ({state,getters,commit,rootState},montominimo){
      commit('eventoMonto_minimo',montominimo);
    },
    eventoMonto_minimo2 ({state,getters,commit,rootState},montominimo){
      commit('eventoMonto_minimo2',montominimo);
    },    
    imagenesEventoCarousel ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoCarousel',imagen);
    },
    imagenesEventoCarousel2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoCarousel2',imagen);
    },
    imagenesEventoTwitter ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoTwitter',imagen);
    },
    imagenesEventoTwitter2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoTwitter2',imagen);
    },
    imagenesEventoDisenoBoleta ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoDisenoBoleta',imagen);
    },
    imagenesEventoDisenoBoleta2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoDisenoBoleta2',imagen);
    },
    imagenesEventoLocalidad ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoLocalidad',imagen);
    },
    imagenesEventoLocalidad2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoLocalidad2',imagen);
    },
    imagenesEventoFacebook ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoFacebook',imagen);
    },
    imagenesEventoFacebook2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoFacebook2',imagen);
    },
    imagenesEventoVert ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoVert',imagen);
    },
    imagenesEventoVert2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoVert2',imagen);
    },
    imagenesEventoMiniatura ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoMiniatura',imagen);
    },
    imagenesEventoMiniatura2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoMiniatura2',imagen);
    },
    imagenesEventoBanner ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoBanner',imagen);
    },
    imagenesEventoBanner2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoBanner2',imagen);
    },
    imagenesEventoDesktop ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoDesktop',imagen);
    },
    imagenesEventoDesktop2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoDesktop2',imagen);
    },
    imagenesEventoMovil ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoMovil',imagen);
    },
    imagenesEventoMovil2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoMovil2',imagen);
    },
    imagenesEventoPrecios ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoPrecios',imagen);
    },
    imagenesEventoPrecios2 ({state,getters,commit,rootState},imagen){
      commit('imagenesEventoPrecios2',imagen);
    },
    clear({state,getters,commit,rootState}){
      commit('clear');
    },
  },
}