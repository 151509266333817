<template>
  <v-app>
    <v-container>
      <h3>Visión</h3>
      <div class="row row-clearfix">
        <div class="col-md col-lg col-sm">
          <p>
            Ser la empresa con el mayor portafolio de productos de
            identificación, control y comercialización de eventos, apoyados en
            tecnología de punta, con altos niveles de calidad que nos permitan
            satisfacer las necesidades existentes en el mercado, ofreciendo los
            mejores tiempos de respuesta a un precio justo.
          </p>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "vision",
};
</script>