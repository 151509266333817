<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>
              Eventos del punto de venta: <b>{{ puntoventa.nombre_razon }}</b>
            </h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: #1976d2 !important"
                :to="{ name: 'PuntosVentaJefePunto' }"
                title="Ver todos los puntos de venta"
                ><u>Volver a los puntos de venta</u></router-link
              ></span
            ><span> > </span><span>Eventos por puntos de venta</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg8 md8 sm6 xs12 mb-3>
          <v-select
            v-model="eventosParaAsignar"
            :items="eventosSinAsignar"
            item-value="id"
            item-text="nombre"
            attach
            chips
            label="Asignar eventos a este punto de venta"
            multiple
          ></v-select>
        </v-flex>
        <v-flex lg4 md4 sm6 xs12 mb-3>
          <v-btn
            :disabled="eventosParaAsignar.length <= 0"
            class="mt-4"
            color="success"
            @click="asignarEventos"
            ><i class="material-icons">save</i> GUARDAR ASIGNACION</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </v-container>
    <div class="pl-5 pr-5">
      <v-card>
        <v-card-title class="pr-5 pl-5">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="puntoventa.eventos"
          :search="search"
          class="elevation-1 table-ventas pr-3 pl-3"
          :no-data-text="'No se han encontrado eventos...'"
          must-sort
          rows-per-page-text="Resultados por pagina"
        >
          <template v-slot:items="props">
            <td class="font-weight-bold">{{ props.item.nombre }}</td>
            <td :title="props.item.descripcion">
              {{ props.item.descripcion.substr(0, 300) }}...
            </td>
            <td>{{ formatFechaDate(props.item.fecha_evento) }}</td>
            <td>{{ props.item.hora_inicio }}</td>
            <td>{{ props.item.codigo_pulep }}</td>
          </template>
          <template v-slot:item.acciones="props">
            <td class="text-xs-center">
              <v-icon
                medium
                title="Remover de este punto de venta"
                @click="deleteEventoPuntoVenta(props.item.id)"
              >
                delete
              </v-icon>
            </td>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              No se encontraron puntos de venta con "{{ search }}".
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "NOMBRE", value: "nombre" },
        { text: "DESCRIPCION", value: "descripcion" },
        { text: "FECHA", value: "fecha_evento" },
        { text: "HORA INICIO", value: "hora_inicio" },
        { text: "CODIGO PULEP", value: "codigo_pulep" },
        { text: "ACCIONES", value: "acciones", sortable: false },
      ],
      eventosParaAsignar: [],
    };
  },
  async beforeCreate() {
    document.title = `Eventos por punto de venta - TicketShop`;
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("user/listTipoIdentificaciones");
    await this.$store.dispatch(
      "puntoventa/eventosPuntoVenta",
      this.$route.params.id
    );
    await this.$store.dispatch(
      "puntoventa/eventosSinAsignar",
      this.$route.params.id
    );
  },
  computed: {
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.user.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    puntoventa() {
      return this.$store.getters["puntoventa/puntoventa"];
    },
    eventosSinAsignar() {
      return this.$store.getters["puntoventa/eventosSinAsignar"];
    },
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.user.tipo_identificacion
      )[0].descripcion;
    },
  },
  methods: {
    deleteEventoPuntoVenta(id_evento) {
      var self = this;
      swal({
        title: "¿Estas seguro de remover este evento del punto de venta?",
        text: "",
        icon: "warning",
        buttons: ["No, cancelar", "Confirmar"],
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {
          self.$store.dispatch("puntoventa/deleteEventoPorPuntoVenta", {
            id_evento: id_evento,
            punto_venta: self.$route.params.id,
          });
          self.$store.dispatch(
            "puntoventa/eventosPuntoVenta",
            self.$route.params.id
          );
          self.$store.dispatch(
            "puntoventa/eventosSinAsignar",
            self.$route.params.id
          );
        }
      });
    },
    getDepartamento(id_ciudad) {
      var id_departamento = this.ciudades.filter(
        (item) => item.id == id_ciudad
      )[0].id_departamento;
      return this.departamentos.filter((item) => item.id == id_departamento)[0]
        .descripcion;
    },
    eventosPuntoVenta(id_pdv) {
      this.$router.push({ name: "eventosPuntoVenta", params: { id: id_pdv } });
    },
    formatFechaDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    asignarEventos() {
      if (this.eventosParaAsignar.length > 0) {
        this.$store.dispatch("puntoventa/eventosParaAsignar", {
          id_puntoventa: this.$route.params.id,
          eventos: this.eventosParaAsignar,
        });
        this.$store.dispatch(
          "puntoventa/eventosPuntoVenta",
          this.$route.params.id
        );
        this.$store.dispatch(
          "puntoventa/eventosSinAsignar",
          this.$route.params.id
        );
        this.eventosParaAsignar = [];
      } else {
        swal("Error!", "Debes seleccionar por lo menos un evento!", "error");
      }
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
}
.check_asignar_pv .accent--text {
  color: #1969ed !important;
  caret-color: #1969ed !important;
}
</style>
