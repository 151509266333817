import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers,headersMultipart} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       auditorio_mapeados: [],
       auditorio_mapeadosauditorio: [],
       localidadesauditorio_mapeado: [],
       localidadeseventoauditorio_mapeado: [],
       localidadeseventoauditorioGeneral_mapeado: [],
       generos: [],
       auditorio_mapeado: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listAuditorio_mapeados: (state,getters,rootState) => {
          return state.auditorio_mapeados;
    },
    listAuditorio_mapeadosAuditorio: (state,getters,rootState) => {
          return state.auditorio_mapeadosauditorio;
    },
    listLocalidadesAuditorio_mapeado: (state,getters,rootState) => {
          return state.localidadesauditorio_mapeado;
    },
    listLocalidadesEventoAuditorio_mapeado: (state,getters,rootState) => {
          return state.localidadeseventoauditorio_mapeado;
    },
    listLocalidadesEventoAuditorioGeneral_mapeado: (state,getters,rootState) => {
      return state.localidadeseventoauditorioGeneral_mapeado;
},
    auditorio_mapeado: (state,getters,rootState) => {
        return state.auditorio_mapeado;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listAuditorio_mapeados: (state,auditorio_mapeados) => {
          state.auditorio_mapeados = auditorio_mapeados.data;
          state.pagination.totalItems = auditorio_mapeados.total;
          state.pagination.page = auditorio_mapeados.current_page;
          state.pagination.rowsPerPage = auditorio_mapeados.per_page;
       },
       listAuditorio_mapeadosAuditorio: (state,auditorio_mapeadosauditorio) => {
          state.auditorio_mapeadosauditorio = auditorio_mapeadosauditorio;
       },
       listLocalidadesAuditorio_mapeado: (state,localidadesauditorio_mapeado) => {
          state.localidadesauditorio_mapeado = localidadesauditorio_mapeado;
       },
       listLocalidadesEventoAuditorio_mapeado: (state,localidadeseventoauditorio_mapeado) => {
          state.localidadeseventoauditorio_mapeado = localidadeseventoauditorio_mapeado;
       },
       listLocalidadesEventoAuditorioGeneral_mapeado: (state,localidadeseventoauditorioGeneral_mapeado) => {
        state.localidadeseventoauditorioGeneral_mapeado = localidadeseventoauditorioGeneral_mapeado;
     },
      viewAuditorio_mapeado(state,id){
        let auxAuditorio_mapeado = state.auditorio_mapeados.filter((item) => item.id == id);
        state.auditorio_mapeado = auxAuditorio_mapeado[0];
      },
           listAuditorio_mapeadosSearch: (state,auditorio_mapeados) => {
            if(auditorio_mapeados != null){ 
                state.auditorio_mapeados = auditorio_mapeados;
            }else{
              state.auditorio_mapeados = null;
            }
       },
       setAuditorio_mapeado(state,auditorio_mapeado){
        state.auditorio_mapeado=auditorio_mapeado;
       },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listAuditorio_mapeados({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}auditorios_map_all`,null,{headers: headers})
      .then(response => {
        commit('listAuditorio_mapeados',response.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los auditorio_mapeados!", "error");
        console.error(error);
      });

    },
    listAuditorio_mapeadosAuditorio({state,getters,commit,rootState},key){
      state.loading = true;
      return axios.get(`${configApi.apiUrl}auditoriosmap_auditorio/${key}`,null,{headers: headers})
      .then(response => {
        commit('listAuditorio_mapeadosAuditorio',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los auditorio_mapeados por auditorio!", "error");
        console.error(error);
      });

    },
    listLocalidadesAuditorio_mapeado({state,getters,commit,rootState},key){
      state.loading = true;
      return axios.get(`${configApi.apiUrl}localidades_auditorio_map/${key}`,null,{headers: headers})
      .then(response => {
        commit('listLocalidadesAuditorio_mapeado',response.data.data[0].localidades);
        store.dispatch('solicitudevento/localidades5',response.data.data[0].localidades);
        //inicio sin multiples monedas
        var localidadeseventoTemp = [];
        for (var i = 0; i < response.data.data[0].localidades.length; i++) {
          localidadeseventoTemp.push({
            id_localidad: response.data.data[0].localidades[i].id,
            impuesto: '',
            precio_venta: '',
            precio_servicio: '',
            url_imagen: '',
            imagen: '',
          });
        }
        store.dispatch('solicitudevento/localidadesevento5',localidadeseventoTemp);
        //fin sin multiples monedas
        //inicio con multiples monedas
        var localidadesmonedaevento = [];
        for (var i = 0; i < response.data.data[0].localidades.length; i++) {
          localidadesmonedaevento.push([]);
        }
        for (var i = 0; i < response.data.data[0].localidades.length; i++) {
          for (var j = 0; j < store.getters['solicitudevento/codigomonedaEvento'].length; j++) {
            localidadesmonedaevento[i].push({
              precio_venta: '',
              precio_servicio: '',
            });
          }
        }
        store.dispatch('solicitudevento/localidadesmonedaevento5',localidadesmonedaevento);
        //fin con multiples monedas
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        commit('listLocalidadesAuditorio_mapeado',[]);
        store.dispatch('solicitudevento/localidades5',[]);
        store.dispatch('solicitudevento/localidadesevento5',[]);
        store.dispatch('solicitudevento/localidadesmonedaevento5',[]);
        store.dispatch('solicitudevento/tribunasShow25',[]);
        /*swal("Error!", "Error al obtener las localidades por auditorio_mapeado!", "error");*/
        console.error(error);
      });
    },
    listLocalidadesEventoAuditorioGeneral_mapeado({state,getters,commit,rootState},key){
      state.loading = true;
      return axios.get(`${configApi.apiUrl}localidadesevento_auditorio_map/${key}`,null,{headers: headers})
      .then(response => {
        commit('listLocalidadesEventoAuditorioGeneral_mapeado',response.data.data[0].localidades);
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        commit('listLocalidadesEventoAuditorioGeneral_mapeado',[]);
        /*swal("Error!", "Error al obtener las localidades por auditorio_mapeado!", "error");*/
        console.error(error);
      });
    }, 
    
    listLocalidadesEventoAuditorio_mapeado({state,getters,commit,rootState},key){
      state.loading = true;
      return axios.get(`${configApi.apiUrl}localidadesevento_auditorio_map/${key}`,null,{headers: headers})
      .then(response => {
        commit('listLocalidadesEventoAuditorio_mapeado',response.data.data[0].localidades);
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        commit('listLocalidadesEventoAuditorio_mapeado',[]);
        /*swal("Error!", "Error al obtener las localidades por auditorio_mapeado!", "error");*/
        console.error(error);
      });
    },   
    getfuncioneslocalidadesFis({ state, getters, commit, rootState }, key) {
      store.commit('changeLoader', true);
      state.loading = true;
      return axios.get(`${configApi.apiUrl}localidadesevento_funcion/${key}`,null,{headers: headers})
      .then(response => {
        console.log(response.data.data[0].localidades.length);
        if(response.data.data[0].localidades.length > 0){
          commit('listLocalidadesEventoAuditorio_mapeado',response.data.data[0].localidades);
        }else{
           commit('listLocalidadesEventoAuditorio_mapeado',[]);
        }
        store.commit('changeLoader', false);
        state.loading = false;
      })
            .catch(error => {
        state.loading = false;
        store.commit('changeLoader', false);
        commit('listLocalidadesEventoAuditorio_mapeado',[]);
        /*swal("Error!", "Error al obtener las localidades por auditorio_mapeado!", "error");*/
        console.error(error);
      });

    }, 
  newAuditorio_mapeado({state,getters,commit,rootState},fileImage){
    store.commit('changeLoader', true);
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}auditorio_map`,fileImage,{headers: headersMultipart})
      .then(response => {
        commit('setAuditorio_mapeado', response.data.data);
        store.dispatch('solicitudevento/imagenMapeo',response.data.data);
        commit('listLocalidadesAuditorio_mapeado',[]);
        store.dispatch('solicitudevento/localidades5',[]);
        store.dispatch('solicitudevento/localidadesevento5',[]);
        store.dispatch('solicitudevento/localidadesmonedaevento5',[]);
        store.dispatch('solicitudevento/tribunasShow25',[]);
        store.commit('changeLoader', false);
        swal("Exito!", "Se creo el auditorio_mapeado correctamente!", "success");
        /*store.dispatch('auditorio_mapeado/listAuditorio_mapeados',state.pagination.page);*/
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al crear el auditorio_mapeado!", "error");
        console.error(error);
      });
  },
  setAuditorio_mapeado({state,getters,commit,rootState},payload){
    commit('setAuditorio_mapeado', payload);
  },
  // updateAuditorio_mapeado({state,getters,commit,rootState},payload){
  //     store.dispatch('validateToken');
  //     axios.put(`${configApi.apiUrl}update_auditorio_map/${payload.id}`,
  //       {
  //         _method: 'PUT',
  //         id_auditorio: payload.id_auditorio,
  //         area_mapeada: payload.area_mapeada
  //       }
  //       ,{headers: headers})
  //     .then(response => {
  //       commit('setAuditorio_mapeado', response.data.data);
  //       store.dispatch('solicitudevento/imagenMapeo',response.data.data);
  //       swal("Exito!", "Se actualizo el auditorio_mapeado correctamente!", "success");
  //       /*store.dispatch('condicion/listAuditorio_mapeados',state.pagination.page);*/
  //     })
  //     .catch(error => {
  //       swal("Error!", "Error al actualizar el auditorio_mapeado!", "error");
  //       console.error(error);
  //     });
  // },
  updateAuditorio_mapeado({state,getters,commit,rootState},payload){
    // id_auditorio
    // area_mapeada
    // nombre
    // status
    store.commit('changeLoader', true);
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}update_auditorio_map/${payload.id}`,payload,{headers: headers})
      .then(response => {
        commit('setAuditorio_mapeado', response.data.data);
        store.dispatch('solicitudevento/imagenMapeo',response.data.data);
        store.commit('changeLoader', false);
        swal("Exito!", "Se actualizo el auditorio_mapeado correctamente!", "success");
        /*store.dispatch('condicion/listAuditorio_mapeados',state.pagination.page);*/
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al actualizar el auditorio_mapeado!", "error");
        console.error(error);
      });
  },
      searchAuditorio_mapeados({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarAuditorio_mapeados?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listAuditorio_mapeadosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los auditorio_mapeados!", "error");
        console.error(error);
      });

    },
  },
}