import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       tipoEventos: [],
       tipoEvento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listTipoEvento: (state,getters,rootState) => {
          return state.tipoEventos;
    },
    tipoEvento: (state,getters,rootState) => {
        return state.tipoEvento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listTipoEvento: (state,tipoEventos) => {
          state.tipoEventos = tipoEventos;
          state.pagination.totalItems = tipoEventos.total;
          state.pagination.page = tipoEventos.current_page;
          state.pagination.rowsPerPage = tipoEventos.per_page;
       },
      listTipoEventoSearch: (state,tipoEventos) => {
            if(tipoEventos != null){ 
                state.tipoEventos = tipoEventos;
            }else{
              state.tipoEventos = null;
            }
       },
      viewTipoEvento(state,id){
        let auxTipoEvento = state.tipoEventos.filter((item) => item.id == id);
        state.tipoEvento = auxTipoEvento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listTipoEvento({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}tipo_evento_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listTipoEvento',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los tipos de evento!", "error");
        console.error(error);
      });
    },
    searchTipoEventos({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarTipoEvento?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listTipoEventoSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los tipos de eventos!", "error");
        console.error(error);
      });

    },
  },
}