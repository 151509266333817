<template>
       <div class="container-evento">
            <div class="row row-clearfix">
                  <div class="col-md-3 col-lg-3 col-sm-3" v-if="cambio_moneda === 'USD'"></div>
                  <div class="col-md-6 col-lg-6 col-sm-6" v-if="cambio_moneda === 'VEF' || evento.seguro == 1">
                    <div class="cont-desc-evento">
                      <h2 class="header">Seleccione Método de Pago</h2>
                      <h2  class="header" v-if="evento.seguro == 1">Reserva Reembolsable</h2>
                      <br>
  
                      <PgWidgetLoaded ref="miComponente" :valortotalcompra="valortotalcompra"  :fechaevento="evento.fecha_evento" v-if="evento.seguro == 1"></PgWidgetLoaded>
          
                      <!-- <div v-if="cambio_moneda === 'USD'"> -->

                        <!-- <div class="container" style="border: 1px solid #33150A; width: 20%; float: left;">
                          <img src="/images/pago-tarjetas.svg" alt="">
                          <br>
                          <b style="font-size: 12px;">Débito/Crédito</b>
                        </div>
                        <br><br><br><br>  -->
                        <!-- <VCreditCard @change="creditInfoChanged" @cardChanged="cardChanged" :trans="translations"/>
                        {{ name }}
                        {{ cardNumber }}
                        {{ expiration }}
                        {{ security }} -->
                      <!-- </div> -->

                      <div v-if="cambio_moneda === 'VEF'">

                        <div class="card-deck">
                          <div class="card" style="cursor: pointer; padding: 10px 10px 10px 10px; height: 100px" @click="opcion(1)" id="opcionesPagos" >
                            <img src="/images/bnc.svg" style="width: 30px; margin: auto;" class="card-img-top" alt="...">
                            <div class="card-body">
                              <b class="card-title" style="font-size: 14px;">Débito</b>                              
                            </div>
                          </div>
                          <div class="card" style="cursor: pointer; padding: 10px 10px 10px 10px; height: 100px" @click="opcion(2)" id="opcionesPagos">
                            <img src="/images/pagomovil.svg" style="width: 28px; margin: auto;" class="card-img-top" alt="...">
                            <div class="card-body">
                              <b class="card-title" style="font-size: 14px;">Pago Móvil P2P</b>                              
                            </div>
                          </div>
                          <div class="card" style="cursor: pointer; padding: 10px 10px 10px 10px; height: 100px" @click="opcion(3)" id="opcionesPagos">
                            <img src="/images/pagomovil.svg" style="width: 28px; margin: auto;" class="card-img-top" alt="...">
                            <div class="card-body">
                              <b class="card-title" style="font-size: 14px;">Pago Móvil C2P</b>                              
                            </div>
                          </div>
                        </div>
                        
                        <DebitoBNC style="width: 100%; float: left;" @resultado="obtenerValorDelHijo" @datos_form="guardar_datos_form($event)" v-if="tipo_pago == 1"></DebitoBNC>
                        <P2P style="width: 100%; float: left;" @resultado="obtenerValorDelHijo" @datos_p2p="guardar_datos_p2p($event)" v-if="tipo_pago == 2"></P2P>
                        <C2P style="width: 100%; float: left;" @resultado="obtenerValorDelHijo" @datos_c2p="guardar_datos_c2p($event)" v-if="tipo_pago == 3"></C2P>                                              

                      </div>                    

                    </div>    
                  </div>                 
                  <div class="col-md-6 col-lg-6 col-sm-6"> 
                    <div class="cont-desc-evento">                      
                      <h2  class="header">Resumen de compra</h2>
                    <br>

                    <v-btn-toggle
                      v-model="cambio_moneda"
                      rounded="0"      
                      style="float: left;"                                                        
                      group
                    >
                      <v-btn value="USD" small @click="refrescarvalorwidget('USD')">
                        Ver Precios en USD
                      </v-btn>

                      <v-btn value="VEF" style="background-color: green !important; color: white !important; font-size: 13px;" small @click="refrescarvalorwidget('VEF')">
                        ¡Pagar en Bolívares Aquí!
                      </v-btn>
                    </v-btn-toggle>
                  
                    <br><br>
                    <table  v-if="$store.state.resumenFinalizarCompra"   width="100%" border="0" cellspacing="0" cellpadding="0" class="table">
                      <tr  :key="index" v-for="(product, index) in $store.state.resumenFinalizarCompra.productos">
                        <td  style=" text-align:left; font-family: Verdana;  text-transform: uppercase;" width="70%">&nbsp;{{product.nombre}}</td>
                        <td  style=" text-align:right;  font-family: Verdana;" width="30%">&nbsp;{{ divisaseleccionada }} {{ currencyFormatDE(product.total, ',','.',true) }}</td>
                      </tr>
                      
                        <tr v-if="evento.seguro == 1">
                        <td style="text-align:left;   font-family: Verdana;   background:#F3F3F3;">&nbsp; SUBTOTAL:</td>
                        <td  style="text-align:right;  font-family: Verdana;   background:#F3F3F3;">&nbsp; {{ divisaseleccionada }} {{ currencyFormatDE(cartsubtotal, ',','.',true) }}</td>
                      </tr>
                      <tr  v-if="evento.seguro == 1">
                        <td style="text-align:left;   font-family: Verdana;  ">&nbsp;  RESERVA REEMBOSABLE:</td>
                        <td  style="text-align:right;  font-family: Verdana; ">&nbsp; {{ divisaseleccionada }} {{   currencyFormatDE($store.state.protectionValue, ',','.',true) }} </td>
                      </tr>
                     
                      <tr>
                        <td style="text-align:left;  font-weight: bold; font-family: Verdana;   background:#F3F3F3;">&nbsp; TOTAL:</td>
                        <td  style="text-align:right; font-weight: bold;  font-family: Verdana;   background:#F3F3F3;">&nbsp; {{ divisaseleccionada }}   {{  currencyFormatDE($store.state.totalValue, ',','.',true)   }}  </td>
                      </tr>
                    </table>


                    <div class="pl-4">
                      <div class="d-flex align-items-center">
                        <v-checkbox v-model="acceptTyC">
                          <template v-slot:label>
                            <span class="px-2">Acepto</span>
                          </template>
                        </v-checkbox>
                        <router-link target="_blank" :to="{name: 'terminoscondiciones'}">Términos y condiciones</router-link>
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <v-checkbox class="mt-0" v-model="acceptTD">
                          <template v-slot:label>
                            <label class="px-2">Acepto</label>
                          </template>
                        </v-checkbox>
                        <router-link class="mb-3" :to="{name: 'politicatratamiento'}" target="_blank">Tratamiento de datos personales</router-link>
                      </div>
                    </div>



                    <v-btn color="primary" :disabled="!acceptTD || !acceptTyC || !valorRecibido || cambio_moneda == 'USD'" class="btn-finalizar-compra btn-block" v-if="evento.venta_linea==1 && evento.status==1" @click="pagar()">
                            <v-icon center>payment</v-icon>&nbsp;IR A PAGAR
                          </v-btn>
 


                          <div class="p-3 text-center">
                          <router-link to="/terminoscondiciones" target="_blank">Antes de realizar una compra lee nuestros
                            terminos y condiciones aqui
                          </router-link>
                        </div>

                        <a class="btn btn-info"   style="color: white;  " @click="enviaraEvento()" ><v-icon>shopping_cart</v-icon>&nbsp;  SEGUIR COMPRANDO</a>
                       
                        &nbsp;&nbsp;
                        <v-chip v-if="evento.venta_linea==1 && evento.status==1" @click="clearCantBoleta"> 
                      <v-icon center color="#907b16">remove_shopping_cart</v-icon>
                      <b>&nbsp;VACIAR CARRITO</b>
                    </v-chip>


                    </div>
                    
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3"  v-if="evento.seguro == 0">
                 </div>  
            </div>
       </div>




</template>
<script>

import VCreditCard from 'v-credit-card';
import 'v-credit-card/dist/VCreditCard.css';

import TipoEventos from "./fragments/TipoEventos.vue";
import Carousel from "./fragments/Carousel.vue";
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import TablaEvento from './Templates/Evento/TablaEvento';

import DebitoBNC from './utils/PaymentMethods/DebitoBNC.vue';
import P2P from './utils/PaymentMethods/P2P.vue';
import C2P from './utils/PaymentMethods/C2P.vue';

import PgWidgetLoaded from './PgWidgetLoaded.vue';
import moment from "moment";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/themes/material_blue.css'; 
import { asyncDispose } from 'core-js/fn/symbol';
moment.locale("es");

export default {
  components: {
    TipoEventos,
    Carousel,
    BoletasxLocalidad,
    TablaEvento,
    flatPickr,
    PgWidgetLoaded,
    VCreditCard,
    DebitoBNC,
    P2P,
    C2P
  },
  data() {
    return {
      valorRecibido: false,
      tipo_pago: 0,
      cambio_moneda: 'USD',
      cargarWidgetSeguro:0,
       valortotalcompra:0,
       protectionValue:0,
       fechaevento:'',
       cartsubtotal:0,
       cartotal:0,
       cartseguro:0,
      type: 'month',
      start: new Date(),
      end: new Date(),
      typeOptions: [
        { text: 'Day', value: 'day' },
        { text: '4 Day', value: '4day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Custom Daily', value: 'custom-daily' },
        { text: 'Custom Weekly', value: 'custom-weekly' }
      ],
      selectedDate:  new Date(), 
      seleccionado: null,
      enabledDates: [ ],
      fechaselectedDate:'',
      seleccionadohora:'',
      menu: false, 
      acceptTyC: false,
      acceptTD: false,
      dialogotablaprecios: false,
      dataCompare: null,
      tab: "general",
      tabpreventa: 0,
      modalSelectEntregaBoletas: false,
      entregaBoletas: "digital",
      keyBoletasxLocalidad: 0,
      mostrarmapa: true,
      modalVisible: false,
      horario:[],  
      flatpickrStyle: {
        width: '500px',
        height: '70px',
      },
      name: '',
      cardNumber: '',
      expiration: '',
      security: '',
      translations: {
          name: {
              label: 'Nombre',
              placeholder: 'Nombre completo'
          },
          card: {
              label: 'Número de tarjeta',
              placeholder: 'Número de tarjeta'
          },
          expiration: {
              label: 'Expiration'
          },
          security: {
              label: 'Código de seguridad',
              placeholder: 'Código'
          }
      },
      funcionseleccionada:0,
      ventas: [
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
      ],
      keyPuntoventas: "",
      headersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "punto_ventum.nombre_razon",
        },
        {
          text: "Dirección",
          value: "punto_ventum.direccion",
        },
        {
          text: "Ciudad",
          value: "punto_ventum.id_ciudad",
        },
        {
          text: "Zona/Region",
          value: "punto_ventum.zona",
        },
        {
          text: "Telefono",
          value: "punto_ventum.telefono",
        },
        {
          text: "Responsable",
          value: "punto_ventum.responsable",
        },
      ],
      divisaseleccionada: '$',
      rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
      idLocalidad: "",
      dialogmapasientos: false,
      elhtml2: "",
      monedaSelectShop: null, 
      activeModalAsientos: false, 
      datos_tarjeta: '',
      datos_p2p: '',
      datos_c2p: ''
    };
  },
  watch: {
    // evento(){
    //   this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.evento.id_auditorio_mapeado);
    // },
    async evento() {
      this.$store.commit('evento/setImagenesEvento', null);
      if(this.evento){
        this.$store.dispatch(
          "evento/listPreventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
          this.evento.id
        );
        await this.$store.dispatch("evento/changeMonedaShopEvento", {
          codigo_moneda: this.monedaSelectShopEvento,
          evento_id: this.evento.id,
        });
        this.$store.dispatch(
          "evento/getCondicionesEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "evento/listPreventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "evento/getpuntoventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
          this.evento.id
        ); 
        this.mostrarmapa=true;
        if( this.evento.id_tipo_evento==7 ){
        this.mostrarmapa=false;
          }

        if (this.monedaSelectShopEvento === null && this.evento.monedas_evento.length > 0) {
          this.$store.commit(
            "evento/changeMonedaShopEvento",
            this.evento.monedas_evento[0].codigo_moneda
          );
          this.monedaSelectShop = this.monedaSelectShopEvento;
        } else {
          this.monedaSelectShop = this.monedaSelectShopEvento;
        }
        this.$store.dispatch("evento/getImagenesEvento", this.evento.id);
        $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
        this.activeModalAsientos = false;
        if(this.evento && this.evento.slug != this.$route.params.evento){
          this.$store.dispatch(
            "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
            this.evento.id
          );
          this.$store.dispatch(
            "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
            this.evento.id
          );
        }
        if (this.idLocalidad && this.evento.localidades_evento) {
          let element = this.evento.localidades_evento.find(
            (e) => e.id_localidad == parseInt(this.idLocalidad)
          );
          if (!element) {
            this.idLocalidad = "";
          }
        }
        setTimeout(() => {
          this.activeModalAsientos = true;
        }, 500);
        this.keyBoletasxLocalidad += 1;
      }
    },


 
    totalValorBoletasCarrito() { 
     //  this.refrescarvalorwidget();
     },

    dialogmapasientos() {
      setTimeout(() => {
        var self = this;
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(
              this.evento.auditorio_mapeado.area_mapeada.replaceAll("<br>", "")
            )
            .text()
            .replaceAll("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          self.dialogmapasientos = false;
        });
      }, 200);
    },
    "$route.params.evento": function (from, to) {
      this.onMounted();
      window.scroll(0, 0);
    },
  },
  async beforeCreate() {
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("auditorio/listAuditorios");
    //await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
    await this.$store.dispatch("evento/listEventosStatus", {
      page: 1,
      status: 1,
    });
    
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.$store.getters['evento/evento'].id_auditorio_mapeado);
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado', this.$route.params.evento);
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado', this.$route.params.evento);

    if (this.evento && this.evento.status != 1 && this.evento.status != 3) {
      this.$router.push({ path: "/" });
    }

    if (this.userAuth.id_rol != null && this.userAuth.id_rol == 5) {
      this.$router.push({ name: "misEventos" });
    }
  },
  async mounted() {
    $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
    await this.onMounted();
    window.setInterval(() => {
      this.validateDateTimeSale();
    }, 3000);
    // this.mapasientos(true);

  
this.$nextTick(() => {
 
 setTimeout(() => {

  // Obtener la estructura desde localStorage
const estadoGuardado = localStorage.getItem('estadoVuex');

// Asegurarse de que haya un estado guardado y establecerlo en Vuex si existe
    if (estadoGuardado) {
      this.$store.replaceState(JSON.parse(estadoGuardado));
    } 
  //  console.log( this.$store.state);
    this.refrescarvalorwidget(); 
 }, 1000);


  
});

   if(this.evento.id_tipo_evento==7){
    this.traerFechasFunciones(); 
   }

  },
  beforeUpdate() {
    // document.title = `${this.evento ? this.evento.nombre : 'Evento Ticketshop'} - TicketShop`;

    if(this.evento.id == 258) {
      document.title = 'Venta de Boletas Concierto Marco Antonio Solís en Cali - TicketShop';
    } else {
      document.title = `${this.evento ? this.evento.nombre : 'Evento Ticketshop'} - TicketShop`;
    }

    if (this.idLocalidad != "") {
      document.getElementsByClassName(
        "cont-mapa-asientos"
      )[0].style.backgroundImage = "";
    }
  },
  updated() {
    /*if (this.elhtml2!="")
    {
      $('#btnPayU2').submit();
    }*/
  },
  methods: {
    currencyFormatDE (
      num,
      thousandsSeparator = ',',
      decimalSeparator = '.',
      showDecimals = false
    ) {
      let numero = 0;
      if (num) {
        numero = num
          .toFixed(showDecimals ? '2' : '0')
          .replace('.', decimalSeparator) // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandsSeparator}`)
      }
      return numero
    },
    obtenerValorDelHijo(valor) {
      this.valorRecibido = valor;
    },
    guardar_datos_form(data) {
      this.datos_tarjeta = data
    },
    guardar_datos_p2p(data){
      this.datos_p2p = data
    },  
    guardar_datos_c2p(data){
      this.datos_c2p = data
    }, 
    miFuncion(selectedDates, dateStr, instance) { 
       this.$store.dispatch("carrito/getfuncioneshora", {
          id_evento:  this.evento.id,
           fecha:  dateStr,
        }); 
        this.fechaselectedDate= dateStr

    },
    creditInfoChanged(values) {
        for (const key in values) {
            this[key] = values[key];
        }
    },
    cardChanged(cardName) {
      this.cardName = cardName;
    },
    async  traerFechasFunciones() {
      this.$store.state.fechafunciones=null; 
       await  this.$store.dispatch("carrito/getfechafunciones", {
           id: this.evento.id 
        }); 
        
  },
  opcion(valor) {
    this.tipo_pago = valor
    this.valorRecibido = false
  },
  creditInfoChanged(values) {
      for (const key in values) {
          this[key] = values[key];
      }
  },
  async  traerfuncionalcargar() {
       //  console.log( this.$store.state.fechafunciones);
      /*  this.enabledDates = this.$store.state.fechafunciones;
         this.$store.dispatch("carrito/getfuncioneshora", {
           id_evento:  this.evento.id,
           fecha:   this.$store.state.fechafunciones[0],
        }); */ 
  },
 
    async  cargarPreciosFunciones(id) {
      this.modalVisible = true;
      this.$store.state.funcioneslocalidades=null;
      this.funcionseleccionada=id;
       await  this.$store.dispatch("carrito/getfuncioneslocalidades", {
           id: id 
        });  
     
    }, 
 
    cerrarModalFunciones() {
      this.modalVisible = false;
    },
    ejecutarGetPuestos(id) {
          var self = this;
           self.getPuestos(id, null);
            window.location.hash = "boletas";
            this.modalVisible = false; 
    }, 
    async handleDateClick(date) {
          
         await this.$store.dispatch("carrito/getfuncioneshora", {
          id_evento:  this.evento.id,
           fecha:  this.selectedDate,
        }); 
    },
    async onMounted() {
      var self = this;
      await this.$store.dispatch("evento/getEvento", this.$route.params.evento);      
      
      //$('.carousel').height($('.cont-desc-evento').height());
      
      var self = this;
      setTimeout(() => {
        self.activeModalAsientos = true;
      }, 500);
      setTimeout(() => {
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(
              self.$store.getters["evento/evento"].auditorio_mapeado
                .area_mapeada
            )
            .text()
            .replace("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
          self.dialogmapasientos = false;
          //$("html, body").animate({ scrollTop: 700 }, 1000);
        });
      }, 900);
    },
    enviaraEvento(){
      window.location.href ='/eventos/'+ this.evento.slug ;

    },
    async clearCantBoleta() {
      this.$store.state.carrito.descuentocupon = 0;
      this.$store.state.carrito.cuponestado =0;
      this.$store.state.carrito.vservicio =0;
      this.$store.state.carrito.cupon  = '';
      this.$store.state.carrito.cuponok=false
      this.$store.state.funcioneshora=[]; 
      if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
        var lasBoletas = [];
        var losPalcos = [];
 

          lasBoletas = JSON.parse(localStorage.getItem('newboletasclear'));
          losPalcos = JSON.parse(localStorage.getItem('newpalcosclear'));

        await this.$store.dispatch("carrito/setNullReservas", {
          boletas: lasBoletas,
          palcos: losPalcos,
        });

        localStorage.removeItem('newboletasclear'); 
        localStorage.removeItem('newpalcosclear');

        this.idLocalidad = "";
        this.$store.dispatch("evento/clearCantBoleta");
        this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
        this.$store.dispatch("carrito/dateTimeSaleStart", null);
        this.$store.dispatch("carrito/dateTimeSaleEnd", null);
        swal("Exito!", "El carrito se vacio correctamente.", "success");
        this.$store.state.showModalFinalizarCompra = false;
        localStorage.removeItem('estadoVuex');
         this.$router.push('/eventos/'+ this.evento.slug);
      } else {
        var lasBoletas = [];
        var losPalcos = [];
        this.idLocalidad = "";
        this.$store.dispatch("evento/clearCantBoleta");
        swal("Informacion", "El carrito esta vacio.", "info");
        localStorage.removeItem('estadoVuex');
        this.$store.state.showModalFinalizarCompra = false;
         this.$router.push('/eventos/'+ this.evento.slug);
      }
    },
    dateTimeToDate(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("MMMM, DD YYYY");
      } else {
        return "";
      }
    },
    formatTime(time) {
      if (time != null && time != "") {
        return moment(time, "HH:mm:ss").format("LT");
      } else {
        return "";
      }
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format(
          "dddd Do [de] MMMM [del] YYYY [a las] h:m a"
        );
      } else {
        return "";
      }
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    getCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        return filter_ciudad[0].descripcion;
      }
    },
    getPuestos(id, localidad) {
      if (this.evento && this.evento.status === 3) {
        return false;
      }

      this.idLocalidad = id;
      if (localidad === null) {
        var localidadFilter = null;
        if (
          this.listLocalidadesEventoAuditorio_mapeado &&
          this.listLocalidadesEventoAuditorio_mapeado.length > 0
        ) {
          localidadFilter = this.listLocalidadesEventoAuditorio_mapeado.filter(
            (item) => item.id == id
          );
          this.$store.dispatch(
            "carrito/localidadSelectCarrito",
            localidadFilter
          );
        }
      } else {
        this.$store.dispatch("carrito/localidadSelectCarrito", localidad);
      }
    },
    getAuditorio(id) {
      let filter_auditorio = [];
      if (this.auditorios) {
        filter_auditorio = this.auditorios.filter(
          (auditorio) => auditorio.id == id
        );
      }
      if (filter_auditorio[0] == "" || filter_auditorio[0] == null) {
        return "";
      } else {
        return filter_auditorio[0].nombre;
      }
    },
    async mapasientos(mostrar) {
      if (mostrar) {
        await this.$store.commit("evento/setEvento", this.evento);
      }
      this.dialogmapasientos = mostrar;
    },
   
    
    async refrescarvalorwidget(moneda) {
      this.divisaseleccionada = 'Bs.S';
      if(moneda == undefined) {
        moneda='USD'
      }
      if( moneda=='USD'){   this.divisaseleccionada = '$';  }
      var cupon =   'NA';
      if ( this.currentRoute != "evento" || this.evento.fechaValidaInicioInternet ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
        } else {
          if (this.$store.state.carrito.cantBoletaTotal == 0) {
            swal("Carrito Vacio!", "No ha reservado boletas!", "success");
            localStorage.removeItem('estadoVuex');
            this.$router.push('/eventos/'+ this.evento.slug);
          } else {
            if ( this.entregaBoletas != null && this.entregaBoletas != undefined ) {
              var lasBoletas = [];
              var losPalcos = [];
            
              for ( var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++ ) {
                if ( this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ] != null ) {
                  if ( this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1 ) {
                    losPalcos = losPalcos.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  } else {
                    lasBoletas = lasBoletas.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  }
                  this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ].length;
                }
              }
              if (lasBoletas != [] || losPalcos != []) {  
              if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                }

                  //Eliminar Duplicidad ID
                  const duplicidadPalcos = new Set(losPalcos);
                  let resultDupliPalcos = [...duplicidadPalcos];

                  const duplicidadBoletas = new Set(lasBoletas);
                  let resultDupliBoletas = [...duplicidadBoletas];

                  await this.$store.dispatch("detalle_Boletas_Finalizarcompra", {
                  palcos: resultDupliPalcos.length > 0 ? resultDupliPalcos : '',
                  boletas: resultDupliBoletas.length > 0 ? resultDupliBoletas : '',
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  funcionseleccionada : this.funcionseleccionada,
                   protectionValue: this.$store.state.protectionValue,
                   pgwidget: this.$store.state.pgwidget,
                   productId:this.$store.state.productId,
                   sold:this.$store.state.sold,
                   moneda: moneda
                }).then(()=>{
                  if(this.$store.state.errorDetalleCompra){
                    swal("Parece que algo salio mal!", "Intentalo nuevamente", "error");
                    return '';
                  }else{
                   // console.log(this.$store.state.resumenFinalizarCompra);
                    this.$store.state.showModalFinalizarCompra = true;

                    var cantidadDinero =  this.$store.state.resumenFinalizarCompra.totalVenta;
                    // cantidadDinero =cantidadDinero.replace(/[^0-9,]/g, '').replace(',', '.');
                    
                    this.valortotalcompra=this.$store.state.resumenFinalizarCompra.totalVenta ;
                    this.cartsubtotal= cantidadDinero;
                    
                    this.$store.state.totalValue = cantidadDinero;



                    
                  }
                });




              } else if (lasBoletas == [] && losPalcos == []) {
                swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                localStorage.removeItem('estadoVuex');
                this.$router.push('/eventos/'+ this.evento.slug);
                return "";
              }
            } else {
              this.modalSelectEntregaBoletas = true;
            }
          }
        }
      } else {
        swal(
          "Error!",
          "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
          "error"
        );
      }      
    },
    

    async pagar() {
      var cupon =   'NA';


        if(this.evento.id_tipo_evento==7 &&  this.funcionseleccionada==0  ) {
                               swal("Funcion no Selecciona!", "Algo salió mal. Limpiar el carrito e iniciar de nuevo!", "success");
             } else{



      if (
        this.currentRoute != "evento" ||
        this.evento.fechaValidaInicioInternet
      ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
          //swal("Error!", "Debe Iniciar Sesión!", "error");
        } else {
          if (this.$store.state.carrito.cantBoletaTotal == 0) {
            swal("Carrito Vacio!", "No ha reservado boletas!", "success");
            localStorage.removeItem('estadoVuex');
            this.$router.push('/eventos/'+ this.evento.slug);
          } else {

                    


            if (
              this.entregaBoletas != null &&
              this.entregaBoletas != undefined
            ) {
              var lasBoletas = [];
              var losPalcos = [];

              for (
                var i = 0;
                i < this.listLocalidadesEventoAuditorio_mapeado.length;
                i++
              ) {
                if (
                  this.$store.state.carrito.cantBoleta[
                    this.listLocalidadesEventoAuditorio_mapeado[i].id
                  ] != null
                ) {
                  if (
                    this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1
                  ) {
                    losPalcos = losPalcos.concat(
                      this.$store.state.carrito.cantBoleta[
                        this.listLocalidadesEventoAuditorio_mapeado[i].id
                      ]
                    );
                  } else {
                    lasBoletas = lasBoletas.concat(
                      this.$store.state.carrito.cantBoleta[
                        this.listLocalidadesEventoAuditorio_mapeado[i].id
                      ]
                    );
                  }
                  this.$store.state.carrito.cantBoleta[
                    this.listLocalidadesEventoAuditorio_mapeado[i].id
                  ].length;
                }
              }
              if (lasBoletas != [] && losPalcos == []) {
                if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                 }
                await this.$store.dispatch("obtener_refventa", {
                  boletas: lasBoletas,
                  moneda: this.$store.getters["evento/monedaSelectShop"],
                  email: this.$store.getters["user/dataUserLogged"].email,
                  nombre: this.$store.getters["user/dataUserLogged"].nombre,
                  identificacion:
                    this.$store.getters["user/dataUserLogged"].identificacion,
                  tipo_identificacion:
                    this.$store.getters["user/dataUserLogged"]
                      .tipo_identificacion,
                  entrega_boletas: this.entregaBoletas,
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  funcionseleccionada : this.funcionseleccionada,
                   protectionValue: this.$store.state.protectionValue,
                   pgwidget: this.$store.state.pgwidget,
                   productId:this.$store.state.productId,
                   sold:this.$store.state.sold,
                   datos_tarjeta: this.datos_tarjeta,
                   datos_p2p: this.datos_p2p,
                   datos_c2p: this.datos_c2p,
                   opcion_pago: this.tipo_pago
                });
                      this.$store.state.carrito.descuentocupon = 0;
                      this.$store.state.carrito.cuponestado =0;
                      this.$store.state.carrito.vservicio =0;
                      this.$store.state.carrito.cupon  = '';
                      this.$store.state.carrito.cuponok=false
                      localStorage.removeItem('estadoVuex');

              } else if (lasBoletas == [] && losPalcos != []) {
                if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                 }
                await this.$store.dispatch("obtener_refventa", {
                  palcos: losPalcos,
                  moneda: this.$store.getters["evento/monedaSelectShop"],
                  email: this.$store.getters["user/dataUserLogged"].email,
                  nombre: this.$store.getters["user/dataUserLogged"].nombre,
                  identificacion:
                    this.$store.getters["user/dataUserLogged"].identificacion,
                  tipo_identificacion:
                    this.$store.getters["user/dataUserLogged"]
                      .tipo_identificacion,
                  entrega_boletas: this.entregaBoletas,
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  funcionseleccionada : this.funcionseleccionada,
                   protectionValue: this.$store.state.protectionValue,
                   pgwidget: this.$store.state.pgwidget,
                   productId:this.$store.state.productId,
                   sold:this.$store.state.sold,
                   datos_tarjeta: this.datos_tarjeta,
                   datos_p2p: this.datos_p2p,
                   datos_c2p: this.datos_c2p,
                   opcion_pago: this.tipo_pago
                });
                this.$store.state.carrito.descuentocupon = 0;
                this.$store.state.carrito.cuponestado =0;
                this.$store.state.carrito.vservicio =0;
                this.$store.state.carrito.cupon  = '';
                this.$store.state.carrito.cuponok=false
                localStorage.removeItem('estadoVuex');
              } else if (lasBoletas != [] && losPalcos != []) {
                if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                 }
                await this.$store.dispatch("obtener_refventa", {
                  boletas: lasBoletas,
                  palcos: losPalcos,
                  moneda: this.$store.getters["evento/monedaSelectShop"],
                  email: this.$store.getters["user/dataUserLogged"].email,
                  nombre: this.$store.getters["user/dataUserLogged"].nombre,
                  identificacion:
                    this.$store.getters["user/dataUserLogged"].identificacion,
                  tipo_identificacion:
                    this.$store.getters["user/dataUserLogged"]
                      .tipo_identificacion,
                  entrega_boletas: this.entregaBoletas,
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  funcionseleccionada : this.funcionseleccionada,
                   protectionValue: this.$store.state.protectionValue,
                   pgwidget: this.$store.state.pgwidget,
                   productId:this.$store.state.productId,
                   sold:this.$store.state.sold,
                   datos_tarjeta: this.datos_tarjeta,
                   datos_p2p: this.datos_p2p,
                   datos_c2p: this.datos_c2p,
                   opcion_pago: this.tipo_pago
                });
                this.$store.state.carrito.descuentocupon = 0;
                this.$store.state.carrito.cuponestado =0;
                this.$store.state.carrito.vservicio =0;
                this.$store.state.carrito.cupon  = '';
                this.$store.state.carrito.cuponok=false 
                this.$store.state.funcioneshora=[];
                localStorage.removeItem('estadoVuex');
              } else if (lasBoletas == [] && losPalcos == []) {
                swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                localStorage.removeItem('estadoVuex');
                this.$router.push('/eventos/'+ this.evento.slug);
                return "";
              }

              if (this.$store.getters["get_init_point"] != null) {
                this.idLocalidad = "";
                await this.$store.dispatch("evento/clearCantBoleta");
                await this.$store.dispatch(
                  "carrito/setNullPreciosBoletasCarrito"
                );
                await this.$store.dispatch("carrito/dateTimeSaleStart", null);
                await this.$store.dispatch("carrito/dateTimeSaleEnd", null);
                window.location.href = this.$store.getters["get_init_point"];
              }
            } else {
              this.modalSelectEntregaBoletas = true;
            }

            //var refventa = this.$store.getters['obtener_refventa'].refVenta;

            //var total = this.$store.getters['obtener_refventa'].precio_total;

            //var moneda = this.listLocalidadesEventoAuditorio_mapeado[0].localidad_evento[0].codigo_moneda;
            //var moneda = this.$store.getters['evento/monedaSelectShop'];

            //await this.$store.dispatch('generarsha',{
            //  moneda: moneda,
            //  refventa: refventa
            //});

            /* var usuario = this.$store.getters['user/dataUserLogged'];
             var info_pago = this.$store.getters['sha'];

             this.elhtml2 = '<input type="image" src="https://paymentsbakery.co/gateway/images/payments-bakery.png" border="0" alt="payments-bakery">\
             <input name="usuarioId" type="hidden" value="'+info_pago.usuarioId+'">\
             <input name="firma" type="hidden" value="'+info_pago.firma_codificada+'">\
             <input name="descripcion" type="hidden" value="'+'descripcion'+'">\
             <!-- Descripcon de la Venta Productos-->\
             <input name="refVenta" type="hidden" value="'+info_pago.refVenta+'">\
             <!--  Referencia de Venta Numero de 6 digitos -->\
             <input name="valor" type="hidden" value="'+total+'">\
             <!--  Valor de la Compra -->\
             <input name="moneda" type="hidden" value="'+moneda+'">\
             <!--  Moneda de la Compra:  PERU:  604 soles y 840 dolares. COLOMBIA COP -->\
             <input name="lang" type="hidden" value="SP">\
             <!--  lenguage   SP - Español   EN - Ingles-->\
             <input name="extra1" type="hidden" value="'+'datosCompra'+'">\
             <input name="extra2" type="hidden" value="'+'datosCompra'+'">\
             <input name="extra3" type="hidden" value="'+'datosCompra'+'">\
             <input name="url_respuesta" type="hidden" value="'+info_pago.URLRespuesta+'">\
             <input name="url_confirmacion" type="hidden" value="'+info_pago.URLConfirma+'">\
             <input name="emailComprador" type="hidden" value="'+usuario.email+'"/>\
             <input name="Comprador" type="hidden" value="'+usuario.nombre+'"  />\
             <!--  Nombre Comprador -->\
             <input name="documentoIdentificacion" type="hidden" value="'+'10628701'+'"  />\
             <!--  Documento de Identidad Comprador -->\
             <input name="Test" type="hidden" value="1"/>';
             */
          }
        }
      } else {
        swal(
          "Error!",
          "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
          "error"
        );
      }
    }
    },
    getPreventa(index) {
      switch (index) {
        case -1:
          this.accion = "Aprobar";
          break;
        case 1:
          this.accion = "Aprobar";
          break;
        case 2:
          this.accion = "Rechazar";
          break;
        case 4:
          this.accion = "Aprobar Con Modificaciones";
          break;
      }
    },
    getLocalidadDescuento(localidad, preventa) {
      var total =
        parseFloat(
          eval(
            "localidad.localidad_evento[0].precio_venta" +
              this.indexMonedaSelectShopEvento
          )
        ) +
        parseFloat(
          eval(
            "localidad.localidad_evento[0].precio_servicio" +
              this.indexMonedaSelectShopEvento
          )
        );

      if (preventa) {
        if (
          preventa.id_localidad == localidad.id ||
          preventa.id_tribuna == localidad.id_tribuna ||
          preventa.id_evento == this.evento.id
        ) {
          if (preventa.precios_monedas) {
            var precioMonedaActual = preventa.precios_monedas.filter(
              (item) => item.codigo_moneda === this.monedaSelectShopEvento
            )[0];

            if (precioMonedaActual) {
              if (precioMonedaActual.descuento_fijo_servicio) {
                total =
                  parseFloat(total) -
                  parseFloat(precioMonedaActual.descuento_fijo_servicio);
              } else if (preventa.porcentaje_descuento_servicio) {
                total =
                  parseFloat(total) -
                  (parseFloat(
                    eval(
                      "localidad.localidad_evento[0].precio_servicio" +
                        this.indexMonedaSelectShopEvento
                    )
                  ) *
                    parseFloat(preventa.porcentaje_descuento_servicio)) /
                    100;
              }
              if (precioMonedaActual.descuento_fijo_precio) {
                total =
                  parseFloat(total) -
                  parseFloat(precioMonedaActual.descuento_fijo_precio);
              } else if (preventa.porcentaje_descuento_precio) {
                total =
                  parseFloat(total) -
                  (parseFloat(
                    eval(
                      "localidad.localidad_evento[0].precio_venta" +
                        this.indexMonedaSelectShopEvento
                    )
                  ) *
                    parseFloat(preventa.porcentaje_descuento_precio)) /
                    100;
              }
            }
          }
        }
      }
      return (total + (total * localidad.localidad_evento[0].impuesto) / 100) <= 0 ? 0 : this.formatNumber(total + (total * localidad.localidad_evento[0].impuesto) / 100);
    },
    getPrecioServicioLocalidad(localidad) {
      var precio_servicio = parseFloat(
        eval(
          "localidad.localidad_evento[0].precio_servicio" +
            this.indexMonedaSelectShopEvento
        )
      );

      return this.formatNumber(precio_servicio);
    },
    getPrecioVentaLocalidad(localidad) {
      var precio_servicio = parseFloat(
        eval(
          localidad.localidad_evento[0].precio_venta +
            this.indexMonedaSelectShopEvento
        )
      );
      return this.formatNumber(precio_servicio);
    },
    changeMonedaShopEvento() {
      this.$store.dispatch("evento/changeMonedaShopEvento", {
        codigo_moneda: this.monedaSelectShop,
        evento_id: this.evento.id,
      });
      this.$router.go(this.$router.currentRoute);
    },
    concatTextMoneda(item) {
      return `(${item.moneda.simbolo}) ${item.moneda.descripcion}`;
    },
    validateDateTimeSale() {
      let fecha = new Date();
      if (this.dateTimeSaleStart != null && this.dateTimeSaleEnd != null) {

        if(this.getBrowser.includes('Safari')) {
          swal("Safari Mode", "Modo Safari", "info")
        }

        if (
          Date.parse(fecha) >= Date.parse(this.dateTimeSaleStart) &&
          Date.parse(fecha) <= Date.parse(this.dateTimeSaleEnd)
        ) {
        } else {
          if(this.dateTimeSaleStart){
            this.$store.state.showModalFinalizarCompra = false;
            this.$store.state.resumenFinalizarCompra = null;
            this.idLocalidad = "";
            this.$store.dispatch("carrito/dateTimeSaleStart", null);
            this.$store.dispatch("carrito/dateTimeSaleEnd", null);
            this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
            this.$store.dispatch("evento/clearCantBoleta");
            swal("Informacion", "El carrito esta vacio.", "info");
            localStorage.removeItem('estadoVuex');
            this.$store.state.showModalFinalizarCompra = false;
            this.$router.push('/eventos/'+ this.evento.slug);
          }
        }
      }
    },
    getBrowser() {
      var ua= navigator.userAgent, tem, 
      M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
          tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
          tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    },
    formatFechaClasic(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
   
  },
  computed: {
    flatpickrConfig() {
      
  return {
        inline: true,
        locale: 'es',
        enable:   this.$store.state.fechafunciones ,
        onChange: this.miFuncion
      };
    },
    pagos() {
      if (this.evento && this.evento.status == 1) {
        return true;
      } else {
        return false;
      }
    },
    hoy() {
      return moment().format("Y-MM-DD");
    },
    cantBoleta() {
      return this.$store.getters["evento/cantBoleta"];
    },
    cantBoletaTotal() {
      return this.$store.getters["evento/cantBoletaTotal"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    condicionesEvento() {
      return this.$store.getters["evento/condicionesEvento"];
    },
    preventasEvento() {
      return this.$store.getters["evento/listPreventasEvento"];
    },
    showEventosDestacados() {
      return this.$store.getters["evento/listEventosDestacados"];
    },
    eventosDestacados() {
      return this.showEventosDestacados.slice(0, 4);
    },
    imagenesEvento() {
      return this.$store.getters["evento/imagenesEvento"];
    },
    userAuth() {
      return this.$store.getters["user/dataUserLogged"];
    },
    puntoventasEvento() {
      return this.$store.getters["evento/puntoventasEvento"];
    },
    // listLocalidadesAuditorio_mapeado() {
    //   return this.$store.getters['auditorio_mapeado/listLocalidadesAuditorio_mapeado'];
    // },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    mapaauditorio() {
      if (
        this.evento.auditorio.latitud != "" &&
        this.evento.auditorio.longitud != ""
      ) {
        return (
          "https://maps.google.com/?ll=" +
          this.evento.auditorio.latitud +
          "," +
          this.evento.auditorio.longitud +
          "&z=14&t=m&output=embed"
        );
      } else {
        return "";
      }
    },
    auditorios() {
      return this.$store.getters["auditorio/listAuditorios"];
    },
    // boletas(){
    //   return this.$store.getters['carrito/listBoletas'];
    // },
    imagenesEventoCarousel() {
      let imagenesEventoCarouselShow = [];
      if (this.imagenesEvento) {
        imagenesEventoCarouselShow = this.imagenesEvento.filter((imagen) => {
          return imagen.imagen.nombre.includes("banner");
        });
      }
      return imagenesEventoCarouselShow;
    },
    monedaSelectShopEvento() {
      return this.$store.getters["evento/monedaSelectShop"];
    },
    indexMonedaSelectShopEvento() {
      if (this.evento.monedas_evento && this.evento.monedas_evento.length > 0) {
        var index = this.evento.monedas_evento.findIndex(
          (moneda) => moneda.codigo_moneda === this.monedaSelectShopEvento
        );
        if (index === 0) {
          return "";
        } else {
          return index + 1;
        }
      }
    },
    simboloMoneda() {
      if (
        this.indexMonedaSelectShopEvento === "" ||
        this.indexMonedaSelectShopEvento === 0
      ) {
        return this.evento ? this.evento.monedas_evento[0].moneda.simbolo : ''
      } else {
        return this.evento ? this.evento.monedas_evento[this.indexMonedaSelectShopEvento - 1] : ''
          .moneda.simbolo;
      }
    },
    preciosBoletasCarrito() {
      return this.$store.getters["carrito/preciosBoletasCarrito"];
    },
    totalValorBoletasCarrito() {
      var preciosBoletasCarritoMonedaActual = this.$store.getters[
        "carrito/preciosBoletasCarrito"
      ].filter((item) => item.codigo_moneda == this.monedaSelectShopEvento);
      if (
        preciosBoletasCarritoMonedaActual &&
        preciosBoletasCarritoMonedaActual.length > 0
      ) {
        var total = 0;
        for (
          var i = preciosBoletasCarritoMonedaActual.length - 1;
          i >= 0;
          i--
        ) {
          total += preciosBoletasCarritoMonedaActual[i].precio;
        }
        return this.formatNumber(total);
      } else {
        return 0;
      }
    },
    localidadSelectCarrito() {
      return this.$store.getters["carrito/localidadSelectCarrito"];
    },
    countBoletas() {
      return this.$store.getters["carrito/countBoletas"];
      /* var lasBoletas = [];
      var losPalcos = [];

      for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
        if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
          if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
            losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          } else {
            lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          }
          this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
        }
      }

      return lasBoletas.length; */
    },
    countPalcos() {
      return this.$store.getters["carrito/countPalcos"];
      /* var lasBoletas = [];
      var losPalcos = [];

      for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
        if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
          if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
            losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          } else {
            lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          }
          this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
        }
      }

      return losPalcos.length;*/
    },
    dateTimeSaleStart() {
      return this.$store.getters["carrito/dateTimeSaleStart"];
    },
    dateTimeSaleEnd() {
      return this.$store.getters["carrito/dateTimeSaleEnd"];
    },
    listLocalidadesEventoAuditorioGeneral_mapeado() {
      const listadoVentaNormal =
        this.$store.getters[
          "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado"
        ];
      const listadoPreventas =
        this.$store.getters["evento/listPreventasEvento"];
      let listadoFinalPreventas = [];

      for (let i = 0; i < listadoVentaNormal.length; i++) {
        for (let j = 0; j < listadoPreventas.length; j++) {
          if (
            (listadoVentaNormal[i].id_tribuna ==
              listadoPreventas[j].id_tribuna &&
            !listadoFinalPreventas.includes(listadoVentaNormal)) ||
            (listadoVentaNormal[i].id_evento ==
              listadoPreventas[j].id_evento &&
            !listadoFinalPreventas.includes(listadoVentaNormal)) ||
            (listadoVentaNormal[i].id_tribuna ==
              listadoPreventas[j].id_tribuna &&
            !listadoFinalPreventas.includes(listadoVentaNormal))
          ) {
            var tempData = listadoVentaNormal[i];
            tempData.fecha_inicio = listadoPreventas[j].fecha_inicio;
            tempData.fecha_fin = listadoPreventas[j].fecha_fin;
            tempData.total = this.getLocalidadDescuento(
              tempData,
              listadoPreventas[j]
            );
            if (listadoPreventas[j].porcentaje_descuento_precio) {
              tempData.localidad_evento[0].precio_venta =
                (tempData.localidad_evento[0].precio_venta * 100) /
                listadoPreventas[j].porcentaje_descuento_precio;
            } else if (listadoPreventas[j].porcentaje_descuento_servicio) {
              tempData.localidad_evento[0].precio_venta =
                (tempData.localidad_evento[0].precio_venta * 100) /
                listadoPreventas[j].porcentaje_descuento_servicio;
            } else if (
              listadoPreventas[j].precios_monedas[0].descuento_fijo_precio
            ) {
              tempData.localidad_evento[0].precio_venta =
                tempData.localidad_evento[0].precio_venta -
                listadoPreventas[j].precios_monedas[0].descuento_fijo_precio;
            } else if (
              listadoPreventas[j].precios_monedas[0].descuento_fijo_servicio
            ) {
              tempData.localidad_evento[0].precio_venta =
                tempData.localidad_evento[0].precio_venta -
                listadoPreventas[j].precios_monedas[0].descuento_fijo_servicio;
            }
            listadoFinalPreventas.push(tempData);
          }
        }
      }
      return listadoFinalPreventas;
    },
  },
};
</script>
<style>
h6,
li,
p {
  font-size: 0.875rem;
}
.cont-desc-evento {
  background-color: white;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 0px 10px #eaeaea;
  border-radius: 4px;
  border-top: 5px #c7a923 solid;
  height: 100%;
}

#opcionesPagos::selection {
  background-color: black;
}

.cont-mapa-asientos {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
  padding: 10px;
  min-height: 700px;
  background-size: contain;
}

@media (max-width: 1480px) {
  .cont-mapa-asientos {
    min-height: 600px;
  }
}
@media (max-width: 768px) {
  .cont-mapa-asientos {
    min-height: 400px;
  }
  .container-tab {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.cont-mapa-asientos .sub {
  padding: 0px;
}

.cont-preventa-evento {
  background-color: white;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
}

.heading-cont-preventa-evento {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.sub-cont-preventa-evento {
  border: 1px #c5c5c5 solid;
}

.cont-descuento-evento {
  position: absolute;
  text-align: center;
  top: 60px;
  background-color: red;
  color: white;
  padding: 10px;
  right: 1rem;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 2px #a0a0a0;
}

.container-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.cont-terminos-condiciones {
  border: 1px #bfbfbf solid;
  padding: 10px;
  max-height: 235px;
  overflow-y: scroll;
  background-color: white;
}

.cont-evento {
  background-color: white;
  border: 1px gray solid;
  text-align: right;
  aspect-ratio: 5/6;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.cont-fecha {
  /*padding:10px;*/
}

.cont-fecha-activo {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
  background-color: #ffff00;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-cancelado {
  background-color: red;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.list-condiciones-evento li {
  text-transform: capitalize;
}
.carousel {
  height: auto !important;
}
.mt-50 {
  margin-top: 50px;
}
.color-slide-table {
  color: black !important;
  margin-left: auto;
  margin-right: auto;
}
.container-evento {
  padding-bottom: 4rem;
}
.btn-finalizar-compra {
  background-color: #1867c0 !important;
  color: white !important;
}
.skeleton-banner-principal-evento{
  height: 100%;
  aspect-ratio: 880/448;
}
.img-skeleton .v-skeleton-loader__image{
  height: 500px;
}
.skeleton-banner-principal-evento .v-skeleton-loader .v-skeleton-loader__image, .skeleton-banner-principal-evento .v-skeleton-loader{
  height: 100%;
}
.tag-img-evento{
  position: absolute;
  display: block;
  right: 0;
  top: .4rem;
  width: 170px;
  z-index: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.container-modal-resumen{
  background-color: white;
  padding: 1.5rem 1rem;
}
@media (max-width: 768px) {
  .container-modal-resumen{padding: 1rem .5rem;}
}
 
 
.custom-calendar .flatpickr-months {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  width: 600px;
  height: 40px;
}

.custom-calendar .flatpickr-days .flatpickr-day {
  background-color: #fff;
  color: #333;
}

.custom-calendar .flatpickr-days .flatpickr-day:hover {
  background-color: #eee;
}

.custom-calendar .flatpickr-weekdays .flatpickr-weekday {
  color: #999;
}

.custom-calendar .flatpickr-calendar.open {
  border-color: #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.flatpickr-input {
  display: none;
}
.disabled-date {
  background-color: #696565;
  color: #111010;
}
.custom-flatpickr {
 
}

.btn-active {
  background-color: #1E0102 !important; 
  color: white;
}


</style>