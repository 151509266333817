<template>
  <div class="container-page-404">
    <div class="text-center w-100">
      <img src="@/assets/icons/logo-ticketshop.png" alt="">
      <h3>Parece que no encontramos lo que estas buscando.</h3>
      <button class="btn btn-primary mt-4" @click="$router.replace({path: '/'})">Volver al inicio</button>
      <p></p>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = 'No se encontraron resultados | TicketShop'
  },
}
</script>
<style>
  .container-page-404{
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
  }
  .container-page-404 img{
    max-width: 760px;
    width: 100%;
  }
</style>