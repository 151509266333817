<template>
  <v-app>
    <!-- <div class="d-flex w-100 flex-wrap" v-if="$store.state.evento.dataBannersDestacados">
      <div class="col-12 col-md-12 px-2 py-2">
       <a href="/el-buki-marco-antonio-solis"> 
          <img :src="'/images/banner_marco.png'" :title="'MARCO ANTONIO SOLIS'" class="d-none d-md-flex w-100 pr-1">        
          <img :src="'/images/banner_marco.png'" :title="'MARCO ANTONIO SOLIS'" class="d-flex d-md-none w-100">          
        </a>
      </div> 
    </div>-->
    <div v-if="imagenesEventoCarousel.length > 0">
      <div class="d-flex w-100 flex-wrap" v-if="$store.state.evento.dataBannersDestacados.banners.length > 1">
        <div class="col-12 col-md-6 px-0 py-0" v-if="$store.state.evento.dataBannersDestacados.banners[0].status == 1">
          <a :href="$store.state.evento.dataBannersDestacados.banners[0].url_evento" :title="$store.state.evento.dataBannersDestacados.banners[0].nombre_evento">
            <img :src="$store.state.evento.dataBannersDestacados.banners[0].banner_desktop" :title="$store.state.evento.dataBannersDestacados.banners[0].nombre_evento" class="d-none d-md-flex w-100 pr-1">
            <img :src="$store.state.evento.dataBannersDestacados.banners[0].banner_movil" :title="$store.state.evento.dataBannersDestacados.banners[0].nombre_evento" class="d-flex d-md-none w-100">
          </a>
        </div>
        <div class="col-12 col-md-6 px-0 py-0" v-if="$store.state.evento.dataBannersDestacados.banners[1].status == 1">
          <a :href="$store.state.evento.dataBannersDestacados.banners[1].url_evento" :title="$store.state.evento.dataBannersDestacados.banners[1].nombre_evento">
            <img :src="$store.state.evento.dataBannersDestacados.banners[1].banner_desktop" :title="$store.state.evento.dataBannersDestacados.banners[1].nombre_evento" class="d-none d-md-flex w-100 pl-1">
            <img :src="$store.state.evento.dataBannersDestacados.banners[1].banner_movil" :title="$store.state.evento.dataBannersDestacados.banners[1].nombre_evento" class="d-flex d-md-none w-100">
          </a>
        </div>
      </div>
      <div class="row row-clearfix mb-5 pb-5">
        <div
          ref="prinCartel"
          class="col-md-8 col-lg-8 col-xl-9 col-sm-12 mb-12 mt-2"
          style="padding: 0 !important"
        >
          <div class="row row-clearfix">
            <Carousel :items="imagenesEventoCarousel" :redirect="true"></Carousel>
          </div>
          <div class="row row-clearfix">
            <div
              class="col-md-4 col-lg-4 col-sm-4"
              :key="index"
              v-for="(evento, index) in $store.state.evento.eventos_destacados"
            >
              <router-link
                :to="{ name: 'evento', params: { evento: evento.slug } }"
              >
                <div class="cont-evento">
                  <div :key="index2" v-for="(imagen, index2) in evento.imagens">
                    <v-img
                      v-if="imagen.nombre.includes('vertical')"
                      :src="imagen.url"
                      lazy-src="/images/logo-ticketshop_color.png"
                      witdh="100%"
                      class="text-right pa-2"
                      position="center center"
                      contain
                      style="aspect-ratio: 5/6; width: 100%"
                    >
                      <div class="cont-fecha">
                        <span
                          :class="
                            evento.status === 3
                              ? 'cont-fecha-aplazado'
                              : 'cont-fecha-activo'
                          "
                        >
                          {{
                            evento.status === 3
                              ? "Aplazado"
                              : formatFecha(evento.fecha_evento)
                          }}
                        </span>
                      </div>
                    </v-img>
                  </div>
                  <v-img
                    v-if="evento.imagens.length <= 0"
                    src="/images/logo-ticketshop_color.png"
                    lazy-src="/images/logo-ticketshop_color.png"
                    height="100%"
                    witdh="100%"
                    class="text-right pa-2"
                  >
                    <div class="cont-fecha">
                      <span>
                        {{ formatFecha(evento.fecha_evento) }}
                      </span>
                    </div>
                  </v-img>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-lg-4 col-xl-3 col-sm-12 col-xs-12 mb-12 pt-2 px-0"
          ref="faceCartel"
        >
          <!--<div class="row row-clearfix">
                      <TipoEventos class="proximamente-img" :tiposEventosFilter.sync="tiposEventosFilter" :redirect="true"></TipoEventos>
                  </div> -->
          <div class="row row-clearfix" id="div-facebook-page">
            <!--<div ref="blockFace" class="fb-page proximamente-img" data-href="https://www.facebook.com/TICKETSHOPCOL" data-tabs="timeline" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true" style="width: 450px !important;">
                          <blockquote cite="https://www.facebook.com/TICKETSHOPCOL" class="fb-xfbml-parse-ignore">
                              <a href="https://www.facebook.com/TICKETSHOPCOL">Ticket Shop Colombia</a>
                          </blockquote>
                      </div>-->
            <!--                  <div class="fb-page" data-href="https://www.facebook.com/TICKETSHOPCOL" data-tabs="timeline" data-width="480" data-height="600" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/TICKETSHOPCOL" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/TICKETSHOPCOL">Ticket Shop Colombia</a></blockquote></div>-->
            <div class="iframe-fb-container">
              <iframe
                class="iframe-fb border"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allow="encrypted-media"
                :src="srcIframeFacebook"
                height="100%"
                width="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-clearfix">
        <div class="col-md-3 col-lg-3">
          <div>
            <h5><b>Tipo de evento</b></h5>
            <v-select
              v-if="showSelectsFilter"
              v-model="tiposEventosFilter"
              :items="tiposEventos"
              chips
              item-text="nombre"
              item-value="id"
              multiple
              label="Tipos de evento"
            ></v-select>
          </div>
          <br />
          <div>
            <h5><b>Rango de fechas</b></h5>
            <div class="row row-clearfix">
              <div class="col-md-6 col-lg-6">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="desde"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="desde"
                      label="Desde"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="desde" scrollable locale="es-ES">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal = false"
                      >Cancelar</v-btn
                    >
                    <v-btn flat color="primary" @click="$refs.dialog.save(desde)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
              <div class="col-md-6 col-lg-6">
                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  :return-value.sync="hasta"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="hasta"
                      label="Hasta"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="hasta" scrollable locale="es-ES">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="modal2 = false"
                      >Cancelar</v-btn
                    >
                    <v-btn flat color="primary" @click="$refs.dialog2.save(hasta)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
          </div>
          <div>
            <h5><b>Rango de precios</b></h5>
            <h6 class="text-center">
              <b>{{ formatNumber(rangoPrecio[0]) }}</b> -
              <b>{{ formatNumber(rangoPrecio[1]) }}</b>
            </h6>
            <v-card flat color="transparent" v-if="showSelectsFilter">
              <v-layout row>
                <v-flex shrink style="width: 60px; display: none">
                  <v-text-field
                    v-model="rangoPrecio[0]"
                    class="mt-0"
                    hide-details
                    single-line
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-3">
                  <v-range-slider
                    v-model="rangoPrecio"
                    :max="precioFinal"
                    :min="precioInicial"
                    step="1000"
                  ></v-range-slider>
                </v-flex>
                <v-flex shrink style="width: 60px; display: none">
                  <v-text-field
                    v-model="rangoPrecio[1]"
                    class="mt-0"
                    hide-details
                    single-line
                    type="number"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
          <div>
            <br />
            <h5><b>Artista</b></h5>
            <v-select
              v-if="showSelectsFilter"
              v-model="artistasFilter"
              :items="artistas"
              chips
              item-text="nombre"
              item-value="id"
              multiple
              label="Artistas"
            ></v-select>
          </div>
          <div class="text-center">
            <v-btn color="success" @click="filterEventos">FILTRAR EVENTOS</v-btn>
          </div>
          <br />
          <div>
            <v-switch v-model="btnAccesibilidad" color="orange darken-3">
              <template v-slot:label>
                <v-icon class="material-icons">accessible</v-icon>
              </template>
            </v-switch>
          </div>
        </div>
        <div class="col-lg-9 col-md-9">
          <Eventos
            :precioInicio="rangoPrecio[0]"
            :precioFin="rangoPrecio[1]"
            :artistasFilter="artistasFilter"
            :tiposEventosFilter="tiposEventosFilter"
            :fechaInicio="desde"
            :fechaFin="hasta"
          ></Eventos>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-wrap">
        <div class="col-12 d-flex flex-wrap skeleton-imgs-principales p-0">
          <div class="col-12 col-md-6 py-0 pl-0" style="aspect-ratio: 2/1;">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </div>
          <div class="col-12 col-md-6 py-0 pr-0" style="aspect-ratio: 2/1;">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </div>
        </div>
        <div class="col-12 col-md-8 col-xl-9">
          <div class="skeleton-banner-principal col-12 p-0">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </div>
          <div class="skeleton-carousel-under-banner col-12 p-0">
            <div class="col-12 col-md-4" style="aspect-ratio: 5/6;">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </div>
            <div class="col-12 col-md-4" style="aspect-ratio: 5/6;">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </div>
            <div class="col-12 col-md-4" style="aspect-ratio: 5/6;">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="skeleton-fb col-sm-12 col-md-4 col-xl-3">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import moment from "moment";

moment.locale("es");

import Carousel from "./fragments/Carousel.vue";
import TipoEventos from "./fragments/TipoEventos.vue";
import Eventos from "./utils/Eventos.vue";

export default {
  components: {
    Carousel,
    TipoEventos,
    Eventos,
  },
  async beforeCreate() {
    this.$store.dispatch("tipoevento/listTipoEvento", 1);
    this.$store.dispatch("artista/listArtistasActive", 1);
    this.$store.dispatch("evento/listEventosEstadoBanner");
    this.$store.dispatch("evento/bannersDestacados");
  },
  async created() {
    document.title = "Inicio - TicketShop";
  },
  mounted() {
    this.setWidthDivFacebook();
    setTimeout(() => {
      //let heightFace = this.$refs.prinCartel.clientHeight;
      //let widthFace = this.$refs.faceCartel.clientWidth;
      //this.$refs.blockFace.dataset.height=heightFace;
      //this.$refs.blockFace.dataset.width=widthFace;
    }, 1000);
    setTimeout(() => {
      this.showSelectsFilter = true;
    }, 500);
    this.listAllEventos();
    //   let heightFace = this.$refs.prinCartel.clientHeight;
    //   let widthFace = this.$refs.faceCartel.clientWidth;
    //   this.$refs.blockFace.dataset.height=heightFace;
    //   this.$refs.blockFace.dataset.width=widthFace;
    // document.getElementById('div-facebook-page').innerHTML = `
    //   <div class="fb-page" data-href="https://www.facebook.com/TICKETSHOPCOL" data-tabs="timeline" data-width="500" data-height="600" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
    //     <blockquote cite="https://www.facebook.com/TICKETSHOPCOL" class="fb-xfbml-parse-ignore">
    //       <a href="https://www.facebook.com/TICKETSHOPCOL">Ticket Shop Colombia</a>
    //     </blockquote>
    //   </div>
    // `
    //}, 15000);
  },
  data() {
    return {
      ordenarEventosSelect: ["Precio boleta"],
      desde: null,
      hasta: null,
      menu: false,
      modal: false,
      modal2: false,
      menu2: false,
      precioInicial: 10,
      precioFinal: 10000,
      rangoPrecio: [10, 1500],
      btnAccesibilidad: false,
      artistasFilter: [],
      tiposEventosFilter: [],
      showSelectsFilter: false,
      widthDivFacebook: 500,
      heightDivFacebook: 600,
    };
  },
  computed: {
    eventosDestacados(){
      return this.showEventosDestacados.reverse().slice(0, 3);
    },
    showEventosDestacados() {
      return this.$store.getters["evento/listEventos"];
    },
    tiposEventos() {
      return this.$store.getters["tipoevento/listTipoEvento"];
    },
    artistas() {
      return this.$store.getters["artista/listArtistas"];
    },
    eventosEstadoBanner() {
      return this.$store.getters["evento/listEventosEstadoBanner"];
    },
    imagenesEventoCarousel() {
      var imagenesEventoCarouselShow = [];
      for (var i = 0; i < this.eventosEstadoBanner.length; i++) {
        for (var j = 0; j < this.eventosEstadoBanner[i].imagens.length; j++) {
          if (
            this.eventosEstadoBanner[i].imagens[j].nombre.includes("banner")
          ) {
            imagenesEventoCarouselShow.push({
              imagen: this.eventosEstadoBanner[i],
            });
          }
        }
      }

      //reordenar al azar
      var currentIndex = imagenesEventoCarouselShow.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = imagenesEventoCarouselShow[currentIndex];
        imagenesEventoCarouselShow[currentIndex] =
          imagenesEventoCarouselShow[randomIndex];
        imagenesEventoCarouselShow[randomIndex] = temporaryValue;
      }

      return imagenesEventoCarouselShow;
    },
    srcIframeFacebook() {
      let divFacebook = document.getElementById("div-facebook-page");
      if (divFacebook) {
        this.widthDivFacebook = divFacebook.clientWidth;
        this.heightDivFacebook = divFacebook.clientHeight;
        if (window.innerWidth < 768) {
          this.widthDivFacebook = window.innerWidth;
        }
      } else {
        this.setWidthDivFacebook();
      }
      return `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61554355611491&tabs=timeline&width=${
        this.widthDivFacebook
      }&height=${
        this.heightDivFacebook < 600 ? 600 : this.heightDivFacebook * 2
      }&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2235597906716847`;
    },
  },
  methods: {
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("MMMM, DD YYYY");
      } else {
        return "";
      }
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(number);
    },
    filterEventos() {
      this.$store.dispatch("evento/filterEventos", {
        page: 1,
        precio_inicio: this.rangoPrecio[0],
        precio_fin: this.rangoPrecio[1],
        artistas: this.artistasFilter,
        tiposEvento: this.tiposEventosFilter,
        fecha_inicio: this.desde,
        fecha_fin: this.hasta,
      });
    },
    listAllEventos() {
      this.$store.dispatch("evento/filterEventos", {
        page: 1,
        precio_inicio: null,
        precio_fin: null,
        artistas: [],
        tiposEvento: [],
        fecha_inicio: null,
        fecha_fin: null,
      });
      this.$store.dispatch("evento/filterEventos", {
        precio_inicio: this.rangoPrecio[0],
        precio_fin: this.rangoPrecio[1],
        artistas: this.artistasFilter,
        tipos_evento: this.tiposEventosFilter,
        fecha_inicio: this.desde,
        fecha_fin: this.hasta,
      });
    },
    setWidthDivFacebook() {
      let divFacebook = document.getElementById("div-facebook-page");
      if (!divFacebook) {
        return setTimeout(() => this.setWidthDivFacebook(), 1000);
      }
      this.widthDivFacebook = divFacebook.clientWidth;
      this.heightDivFacebook = divFacebook.clientHeight;
    },
  },
};
</script>
<style>
.cont-evento {
  background-color: white;
  border: 1px gray solid;
  text-align: right;
  aspect-ratio: 5/6;
  width: 100%;
  margin-top: 40px;
}
.cont-fecha {
  /* padding:10px;*/
}
.cont-fecha-activo {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
  background-color: #ffff00;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.proximamente-img {
  max-width: 100%;
  width: 100%;
}
.searchEventos .v-input__slot {
  border-radius: 30px !important;
}
.label-text-ordenar-eventos {
  margin-top: 10px;
}
label {
  margin-bottom: 0 !important;
}
.checkFilter {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.checkFilter .v-input__slot {
  margin: 0px !important;
}

.iframe-fb-container {
  text-align: center;
  min-width: 500px;
  height: 460px;
  display: inline-block;
}
@media (max-width: 600px) {
  .iframe-fb-container {
    min-width: 100%;
    max-width: 100vw;
  }
}
.iframe-fb {
  min-width: 500px;
  min-height: 600px;
  width: 100%;
}
@media (max-width: 768px) {
  .iframe-fb{
    min-width: 100vw;
    max-width: 100vw;
  }
}
/** Contenedor de facebook */
#div-facebook-page {
  min-width: 100%;
  min-height: 600px;
  height: 100%;
}
.iframe-fb-container {
  margin-right: auto;
  margin-left: auto;
  height: 100%;
}
.skeleton-imgs-principales .v-skeleton-loader .v-skeleton-loader__image, .skeleton-imgs-principales .v-skeleton-loader{
  height: 100% !important;
}
.skeleton-banner-principal{
  aspect-ratio: 19/10;
}
.skeleton-banner-principal .v-skeleton-loader .v-skeleton-loader__image, .skeleton-banner-principal .v-skeleton-loader{
  height: 100% !important;
}
.skeleton-carousel-under-banner{
  display: flex;
  flex-wrap: wrap;
}
.skeleton-carousel-under-banner .v-skeleton-loader .v-skeleton-loader__image, .skeleton-carousel-under-banner .v-skeleton-loader{
  height: 100%;
}
.skeleton-fb .v-skeleton-loader .v-skeleton-loader__image, .skeleton-fb .v-skeleton-loader{
  height: 100%;
  min-height: 500px;
}
</style>