<template>
  <v-app>
	  <v-container>
      <v-layout row wrap>
        <!--<v-flex lg12 md12 sm12 xs12>
        <center class="area_mapeadaAuditorio">
          <img 
          :src="evento.auditorio_mapeado.imagen"
          usemap="#map">
        </center>
      </v-flex>-->
        <v-flex lg12 md12 sm12 xs12>
          <div class="sub mb-5 pb-5" id:="boletas">
            <BoletasxLocalidad :pagos="true" :fisico="true" v-if="idLocalidad!=''" :idlocalidad="idLocalidad" :idEvento="evento.id" monedaEvento="'COP'" indexMonedaSelectShopEvento="0" simboloMoneda="'$'" monedasEvento="[]"></BoletasxLocalidad>
          </div>
        </v-flex>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-5 pt-5 mt-5">
            <h2>Cambio de estado de puestos por evento</h2>
          </div>
          <div>
            <span><router-link style="color: #1976d2 !important;" :to="{path: '/mis-eventos'}" title="Ver todos mis eventos"><u>Volver a mis eventos</u></router-link></span><span> > </span><span>Visualizar puestos por localidad del evento</span>
          </div>
          <div class="mt-3 mb-4">
            <h2 class="bg-white p-3"><b v-text="evento.nombre"></b></h2>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex mb-5 lg8 md8 sm6 xs12>
          <v-data-table
            :headers="headers"
            :items="listLocalidadesEventoAuditorio_mapeado"
            hide-default-footer
            hide-default-header
            :itemsPerPage="listLocalidadesEventoAuditorio_mapeado.length"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <tr style="cursor: pointer;" title="Ver puestos de la localidad">
                <td @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.tribuna.nombre }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.nombre }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.aforo }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.localidad_evento[0].impuesto }} %</h5></td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex mb-5 lg4 md4 sm6 xs12 p-5> 
          <v-flex align-self-end>
            <v-chip @click="mapasientos(true)">
              <v-icon left color="#907b16">search</v-icon>
              <b>VER MAPA DE PUESTOS</b>
            </v-chip>  
            <br>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-dialog v-model="dialogmapasientos" v-if="activeModalAsientos">
        <v-card>
          <v-card-title class="headline">
            <v-alert
              :value="true"
              color="success"
            >
              Selecciona tu localidad
            </v-alert>
          </v-card-title>    
          <v-card-text>
            <center class="area_mapeadaAuditorio2">
              <img 
              :src="evento.auditorio_mapeado.imagen"
              usemap="#map">
            </center>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              @click="mapasientos(false)"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import moment from "moment";

moment.locale("es");

export default {
  components: {
    BoletasxLocalidad,
  },
  data() {
    return {
      idLocalidad: "",
      dialogmapasientos: false,
      showSelect: false,
      activeModalAsientos: false,
      listening: false,
    };
  },
  async mounted() {
    var self = this;
    setTimeout(() => {
      self.showSelect = true;
    }, 400);

    await self.$store.dispatch("evento/getEvento", this.$route.params.evento);

    var self = this;
    setTimeout(() => {
      self.activeModalAsientos = true;
    }, 500);
    setTimeout(() => {
      $(".area_mapeadaAuditorio2").append(
        $("<textarea />")
          .html(
            self.$store.getters["evento/evento"].auditorio_mapeado.area_mapeada_admin
          )
          .text()
          .replace("&nbsp;", "")
      );
      $(".maparea").click(function (e) {
        e.preventDefault();
        var texthref = e.currentTarget.href;
        var arrayhref = texthref.split("/");
        self.getPuestos(arrayhref[arrayhref.length - 1], null);
        window.location.hash = "boletas";
        //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
        self.dialogmapasientos = false;
      });
    }, 900);
  },
  async beforeCreate() {
    document.title = "Puestos por localidad por evento - TicketShop";
    await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
      this.$route.params.evento
    );
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
      this.$route.params.evento
    );
  },

  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.userSale.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    headers() {
      return [
        {
          text: "TRIBUNA",
          align: "left",
          sortable: true,
          value: "tribuna.nombre",
        },
        { text: "LOCALIDAD", value: "nombre" },
        { text: "AFORO ", value: "aforo" },
        { text: "IMPUESTO ", value: "impuesto" },
      ];
    },
  },
  watch: {
    dialogmapasientos() {
      setTimeout(() => {
        var self = this;
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(this.evento.auditorio_mapeado.area_mapeada_admin)
            .text()
            .replaceAll("&nbsp;", "")
        );
        if(!this.listening){
          this.listening = true;
          $(".maparea").click(function (e) {
            e.preventDefault();
            var texthref = e.currentTarget.href;
            var arrayhref = texthref.split("/");
            self.getPuestos(arrayhref[arrayhref.length - 1], null);
            window.location.hash = "boletas";
            //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
            self.dialogmapasientos = false;
            //$("html, body").animate({ scrollTop: 700 }, 1000);
          });
        }
      }, 200);
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    getnomtribuna(id) {
      var arrayTribunas = [];
      for (
        var i = 0;
        i < this.listLocalidadesEventoAuditorio_mapeado.length;
        i++
      ) {
        arrayTribunas.push(
          this.listLocalidadesEventoAuditorio_mapeado[i].tribuna
        );
      }
      let filter_tribuna = arrayTribunas.filter((tribuna) => tribuna.id == id);
      if (filter_tribuna[0] == "" || filter_tribuna[0] == null) {
        return "";
      } else {
        return filter_tribuna[0].nombre;
      }
    },
    getnomlocalidad(id) {
      let filter_localidad = this.listLocalidadesEventoAuditorio_mapeado.filter(
        (localidad) => localidad.id == id
      );
      if (filter_localidad[0] == "" || filter_localidad[0] == null) {
        return "";
      } else {
        return filter_localidad[0].nombre;
      }
    },
    getPuestos(id, localidad) {
      this.idLocalidad = id;
      if (localidad === null) {
        var localidadFilter = null;
        if (
          this.listLocalidadesEventoAuditorio_mapeado &&
          this.listLocalidadesEventoAuditorio_mapeado.length > 0
        ) {
          localidadFilter = this.listLocalidadesEventoAuditorio_mapeado.filter(
            (item) => item.id == id
          );
          this.$store.dispatch(
            "carrito/localidadSelectCarrito",
            localidadFilter
          );
          $("html, body").animate({ scrollTop: 0 }, 1000);
        }
      } else {
        this.$store.dispatch("carrito/localidadSelectCarrito", localidad);
        $("html, body").animate({ scrollTop: 0 }, 1000);
      }
    },
    async mapasientos(mostrar) {
      if (mostrar) {
        await this.$store.commit("evento/setEvento", this.evento);
      }
      this.dialogmapasientos = mostrar;
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format(
          "dddd Do [de] MMMM [del] YYYY [a las] h:m a"
        );
      } else {
        return "";
      }
    },
  },
};
</script>