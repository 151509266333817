<template>
  <v-app v-if="evento">
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Informe de ventas</h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: black"
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Informe de ventas</span>
          </div>
          <div class="mt-3 mb-4">
            <h2 class="bg-white p-3">Evento <b>{{evento.nombre}}</b></h2>
          </div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <!--<h4>FILTROS:</h4>
	<v-layout row wrap>
		<v-flex lg6 md6 sm6 xs12 mb-3>
			  <v-dialog
                ref="dialogDesde"
                v-model="modalFechaDesde"
                :return-value.sync="fechaDesde"
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaDesde"
                    label="Fecha inicio *"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fechaDesde" scrollable locale="es-ES">
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalFechaDesde = false">Cancelar</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialogDesde.save(fechaDesde)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
		</v-flex>
		<v-flex lg6 md6 sm6 xs12 mb-3>
				   <v-dialog
			        ref="dialogHoraDesde"
			        v-model="modalHoraDesde"
			        :return-value.sync="horaDesde"
			        persistent
			        lazy
			        full-width
			        width="290px"
			      >
			        <template v-slot:activator="{ on }">
			          <v-text-field
			            v-model="horaDesde"
			            label="Hora inicial"
			            prepend-icon="access_time"
			            readonly
			            v-on="on"
			          ></v-text-field>
			        </template>
			        <v-time-picker
			          v-if="modalHoraDesde"
			          v-model="horaDesde"
			          full-width
			        >
			          <v-spacer></v-spacer>
			          <v-btn flat color="primary" @click="modalHoraDesde = false">Cancelar</v-btn>
			          <v-btn flat color="primary" @click="$refs.dialogHoraDesde.save(horaDesde)">OK</v-btn>
			        </v-time-picker>
			      </v-dialog>
		</v-flex>
		<v-flex lg6 md6 sm6 xs12 mb-3>
			<v-dialog
                ref="dialogHasta"
                v-model="modalHasta"
                :return-value.sync="fechaHasta"
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaHasta"
                    label="Fecha final *"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fechaHasta" scrollable locale="es-ES">
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="modalHasta = false">Cancelar</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialogHasta.save(fechaHasta)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
		</v-flex>
				<v-flex lg6 md6 sm6 xs12 mb-3>
				   <v-dialog
			        ref="dialogHoraHasta"
			        v-model="modalHoraHasta"
			        :return-value.sync="horaHasta"
			        persistent
			        lazy
			        full-width
			        width="290px"
			      >
			        <template v-slot:activator="{ on }">
			          <v-text-field
			            v-model="horaHasta"
			            label="Hora final"
			            prepend-icon="access_time"
			            readonly
			            v-on="on"
			          ></v-text-field>
			        </template>
			        <v-time-picker
			          v-if="modalHoraHasta"
			          v-model="horaHasta"
			          full-width
			        >
			          <v-spacer></v-spacer>
			          <v-btn flat color="primary" @click="modalHoraHasta = false">Cancelar</v-btn>
			          <v-btn flat color="primary" @click="$refs.dialogHoraHasta.save(horaHasta)">OK</v-btn>
			        </v-time-picker>
			      </v-dialog>
		</v-flex>
	</v-layout>
<v-divider></v-divider>-->
    </v-container>
    <div class="pl-3 pr-3" v-if="ventasEventoEmpresario">
      <v-layout row wrap>
        <v-flex lg8 md8 sm8 xs12 mb-3>
          <h4>
            Cantidad total de boletas vendidas:
            <b>{{ getTotalBoletasVendidas }}</b>
          </h4>
          <h4>
            Venta total de boletas:
            <b
              >{{
                ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                  .simbolo
              }}{{ formatNumber(getTotalVentasRealizadas) }}</b
            >
          </h4>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12>
          <export-excel
            style="width: fit-content"
            :data="exportVentasEventoEmpresario"
            :export-fields="json_fields"
            worksheet="Informe ventas"
            :name="evento.nombre + '.xls'"
            ><v-btn color="success"
              ><v-icon left>cloud_download</v-icon> EXPORT A EXCEL</v-btn
            ></export-excel
          >
        </v-flex>
      </v-layout>
      <br />
      <v-layout row wrap mb-3 mt-3>
        <v-flex lg3 md3 sm4 xs12>
          <v-chip color="primary" text-color="white">Puestos Vendidos</v-chip>
        </v-flex>
        <v-flex lg3 md3 sm4 xs12>
          <v-chip color="warning" text-color="white"
            >Puestos de cortesia</v-chip
          >
        </v-flex>
      </v-layout>
      <br />
      <div class="table-responsive">
        <table class="elevation-1 table-ventas table-striped table table-hover">
          <thead class="thead-dark">
            <th>LOCALIDAD</th>
            <th>TIPO</th>
            <th>SILLETERIA</th>
            <th>PUESTOS VENDIDOS</th>
            <th>CANTIDAD EN P.D.V</th>
            <th>CANTIDAD EN LINEA</th>
            <th>TOTAL CANTIDAD</th>
            <th>VALOR UNITARIO</th>
            <th>TOTAL EN P.D.V</th>
            <th>TOTAL EN LINEA</th>
            <th>TOTAL</th>
          </thead>
          <tbody>
            <tr :key="index" v-for="(item, index) in ventasEventoEmpresario.localidades_evento">
              <td class="font-weight-bold text-uppercase">
                {{ item.localidad.nombre }}
              </td>
              <td class="">
                {{ item.localidad.palco ? "PALCO" : "BOLETERIA" }}
              </td>
              <td class="">{{ item.localidad.silleteria ? "SI" : "NO" }}</td>
              <td class="">
                <div v-if="item.localidad.silleteria">
                  <v-chip
                    color="primary"
                    text-color="white"
                    :key="index"
                    v-for="(puesto, index) in item.localidad.ventas.puestos_vendidos.filter(
                      (item) =>
                        item.detalle_vents[0].vent.forma_pago != 'cortesia'
                    )"
                    >{{ puesto.palco.puestos[0].numero }}</v-chip
                  >
                  <v-chip
                    color="warning"
                    text-color="white"
                    :key="index"
                    v-for="(puesto, index) in item.localidad.ventas.puestos_vendidos.filter(
                      (item) =>
                        item.detalle_vents[0].vent.forma_pago === 'cortesia'
                    )"
                    >{{ puesto.palco.puestos[0].numero }}</v-chip
                  >
                </div>
                <div v-else>
                  <v-chip
                    x-large
                    color="primary"
                    text-color="white"
                    v-if="
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago != 'cortesia'
                      ).length > 0
                    "
                  >
                    <div class="blue darken-4 mr-2 py-1 px-2" style="border-radius: 50px">{{
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago != "cortesia"
                      ).length
                    }}</div>
                    Vendidos</v-chip>
                  <v-chip
                    x-large
                    color="warning"
                    text-color="white"
                    v-if="
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago == 'cortesia'
                      ).length > 0
                    "
                  >
                    <v-avatar class="orange darken-4">{{
                      item.localidad.ventas.puestos_vendidos.filter(
                        (item) =>
                          item.detalle_vents[0].vent.forma_pago == "cortesia"
                      ).length
                    }}</v-avatar>
                    Cortesias</v-chip
                  >
                </div>
              </td>
              <td class="font-weight-bold text-center">
                <span
                  v-if="
                    getPuestosVendidosFisicos(
                      item.localidad.ventas.puestos_vendidos
                    ) > 0
                  "
                  >{{
                    getPuestosVendidosFisicos(
                      item.localidad.ventas.puestos_vendidos
                    )
                  }}</span
                >
              </td>
              <td class="font-weight-bold text-center">
                <span
                  v-if="
                    getPuestosVendidosEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    ) > 0
                  "
                  >{{
                    getPuestosVendidosEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    )
                  }}</span
                >
              </td>
              <td class="font-weight-bold text-center">
                <span>{{
                  getPuestosVendidosEnLinea(
                    item.localidad.ventas.puestos_vendidos
                  ) +
                  getPuestosVendidosFisicos(
                    item.localidad.ventas.puestos_vendidos
                  )
                }}</span>
              </td>
              <td class="font-weight-bold">
                {{ item.codigo_moneda.simbolo
                }}{{
                  formatNumber(
                    item.precio_venta + item.precio_venta * item.impuesto
                  )
                }}
              </td>
              <td class="font-weight-bold">
                {{ item.codigo_moneda.simbolo
                }}{{
                  formatNumber(
                    getTotalVentasFisicas(
                      item.localidad.ventas.puestos_vendidos
                    )
                  )
                }}
              </td>
              <td class="font-weight-bold">
                {{ item.codigo_moneda.simbolo
                }}{{
                  formatNumber(
                    getTotalVentasEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    )
                  )
                }}
              </td>
              <td class="font-weight-bold">
                {{ item.codigo_moneda.simbolo
                }}{{
                  formatNumber(
                    getTotalVentasEnLinea(
                      item.localidad.ventas.puestos_vendidos
                    ) +
                      getTotalVentasFisicas(
                        item.localidad.ventas.puestos_vendidos
                      )
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold bg-dark text-white">TOTAL</td>
              <td class="font-weight-bold bg-dark text-white"></td>
              <td class="font-weight-bold bg-dark text-white"></td>
              <td class="font-weight-bold bg-dark text-white"></td>
              <td class="font-weight-bold bg-dark text-white text-center">
                {{ getTotalBoletasVendidasFisicas }}
              </td>
              <td class="font-weight-bold bg-dark text-white text-center">
                {{ getTotalBoletasVendidasEnLinea }}
              </td>
              <td class="font-weight-bold bg-dark text-white text-center">
                {{ getTotalBoletasVendidas }}
              </td>
              <td class="font-weight-bold bg-dark text-white"></td>
              <td class="font-weight-bold bg-dark text-white">
                {{
                  ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo
                }}{{ formatNumber(getTotalVentasRealizadasFisicas) }}
              </td>
              <td class="font-weight-bold bg-dark text-white">
                {{
                  ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo
                }}{{ formatNumber(getTotalVentasRealizadasEnLinea) }}
              </td>
              <td class="font-weight-bold bg-dark text-white">
                {{
                  ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                    .simbolo
                }}{{ formatNumber(getTotalVentasRealizadas) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12 mb-3>
          <h4>
            Cantidad total de boletas vendidas:
            <b>{{ getTotalBoletasVendidas }}</b>
          </h4>
          <h4>
            Venta total de boletas:
            <b
              >{{
                ventasEventoEmpresario.localidades_evento[0].codigo_moneda
                  .simbolo
              }}{{ formatNumber(getTotalVentasRealizadas) }}</b
            >
          </h4>
        </v-flex>
      </v-layout>
    </div>
  </v-app>
</template>
<script>
import moment from "moment";

moment.locale("es");
export default {
  data() {
    return {
      fechaDesde: null,
      fechaHasta: null,
      modalDesde: false,
      modalHasta: false,
      modalHoraDesde: false,
      horaDesde: null,
      modalFechaDesde: false,
      horaHasta: null,
      modalHoraHasta: false,
      headers: [
        { text: "LOCALIDAD", value: "", align: "left", sortable: false },
        { text: "TIPO", value: "", align: "left", sortable: false },
        { text: "SILLETERIA", value: "", align: "left", sortable: false },
        { text: "PUESTOS VENDIDOS", value: "", align: "left", sortable: false },
        {
          text: "CANTIDAD EN P.D.V",
          value: "",
          align: "left",
          sortable: false,
        },
        {
          text: "CANTIDAD EN LINEA",
          value: "",
          align: "left",
          sortable: false,
        },
        { text: "VALOR UNITARIO", value: "", align: "left", sortable: false },
        { text: "TOTAL EN P.D.V", value: "", align: "left", sortable: false },
        { text: "TOTAL EN LINEA", value: "", align: "left", sortable: false },
        { text: "TOTAL", value: "", align: "left", sortable: false },
      ],
      json_fields: {
        LOCALIDAD: "localidad",
        TIPO: "tipo",
        SILLETERIA: "silleteria",
        "PUESTOS VENDIDOS": "puestos_vendidos",
        "CANTIDAD EN P.D.V": "cantidad_fisicos",
        "CANTIDAD EN LINEA": "cantidad_linea",
        "TOTAL CANTIDAD": "total_cantidad",
        "VALOR UNITARIO": "valor_unitario",
        "TOTAL EN P.D.V": "total_ventas_fisicas",
        "TOTAL EN LINEA": "total_ventas_linea",
        TOTAL: "total_ventas",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  async beforeCreate() {
    document.title = "Informe de ventas - TicketShop";
    await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
    await this.$store.dispatch(
      "evento/ventasEventoEmpresario",
      this.$route.params.evento
    );
    // if (
    //   this.evento.cliente.tipo_identificacion !=
    //     this.userAuth.tipo_identificacion ||
    //   this.evento.cliente.Identificacion != this.userAuth.identificacion
    // ) {
    //   this.$router.push({ name: "misEventos" });
    // }
  },

  computed: {
    evento() {
      return this.$store.getters["evento/evento"];
    },
    userAuth() {
      return this.$store.getters["user/dataUserLogged"];
    },
    ventasEventoEmpresario() {
      return this.$store.getters["evento/ventasEventoEmpresario"];
    },
    getTotalBoletasVendidas() {
      var total = 0;
      if(this.ventasEventoEmpresario.length > 0){
        for (
          var i = 0;
          i < this.ventasEventoEmpresario.localidades_evento.length;
          i++
        ) {
          total +=
            this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
              .puestos_vendidos.length;
        }
        return total;
      }
      return total;
    },
    getTotalBoletasVendidasFisicas() {
      var total = 0;
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        total += this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) => item.detalle_vents[0].vent.tipo_venta == 1
        ).length;
      }
      return total;
    },
    getTotalBoletasVendidasEnLinea() {
      var total = 0;
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        total += this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) => item.detalle_vents[0].vent.tipo_venta == 0
        ).length;
      }
      return total;
    },
    getTotalVentasRealizadas() {
      var total = 0;
      var ventas_id = [];
      var precios_servicio = 0;
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        var puestosArr = this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) => item.detalle_vents[0].vent.forma_pago != "cortesia"
        );
        var puestos = puestosArr;

        for (var l = 0; l < puestos.length; l++) {
          for (
            var u = 0;
            u <
            puestos[l].detalle_vents.filter((item) => item.vent != null).length;
            u++
          ) {
            precios_servicio += puestos[l].detalle_vents[u].precio_servicio;
          }

          if (
            !ventas_id.includes(parseInt(puestos[l].detalle_vents[0].id_venta))
          ) {
            total +=
              puestos[l].detalle_vents[0].vent.monto_tarjeta +
              puestos[l].detalle_vents[0].vent.monto_efectivo;

            for (
              var a = 0;
              a < puestos[l].detalle_vents[0].vent.abonos_venta_parcial.length;
              a++
            ) {
              total +=
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_tarjeta +
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_efectivo;
            }

            ventas_id.push(parseInt(puestos[l].detalle_vents[0].id_venta));
          }
        }
      }
      return total - precios_servicio;
    },
    getTotalVentasRealizadasFisicas() {
      var total = 0;
      var ventas_id = [];
      var precios_servicio = 0;
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        var puestosArr = this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) =>
            item.detalle_vents[0].vent.tipo_venta == 1 &&
            item.detalle_vents[0].vent.forma_pago != "cortesia"
        );
        var puestos = puestosArr;

        for (var l = 0; l < puestos.length; l++) {
          for (
            var u = 0;
            u <
            puestos[l].detalle_vents.filter((item) => item.vent != null).length;
            u++
          ) {
            precios_servicio += puestos[l].detalle_vents[u].precio_servicio;
          }

          if (
            !ventas_id.includes(parseInt(puestos[l].detalle_vents[0].id_venta))
          ) {
            total +=
              puestos[l].detalle_vents[0].vent.monto_tarjeta +
              puestos[l].detalle_vents[0].vent.monto_efectivo;

            for (
              var a = 0;
              a < puestos[l].detalle_vents[0].vent.abonos_venta_parcial.length;
              a++
            ) {
              total +=
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_tarjeta +
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_efectivo;
            }

            ventas_id.push(parseInt(puestos[l].detalle_vents[0].id_venta));
          }
        }
      }
      return total - precios_servicio;
    },
    getTotalVentasRealizadasEnLinea() {
      var total = 0;
      var ventas_id = [];
      var precios_servicio = 0;
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        var puestosArr = this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) =>
            item.detalle_vents[0].vent.tipo_venta == 0 &&
            item.detalle_vents[0].vent.forma_pago != "cortesia"
        );
        var puestos = puestosArr;

        for (var l = 0; l < puestos.length; l++) {
          for (
            var u = 0;
            u <
            puestos[l].detalle_vents.filter((item) => item.vent != null).length;
            u++
          ) {
            precios_servicio += puestos[l].detalle_vents[u].precio_servicio;
          }

          if (
            !ventas_id.includes(parseInt(puestos[l].detalle_vents[0].id_venta))
          ) {
            total +=
              puestos[l].detalle_vents[0].vent.monto_tarjeta +
              puestos[l].detalle_vents[0].vent.monto_efectivo;

            for (
              var a = 0;
              a < puestos[l].detalle_vents[0].vent.abonos_venta_parcial.length;
              a++
            ) {
              total +=
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_tarjeta +
                puestos[l].detalle_vents[0].vent.abonos_venta_parcial[a]
                  .monto_efectivo;
            }

            ventas_id.push(parseInt(puestos[l].detalle_vents[0].id_venta));
          }
        }
      }
      return total - precios_servicio;
    },
    exportVentasEventoEmpresario() {
      var ventas = [];
      for (
        var i = 0;
        i < this.ventasEventoEmpresario.localidades_evento.length;
        i++
      ) {
        var puestos_vendidos = "";
        var cantidad_fisicos = 0;
        var cantidad_linea = 0;
        var total_cantidad = 0;
        var valor_unitario = 0;
        var total_ventas_fisicas = 0;
        var total_ventas_linea = 0;
        var total_ventas = 0;
        for (
          var j = 0;
          j <
          this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
            .puestos_vendidos.length;
          j++
        ) {
          if (
            this.ventasEventoEmpresario.localidades_evento[i].localidad.palco
          ) {
            puestos_vendidos +=
              this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
                .puestos_vendidos[j].palco.puestos[0].numero + ",";
          }
        }

        total_ventas_fisicas =
          this.ventasEventoEmpresario.localidades_evento[i].codigo_moneda
            .simbolo +
          "" +
          this.formatNumber(
            this.getTotalVentasFisicas(
              this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
                .puestos_vendidos
            )
          );

        total_ventas_linea =
          this.ventasEventoEmpresario.localidades_evento[i].codigo_moneda
            .simbolo +
          "" +
          this.formatNumber(
            this.getTotalVentasEnLinea(
              this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
                .puestos_vendidos
            )
          );

        valor_unitario =
          this.ventasEventoEmpresario.localidades_evento[i].codigo_moneda
            .simbolo +
          "" +
          this.formatNumber(
            this.ventasEventoEmpresario.localidades_evento[i].precio_venta +
              this.ventasEventoEmpresario.localidades_evento[i].precio_venta *
                this.ventasEventoEmpresario.localidades_evento[i].impuesto
          );

        cantidad_fisicos = this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) => item.detalle_vents[0].vent.tipo_venta == 1
        ).length;

        cantidad_linea = this.ventasEventoEmpresario.localidades_evento[
          i
        ].localidad.ventas.puestos_vendidos.filter(
          (item) => item.detalle_vents[0].vent.tipo_venta == 0
        ).length;

        total_cantidad = cantidad_fisicos + cantidad_linea;

        total_ventas =
          this.ventasEventoEmpresario.localidades_evento[i].codigo_moneda
            .simbolo +
          "" +
          this.formatNumber(
            this.getTotalVentasFisicas(
              this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
                .puestos_vendidos
            ) +
              this.getTotalVentasEnLinea(
                this.ventasEventoEmpresario.localidades_evento[i].localidad
                  .ventas.puestos_vendidos
              )
          );

        if (
          !this.ventasEventoEmpresario.localidades_evento[i].localidad.palco
        ) {
          var puestos_vendidos =
            this.ventasEventoEmpresario.localidades_evento[i].localidad.ventas
              .puestos_vendidos.length;
        }

        var tipoBoleta = this.ventasEventoEmpresario.localidades_evento[i]
          .localidad.palco
          ? "PALCO"
          : "BOLETERIA";
        var silleteria = this.ventasEventoEmpresario.localidades_evento[i]
          .localidad.silleteria
          ? "SI"
          : "NO";

        ventas.push({
          localidad:
            this.ventasEventoEmpresario.localidades_evento[i].localidad.nombre,
          tipo: tipoBoleta,
          silleteria: silleteria,
          puestos_vendidos: puestos_vendidos,
          cantidad_fisicos: cantidad_fisicos,
          cantidad_linea: cantidad_linea,
          total_cantidad: total_cantidad,
          valor_unitario: valor_unitario,
          total_ventas_fisicas: total_ventas_fisicas,
          total_ventas_linea: total_ventas_linea,
          total_ventas: total_ventas,
        });
      }
      return ventas;
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    getPuestosVendidosFisicos(puestos_vendidos) {
      return puestos_vendidos.filter(
        (item) => item.detalle_vents[0].vent.tipo_venta == 1
      ).length;
    },
    getPuestosVendidosEnLinea(puestos_vendidos) {
      return puestos_vendidos.filter(
        (item) => item.detalle_vents[0].vent.tipo_venta == 0
      ).length;
    },
    getTotalVentasFisicas(puestos_vendidos) {
      var total = 0;
      var ventas_id = [];
      var puestos = puestos_vendidos.filter(
        (item) =>
          item.detalle_vents[0].vent.tipo_venta == 1 &&
          item.detalle_vents[0].vent.forma_pago != "cortesia"
      );

      if (puestos.length > 0) {
        total =
          (puestos[0].detalle_vents[0].precio_venta +
            puestos[0].detalle_vents[0].precio_venta *
              puestos[0].detalle_vents[0].impuesto) *
          puestos.length;
      }

      return total;
    },
    getTotalVentasEnLinea(puestos_vendidos) {
      var total = 0;
      var ventas_id = [];

      var puestos = puestos_vendidos.filter(
        (item) =>
          item.detalle_vents[0].vent.tipo_venta == 0 &&
          item.detalle_vents[0].vent.forma_pago != "cortesia"
      );

      if (puestos.length > 0) {
        total =
          (puestos[0].detalle_vents[0].precio_venta +
            puestos[0].detalle_vents[0].precio_venta *
              puestos[0].detalle_vents[0].impuesto) *
          puestos.length;
      }

      return total;
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 13px;
}
</style>