import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers,headersMultipart} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puestos: [],
       puestosauditorio: [],
       puestoslocalidad: [],
       puestosfila: [],
       puesto: null,
       precio: 0,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuestos: (state,getters,rootState) => {
          return state.puestos;
    },
    listPuestosAuditorio: (state,getters,rootState) => {
          return state.puestosauditorio;
    },
    listPuestosLocalidad: (state,getters,rootState) => {
          return state.puestoslocalidad;
    },
    listPuestosFila: (state,getters,rootState) => {
          return state.puestosfila;
    },
    puesto: (state,getters,rootState) => {
        return state.puesto;
    },
    viewPrecio: (state,getters,rootState) => {
      return state.precio;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuestos: (state,puestos) => {
          state.puestos = puestos.data;
          state.pagination.totalItems = puestos.total;
          state.pagination.page = puestos.current_page;
          state.pagination.rowsPerPage = puestos.per_page;
       },
       listPuestosAuditorio: (state,puestosauditorio) => {
          state.puestosauditorio = puestosauditorio.data;
       },
       listPuestosLocalidad: (state,puestoslocalidad) => {
          state.puestoslocalidad = puestoslocalidad.data;
       },
       listPuestosFila: (state,puestosfila) => {
          state.puestosfila = puestosfila;
       },
       viewPuesto(state,id){
         let auxPuesto = state.puestos.filter((item) => item.id == id);
         state.puesto = auxPuesto[0];
       },
       listPuestosSearch: (state,puestos) => {
         if(puestos != null){ 
             state.puestos = puestos;
         }else{
           state.puestos = null;
         }
       },
       setPrecio(state,precio){
          state.precio = precio;
       },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuestos({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}puesto_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listPuestos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos!", "error");
        console.error(error);
      });
    },
    listPuestosAuditorio({state,getters,commit,rootState},key){
      state.loading = true;
      axios.get(`${configApi.apiUrl}puestos_auditorio/${key}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listPuestosAuditorio',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos por auditorio!", "error");
        console.error(error);
      });
    },
    listPuestosLocalidad({state,getters,commit,rootState},key){
      state.loading = true;
      axios.get(`${configApi.apiUrl}puestos_localidad/${key}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listPuestosLocalidad',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos por auditorio!", "error");
        console.error(error);
      });
    },
    listPuestosFila({state,getters,commit,rootState},key){
      state.loading = true;
      return axios.get(`${configApi.apiUrl}puestos_fila/${key}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listPuestosFila',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos por fila!", "error");
        console.error(error);
      });
    },
  newPuesto({state,getters,commit,rootState},payload){
      // numero
      // id_localidad
      // id_fila
      //store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}puesto`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el puesto correctamente!", "success");
        // store.dispatch('puesto/listPuestos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto!", "error");
        console.error(error);
      });
  },
  newPuestosxfila({state,getters,commit,rootState},payload){
      // numeros
      // id_localidad
      // id_fila
      //store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}puestosxfila`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se crearon los puestos correctamente!", "success");
        // store.dispatch('puesto/listPuestos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto!", "error");
        console.error(error);
      });
  },
  updatePuesto({state,getters,commit,rootState},payload){
      // numero
      // id_localidad
      // id_fila
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puesto/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el puesto correctamente!", "success");
        store.dispatch('condicion/listPuestos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el puesto!", "error");
        console.error(error);
      });
  },
  deletePuesto({state,getters,commit,rootState},puesto){
    store.dispatch('validateToken');
    return axios.delete(`${configApi.apiUrl}puesto/${puesto}`,null,{headers: headers})
     .then(response => {
        /*swal("Exito!", "Se elimino el puesto correctamente!", "success");*/
        /*store.dispatch('puesto/listPuestos,state.pagination.page);*/
     })
     .catch(error => {
        swal("Error!","Error al eliminar el puesto!","error");
        //console.log(error);
     });
  },
      searchPuestos({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPuestos?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPuestosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los puestos!", "error");
        console.error(error);
      });
    },
    getPrecio({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      return axios.post(`${configApi.apiUrl}precioPuestoEvento`,payload,{headers: headers})
      .then(response => {
        commit('setPrecio',response.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los precio del puestos!", "error");
        console.error(error);
      });
    },
    getAccionPuesto({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      return axios.post(`${configApi.apiUrl}get_accion_puesto`,payload,{headers: headers})
      .then(response => {
        store.commit('changeLoader', false);
        return response.data;
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener informacion del puesto!", "error");
        console.error(error);
      });
    },
     saveAccionPuesto({state,getters,commit,rootState},payload){
      store.commit('changeLoader', true);
      return axios.post(`${configApi.apiUrl}save_accion_puesto`,payload,{headers: headers})
      .then(response => {
        store.commit('changeLoader', false);
        return response.data;
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener guardar la accion del puesto!", "error");
        console.error(error);
      });
    },
  },
}