//Modulos y Componentes
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import axios from 'axios';
import { store } from './store/';
import { routes } from './routes';
//import Vuetify from 'vuetify';
import vuetify from './plugins/vuetify';
import 'babel-polyfill';
import 'vuetify/dist/vuetify.min.css';
import moment from 'moment';
import swal from 'sweetalert';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import es from 'vee-validate/dist/locale/es';
import VeeValidate, { Validator } from 'vee-validate';
import VueApexCharts from 'vue-apexcharts';
import VCurrencyField from 'v-currency-field';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueHtmlToPaper from 'vue-html-to-paper';
import excel from 'vue-excel-export'

import VueMask from 'v-mask'
Vue.use(VueMask);

const optionsPrinter = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, optionsPrinter);
Vue.use(excel);
Vue.use(VCurrencyField, {
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDFZn-_2DpGEgdfnXX4gywzaGRS01HgA-U'
  },
  installComponents: false
});
Vue.component('google-map', VueGoogleMaps.Map);

Vue.use(VueFormWizard);
Vue.use(VeeValidate);
Validator.localize('es', es);

library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts);

moment.locale('es');

//Librerias
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'material-icons';
import 'fontawesome';
import '../public/css/app.css';
import '../public/vendors/themify-icons/themify-icons.css';
import '../public/vendors/themify-icons/ie7/ie7.css';


Vue.config.productionTip = false

window.Emit = new Vue();

Vue.use(VueRouter);

export const router = new VueRouter({
  routes,
  mode: 'history',
});

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessTokenTicketClient')}`;
axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.user.accessToken}`;
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  let originalRequest = error.config
  if (error.response.status === 401) {
    //store.dispatch('logoutLocal');
  }
  return Promise.reject(error)
});

router.beforeEach(async (to, from, next) => {
  if (store.state.user.accessToken) {
    await store.dispatch('validateToken');
  }
  var permisos = [];
  if (to.meta.requiresAdministrador) {
    permisos.push(1);
  }
  if (to.meta.requiresComprador) {
    permisos.push(2);
  }
  if (to.meta.requiresEmpresario) {
    permisos.push(3);
  }
  if (to.meta.requiresComercial) {
    permisos.push(4);
  }
  if (to.meta.requiresPuntodeVenta) {
    permisos.push(5);
  }
  if (to.meta.requiresJefeComercial) {
    permisos.push(6);
  }
  if (to.meta.requiresJefedePuntosdeVenta) {
    permisos.push(7);
  }
  if (to.meta.requiresFinanzas) {
    permisos.push(8);
  }
  if (to.meta.requiresAnalista) {
    permisos.push(9);
  }
  if (to.meta.requiresGerencia) {
    permisos.push(10);
  }
  if (!to.meta.requiresAuth && permisos.length == 0) {
    next();
  } else if (to.meta.requiresAuth && permisos.length == 0) {
    if (store.state.user.accessToken === null || store.state.user.accessToken == '' || store.state.user.accessToken == undefined) {
      next('/');
    } else {
      next();
    }
    // store.dispatch('fetchAccessToken')
    // .then(response =>{
    //   if (store.state.accessToken === null || store.state.accessToken == '') {
    //     next('/');
    //   }else{
    //     store.dispatch('validateToken')
    //     .then(response => {
    //       store.dispatch('user/updateDataUserLogged');
    //       next();
    //     });
    //   }
    // });
  } else {
    if (permisos.length == 0) {
      next();
      // store.dispatch('validateToken')
      // .then(response =>{
      //    store.dispatch('user/updateDataUserLogged')
      //    .then(response => {
      //      next();
      //    });
      //  });
    } else {
      if (store.state.user.accessToken === null || store.state.user.accessToken == '' || store.state.user.accessToken == undefined) {
        next('/');
      } else {
        var cont = false
        for (var i = 0; i < permisos.length; i++) {
          if (store.state.user.dataUserLogged.id_rol == permisos[i]) {
            cont = true;
          }
        }
        if (cont) {
          next();
        } else {
          next('/');
        }
        // store.dispatch('validateToken')
        // .then(response => {
        //   store.dispatch('user/detailsUser')
        //   .then(response => {
        //     store.dispatch('user/updateDataUserLogged')
        //     .then(response => {
        //       for (var i = 0; i < permisos.length; i++) {
        //         if (store.state.user.dataUserLogged.id_rol==permisos[i]) {
        //           cont = true;
        //         }  
        //       }
        //       if (cont) {
        //         next();
        //       } else {
        //         next('/');
        //       }
        //     });
        //   });
        // });
      }
    }
  }
});

Vue.filter('mayusculas', (value) => value.toUpperCase());

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
