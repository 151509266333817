<template>
  <v-app id="boletasxlocalidadcomponent">

    <!-- Cupones -->
    <div v-if="boletaslocalidad" class="puestos">
      <div  v-if="this.evento != null && currentRoute != 'solicitud'"> 
        <div  v-show="this.evento.fechaValidaInicioCuponVenta && currentRoute != 'CortesiasEventoJefePuntoVenta'">  
          <v-card-text>
            <v-alert  v-show="this.evento.activoevento" 
              border="bottom"
              colored-border
              type="success"
              elevation="2"
            >
            <div  v-show="!$store.state.carrito.cuponok" >
              Si Cuentas con un <b>CUPON</b>,  venta  en linea disponible a partir de  {{  this.formatDatetimeString( this.evento.fecha_inicio_venta_cupon_formateada ) }}
              <v-divider></v-divider>
              <div class="row"     v-show="this.evento.fechaValidaInicioCuponVenta"  >
                <div class="col-lg-6 col-sm-6">
                  <v-text-field
                    v-model="cupon"
                    label="INGRESAR CUPON *"
                    style="text-align:center; font-weight:bold; font-size:18px;"
                    required
                  ></v-text-field>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <v-btn
                      color="success"
                      dark
                      @click="getvalidarcupon()"
                      :loading="validandocupon"
                      >VALIDAR CUPON</v-btn>
                  </div>
                </div>
              </div>
              <div  v-show="$store.state.carrito.cuponok" >
                <b>CUPON</b>, Validado Correctamente, puedes seguir comprando y al finalizar la compra se te aplicara el descuento de : {{ $store.state.carrito.descuentocupon }} %
              </div>
            </v-alert>   
          </v-card-text>
        </div>
      </div>
      <center>
        <div>
          <h2 class="text-center text-dark">
            LOCALIDAD   {{ boletaslocalidad.nombre }}
          </h2>
          <br />
          <!-- encabezado -->
          <v-layout row wrap>
            <v-flex class="m-3">
              <v-btn small color="success" class="m-3" v-if="listBoletasLocalidadDisponibilidad.disponibles > 0"
                >DISPONIBLES&nbsp;
                <span class="badge bg-white text-dark">
                  {{ listBoletasLocalidadDisponibilidad.disponibles }}
                </span>
              </v-btn>
              <v-btn small color="warning" class="m-3"
                >EN PROCESO&nbsp;
                <span class="badge bg-white text-dark">
                  {{ listBoletasLocalidadDisponibilidad.reservados }}
                </span>
              </v-btn>
              <v-btn
                v-if="usuarioAuth.id_rol != 2 && usuarioAuth.id_rol != null"
                small
                color="purple"
                class="m-3"
                >RESERVADOS&nbsp;
                <span class="badge bg-white text-dark">
                  {{ listBoletasLocalidadDisponibilidad.pagos_parcial }}
                </span>
              </v-btn>
              <v-btn small color="error" class="m-3"
                >OCUPADOS&nbsp;
                <span class="badge bg-white text-dark"  v-if="usuarioAuth.id_rol != 2 && usuarioAuth.id_rol != null">
                  {{
                    usuarioAuth.id_rol == 2 || usuarioAuth.id_rol == null
                      ? listBoletasLocalidadDisponibilidad.ocupados +
                        listBoletasLocalidadDisponibilidad.pagos_parcial +
                        listBoletasLocalidadDisponibilidad.inhabilitados
                      : listBoletasLocalidadDisponibilidad.ocupados
                  }}
                </span>
              </v-btn>
              <v-btn small color="info" class="m-3"
                >MESA INDIVIDUAL&nbsp;
              </v-btn>
              <v-btn
                v-if="usuarioAuth.id_rol != 2 && usuarioAuth.id_rol != null"
                small
                color="#808080"
                >INHABILITADOS&nbsp;
                <span class="badge bg-white text-dark">
                  {{ listBoletasLocalidadDisponibilidad.inhabilitados }}
                </span>
              </v-btn>
            </v-flex>
            <!-- <v-flex xs12 class="mt-3">
              <v-btn v-if="boletaslocalidad.silleteria == 1" color="primary"
                >Elige tu
                {{ boletaslocalidad.palco != 1 ? "boleto" : "palco" }}</v-btn
              >
              <v-btn
                class="mb-3"
                v-if="boletaslocalidad.silleteria != 1"
                color="success"
              >
                {{ listBoletasLocalidadDisponibilidad.disponibles }} disponibles
              </v-btn>
              <br />
              <v-btn
                class="mb-3"
                v-if="boletaslocalidad.silleteria != 1"
                color="warning"
              >
                {{ listBoletasLocalidadDisponibilidad.reservados }} 
                en proceso
              </v-btn>
              <br />
              <v-btn
                class="mb-3"
                v-if="
                  boletaslocalidad.silleteria != 1 &&
                  usuarioAuth.id_rol != 2 &&
                  usuarioAuth.id_rol != null
                "
                color="purple"
              >
                {{ listBoletasLocalidadDisponibilidad.pagos_parcial }} 
                reservados
              </v-btn>
              <br />
              <v-btn
                class="mb-3"
                v-if="boletaslocalidad.silleteria != 1"
                color="error"
              > -->
                <!-- {{
                  usuarioAuth.id_rol == 2 || usuarioAuth.id_rol == null
                    ? listBoletasLocalidadDisponibilidad.ocupados +
                      listBoletasLocalidadDisponibilidad.pagos_parcial +
                      listBoletasLocalidadDisponibilidad.inhabilitados
                    : listBoletasLocalidadDisponibilidad.ocupados
                }} -->
                <!-- ocupados
              </v-btn>
              <br />
              <v-btn
                class="mb-3"
                v-if="
                  boletaslocalidad.silleteria != 1 &&
                  usuarioAuth.id_rol != 2 &&
                  usuarioAuth.id_rol != null
                "
                color="#808080"
              > -->
                <!-- {{ listBoletasLocalidadDisponibilidad.inhabilitados }} inhabilitados -->
              <!-- </v-btn>
              <br /> <br>
            </v-flex> -->
          </v-layout>
          <!-- fin encabezado -->

          <!-- boletas -->
          <div
            v-if="
              boletaslocalidad.silleteria == 1 && boletaslocalidad.palco != 1
            "
            style="overflow-x: scroll"
          >
            <div
              :key="index"
              v-for="(fila, index) in boletaslocalidad.filas"
              :class="{
                'justify-start': fila.alineacion == 1,
                'justify-start': fila.alineacion == 2,
                'justify-start': fila.alineacion == 3,
              }"
              :style="{
                display: 'flex',
                width: (puestosmayor + 120) + 'px',
                borderBottom: '1px #a0a0a0 solid',
              }"
              style="align-items: center"
            >
              <h3
                v-text="fila.nombre"
                class="text-center text-dark font-weight-bold"
                style="width: 5%; font-size: 10px;"
              ></h3>
              <div class="d-flex w-100" v-if="fila.orientacion == 1 && fila.puestos.length > 0">
                <div
                  :key="index"
                  v-for="(puesto, index) in fila.puestos"
                  class="sizePuestos"                  
                >
                  <div
                    v-if="
                      puesto.boleta_eventos.length != 0 &&
                      puesto.boleta_eventos[0].status != 9
                    "
                  >
                    <v-card
                      v-if="puesto.boleta_eventos.length != 0"
                      class="iconPuesto"
                      @click="
                        getBoleta(puesto.boleta_eventos[0], puesto.numero)
                      "
                      :class="{
                        success: puesto.boleta_eventos[0].status == 1,
                        warning: puesto.boleta_eventos[0].status == 2 || puesto.boleta_eventos[0].status == 6,
                        error:                          
                          puesto.boleta_eventos[0].status == 7 ||
                          puesto.boleta_eventos[0].status == 8 ||
                          puesto.boleta_eventos[0].status == 10 ||
                          ((puesto.boleta_eventos[0].status == 3 ||
                            puesto.boleta_eventos[0].status == 4) &&
                            (usuarioAuth.id_rol == 2 ||
                              usuarioAuth.id_rol == null)),
                        purple:
                          puesto.boleta_eventos[0].status == 3 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        gray:
                          puesto.boleta_eventos[0].status == 4 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        transparent: puesto.boleta_eventos[0].status == 9,
                      }"
                    >
                      <!-- <v-icon v-if="puesto.boleta_eventos[0].status != 9"
                        >person</v-icon
                      > -->
                      {{ puesto.numero }}
                      <br />
                    </v-card>
                  </div>
                  <v-card
                    v-if="puesto.boleta_eventos.length == 0"
                    class="iconPuesto success"
                  >
                    <!-- <v-icon>person</v-icon> -->
                    {{ puesto.numero }}                
                  </v-card>
                </div>
              </div>

              <div
                :key="index"
                v-for="(puesto, index) in fila.puestos.slice().reverse()"
                v-if="fila.orientacion == 2 && fila.puestos.length > 0"
                class="sizePuestos"                
              >
                <div
                  v-if="
                    puesto.boleta_eventos.length != 0 &&
                    puesto.boleta_eventos[0].status != 9
                  "
                >
                  <v-card
                    v-if="puesto.boleta_eventos.length != 0"
                    class="iconPuesto"
                    @click="getBoleta(puesto.boleta_eventos[0], puesto.numero)"
                    :class="{
                      success: puesto.boleta_eventos.length != 0,
                      success: puesto.boleta_eventos[0].status == 1,
                      warning: puesto.boleta_eventos[0].status == 2 || puesto.boleta_eventos[0].status == 6,
                      error:                        
                        puesto.boleta_eventos[0].status == 7 ||
                        puesto.boleta_eventos[0].status == 8 ||
                        puesto.boleta_eventos[0].status == 10 ||
                        ((puesto.boleta_eventos[0].status == 3 ||
                          puesto.boleta_eventos[0].status == 4) &&
                          (usuarioAuth.id_rol == 2 ||
                            usuarioAuth.id_rol == null)),
                      purple:
                        puesto.boleta_eventos[0].status == 3 &&
                        usuarioAuth.id_rol != 2 &&
                        usuarioAuth.id_rol != null,
                      gray:
                        puesto.boleta_eventos[0].status == 4 &&
                        usuarioAuth.id_rol != 2 &&
                        usuarioAuth.id_rol != null,
                      transparent: puesto.boleta_eventos[0].status == 9,
                    }"
                  >
                    <!-- <v-icon v-if="puesto.boleta_eventos[0].status != 9"
                      >person</v-icon
                    > -->
                    {{ puesto.numero }}                     
                  </v-card>
                </div>
                <v-card
                  v-if="puesto.boleta_eventos.length == 0"
                  class="iconPuesto success"
                >
                  <!-- <v-icon>person</v-icon> -->
                  {{ puesto.numero }}                  
                </v-card>
              </div>
              <div v-if="fila.puestos.length == 0">
                <v-card class="iconPuestoVacio red">
                  <br />
                </v-card>
                <br />
              </div>
            </div>    
            <br>
          </div>
          <!-- fin boletas -->

          <!-- palcos -->
          <div
            v-if="
              boletaslocalidad.silleteria == 1 && boletaslocalidad.palco == 1
            "
            style="overflow-x: scroll; width: 100%;"
          >
            <div
              :key="index"
              v-for="(fila, index) in boletaslocalidad.filas"
              :class="{
                'justify-start': fila.alineacion == 1,
                'justify-start': fila.alineacion == 2,
                'justify-start': fila.alineacion == 3,
              }"
              :style="{ display: 'flex', width: puestosmayor + 200 + 'px' }"
              style="min-height: 100%; min-width: 100%;"
            >
              <h3
                v-text="fila.nombre"
                class="text-center text-dark font-weight-bold"
                style="width: 5%; font-size: 10px;"
              ></h3>
              <v-snackbar
                  v-model="snackbar"                                    
                  color="primary" 
                  style="font-family: Arial, Helvetica, sans-serif;"   
                  :auto-height="true"  
                  :timeout="timeout"                                                                          
                >
                <br>                
                <h3 align="center">Mesa Individual # {{ numero_palco }}</h3>
                <hr>
                <h5 align="center"  style="font-weight: bold;">Puestos Disponibles: <h2 style="font-weight: bold;">{{ cantidad_mostrar }}</h2></h5>
                <br>                
              </v-snackbar>
              <br>
              <div
                v-for="(puesto, index) in fila.puestos"
                :key="index"
                v-if="
                  fila.orientacion == 1 &&
                  fila.puestos.length > 0 &&
                  (index % listBoletasLocalidadpuestosxpalco[puesto.palcos[0].id] == 0 || index == 0)
                "
              >
                <div
                  :key="index"
                  v-for="(palco, index) in puesto.palcos"
                  class="sizePuestos"  
                  style="font-size: 12px; font-weight: bold;"      
                  @mouseover="mostrarPuestosDisponibles(palco, puesto.numero)"        
                >
                  <div
                    v-if="
                      palco.palco_eventos.length != 0 &&
                      palco.palco_eventos[0].status != 9
                    "
                  >
                    <v-card
                      v-if="palco.palco_eventos.length != 0"
                      class="iconPuesto"
                      @click="getBoleta(palco.palco_eventos[0], puesto.numero)"
                      :class="{
                        success: palco.palco_eventos[0].status == 1,
                        warning: palco.palco_eventos[0].status == 2 || palco.palco_eventos[0].status == 6,
                        info: palco.palco_eventos[0].status == 12,
                        error:                          
                          palco.palco_eventos[0].status == 7 ||
                          palco.palco_eventos[0].status == 8 ||
                          palco.palco_eventos[0].status == 10 ||
                          ((palco.palco_eventos[0].status == 3 ||
                            palco.palco_eventos[0].status == 4) &&
                            (usuarioAuth.id_rol == 2 ||
                              usuarioAuth.id_rol == null)),
                        purple:
                          palco.palco_eventos[0].status == 3 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        gray:
                          palco.palco_eventos[0].status == 4 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        transparent: palco.palco_eventos[0].status == 9,
                      }"
                    >
                      <!-- <v-icon v-if="palco.palco_eventos[0].status != 9"
                        >people</v-icon
                      > -->
                      <!-- <br>{{index==0?1:((index/boletaslocalidad.puestosxpalco)+1)}}
								  	<br> -->
                      {{ puesto.numero }}                      
                    </v-card>
                  </div>
                  <v-card
                    v-if="palco.palco_eventos.length == 0"
                    class="iconPuesto success"
                  >
                    <!-- <v-icon>people</v-icon> -->
                    {{ puesto.numero }}
                    
                  </v-card>
                </div>
              </div>

              <div
                v-for="(puesto, index) in fila.puestos.slice().reverse()"
                :key="index"
                v-if="
                  fila.orientacion == 2 &&
                  fila.puestos.length > 0 &&
                  (index % listBoletasLocalidadpuestosxpalco[puesto.palcos[0].id] == 0 || index == 0)
                "
              >
                <div
                  :key="index"
                  v-for="(palco, index) in puesto.palcos"
                  class="sizePuestos"
                >
                  <div
                    v-if="
                      palco.palco_eventos.length != 0 &&
                      palco.palco_eventos[0].status != 9
                    "
                  >
                    <v-card
                      v-if="palco.palco_eventos.length != 0"
                      class="iconPuesto"
                      @click="getBoleta(palco.palco_eventos[0], puesto.numero)"
                      :class="{
                        success: palco.palco_eventos[0].status == 1,
                        warning: palco.palco_eventos[0].status == 2 || palco.palco_eventos[0].status == 6,
                        info: palco.palco_eventos[0].status == 12,
                        error:                          
                          palco.palco_eventos[0].status == 7 ||
                          palco.palco_eventos[0].status == 8 ||
                          palco.palco_eventos[0].status == 10 ||
                          ((palco.palco_eventos[0].status == 3 ||
                            palco.palco_eventos[0].status == 4) &&
                            (usuarioAuth.id_rol == 2 ||
                              usuarioAuth.id_rol == null)),
                        purple:
                          palco.palco_eventos[0].status == 3 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        gray:
                          palco.palco_eventos[0].status == 4 &&
                          usuarioAuth.id_rol != 2 &&
                          usuarioAuth.id_rol != null,
                        transparent: palco.palco_eventos[0].status == 9,
                      }"
                    >
                      <!-- <v-icon v-if="palco.palco_eventos[0].status != 9"
                        >people</v-icon
                      > -->
                      <!-- <br>{{(fila.puestos.length/boletaslocalidad.puestosxpalco)-((index/boletaslocalidad.puestosxpalco)+2)}}
								  	<br> -->
                      {{ puesto.numero }}
                    </v-card>
                  </div>
                  <v-card
                    v-if="palco.palco_eventos.length == 0"
                    class="iconPuesto success"
                  >
                    <!-- <v-icon>people</v-icon> -->
                    {{ puesto.numero }}                    
                  </v-card>
                </div>
              </div>
              <div v-if="fila.puestos.length == 0">
                <v-card class="iconPuestoVacio red">
                  <br />
                </v-card>
                <br />
              </div>
            </div>
            <br>
          </div>
          <!-- fin palcos -->
        </div>

        <div class="row row-clearfix" v-if="pagos">
          <div class="col-md-4 col-lg-4 col-sm-6"></div>
          <div class="col-md-4 col-lg-4 col-sm-6 no-padding text-center mt-4" v-if="boletaslocalidad.silleteria != 1">
            <v-layout row wrap>
              <v-flex xs3>
                <v-btn fab small color="error" @click="setCantBoleta('restar', _ , 0)">
                  <v-icon dark>remove</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs4>
                <h1 class="count-boletas-evento">
                  {{boletasLocalidadCount}}
                  <!-- {{
                    cantBoleta[boletaslocalidad.id] == undefined
                      ? 0
                      : cantBoleta[boletaslocalidad.id].length
                  }} -->
                </h1>
              </v-flex>
              <v-flex xs4>
                <v-btn
                  fab
                  small
                  @click="setCantBoleta('sumar', _ , 0)"
                  color="success"
                >
                  <v-icon dark>add</v-icon>
                </v-btn>
              </v-flex>
              <!--<v-flex xs3>
		              <v-btn fab small color="warning">
		                <v-icon dark  color="#907b16" @click="clearCantBoletaLocalidad">autorenew</v-icon>
		              </v-btn>
		            </v-flex>-->
            </v-layout>
          </div>
          <!-- <div class="col-md-7 col-lg-7 col-sm-6 no-padding text-center mt-4 pb-4">
		           <v-btn @click="pagar()" color="primary">Compra ahora tus {{ cantBoletaTotal }} boletos</v-btn> -->
          <!-- <br>
		              <a href="#">Antes de realizar una compra lee nuestros terminos y condiciones aqui</a> -->
          <!-- <v-chip @click="clearCantBoleta">
			            <v-icon left color="#907b16">autorenew</v-icon>
			            Renovar
			          </v-chip>	              
		        </div> -->
        </div>
      </center>
    </div>

    <v-dialog v-model="modalCosto" max-width="400px">
      <v-card>
        <v-card-title
          class="headline"
          v-if="
            currentRoute != 'MapeoEventoJefePuntoVenta' &&
            currentRoute != 'CortesiasEventoJefePuntoVenta'
          "
          >Costos ({{ monedaEvento }}) </v-card-title
        >
        <v-card-title
          class="headline"
          v-if="currentRoute == 'MapeoEventoJefePuntoVenta'"
          >Habilitar/Deshabilitar puesto</v-card-title
        >

        <v-card-text v-if="currentRoute == 'MapeoEventoJefePuntoVenta'">
          <v-textarea
            :readonly="accionPuesto.created_at != null"
            name="accionPuesto.nota"
            label="Nota"
            v-model="accionPuesto.nota"
            :value="accionPuesto.nota"
          ></v-textarea>
          <small v-if="accionPuesto.created_at != null"
            ><em>Modificado el: {{ accionPuesto.created_at }}</em></small
          >
          <br />
          <br />
          <v-btn @click="saveAccionPuesto" color="success">{{
            boleta.status == 1 ? "DESHABILITAR PUESTO" : "HABILITAR PUESTO"
          }}</v-btn>
        </v-card-text>

        <v-card-title
          class="headline"
          v-if="currentRoute == 'CortesiasEventoJefePuntoVenta'"
          ><v-icon>card_giftcard</v-icon> Añadir&nbsp;<span
            v-if="boletaslocalidad.palco != 1"
          >
            esta boleta</span
          ><span v-else> esta mesa </span></v-card-title
        >
        <v-divider
          v-if="currentRoute == 'CortesiasEventoJefePuntoVenta' && boleta.status != 12"
        ></v-divider>

        <v-card-text v-if="currentRoute == 'CortesiasEventoJefePuntoVenta' && boleta.status != 12">
          <p class="font-weight-bold">
            <v-icon>info</v-icon> &nbsp;<span
              v-if="boletaslocalidad.palco != 1"
            >
              BOLETA</span
            ><span v-else> MESA</span> - PUESTO {{ boleta.numero_puesto }} -
            LOCALIDAD {{ boletaslocalidad.nombre }}
          </p>
          <div class="text-center">
            <br />
            <v-btn @click="reservar()" color="success">AÑADIR AL CARRITO</v-btn>
            <br />
            <br />
          </div>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-text
          v-if="
            currentRoute != 'MapeoEventoJefePuntoVenta' && (currentRoute != 'CortesiasEventoJefePuntoVenta' || boleta.status == 12)
          "
        >
          <v-list three-line>
            <template v-if="boleta">
              <v-divider></v-divider>

              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <div v-if="boleta.status != 12">
                      Precio ({{ monedaEvento }})
                    </div>
                    <div v-else>
                      Precio Individual ({{ monedaEvento }})
                    </div>
                  </v-list-tile-title>
                  <v-list-tile-sub-title
                    ><h3 class="text-dark">
                      
                      <div v-if="boleta.status != 12">
                        {{ simboloMoneda + formatNumber(precio)  }}                         
                      </div>
                      <div v-else>
                        {{ simboloMoneda + formatNumber((precio / cant_max_puestos))  }}
                      </div>                                                                             

                    </h3>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>

              <!--<v-divider></v-divider>-->

              <!--<v-list-tile>

			              <v-list-tile-content>
			                <v-list-tile-title v-html="'status'"></v-list-tile-title>
			                <v-list-tile-sub-title v-html="(boleta.status==1)?'Disponible':(boleta.status==2)?'Reservada':'No Disponible'"></v-list-tile-sub-title>
			              </v-list-tile-content>
			            </v-list-tile>-->

              <v-divider
                v-if="pagos && evento.venta_linea == 1 && !fisico"
              ></v-divider>
              
              <div v-if="boleta.status == 12">                            
                  
                  <v-layout row wrap>
                    <v-alert
                      dense
                      type="info"   
                      style="width:100%; text-align: left; font-size: 20px;"      
                    >                      
                      Puestos Disponibles: {{ puestos_disponibles_palco }}
                      
                    </v-alert>
                    <v-flex xs4>
                      <v-btn fab small color="error" @click="setCantBoleta('restarIndividual', _ , boleta)">
                        <v-icon dark>remove</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs4>
                      <h1 class="count-boletas-evento">
                        {{cant_palco_individual}}
                        <!-- {{
                          cantBoleta[boletaslocalidad.id] == undefined
                            ? 0
                            : cantBoleta[boletaslocalidad.id].length
                        }} -->
                      </h1>
                    </v-flex>
                    <v-flex xs4>
                      <v-btn
                        fab
                        small
                        @click="setCantBoleta('sumarIndividual', _ , boleta); reservar();"
                        color="success"
                      >
                        <v-icon dark>add</v-icon>
                      </v-btn>
                    </v-flex>
                    <!--<v-flex xs3>
                        <v-btn fab small color="warning">
                          <v-icon dark  color="#907b16" @click="clearCantBoletaLocalidad">autorenew</v-icon>
                        </v-btn>
                      </v-flex>-->
                  </v-layout>

              </div>

              <!--<v-list-tile v-if="pagos && evento.venta_linea==1 && !fisico">

			              <v-list-tile-content>
			                <v-list-tile-title v-html="'Compra Este ' + (boletaslocalidad.palco != 1 ? 'Boleto' : 'Palco')"></v-list-tile-title>
			                <v-list-tile-sub-title>
			                	<v-btn color="success" @click="pagar()">Comprar</v-btn>
			                </v-list-tile-sub-title>
			              </v-list-tile-content>
			            </v-list-tile>-->

              <v-divider v-if="pagos && evento.venta_linea == 1"></v-divider>

              <v-list-tile v-if="pagos && evento.venta_linea == 1 && !fisico && boleta.status != 12">
                <v-list-tile-content>
                  <v-list-tile-title
                    v-html="  ' ' +
                      (boletaslocalidad.palco != 1 ? ' Agrega Este Boleto' : 'Agrega Esta Mesa') +
                      ' al Carrito'
                    "
                  ></v-list-tile-title>
                  <v-list-tile-sub-title>
                    <v-btn color="success" @click="reservar()"
                      >Añadir al carrito</v-btn
                    >
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile v-if="pagos && fisico && boleta.status != 12">
                <v-list-tile-content>
                  <v-list-tile-title
                    v-html="
                      ' ' +
                      (boletaslocalidad.palco != 1 ? 'Agrega Este  Boleto' : 'Agrega Esta Mesa') +
                      ' al Carrito'
                    "
                  ></v-list-tile-title>
                  <v-list-tile-sub-title>
                    <v-btn color="success" @click="reservar()"
                      >Añadir a la venta</v-btn
                    >
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
          <!--<form method="post" action="https://www.paymentsbakery.co/gateway2/index.php" id='btnPayU' v-html="elhtml"></form>-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" flat="flat" @click="modalCosto = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalfechacupos" max-width="700"   v-show="!$store.state.carrito.cuponok" >
      <template v-slot:default="modalfechacupos">
          <v-card>
            <v-card-title>Realizar Compra</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-alert  
                border="bottom"
                colored-border
                type="warning"
                elevation="2">Ventas en linea disponible despues de  {{ fechaventa }}</v-alert>
                

                <v-alert   v-show="!$store.state.carrito.cuponok" 
                border="bottom"
                colored-border
                type="success"
                elevation="2">Si Cuentas con un <b>CUPON</b>,  venta  en linea disponible despues de   {{ fechacupon }}   <v-divider></v-divider> 
                
                <div class="row"     v-show="validarcuponfecha"  >
                   <div class="col-lg-6 col-sm-6">
                    <v-text-field
                    v-model="cupon"
                      label="INGRESAR CUPON *"
                      style="text-align:center; font-weight:bold; font-size:18px;"
                      required
                ></v-text-field>


         

                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <v-btn
                        color="success"
                        dark
                        @click="getvalidarcupon()"
                        :loading="validandocupon"
                        >VALIDAR CUPON</v-btn>
                  </div>
                 </div>
                
                </v-alert>   
              

 
              <v-alert   v-show="$store.state.carrito.cuponok"
                border="bottom"
                colored-border
                type="success"
                elevation="2">
                  <b>CUPON</b>, Validado Correctamente, puedes seguir comprando y al finalizar la compra se te aplicara el descuento de : {{ $store.state.carrito.descuentocupon }} %
                
              </v-alert>
             
            </v-card-text>



            <v-card-actions class="justify-end">
              <v-btn
                 color="danger"  dark
                class="ma-2"
                @click="cerrarmodalfechacupon()"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>
  </v-app>
</template>
<script>
import moment from "moment";
import {configApi} from './../../api/configApi'
import {headers} from './../../api/headers'
import axios from 'axios'

moment.locale("es");

export default {
  name: "boletas",
  props: [
    "idlocalidad",
    "idEvento",
    "pagos",
    "fisico",
    "monedaEvento",
    "indexMonedaSelectShopEvento",
    "simboloMoneda",
    "monedasEvento",
  ],
  data() {
    return {
      valida_venta_estado: null,
      cant_max_puestos: '',
      tipo_individual: 0,
      snackbar: false,
      timeout: 2000,
      cantidad_mostrar: null,
      numero_palco: 0,
      cant_palco_individual: 0,
      puestos_disponibles_palco: null,
      modalCosto: false,
      modalfechacupos: false,
      fechaventa:'',
      fechacupon:'',
      validarcupon: false, 
      validarcuponfecha: false,
      cupon:'', 
      validandocupon: false,
      ideventocup: 0,
      boleta: "",
      elhtml: "",
      precio: 0,
      disponibles: 0,
      reservados: 0,
      pagos_parcial: 0,
      ocupados: 0,
      inhabilitados: 0,
      accionPuesto: {
        nota: null,
        created_at: null,
      },
      cantidad_individuales: [1,2,3,4,5,6,7,8,9,10]
    };
  },
  async mounted() {
    await this.$store.dispatch("localidad/listBoletasLocalidad", {
      id_localidad: this.idlocalidad,
      id_evento: this.idEvento,
      codigo_moneda: this.monedaEvento,
    });
  },
  updated() {
    /*if (this.elhtml!="")
			{
				$('#btnPayU').submit();
			}*/
  },
  watch: {
    async idlocalidad(after, before) {
      window.location.hash = '#boletasxlocalidadcomponent'
      await this.$store.dispatch("localidad/listBoletasLocalidad", {
        id_localidad: after,
        id_evento: this.idEvento,
        codigo_moneda: this.monedaEvento,
      });
    },
    async idEvento(after, before) {
      await this.$store.dispatch("localidad/listBoletasLocalidad", {
        id_localidad: this.idlocalidad,
        id_evento: after,
        codigo_moneda: this.monedaEvento,
      });
    },
    preciosBoletasCarrito() {
      return this.$store.getters["carrito/preciosBoletasCarrito"];
    },
    totalValorBoletasCarrito() {
      var preciosBoletasCarritoMonedaActual = this.$store.getters[
        "carrito/preciosBoletasCarrito"
      ].filter((item) => item.codigo_moneda == this.monedaSelectShopEvento);
      if (
        preciosBoletasCarritoMonedaActual &&
        preciosBoletasCarritoMonedaActual.length > 0
      ) {
        var total = 0;
        for (
          var i = preciosBoletasCarritoMonedaActual.length - 1;
          i >= 0;
          i--
        ) {
          total += preciosBoletasCarritoMonedaActual[i].precio;
        }
        return this.formatNumber(total);
      } else {
        return 0;
      }
    },
    localidadSelectCarrito() {
      return this.$store.getters["carrito/localidadSelectCarrito"];
    },
  },
  methods: {
    mostrarPuestosDisponibles(palco, numero){
      this.snackbar = false
      this.numero_palco = numero
      this.cantidad_mostrar = 'Cargando...'          
      // this.cantidad_mostrar = 0
      if(palco.palco_eventos[0].status === 12) {      
        axios.get(configApi.apiUrl+'puestospalcoevento/'+palco.palco_eventos[0].id, headers)
        .then((response) => {

          if(response.data.data.length == 0) {
            this.cantidad_mostrar = 'No hay puestos disponibles';
          } else {
            this.cantidad_mostrar = response.data.data.length
          }
          
        }).catch((e) => {
          this.cantidad_mostrar = 'No hay puestos disponibles';
        })                        
        this.snackbar=true
      }
            
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format("dddd Do [de] MMMM [a las] hh:mm a");
      } else {
        return "";
      }
    },
    async setCantBoleta(operacion, localidad, boleta) {

      if (this.$store.getters["user/dataUserLogged"].nombre == null) {
        this.$store.state.modalLogin = true;
        return false;
        //swal("Error!", "Debe Iniciar Sesión!", "error");
      }

      this.valida_venta_estado = 0

      await axios.get(configApi.apiUrl+'valida_ventas_activas', headers)
      .then((response) => {
        this.valida_venta_estado = response.data[0].cantidad
      })

      if(this.valida_venta_estado > 0) {
        swal("¡Tiene una transacción pendiente!", "Tiene una transacción pendiente de pago, intente nuevamente después de 15 minutos", "warning");
        return;
      }

      if(this.puestos_disponibles_palco == 0 && operacion == 'sumarIndividual') {
        swal("¡No hay puestos disponibles!", "Todos los puestos han sido ocupados.", "warning");
        return;
      }

      if (
        this.currentRoute != "evento" ||
        this.evento.fechaValidaInicioInternet ||  this.$store.state.carrito.cuponestado 
      ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
          return false;
          //swal("Error!", "Debe Iniciar Sesión!", "error");
        }
        if (
          operacion == "restar" ||
          this.evento.cant_max_boletas > this.cantBoletaTotal ||
          this.evento.cant_max_boletas == 0 ||
          this.evento.cant_max_boletas == null
        ) {
          if (
            operacion == "restar" &&
            this.countBoletas <= 0 &&
            this.countPalcos <= 0
          ) {
            return;
          }
          if (
            operacion == "sumar" &&
            this.listBoletasLocalidadDisponibilidad.disponibles <= 0             
          ) {
            swal(
              "¡Localidad Agotada!",
              "No hay boletas disponibles para esta localidad.",
              "warning"
            );
            return;
            
          }

          if(operacion == "sumar" && this.countBoletas > this.evento.cant_max_boletas) {
            swal(
              "Cantidad Máxima",
              "Ha excedido la cantidad máxima de boletas por transacción.",
              "warning"
            ); 
            return;
          }

          if (boleta.id_palco > 0) {

              var setCantBoletaApi = await this.$store.dispatch(
              "evento/setCantBoleta",
              {
                operacion: operacion,
                palco: this.boletaslocalidad.palco,
                id_evento: this.idEvento,
                id_localidad: this.boletaslocalidad.id,
                precio: this.precio,
                id_palco: boleta.id_palco
              }
            );            

          } else {

              var setCantBoletaApi = await this.$store.dispatch(
              "evento/setCantBoleta",
              {
                operacion: operacion,
                palco: this.boletaslocalidad.palco,
                id_evento: this.idEvento,
                id_localidad: this.boletaslocalidad.id,
                precio: this.precio                
              }
            );

          }

          if(setCantBoletaApi) {

            this.getPalcosIndividualesUsuario(boleta.id_palco)
            this.getPuestosPalcos(boleta.id)

          }

          //this.$store.dispatch('carrito/preciosBoletasCarrito',this.preciosBoletasLocalidadDescuento);
          if (operacion == "restar") {
            this.$store.dispatch("carrito/restartBoletaCarrito", {
              localidad_id: this.boletaslocalidad.id,
              monedasEvento: this.monedasEvento,
            });
          } else if (operacion == "sumar") {
            if (setCantBoletaApi.message != "error") {
              this.preciosBoletasLocalidadDescuento(
                this.boletaslocalidad.palco
              );
            } else {
              if (this.boletaslocalidad.palco) {
                var mensaje = "No hay más mesas disponibles!";
              } else {
                var mensaje = "No hay más boletas disponibles!";
              }

              if(setCantBoletaApi.data.tope){
                var mensajeError = 'Usuario excedió el límite máximo de boletas que puede comprar para este evento. Código :002A';
              }

              swal("Error!", mensaje, "warning");
            }
            if (
              this.dateTimeSaleStart == null &&
              this.dateTimeSaleEnd == null
            ) {
              var fechaInicioVenta = new Date();
              this.$store.dispatch(
                "carrito/dateTimeSaleStart",
                fechaInicioVenta
              );
              let fecha = new Date();
              var fechaFinVenta = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), fecha.getHours(), fecha.getMinutes(), (fecha.getSeconds() + 600));
              this.$store.dispatch("carrito/dateTimeSaleEnd", fechaFinVenta);
            }
          }

          //actualiza la grafica de las boletas
          await this.$store.dispatch("localidad/listBoletasLocalidad", {
            id_localidad: this.idlocalidad,
            id_evento: this.idEvento,
            codigo_moneda: this.monedaEvento,
          });
        } else {
          swal("¡Alcanzó el tope por día!", "Supera el tope de boletas por día", "warning");
        }
      } else {
        this.modalfechacupos = true;
        this.fechaventa= this.formatDatetimeString( this.evento.fecha_inicio_venta_internet_formateada );
        this.fechacupon= this.formatDatetimeString( this.evento.fecha_inicio_venta_cupon_formateada );
        this.ideventocup=this.evento.id_evento ;
        this.validarcupon= false ;
        this.validarcuponfecha= false ;
        if(this.evento.cupon=='true'){  this.validarcupon=true  ;}
        if(this.evento.fechaValidaInicioCuponVenta==1){  this.validarcuponfecha=true  ;}
        // swal(
        //   "Error!",
        //   "Ventas en linea disponible despues de " +
        //     this.formatDatetimeString(
        //       this.evento.fecha_inicio_venta_internet_formateada
        //     ),
        //   "error"
        // );
      }
    },
    async clearCantBoletaLocalidad() {
      this.$store.dispatch("evento/clearCantBoletaLocalidad", {
        id: this.boletaslocalidad.id,
        precio: this.precio,
      });
    },
    async clearCantBoleta() {
      if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
        var lasBoletas = [];
        var losPalcos = [];

        for (
          var i = 0;
          i < this.listLocalidadesEventoAuditorio_mapeado.length;
          i++
        ) {
          if (
            this.cantBoleta[
              this.listLocalidadesEventoAuditorio_mapeado[i].id
            ] != null
          ) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            } else {
              lasBoletas = lasBoletas.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
              .length;
          }
        }

        await this.$store.dispatch("carrito/setNullReservas", {
          boletas: lasBoletas,
          palcos: losPalcos,
        });

        this.$store.dispatch("evento/clearCantBoleta");
      } else {
        swal("Error!", "El carrito esta vacio!", "error");
      }
    },
    async getPuestosPalcos(id) {
      await axios.get(configApi.apiUrl+'puestospalcoevento/'+id, headers)
      .then((response) => {

        if(response.data.data.length == 0) {
          this.puestos_disponibles_palco = '0';
        } else {
          this.puestos_disponibles_palco = response.data.data.length
        }        
      }).catch((e) => {
        this.puestos_disponibles_palco = '0';
      })

    },
    async getPalcosIndividualesUsuario(id) {
      await axios.get(configApi.apiUrl+'puestosindividualesusuario/'+id, headers)
      .then((response) => {
        this.cant_palco_individual = response.data.cantidad
      })
    },
    async getCantMaxPuestosPalco(id) {
      await axios.get(configApi.apiUrl+'cantmaxpuestospalco/'+id, headers)
      .then((response) => {
        this.cant_max_puestos = response.data[0].cantidad
      })
    },
    async getBoleta(boleta, numero) {

      this.cant_palco_individual = 0
      this.cant_max_puestos = ''
      this.getPuestosPalcos(boleta.id)
      this.getPalcosIndividualesUsuario(boleta.id_palco)
      this.getCantMaxPuestosPalco(boleta.id_palco)
      

      if(boleta.status == 12) {this.tipo_individual = 12} else {this.tipo_individual = 0}

      if (this.currentRoute == "CortesiasEventoJefePuntoVenta") {
        if (boleta.status > 1 && boleta.status != 12) {
          return;
        }

        this.boleta = boleta;
        this.boleta.numero_puesto = numero;
        this.modalCosto = true;
      } else if (this.currentRoute == "MapeoEventoJefePuntoVenta") {
        if (boleta.status != 1 && boleta.status != 4) {
          return;
        }

        var tipoBoleta = "";
        if (this.boletaslocalidad.palco != 1) {
          tipoBoleta = "boleta";
        } else {
          tipoBoleta = "palco";
        }

        this.accionPuesto.nota = null;
        this.accionPuesto.created_at = null;

        if (boleta.status == 4) {
          this.accionPuesto = await this.$store.dispatch(
            "puesto/getAccionPuesto",
            {
              tipo_boleta: tipoBoleta,
              id_boleta: boleta.id,
            }
          );
        }

        this.boleta = boleta;
        this.boleta.numero_puesto = numero;
        this.modalCosto = true;
      } else {
        if (boleta.status > 1  && boleta.status != 12)  {
          return;
        }
        // await this.$store.dispatch("puesto/getPrecio", {
        //   id_evento: boleta.id_evento,
        //   precio_venta: boleta.precios_monedas[0].precio_venta,
        //   precio_servicio: boleta.precios_monedas[0].precio_servicio,
        //   impuesto: boleta.impuesto,
        //   id_tribuna: this.boletaslocalidad.id_tribuna,
        //   id_localidad: this.boletaslocalidad.id,
        //   codigo_moneda: this.monedaEvento,
        // });
        this.precio =  this.getPrecioBoleta();// await this.$store.getters["puesto/viewPrecio"];
        this.boleta = boleta;
        this.boleta.numero_puesto = numero;
        this.modalCosto = true;
      }
    },
    async pagar() {
      if (
        this.currentRoute != "evento" ||
        this.evento.fechaValidaInicioInternet ||  this.$store.state.carrito.cuponestado 
      ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
          //swal("Error!", "Debe Iniciar Sesión!", "error");
        } else {
          await this.$store.dispatch("obtener_refventa", {
            palcos: [this.boleta.id],
            moneda: this.monedaEvento,
            email: this.$store.getters["user/dataUserLogged"].email,
            nombre: this.$store.getters["user/dataUserLogged"].nombre,
            identificacion:
              this.$store.getters["user/dataUserLogged"].identificacion,
            tipo_identificacion:
              this.$store.getters["user/dataUserLogged"].tipo_identificacion,
          });

          if (this.$store.getters["get_init_point"] != null) {
            await this.clearCantBoleta();
            window.location.href = this.$store.getters["get_init_point"];
          }
          //var referencia = this.$store.getters['obtener_refventa'].refVenta;
          //var total = this.$store.getters['obtener_refventa'].precio_total;

          //await this.$store.dispatch('generarsha',{
          //	moneda: this.monedaEvento,
          //	refventa: referencia.refVenta
          //});

          /*var usuario = this.$store.getters['user/dataUserLogged'];
			        	var info_pago = this.$store.getters['sha'];
			        	// var total = (parseInt(this.boleta.precio_venta)+parseInt(this.boleta.precio_servicio))+(((parseInt(this.boleta.precio_venta)+parseInt(this.boleta.precio_servicio))*parseInt(this.boleta.impuesto))/100);

			        	this.elhtml = '<input type="image" src="https://paymentsbakery.co/gateway/images/payments-bakery.png" border="0" alt="payments-bakery">\
							<input name="usuarioId" type="hidden" value="'+info_pago.usuarioId+'">\
							<input name="firma" type="hidden" value="'+info_pago.firma_codificada+'">\
							<input name="descripcion" type="hidden" value="'+'descripcion'+'">\
							<!-- Descripcon de la Venta Productos-->\
							<input name="refVenta" type="hidden" value="'+info_pago.refVenta+'">\
							<!--  Referencia de Venta Numero de 6 digitos -->\
							<input name="valor" type="hidden" value="'+total+'">\
							<!--  Valor de la Compra -->\
							<input name="moneda" type="hidden" value="'+this.monedaEvento+'">\
							<!--  Moneda de la Compra:  PERU:  604 soles y 840 dolares. COLOMBIA COP -->\
							<input name="lang" type="hidden" value="SP">\
							<!--  lenguage   SP - Español   EN - Ingles-->\
							<input name="extra1" type="hidden" value="'+'datosCompra'+'">\
							<input name="extra2" type="hidden" value="'+'datosCompra'+'">\
							<input name="extra3" type="hidden" value="'+'datosCompra'+'">\
							<input name="url_respuesta" type="hidden" value="'+info_pago.URLRespuesta+'">\
							<input name="url_confirmacion" type="hidden" value="'+info_pago.URLConfirma+'">\
							<input name="emailComprador" type="hidden" value="'+usuario.email+'"/>\
							<input name="Comprador" type="hidden" value="'+usuario.nombre+'"  />\
							<!--  Nombre Comprador -->\
							<input name="documentoIdentificacion" type="hidden" value="'+'10628701'+'"  />\
							<!--  Documento de Identidad Comprador -->\
							<input name="Test" type="hidden" value="1"/>';*/
        }
      } else {
        this.modalfechacupos = true; 
        this.fechaventa= this.formatDatetimeString( this.evento.fecha_inicio_venta_internet_formateada );
        this.fechacupon= this.formatDatetimeString( this.evento.fecha_inicio_venta_cupon_formateada );
        this.ideventocup=this.evento.id_evento ;
        this.validarcupon= false ;
        this.validarcuponfecha= false ;
        if(this.evento.cupon=='true'){  this.validarcupon=true  ;}
        if(this.evento.fechaValidaInicioCuponVenta==1){  this.validarcuponfecha=true  ;}
        // swal(
        //   "Error!",
        //   "Ventas en linea disponible despues de " +
        //     this.formatDatetimeString(
        //       this.evento.fecha_inicio_venta_internet_formateada
        //     ),
        //   "error"
        // );
      }
    },
    async cerrarmodalfechacupon() {
      this.modalfechacupos = false;
    },
    async getvalidarcupon(){
      if (this.$store.getters["user/dataUserLogged"].nombre == null) {
        this.$store.state.modalLogin = true;
        //swal("Error!", "Debe Iniciar Sesión!", "error");
      } else {
        this.validandocupon=true;  
        if(this.cupon.length==0){
          swal("Validar Cupón", "Debe Indicar un Cupon!", "warning");
            this.validandocupon=false;
            return
        }
        await this.$store.dispatch('evento/getvalidarcuponventa', {
              id_evento:  this.idEvento,  cupon: this.cupon,
          }) 
        
          if(this.$store.state.carrito.cuponestado==1){
            // this.validarcupon=false 
            // this.cuponok=true
          }else{
          this.$store.state.carrito.cupon=null;
              swal("Cupón no válido", "Cupón no válido o ya fue usado.", "warning");

          }
          this.validandocupon=false;
      }
    }, 
    async reservar() {   

      this.valida_venta_estado = 0

      if (this.$store.getters["user/dataUserLogged"].nombre == null) {
        this.$store.state.modalLogin = true;
        return false;
        //swal("Error!", "Debe Iniciar Sesión!", "error");
      }
      await axios.get(configApi.apiUrl+'valida_ventas_activas', headers)
      .then((response) => {
        this.valida_venta_estado = response.data[0].cantidad
      })

      if(this.valida_venta_estado > 0) {
        swal("¡Tiene una transacción pendiente!", "Tiene una transacción pendiente de pago, intente nuevamente después de 15 minutos", "warning");
        return;
      }

      if (
        this.currentRoute != "evento" ||
        this.evento.fechaValidaInicioInternet ||  this.$store.state.carrito.cuponestado 
      ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
          //swal("Error!", "Debe Iniciar Sesión!", "error");
        } else {
          if (
            this.countPalcos < 2
          ) {
            // await this.$store.dispatch('carrito/listBoletas',{
            // 	id: this.boleta.id,
            // 	id_evento: this.boleta.id_evento,
            // 	id_puesto: this.boleta.id_puesto,
            //     precio_venta: this.boleta.precio_venta,
            //     precio_servicio: this.boleta.precio_servicio,
            //     impuesto: this.boleta.impuesto,
            //     status: 2,
            //     codigo_moneda: this.boleta.codigo_moneda,
            // });
            await this.$store
              .dispatch("evento/setCantBoleta", {
                operacion: "reservar",
                palco: this.boletaslocalidad.palco,
                id_evento: this.idEvento,
                id_localidad: this.boletaslocalidad.id,
                id_boletaOpalco: this.boleta.id,
                palco_status: this.boleta.status
              })
              .then((response) => {
                if (response.message != "error") {
                  this.preciosBoletasLocalidadDescuento(
                    this.boletaslocalidad.palco
                  );
                  if (
                    this.dateTimeSaleStart == null &&
                    this.dateTimeSaleEnd == null
                  ) {
                    var fechaInicioVenta = moment().format("Y-MM-D HH:mm:s");
                    this.$store.dispatch(
                      "carrito/dateTimeSaleStart",
                      fechaInicioVenta
                    );
                    var fechaFinVenta = moment()
                      .add(10, "minutes")
                      .format("Y-MM-D HH:mm:s");
                    this.$store.dispatch(
                      "carrito/dateTimeSaleEnd",
                      fechaFinVenta
                    );
                  }
                } else {
                  if (this.boletaslocalidad.palco == 1) {
                    //console.log(this.boletaslocalidad)
                    var mensajeError =
                      "Error, transaccion en progreso por otro usuario. Escoge otro palco.";
                  } else {
                    var mensajeError =
                      "Error, transaccion en progreso por otro usuario. Escoge otra boleta.";
                  }

                  if(response.data.tope){
                    var mensajeError = 'Usuario excedió el límite máximo de boletas que puede comprar para este evento. Código :002A';
                  }

                  swal("Error!", mensajeError, "error");
                }
              });

            //this.$store.dispatch('carrito/preciosBoletasCarrito',this.preciosBoletasLocalidadDescuento);

            //actualiza la grafica de las boletas
            this.$store.dispatch("localidad/listBoletasLocalidad", {
              id_localidad: this.idlocalidad,
              id_evento: this.idEvento,
              codigo_moneda: this.monedaEvento,
            });
            if(this.boleta.status != 12) {
              this.modalCosto = false;
            }
          } else {
            swal(
              "Mensaje",
              "Ha excedido la cantidad maxima de mesas  por transacción.",
              "warning"
            );
          }
        }
      } else {
        this.modalfechacupos = true;
        this.fechaventa= this.formatDatetimeString( this.evento.fecha_inicio_venta_internet_formateada );
        this.fechacupon= this.formatDatetimeString( this.evento.fecha_inicio_venta_cupon_formateada );
        this.ideventocup=this.evento.id_evento ;
        this.validarcupon= false ;
        this.validarcuponfecha= false ;
        if(this.evento.cupon=='true'){  this.validarcupon=true  ;}
        if(this.evento.fechaValidaInicioCuponVenta==1){  this.validarcuponfecha=true  ;}
        // console.log( this.validarcupon);
        /* swal(
          "Error!",
          "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
          "error"
        );*/
      }
    },
    getPrecioBoleta(){
      var localidad = this.$store.getters["carrito/localidadSelectCarrito"][0];
      if (
        this.$store.getters["evento/listPreventasEvento"] &&
        this.$store.getters["evento/listPreventasEvento"].length > 0
      ) {
        var preventas = this.$store.getters["evento/listPreventasEvento"];
      }
      if (this.boletaslocalidad.palco == 1) {
        var cantidadPreciosLocalidad =
          this.boletaslocalidad.filas[0].puestos[0].palcos[0].palco_eventos[0]
            .precios_monedas_carrito;
          //  console.log('XX');
          //  console.log(  this.boletaslocalidad.filas[0].puestos[0].palcos[0].palco_eventos[0]);
      } else {
        var cantidadPreciosLocalidad =
          this.boletaslocalidad.filas[0].puestos[0].boleta_eventos[0]
            .precios_monedas_carrito;
           // console.log('YY');
          //  console.log( this.boletaslocalidad.filas[0].puestos[0].boleta_eventos[0]);
      }
     
      var indiceMoneda = 1;
      for (var i = cantidadPreciosLocalidad.length - 1; i >= 0; i--) {
        if (indiceMoneda == 1) {
          indiceMoneda = "";
        }
        var total_precio =  parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda))
        var total_servicio = parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda));

        if(this.tipo_individual == 12) {
          this.$store.state.carrito.vservicio =(this.$store.state.carrito.vservicio +total_servicio)/10;
        } else {
          this.$store.state.carrito.vservicio =this.$store.state.carrito.vservicio +total_servicio;
        }
        
        const array = this.$store.getters["carrito/arrayPreventasSelect"];
        const newArraypreventasmanuales = array.filter(item => item !== false);
       


        if (preventas) {
          for (var j = preventas.length - 1; j >= 0; j--) {
            var preventa = preventas[j];
            if(((preventa.automatica == 0 && this.$store.getters["carrito/arrayPreventasSelect"].includes(preventa.id)) || (preventa.automatica == 1))){
              if (
                (preventa.tipo == 1 || (preventa.tipo == 2 && preventa.ids_tribunas.includes(localidad.id_tribuna.toString())) || (preventa.tipo == 3 && preventa.ids_localidades.includes(localidad.id.toString())) && (this.$route.name != 'evento' || preventa.venta_linea == 1) && ((preventa.automatica == 0 && this.$store.getters["carrito/arrayPreventasSelect"].includes(preventa.id)) || (preventa.automatica == 1)))
              ) {


              if(preventa.automatica == 0 ){
                if (parseFloat(preventa.descuento_servicio) > 0) {
                  if(preventa.tipo_descuento_servicio == 1){
                    total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda)) * parseFloat(preventa.descuento_servicio));
                  }else{
                    total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                  }
                }
                if (parseFloat(preventa.descuento_precio) > 0) {
                  if(preventa.tipo_descuento_precio == 1){
                    total_precio = parseFloat(total_precio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda)) * parseFloat(preventa.descuento_precio));
                  }else{
                    total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                  }
                }
              }
              if(preventa.automatica == 1 && newArraypreventasmanuales.length==0){
                if (parseFloat(preventa.descuento_servicio) > 0) {
                  if(preventa.tipo_descuento_servicio == 1){
                    total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda)) * parseFloat(preventa.descuento_servicio));
                  }else{
                    total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                  }
                }
                if (parseFloat(preventa.descuento_precio) > 0) {
                  if(preventa.tipo_descuento_precio == 1){
                    total_precio = parseFloat(total_precio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda)) * parseFloat(preventa.descuento_precio));
                  }else{
                    total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                  }
                }
              }


              }
            }
          }
        }
        if (indiceMoneda == "") {
          indiceMoneda = 1;
        }

        indiceMoneda++;
      }
      return (total_servicio + total_precio) <= 0 ? 0 : total_servicio + total_precio;
    },
    preciosBoletasLocalidadDescuento(tipoBoleta) {
      var localidad = this.$store.getters["carrito/localidadSelectCarrito"][0];
      if (
        this.$store.getters["evento/listPreventasEvento"] &&
        this.$store.getters["evento/listPreventasEvento"].length > 0
      ) {
        var preventas = this.$store.getters["evento/listPreventasEvento"];
      }

      var preciosBoletas = [];
      if (this.boletaslocalidad.palco == 1) {
        var cantidadPreciosLocalidad =
          this.boletaslocalidad.filas[0].puestos[0].palcos[0].palco_eventos[0]
            .precios_monedas_carrito;
      } else {
        var cantidadPreciosLocalidad =
          this.boletaslocalidad.filas[0].puestos[0].boleta_eventos[0]
            .precios_monedas_carrito;
      }

       const array = this.$store.getters["carrito/arrayPreventasSelect"];
        const newArraypreventasmanuales = array.filter(item => item !== false);
       

      var indiceMoneda = 1;
      for (var i = cantidadPreciosLocalidad.length - 1; i >= 0; i--) {
        if (indiceMoneda == 1) {
          indiceMoneda = "";
        }
        var total_precio =  parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda))
        var total_servicio = parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda));
        if (preventas) {
          for (var j = preventas.length - 1; j >= 0; j--) {
            var preventa = preventas[j];
            if(((preventa.automatica == 0 && this.$store.getters["carrito/arrayPreventasSelect"].includes(preventa.id)) || (preventa.automatica == 1))){
              if (
                (preventa.tipo == 1 || (preventa.tipo == 2 && preventa.ids_tribunas.includes(localidad.id_tribuna.toString())) || (preventa.tipo == 3 && preventa.ids_localidades.includes(localidad.id.toString())) && (this.$route.name != 'evento' || preventa.venta_linea == 1))
              ) {
                if (this.$store.getters["carrito/arrayPreventasSelect"].some((valor) => valor !== false)) {
                   if(preventa.automatica==0){
                    if (parseFloat(preventa.descuento_servicio) > 0) {
                  if(preventa.tipo_descuento_servicio == 1){
                    total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda)) * parseFloat(preventa.descuento_servicio));
                  }else{
                    total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                  }
                }
                if (parseFloat(preventa.descuento_precio) > 0) {
                  if(preventa.tipo_descuento_precio == 1){
                    total_precio = parseFloat(total_precio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda)) * parseFloat(preventa.descuento_precio));
                  }else{
                    total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                  }
                }

                   }
                }else{
                  if(preventa.automatica==1  && newArraypreventasmanuales.length==0){
                    if (parseFloat(preventa.descuento_servicio) > 0) {
                  if(preventa.tipo_descuento_servicio == 1){
                    total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_servicio" + indiceMoneda)) * parseFloat(preventa.descuento_servicio));
                  }else{
                    total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                  }
                }
                if (parseFloat(preventa.descuento_precio) > 0) {
                  if(preventa.tipo_descuento_precio == 1){
                    total_precio = parseFloat(total_precio) - parseFloat(parseFloat(eval("localidad.localidad_evento[0].precio_venta" + indiceMoneda)) * parseFloat(preventa.descuento_precio));
                  }else{
                    total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                  }
                }

                  }
                }

               

              }
            }
          }
        }
        var total = total_servicio + total_precio;

        if(this.boleta.status === 12) {
          var total = (total_servicio + total_precio)/10;
          tipoBoleta = 3
        } 

        this.$store.dispatch("carrito/preciosBoletasCarrito", {
          precio:
            (total + (total * localidad.localidad_evento[0].impuesto) / 100) <= 0 ? 0 : (total + (total * localidad.localidad_evento[0].impuesto) / 100),
          codigo_moneda: eval(
            "localidad.localidad_evento[0].codigo_moneda" + indiceMoneda
          ),
          localidad_id: localidad.id,
          tipoBoleta: tipoBoleta,
        });
        //preciosBoletas.push({'precio':(total+((total*localidad.localidad_evento[0].impuesto)/100)),'codigo_moneda':eval('localidad.localidad_evento[0].codigo_moneda'+indiceMoneda)});

        if (indiceMoneda == "") {
          indiceMoneda = 1;
        }

        indiceMoneda++;
      }
    },
    async saveAccionPuesto() {
      if (this.boleta.status == 1) {
        var status = 4;
      } else {
        var status = 1;
      }

      var tipoBoleta = "";
      if (this.boletaslocalidad.palco != 1) {
        tipoBoleta = "boleta";
      } else {
        tipoBoleta = "palco";
      }

      await this.$store
        .dispatch("puesto/saveAccionPuesto", {
          tipo_boleta: tipoBoleta,
          id_boleta: this.boleta.id,
          status: status,
          nota: this.accionPuesto.nota,
        })
        .then((response) => {
          if (response.message == "error") {
            if (this.boletaslocalidad.palco == 1) {
              var mensajeError =
                "Error, transaccion en progreso por otro usuario. Escoge otro palco.";
            } else {
              var mensajeError =
                "Error, transaccion en progreso por otro usuario. Escoge otra boleta.";
            }
            swal("Error!", mensajeError, "error");
          }
        });

      this.$store.dispatch("localidad/listBoletasLocalidad", {
        id_localidad: this.idlocalidad,
        id_evento: this.idEvento,
        codigo_moneda: this.monedaEvento,
      });

      this.modalCosto = false;
    },
  },
  computed: {
    boletasLocalidadCount(){
      let count = 0;
      this.$store.state.carrito.preciosBoletasCarrito.forEach((e)=> {
        if(e.localidad_id == this.boletaslocalidad.id){
          count++
        }
      })
      return count
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    cantBoleta() {
      return this.$store.getters["evento/cantBoleta"];
    },
    cantBoletaTotal() {
      return this.$store.getters["evento/cantBoletaTotal"];
    },
    boletaslocalidad() {
      return this.$store.getters["localidad/listBoletasLocalidad"];
    },
    listBoletasLocalidadDisponibilidad(){
      return this.$store.getters["localidad/listBoletasLocalidadDisponibilidad"];
    },
    listBoletasLocalidadpuestosxpalco() {
      return this.$store.getters["localidad/listBoletasLocalidadpuestosxpalco"];
    },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    usuarioAuth() {
      return this.$store.getters["user/dataUserLogged"];
    },
    currentRoute() {
      return this.$router.currentRoute.name;
    },
    /*puestosdisponibles() {
      this.disponibles = 0;
      this.reservados = 0;
      this.pagos_parcial = 0;
      this.ocupados = 0;
      this.inhabilitados = 0;
      var total = 0;
      if (this.boletaslocalidad.length != 0) {
        for (var i = 0; i < this.boletaslocalidad.filas.length; i++) {
          for (
            var j = 0;
            j < this.boletaslocalidad.filas[i].puestos.length;
            j++
          ) {
            //boletas
            if (
              this.boletaslocalidad.filas[i].puestos[j].boleta_eventos.length !=
              0
            ) {
              switch (
                this.boletaslocalidad.filas[i].puestos[j].boleta_eventos[0]
                  .status
              ) {
                case 1:
                  this.disponibles = parseInt(this.disponibles) + 1;
                  break;
                case 2:
                  this.reservados = parseInt(this.reservados) + 1;
                  break;
                case 3:
                  this.pagos_parcial = parseInt(this.pagos_parcial) + 1;
                  break;
                case 4:
                  this.inhabilitados = parseInt(this.inhabilitados) + 1;
                  break;
                default:
                  this.ocupados = parseInt(this.ocupados) + 1;
                  break;
              }
            }
            //palcos
            if (
              this.boletaslocalidad.filas[i].puestos[j].palcos.length != 0 &&
              (j % this.boletaslocalidad.puestosxpalco == 0 || j == 0)
            ) {
              for (
                var k =
                  this.boletaslocalidad.filas[i].puestos[j].palcos.length - 1;
                k >= 0;
                k--
              ) {
                if (
                  this.boletaslocalidad.filas[i].puestos[j].palcos[k]
                    .palco_eventos.length != 0
                ) {
                  switch (
                    this.boletaslocalidad.filas[i].puestos[j].palcos[k]
                      .palco_eventos[0].status
                  ) {
                    case 1:
                      this.disponibles = parseInt(this.disponibles) + 1;
                      break;
                    case 2:
                      this.reservados = parseInt(this.reservados) + 1;
                      break;
                    case 3:
                      this.pagos_parcial = parseInt(this.pagos_parcial) + 1;
                      break;
                    case 4:
                      this.inhabilitados = parseInt(this.inhabilitados) + 1;
                      break;
                    default:
                      this.ocupados = parseInt(this.ocupados) + 1;
                      break;
                  }
                }
              }
            }
            total = parseInt(total) + 1;
          }
        }
      }
      if (
        parseInt(this.disponibles) +
          parseInt(this.reservados) +
          parseInt(this.ocupados) +
          parseInt(this.pagos_parcial) +
          parseInt(this.inhabilitados) ==
        0
      ) {
        if (this.boletaslocalidad.palco == 1) {
          this.disponibles = total / this.boletaslocalidad.puestosxpalco;
        } else {
          this.disponibles = total;
        }
      }
      return {
        disponibles: this.disponibles,
        reservados: this.reservados,
        pagos_parcial: this.pagos_parcial,
        ocupados: this.ocupados,
        inhabilitados: this.inhabilitados,
      };
    },*/
    puestosmayor() {
      var mayor = 0;
      var puestosmayor = 0;
      for (var i = this.boletaslocalidad.filas.length - 1; i >= 0; i--) {
        if (mayor < this.boletaslocalidad.filas[i].puestos.length) {
          mayor = this.boletaslocalidad.filas[i].puestos.length;
        }
      }
      if (
        this.boletaslocalidad.palco == 1 &&
        this.boletaslocalidad.puestosxpalco != null
      ) {
        puestosmayor =
          (parseInt(mayor) / this.boletaslocalidad.puestosxpalco) * 90;
      } else {
        puestosmayor = parseInt(mayor) * 90;
      }
      return puestosmayor;
    },
    dateTimeSaleStart() {
      return this.$store.getters["carrito/dateTimeSaleStart"];
    },
    dateTimeSaleEnd() {
      return this.$store.getters["carrito/dateTimeSaleEnd"];
    },
    countBoletas() {
      return this.$store.getters["carrito/countBoletas"];
      /* var lasBoletas = [];
				var losPalcos = [];

				for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
				if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
					if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
					losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
					} else {
					lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
					}
					this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
				}
				}

				return lasBoletas.length; */
    },
    countPalcos() {
      return this.$store.getters["carrito/countPalcos"];
      /* var lasBoletas = [];
				var losPalcos = [];

				for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
				if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
					if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
					losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
					} else {
					lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
					}
					this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
				}
				}

				return losPalcos.length;*/
    },
  },
};
</script>
<style>
.sizePuestos {
  width: 23px;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
}

@media (min-width: 200px) {
  .sizePuestos {
    width: 40px;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
  }
}

@media (min-width: 300px) {
  .sizePuestos {
    width: 40px;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
  }
}

@media (min-width: 400px) {
  .sizePuestos {
    width: 40px;
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
  }
}

@media (min-width: 600px) {
  .sizePuestos {
    width: 50px;
    border: 5px solid transparent;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
  }
}

@media (min-width: 800px) {
  .sizePuestos {
    width: 50px;
    border: 5px solid transparent;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
  }
}

@media (min-width: 1200px) {
  .sizePuestos {
    width: 60px;
    border: 5px solid transparent;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
  }
}

@media (min-width: 1920px) {
  .sizePuestos {
    width: 90px;
    border: 5px solid transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
}

.iconPuesto {
  width: 100%;
  padding: 1px;
}

.iconPuestoVacio {
  padding: 5px 15px;
}

.puestos {
  width: 100%;
  display: block;
  margin: auto;
  padding: 15px;
  color: white;
  background: rgb(160, 158, 146);
  background: -webkit-linear-gradient(
    bottom,
    rgb(140, 139, 137) 0%,
    rgb(149, 149, 142) 16%,
    rgb(237, 236, 232) 100%
  );
  background: -o-linear-gradient(
    bottom,
    rgb(140, 139, 137) 0%,
    rgb(149, 149, 142) 16%,
    rgb(237, 236, 232) 100%
  );
  background: linear-gradient(
    to top,
    rgb(140, 139, 137) 0%,
    rgb(149, 149, 142) 16%,
    rgb(237, 236, 232) 100%
  );
  border-radius: 5px;
}
.tagFila {
  float: right;
}
.purple {
  background-color: purple;
}
.gray {
  background-color: #808080 !important;
}
</style>