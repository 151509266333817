import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers,headersMultipart} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       imagenes: [],
       generos: [],
       imagen: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listImagenes: (state,getters,rootState) => {
          return state.imagenes;
    },
    imagen: (state,getters,rootState) => {
        return state.imagen;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listImagenes: (state,imagenes) => {
          state.imagenes = imagenes.data;
          state.pagination.totalItems = imagenes.total;
          state.pagination.page = imagenes.current_page;
          state.pagination.rowsPerPage = imagenes.per_page;
       },
      viewImagen(state,id){
        let auxImagen = state.imagenes.filter((item) => item.id == id);
        state.imagen = auxImagen[0];
      },
           listImagenesSearch: (state,imagenes) => {
            if(imagenes != null){ 
                state.imagenes = imagenes;
            }else{
              state.imagenes = null;
            }
       },
      setImagen(state,imagen){
          state.imagen = imagen;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listImagenes({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}imagen`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data);
        commit('listImagenes',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las imagenes!", "error");
        console.error(error);
      });

    },
  newImagen({state,getters,commit,rootState},fileImage){
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}imagen`,fileImage,{headers: headersMultipart})
      .then(response => {
        /*swal("Exito!", "Se creo la imagen correctamente!", "success");*/
        store.dispatch('imagen/listImagenes',state.pagination.page);
        commit('setImagen',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al crear la imagen!", "error");
        console.error(error);
      });
  },
  newImagen64({state,getters,commit,rootState},payload){
      // imagen
      // nombre
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}save_base64`,payload,{headers: headers})
      .then(response => {
        /*swal("Exito!", "Se creo la imagen correctamente!", "success");*/
        store.dispatch('imagen/listImagenes',state.pagination.page);
        commit('setImagen',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al crear la imagen!", "error");
        console.error(error);
      });
  },
  newImagen64_2023({state,getters,commit,rootState},payload){
    // imagen
    // nombre
    store.dispatch('validateToken');
    return axios.post(`${configApi.apiUrl}save_base64`,payload,{headers: headers})
    .then(response => {
       store.state.urlimagenlocalidad = response.data.data.url
      /*swal("Exito!", "Se creo la imagen correctamente!", "success");*/
      store.dispatch('imagen/listImagenes',state.pagination.page);
       commit('setImagen',response.data.data);
    })
    .catch(error => {
     swal("Error!", "Error al crear la imagen!", "error");
      console.error(error);
    });
},
  updateImagen({state,getters,commit,rootState},payload){
      // imagen
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}updateImage/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la imagen correctamente!", "success");
        store.dispatch('condicion/listImagenes',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la imagen!", "error");
        console.error(error);
      });
  },
  },
}