<template>
  <div class="tabla-evento-component" v-if="tablaEvento">
    <table @click="$emit('showModal', true)" class="table table-bordered table-responsive table-hover" id="table-evento-preventa">
      <thead>
        <tr>
          <th scope="col" :key="index" v-for="(header, index) in tablaEvento.headers" class="header-table-evento" v-html="header" :style="index == 0 ? 'max-width: 100px;' : ''"></th>
        </tr>
      </thead>
      <tbody>
        <tr :key="index2" v-for="(body, index2) in tablaEvento.precios">
          <td :key="index3" v-for="(item, index3) in body" :style="index3 == 0 ? 'background-color: rgba(0,0,0,.075); font-size: 1.125rem; min-width: 150px;' : 'min-width: 100px; width: ' + (100 / (body.length - 1)) + '%;'" class="body-table-evento" v-html="item"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState('evento', ['tablaEvento']),
  }
}
</script>
<style>
@font-face {
    font-family: 'bebasneue';
    src: url('../../../fonts/Bebas_Neue/BebasNeue-Regular.ttf');
}
.tabla-evento-component{
  padding: 1rem;
}
#table-evento-preventa td{
  vertical-align: inherit;
  text-align: center;
  cursor: pointer;
}
#table-evento-preventa th{
  cursor: pointer;
  vertical-align: inherit;
  text-align: center;
  font-size: 1.125rem;
  background-color: rgba(0,0,0,.075);
  padding: 1rem;
  line-height: 1.1rem;
  box-sizing:border-box; clear:both
}
#table-evento-preventa th:first, #table-evento-preventa td{
  width: auto;
}
.header-table-evento, .body-table-evento, .header-table-evento span, .body-table-evento span{
  font-family: bebasneue;
  letter-spacing: .5px;
}
</style>