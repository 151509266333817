<template>
  <v-app v-if="evento">
    <v-container>
      <h2>solicitud de evento a modificar</h2>
      <h2 v-if="auditoria">{{ auditoria.observacion }}</h2>
      <form-wizard
        ref="wizard"
        next-button-text="Siguiente"
        back-button-text="Atras"
        title=""
        subtitle=""
        color="#907b16"
        shape="circle"
        finish-button-text="ACTUALIZAR EVENTO"
        @on-complete="sendFormSolicitudEvento"
      >
        <tab-content :before-change="validateFirstTab">
          <v-form ref="ruleForm1">
            <v-container fluid grid-list-lg>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-card class="p-3 bg-white">
                    <h3 class="text-center mb-4">Datos Generales</h3>
                    <v-text-field
                      name="nombreEvento"
                      label="Nombre del evento"
                      required
                      placeholder="Indique el nombre del evento tal como será mostrado publicamente"
                      v-model="evento.nombre"
                      :rules="[(v) => !!v || 'Nombre de Evento es Requerido']"
                    ></v-text-field>
                    <v-textarea
                      name="descripcionEvento"
                      label="Descripción del evento"
                      required
                      placeholder="Indique la Descripción del evento tal como será mostrado publicamente"
                      v-model="evento.descripcion"
                      :rules="[
                        (v) => !!v || 'Descripción de Evento es Requerido',
                      ]"
                      hint="Hint text"
                    ></v-textarea>
                    <v-layout row wrap>
                      <v-flex lg6 md6 sm6 xs12>
                        <v-dialog
                          ref="dialogFechaEvento"
                          v-model="modalFechaEvento"
                          :return-value.sync="evento.fecha_evento"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="evento.fecha_evento"
                              label="Fecha de Inicio del evento"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              :rules="[
                                (v) => !!v || 'Fecha de Evento es Requerido',
                              ]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="evento.fecha_evento"
                            scrollable
                            locale="es-ES"
                            :min="hoy"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              color="primary"
                              @click="modalFechaEvento = false"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              flat
                              color="primary"
                              @click="
                                $refs.dialogFechaEvento.save(
                                  evento.fecha_evento
                                )
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </v-flex>
                      <v-flex lg6 md6 sm6 xs12>
                        <v-dialog
                          ref="dialogFechaFinEvento"
                          v-model="modalFechaFinEvento"
                          :return-value.sync="evento.fecha_finalizacion_evento"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="evento.fecha_finalizacion_evento"
                              label="Fecha Final del evento"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              :rules="[
                                (v) =>
                                  !!v || 'Fecha final de Evento es Requerido',
                              ]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="evento.fecha_finalizacion_evento"
                            scrollable
                            locale="es-ES"
                            :min="hoy"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              color="primary"
                              @click="modalFechaFinEvento = false"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              flat
                              color="primary"
                              @click="
                                $refs.dialogFechaFinEvento.save(
                                  evento.fecha_finalizacion_evento
                                )
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </v-flex>
                      <!-- <v-flex lg4 md4 sm4 xs5 class="pl-3">
                    <v-checkbox color="primary" label="El evento es de un solo dia" v-model="evento.eventoUnSoloDia"></v-checkbox>
                  </v-flex> -->
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex lg4 md4 sm4 xs12>
                        <v-dialog
                          ref="dialogHoraInicio"
                          v-model="modalHoraInicio"
                          :return-value.sync="evento.hora_inicio"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="evento.hora_inicio"
                              label="Hora de inicio"
                              prepend-icon="access_time"
                              readonly
                              :rules="[
                                (v) => !!v || 'Hora de inicio es Requerido',
                              ]"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="modalHoraInicio"
                            v-model="evento.hora_inicio"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              color="primary"
                              @click="modalHoraInicio = false"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              flat
                              color="primary"
                              @click="
                                $refs.dialogHoraInicio.save(evento.hora_inicio)
                              "
                              >OK</v-btn
                            >
                          </v-time-picker>
                        </v-dialog>
                      </v-flex>
                      <v-flex lg4 md4 sm4 xs12>
                        <v-dialog
                          ref="dialogHoraAperturaPuertas"
                          v-model="modalHoraAperturaPuertas"
                          :return-value.sync="evento.hora_apertura"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="evento.hora_apertura"
                              label="Apertura de puertas"
                              prepend-icon="access_time"
                              readonly
                              :rules="[
                                (v) =>
                                  !!v || 'Apertura de puertas es Requerido',
                              ]"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="modalHoraAperturaPuertas"
                            v-model="evento.hora_apertura"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              color="primary"
                              @click="modalHoraAperturaPuertas = false"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              flat
                              color="primary"
                              @click="
                                $refs.dialogHoraAperturaPuertas.save(
                                  evento.hora_apertura
                                )
                              "
                              >OK</v-btn
                            >
                          </v-time-picker>
                        </v-dialog>
                      </v-flex>
                      <v-flex lg4 md4 sm4 xs12>
                        <v-dialog
                          ref="dialogHoraFinalizacion"
                          v-model="modalHoraFinalizacion"
                          :return-value.sync="evento.hora_finalizacion"
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="evento.hora_finalizacion"
                              label="Hora de finalizacion"
                              prepend-icon="access_time"
                              readonly
                              :rules="[
                                (v) =>
                                  !!v || 'Hora de finalizazion es Requerido',
                              ]"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="modalHoraFinalizacion"
                            v-model="evento.hora_finalizacion"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              color="primary"
                              @click="modalHoraFinalizacion = false"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              flat
                              color="primary"
                              @click="
                                $refs.dialogHoraFinalizacion.save(
                                  evento.hora_finalizacion
                                )
                              "
                              >OK</v-btn
                            >
                          </v-time-picker>
                        </v-dialog>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex lg4 md4 sm4 xs12 class="pr-4">
                        <v-autocomplete
                          label="Tipo de evento"
                          :items="tiposEventos"
                          v-model="evento.id_tipo_evento"
                          item-text="nombre"
                          item-value="id"
                          :rules="[(v) => !!v || 'Tipo de Evento es Requerido']"
                          no-data-text="No se encontraron resultados"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex lg4 md4 sm4 xs12>
                        <v-text-field
                          label="Codigo PULEP"
                          required
                          v-model="evento.codigo_pulep"
                          :rules="[(v) => !!v || 'Codigo PULEP es Requerido']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex lg4 md4 sm4 xs12>
                        <v-autocomplete
                          v-model="evento.codigo_moneda"
                          :rules="[
                            (v) => !!v || 'Moneda del Evento es Requerido',
                          ]"
                          :items="monedas"
                          item-text="descripcion"
                          item-value="codigo_moneda"
                          label="Moneda del Evento"
                          placeholder="Seleccionar..."
                          no-data-text="No se encontraron resultados"
                          required
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-flex xs12> </v-flex>
                  <v-card class="p-3 bg-white">
                    <h3 class="text-center mb-4">Auditorio</h3>
                    <v-layout>
                      <v-flex lg6 md6 sm6 xs12 class="pr-4">
                        <v-autocomplete
                          label="Lugar del evento"
                          :items="auditorios"
                          v-model="evento.id_auditorio"
                          item-text="nombre"
                          item-value="id"
                          no-data-text="No se encontraron resultados"
                          :return-object="true"
                          @change="AuditorioExistente"
                        ></v-autocomplete>
                        <v-flex lg1 md1 sm1 xs1>
                          <v-chip @click="nuevoAuditorioEvento">
                            <v-icon left color="#907b16">autorenew</v-icon>
                            Nuevo
                          </v-chip>
                        </v-flex>
                      </v-flex>
                      <!--  <v-flex lg6 md6 sm6 xs12 class="pr-4">
                       <v-autocomplete
                        solo
                        label="Temporada"
                        v-model="evento.temporada"
                        :items="temporadas"
                        item-text="nombre"
                        item-value="id"
                        :rules="[(v) => !!v || 'Temporada de evento es requerida']"
                        :return-object="true"
                        no-data-text="No se encontraron resultados"
                      ></v-autocomplete>
                    </v-flex> -->
                      <v-autocomplete
                        label="Temporada"
                        :items="temporadas"
                        v-model="evento.id_temporada"
                        item-text="nombre"
                        item-value="id"
                        no-data-text="No se encontraron resultados"
                        :rules="[
                          (v) => !!v || 'Temporada de evento es requerida',
                        ]"
                        :return-object="true"
                      ></v-autocomplete>
                      <v-layout row justify-center>
                        <v-dialog
                          v-model="newDialogTemporada"
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark v-on="on" fab small>
                              <v-icon>add</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline"
                                >Crear nueva temporada</span
                              >
                            </v-card-title>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 sm12 md12>
                                    <v-text-field
                                      label="Nombre"
                                      required
                                      v-model="newTemporada"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="newDialogTemporada = false"
                                >Cancelar</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                flat
                                @click="
                                  newDialogTemporada = false;
                                  saveTemporada();
                                "
                                >Guardar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-layout>
                    </v-layout>

                    <div v-show="DetallesAuditorio">
                      <v-layout>
                        <v-flex lg6 md6 sm6 xs12 class="pr-4">
                          <v-text-field
                            label="Nombre del Lugar del Evento"
                            v-model="auditorioDatosnombre"
                            required
                            :readonly="auditorioDatosid != ''"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre del Auditorio del Evento es Requerido',
                            ]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg6 md6 sm6 xs12 class="pr-4">
                          <v-text-field
                            label="Dirección del Lugar del Evento"
                            v-model="auditorioDatosdireccion"
                            required
                            :readonly="auditorioDatosid != ''"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <center>
                          <google-map
                            :center="mapCenter"
                            :zoom="12"
                            ref="map"
                            @center_changed="updateCenter"
                            @idle="sync"
                            class="map-container"
                          >
                          </google-map>
                        </center>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="País"
                            readonly
                            v-if="auditorioDatosid != ''"
                            :value="getPais(auditorioDatosid_pais)"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre del País del Auditorio del Evento es Requerido',
                            ]"
                          ></v-text-field>
                          <v-autocomplete
                            label="País"
                            no-data-text="No se encontraron resultados"
                            v-model="auditorioDatosid_pais"
                            :items="paises"
                            item-text="descripcion"
                            item-value="id"
                            v-if="auditorioDatosid == ''"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre del País del Auditorio del Evento es Requerido',
                            ]"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="Departamento"
                            readonly
                            v-if="auditorioDatosid != ''"
                            :value="
                              getDepartamento(auditorioDatosid_departamento)
                            "
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre del Departamento del Auditorio del Evento es Requerido',
                            ]"
                          ></v-text-field>
                          <v-autocomplete
                            label="Departamento"
                            no-data-text="No se encontraron resultados"
                            v-model="auditorioDatosid_departamento"
                            :items="departamentosauditorioeventoShow"
                            item-text="descripcion"
                            item-value="id"
                            v-if="auditorioDatosid == ''"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre del Departamento del Auditorio del Evento es Requerido',
                            ]"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="Ciudad"
                            readonly
                            v-if="auditorioDatosid != ''"
                            :value="getCiudad(auditorioDatosid_ciudad)"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre de la Ciudad del Auditorio del Evento es Requerido',
                            ]"
                          ></v-text-field>
                          <v-autocomplete
                            label="Ciudad"
                            no-data-text="No se encontraron resultados"
                            v-model="auditorioDatosid_ciudad"
                            :items="ciudadesauditorioeventoShow"
                            item-text="descripcion"
                            item-value="id"
                            v-if="auditorioDatosid == ''"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Nombre de la Ciudad del Auditorio del Evento es Requerido',
                            ]"
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="Latitud"
                            :readonly="auditorioDatosid != ''"
                            type="number"
                            v-model="auditorioDatoslatitud"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="Longitud"
                            :readonly="auditorioDatosid != ''"
                            type="number"
                            v-model="auditorioDatoslongitud"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            label="Aforo"
                            :readonly="auditorioDatosid != ''"
                            type="number"
                            v-model="auditorioDatosaforo"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12>
                  <v-card class="p-3 bg-white">
                    <h3 class="text-center mb-4 mt-4">
                      Responsable del evento
                    </h3>
                    <v-layout row wrap>
                      <v-flex lg3 md3 sm3 xs12>
                        <v-autocomplete
                          label="Buscar un responsable"
                          :items="clientes"
                          v-model="evento.id_cliente"
                          item-text="nombrerazon"
                          item-value="id"
                          no-data-text="No se encontraron resultados"
                          @change="viewCliente"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex lg2 md2 sm2 xs12>
                        <v-chip @click="nuevoResponsableEvento">
                          <v-icon left color="#907b16">autorenew</v-icon>
                          Nuevo
                        </v-chip>
                      </v-flex>
                      <v-flex
                        lg7
                        md7
                        sm7
                        xs12
                        v-show="DetallesResponsableEvento"
                      >
                        <v-text-field
                          required
                          label="Nombre ó Razon Social"
                          placeholder="Nombre ó Razon Social"
                          v-model="responsableEventonombreRazonSocial"
                          :readonly="cliente != null"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Nombre del Responsable de Evento es Requerido',
                          ]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <div v-show="DetallesResponsableEvento">
                      <v-layout row wrap>
                        <v-flex lg7 md7 sm7 xs12 class="pr-3">
                          <v-text-field
                            required
                            type="text"
                            label="NIT / CEDULA"
                            placeholder="NIT / CEDULA"
                            v-model="responsableEventoIdentificacion"
                            :readonly="cliente != null"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Identificación del Responsable de Evento es Requerido',
                            ]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg2 md2 sm2 xs12 class="pr-3">
                          <v-text-field
                            readonly
                            label="TIPO"
                            :value="
                              getTipoIdentificacion(
                                responsableEventotipoIdentificacion
                              )
                            "
                            v-if="cliente != null"
                          ></v-text-field>
                          <v-autocomplete
                            label="TIPO"
                            no-data-text="No se encontraron resultados"
                            v-model="responsableEventotipoIdentificacion"
                            :items="tipoidentificacion"
                            item-text="descripcion"
                            item-value="id"
                            :return-object="true"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Tipo de Identificación del Responsable de Evento es Requerido',
                            ]"
                            v-if="cliente == null"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex lg5 md5 sm5 xs12>
                          <v-radio-group
                            v-model="responsableEventotipoCliente"
                            row
                            :readonly="cliente != null"
                          >
                            <v-radio
                              label="Entidad Juridica"
                              :value="true"
                              color="primary"
                            ></v-radio>
                            <v-radio
                              label="Persona Natural"
                              :value="false"
                              color="primary"
                            ></v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                      <v-text-field
                        :readonly="cliente != null"
                        required
                        label="Direccion"
                        placeholder="Direccion"
                        v-model="responsableEventodireccion"
                        :rules="[
                          (v) =>
                            !!v ||
                            'Dirección del Responsable de Evento es Requerido',
                        ]"
                      ></v-text-field>
                      <v-layout row wrap>
                        <v-flex
                          lg4
                          md4
                          sm4
                          xs12
                          class="pr-4"
                          v-if="cliente == null"
                        >
                          <v-autocomplete
                            label="País"
                            no-data-text="No se encontraron resultados"
                            v-model="responsableEventopais"
                            :items="paises"
                            item-text="descripcion"
                            item-value="id"
                            :return-object="true"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            readonly
                            v-model="responsableEventodepartamento.descripcion"
                            v-if="cliente != null"
                            label="Departamento"
                          ></v-text-field>
                          <v-autocomplete
                            label="Departamento"
                            no-data-text="No se encontraron resultados"
                            v-model="responsableEventodepartamento"
                            :items="departamentosrespeventoShow"
                            item-text="descripcion"
                            item-value="id"
                            :return-object="true"
                            v-if="cliente == null"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex lg4 md4 sm4 xs12 class="pr-4">
                          <v-text-field
                            readonly
                            v-model="responsableEventociudad.descripcion"
                            v-if="cliente != null"
                            label="Ciudad"
                          ></v-text-field>
                          <v-autocomplete
                            label="Ciudad"
                            no-data-text="No se encontraron resultados"
                            v-model="responsableEventociudad"
                            :items="ciudadesrespeventoShow"
                            item-text="descripcion"
                            item-value="id"
                            :return-object="true"
                            v-if="cliente == null"
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex lg6 md6 sm6 xs12 pa-2>
                          <!-- <v-text-field :readonly="cliente != null" v-validate="'required|email'" :error-messages="errors.collect('email')"
                      data-vv-name="email" required placeholder="Correo electronico" type="email" v-model="responsableEventoemail"></v-text-field> -->
                          <v-text-field
                            :readonly="cliente != null"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Email del Responsable de Evento es Requerido',
                              (v) =>
                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                  v
                                ) || 'E-mail del cliente debe ser Valido',
                            ]"
                            required
                            label="Correo electronico"
                            placeholder="Correo electronico"
                            type="email"
                            v-model="responsableEventoemail"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg6 md6 sm6 xs12 pa-2>
                          <v-text-field
                            :readonly="cliente != null"
                            required
                            label="Telefono"
                            placeholder="Telefono"
                            type="number"
                            v-model="responsableEventotelefono"
                            :rules="[
                              (v) =>
                                !!v ||
                                'Telefono del Responsable de Evento es Requerido',
                            ]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateSecondTab">
          <v-form ref="ruleForm2">
            <h3>Imagenes del evento</h3>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-2>
                <h6>
                  Por favor seleccione las imagenes principales del evento
                </h6>
              </v-flex>
            </v-layout>
            <div class="p-3 bg-white">
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoCarusel"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Carrusel</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoCarusel">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Carrusel</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoCarusel"
                                  accept="image/*"
                                  name="imgEventoCarusel"
                                  style="display: none"
                                  id="imgEventoCarusel"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoCaruselPreview"
                                v-show="sizeImageC != ''"
                              />
                              <p>Tamaño de la imagen: 220X300 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageC }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageCWidth }} y Alto: {{ sizeImageCHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoCarusel = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoTwitter"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Twitter</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoTwitter">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Twitter</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoTwitter"
                                  accept="image/*"
                                  name="imgEventoTwitter"
                                  style="display: none"
                                  id="imgEventoTwitter"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoTwitterPreview"
                                v-show="sizeImageT != ''"
                              />
                              <p>Tamaño de la imagen: 1024X512 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageT }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageTWidth }} y Alto: {{ sizeImageTHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoTwitter = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoDisenoBoleta"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Diseño</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoDisenoBoleta">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Diseño</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoDisenoBoleta"
                                  accept="image/*"
                                  name="imgEventoDisenoBoleta"
                                  style="display: none"
                                  id="imgEventoDisenoBoleta"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoDisenoBoletaPreview"
                                v-show="sizeImageI != ''"
                              />
                              <p>Tamaño de la imagen: 794X1124 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageI }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageIWidth }} y Alto: {{ sizeImageIHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoDisenoBoleta = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoInstagStories"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen InstagStories</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoInstagStories">
                        <v-card>
                          <v-card-title>
                            <span class="headline"
                              >Nueva imagen InstagStories</span
                            >
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoInstagStories"
                                  accept="image/*"
                                  name="imgEventoInstagStories"
                                  style="display: none"
                                  id="imgEventoInstagStories"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoInstagStoriesPreview"
                                v-show="sizeImageIS != ''"
                              />
                              <p>Tamaño de la imagen: 1127X2008 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageIS }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageISWidth }} y Alto: {{ sizeImageISHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoInstagStories = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('carousel')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(
                            eventoImagen, index
                          ) in imagenesEventoCarousel"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoCarouselCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('twitter')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoTwitter"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoTwitterCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('disenoboleta')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(
                            eventoImagen, index
                          ) in imagenesEventoDisenoBoleta"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoDisenoBoletaCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('instagstories')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(
                            eventoImagen, index
                          ) in imagenesEventoInstagStories"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoInstagStoriesCheckTemp(
                                  index
                                )
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoFacebook"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Facebook</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoFacebook">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Facebook</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoFacebook"
                                  accept="image/*"
                                  name="imgEventoFacebook"
                                  style="display: none"
                                  id="imgEventoFacebook"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoFacebookPreview"
                                v-show="sizeImageF != ''"
                              />
                              <p>Tamaño de la imagen: 1200X630 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageF }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageFWidth }} y Alto: {{ sizeImageFHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoFacebook = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog v-model="modalImagenEventoVert" max-width="400px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Vert</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoVert">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Vert</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoVert"
                                  accept="image/*"
                                  name="imgEventoVert"
                                  style="display: none"
                                  id="imgEventoVert"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoVertPreview"
                                v-show="sizeImageV != ''"
                              />
                              <p>Tamaño de la imagen: 300X360 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageV }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageVWidth }} y Alto: {{ sizeImageVHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoVert = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoMiniatura"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Miniatura</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoMiniatura">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Miniatura</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoMiniatura"
                                  accept="image/*"
                                  name="imgEventoMiniatura"
                                  style="display: none"
                                  id="imgEventoMiniatura"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoMiniaturaPreview"
                                v-show="sizeImageM != ''"
                              />
                              <p>Tamaño de la imagen: 150X150 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageM }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageMWidth }} y Alto: {{ sizeImageMHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoMiniatura = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoBanner"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Banner</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoBanner">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Banner</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoBanner"
                                  accept="image/*"
                                  name="imgEventoBanner"
                                  style="display: none"
                                  id="imgEventoBanner"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoBannerPreview"
                                v-show="sizeImageB != ''"
                              />
                              <p>Tamaño de la imagen: 760X400 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageB }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageBWidth }} y Alto: {{ sizeImageBHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoBanner = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoDesktop"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Banner Desktop</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoDesktop">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Banner Desktop</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoDesktop"
                                  accept="image/*"
                                  name="imgEventoDesktop"
                                  style="display: none"
                                  id="imgEventoDesktop"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoDesktopPreview"
                                v-show="sizeImageB != ''"
                              />
                              <p>Tamaño de la imagen: 1000X500 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageB }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageBWidth }} y Alto: {{ sizeImageBHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoDesktop = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoMovil"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Banner Movil</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoMovil">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Banner Movil</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoMovil"
                                  accept="image/*"
                                  name="imgEventoMovil"
                                  style="display: none"
                                  id="imgEventoMovil"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoMovilPreview"
                                v-show="sizeImageB != ''"
                              />
                              <p>Tamaño de la imagen: 600X500 KB</p>
                              <!-- <p>Tamaño de la imagen: {{ sizeImageB }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageBWidth }} y Alto: {{ sizeImageBHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoMovil = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-dialog
                      v-model="modalImagenEventoPrecios"
                      max-width="400px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on"
                          >Nueva imagen Precios</v-btn
                        >
                      </template>
                      <form @submit.prevent="saveImagenEventoPrecios">
                        <v-card>
                          <v-card-title>
                            <span class="headline">Nueva imagen Precios</span>
                          </v-card-title>
                          <v-container text-center>
                            <div class="form-group">
                              <label
                                style="cursor: pointer"
                                title="Seleccionar una imagen para el evento"
                              >
                                <i
                                  class="material-icons"
                                  style="font-size: 60px; color: #777777"
                                  >add_a_photo</i
                                >
                                <input
                                  type="file"
                                  @change="getImageEventoPrecios"
                                  accept="image/*"
                                  name="imgEventoPrecios"
                                  style="display: none"
                                  id="imgEventoPrecios"
                                  required
                                />
                              </label>
                              <br />
                              <img
                                src="#"
                                id="imgEventoPreciosPreview"
                                v-show="sizeImageB != ''"
                              />
                              <!--<p>Tamaño de la imagen: 1280X590 KB</p>
                               <p>Tamaño de la imagen: {{ sizeImageB }} KB</p>
                        <br>
                        <p>Su Ancho: {{ sizeImageBWidth }} y Alto: {{ sizeImageBHeight }}</p> -->
                            </div>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="default"
                              text
                              @click="modalImagenEventoPrecios = false"
                              >Cerrar</v-btn
                            >
                            <v-btn color="success" type="submit" text
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('facebook')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(
                            eventoImagen, index
                          ) in imagenesEventoFacebook"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoFacebookCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('vertical')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoVert"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="updateImagenesEventoVertCheckTemp(index)"
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('miniatura')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(
                            eventoImagen, index
                          ) in imagenesEventoMiniatura"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoMiniaturaCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('banner')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoBanner"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoBannerCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('ban_desktop')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoDesktop"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventodesktopCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('ban_movil')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoMovil"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoMovilCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                    <v-item-group multiple>
                      <v-layout row wrap text-center>
                        <v-flex
                          v-for="eventoImagen in eventoimagenes"
                          v-if="eventoImagen.nombre.includes('precios')"
                          :key="eventoimagenes.id"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.url"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.url"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                deleteImagenesEventoCheck(eventoImagen.id)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                        <v-flex
                          v-for="(eventoImagen, index) in imagenesEventoPrecios"
                          :key="index"
                          lg12
                          md12
                          sm12
                          xs12
                          class="mb-3"
                        >
                          <v-item
                            :value="eventoImagen.imagen"
                            class="checkFilterImagenesEventoCarouselSolicitud"
                          >
                            <v-img
                              :src="eventoImagen.imagen"
                              lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                              height="150"
                              class="text-right pa-2"
                              @click="
                                updateImagenesEventoPreciosCheckTemp(index)
                              "
                            >
                            </v-img>
                          </v-item>
                        </v-flex>
                      </v-layout>
                    </v-item-group>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateThirtTab">
          <v-form ref="ruleForm3">
            <h3>Condiciones de admision y restricciones</h3>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-2>
                <h6>
                  Por favor seleccione todas las condiciones de admision y/o
                  restricciones que apliquen para el evento solicitado
                </h6>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-3 text-right>
                <v-dialog v-model="modalCondicion" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      dark
                      v-on="on"
                      @click="setNullCondicion"
                      >Nueva condicion</v-btn
                    >
                  </template>
                  <form @submit.prevent="saveCondicion">
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ titleModalCondicion }}</span>
                      </v-card-title>
                      <v-container>
                        <v-textarea
                          required
                          solo
                          name="nameCondicion"
                          v-model="nameCondicion"
                          label="Escribe aqui la condicion que desees agregar..."
                          autofocus
                          ref="txtNameCondicion"
                        ></v-textarea>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="default"
                          text
                          @click="modalCondicion = false"
                          >Cancelar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
              </v-flex>
            </v-layout>
            <div class="p-3 bg-white">
              <v-text-field
                solo
                label="Buscar condiciones de admision y restricciones"
                prepend-inner-icon="search"
                class="search"
                v-model="keyCondiciones"
              ></v-text-field>
            </div>
            <!-- <div class="pa-2 bg-white cont-condiciones"> -->
            <v-data-table
              v-model="selectedCondiciones"
              :headers="headersCondicion"
              :items="condiciones"
              item-key="id"
              :search="keyCondiciones"
              select-all
              class="elevation-1"
              rows-per-page-text="Filas por Pag."
              :rows-per-page-items="rowperpageitems2"
            >
              <template v-slot:items="condicion">
                <td>
                  <v-checkbox
                    v-model="condicion.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ condicion.item.descripcion }}</td>
                <td>
                  <v-icon @click="editCondicion(condicion.item.id)">
                    edit
                  </v-icon>
                </td>
              </template>
            </v-data-table>
            <!-- </div> -->
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateFourTab">
          <v-form ref="ruleForm4">
            <h3>Artistas principales</h3>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-2>
                <h6>
                  Por favor seleccione los artistas principales que participaran
                  en el evento
                </h6>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-3 text-right>
                <v-dialog v-model="modalArtista" max-width="400px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">Nuevo artista</v-btn>
                  </template>
                  <form @submit.prevent="saveArtista">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Nuevo artista</span>
                      </v-card-title>
                      <v-container text-center>
                        <div class="form-group">
                          <label
                            style="cursor: pointer"
                            title="Seleccionar una imagen para el artista"
                          >
                            <i
                              class="material-icons"
                              style="font-size: 60px; color: #777777"
                              >add_a_photo</i
                            >
                            <input
                              type="file"
                              @change="getImage"
                              accept="image/*"
                              name="imgArtista"
                              style="display: none"
                              id="imgArtista"
                              required
                            />
                          </label>
                          <br />
                          <img src="#" id="imgArtistaPreview" />
                        </div>
                        <div class="form-group">
                          <v-text-field
                            name="nameArtista"
                            label="Nombre artistico"
                            required
                            placeholder="Nombre artistico"
                            v-model="nameArtista"
                          ></v-text-field>
                        </div>
                        <v-layout row wrap>
                          <!-- <v-flex lg12 md12 sm12 xs12 p-2>
                             <v-autocomplete
                                :items="categoriasArtistas"
                                 item-text="nombre"
                                 item-value="id"
                                 v-model="categoriaNewArtista"
                                 label="Categoria"
                                 solo
                                 required
                              ></v-autocomplete>
                            </v-flex> -->
                          <v-flex lg12 md12 sm12 xs12 p-2>
                            <v-text-field
                              name="managerNewArtista"
                              label="Manager artistico"
                              required
                              placeholder="Manager artistico"
                              v-model="managerNewArtista"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg12 md12 sm12 xs12 p-2>
                            <v-autocomplete
                              no-data-text="No se encontraron resultados"
                              :items="generos"
                              item-text="nombre"
                              item-value="id"
                              v-model="generoNewArtista"
                              label="Genero"
                              solo
                              required
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="default"
                          text
                          @click="modalArtista = false"
                          >Cerrar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
                <br />
                <v-dialog v-model="modalGenero" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on" @click="setNullGenero"
                      >Nuevo Genero</v-btn
                    >
                  </template>
                  <form @submit.prevent="saveGenero">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Nuevo Genero</span>
                      </v-card-title>
                      <v-container>
                        <v-textarea
                          required
                          solo
                          name="nameGenero"
                          v-model="nameGenero"
                          label="Escribe aqui el genero que desees agregar..."
                          autofocus
                        ></v-textarea>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="default" text @click="modalGenero = false"
                          >Cancelar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
              </v-flex>
            </v-layout>
            <div class="p-3 bg-white">
              <v-layout row wrap>
                <v-flex lg4 md4 sm12 xs12 p-2>
                  <v-text-field
                    solo
                    label="Buscar"
                    prepend-inner-icon="search"
                    class="search"
                    v-model="keyArtistas"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex lg4 md4 sm6 xs12 p-2>
         <v-autocomplete
            :items="categoriasArtistas"
             item-text="nombre"
             item-value="id"
             v-model="categoriaSearch"
             label="Categoria"
             solo
          ></v-autocomplete>
        </v-flex> -->
                <v-flex lg4 md4 sm6 xs12 p-2>
                  <v-autocomplete
                    :items="generos"
                    item-text="nombre"
                    item-value="id"
                    v-model="generoSearch"
                    label="Genero"
                  ></v-autocomplete>
                </v-flex>
                <v-flex lg1 md1 sm1 xs1 p-1>
                  <v-chip @click="verTodosArtistas">
                    <v-icon left color="#907b16">remove_red_eye</v-icon>
                    Artistas Seleccionados
                  </v-chip>
                </v-flex>
              </v-layout>
              <v-item-group v-model="artistasCheck" multiple>
                <v-layout row wrap text-center>
                  <v-flex
                    v-for="artista in artistasShow"
                    :key="artista.id"
                    lg4
                    md4
                    sm4
                    xs12
                    class="mb-3"
                  >
                    <v-item
                      v-slot:default="{ active, toggle }"
                      :value="artista.id"
                      class="checkFilterArtistasSolicitud"
                    >
                      <!-- <v-img
                  :src="`https://cdn.vuetifyjs.com/images/backgrounds/bg.jpg`"
                  height="150"
                  class="text-right pa-2"
                  @click="toggle"
                > -->
                      <v-img
                        :src="
                          artista.imagens[0] == null
                            ? ''
                            : artista.imagens[0].url
                        "
                        lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                        height="150"
                        class="text-right pa-2"
                        @click="
                          toggle();
                          updateArtistasCheckTemp(artista.id, active);
                        "
                      >
                        <v-btn icon dark>
                          <v-icon color="#907b16" v-if="active">
                            check_circle
                          </v-icon>
                        </v-btn>
                      </v-img>
                    </v-item>
                    <h6
                      class="text-uppercase font-weight-bold"
                      v-text="artista.nombre"
                    ></h6>
                  </v-flex>
                </v-layout>
              </v-item-group>
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateFiveTab">
          <v-form ref="ruleForm5">
            <v-flex lg1 md1 sm1 xs1>
              <v-chip @click="clearPlantilla">
                <v-icon left color="#907b16">autorenew</v-icon>
                Renovar
              </v-chip>
            </v-flex>
            <Auditorio
              v-if="modalPlantilla == 'new' || modalPlantilla == 'edit'"
              :idAuditorio="auditorioDatos"
            ></Auditorio>
            <center v-if="modalPlantilla == 'set' && auditorio_mapeado != null">
              <img :src="auditorio_mapeado.imagen" usemap="#map" />
            </center>
            <div
              id="area_mapeadaAuditorio"
              v-if="modalPlantilla == 'set' && auditorio_mapeado != null"
            ></div>
            <v-flex lg11 md11 sm11 xs11>
              <v-text-field
                label="Nombre de plantilla"
                placeholder="Nombre de plantilla"
                v-model="imagenMapeo.nombre"
                :rules="[(v) => !!v || 'Nombre de plantilla es Requerido']"
              ></v-text-field>
            </v-flex>
            <v-data-table
              :headers="resumheadersAuditoriomapeadosauditorio"
              :items="auditorio_mapeadosauditorioShow"
              item-key="id"
              class="elevation-1"
              rows-per-page-text="Filas por Pag."
              :rows-per-page-items="rowperpageitems"
            >
              <template v-slot:items="auditoriomapeado">
                <td @click="PlantillaAuditorio(auditoriomapeado.item)">
                  Auditorio Mapeado: {{ auditoriomapeado.item.nombre }}
                </td>
              </template>
            </v-data-table>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateSixTab">
          <v-form ref="ruleForm6">
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-2>
                <h3 class="text-center">Localidades y valores</h3>
                <h6 class="text-center">
                  Por favor indique por cada localidad del evento solicitado los
                  datos que se indican a continuacion. Puede agregar tantas
                  localidades como requiera su evento.
                </h6>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-3 text-right>
                <v-dialog v-model="modalTribuna" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      dark
                      v-on="on"
                      @click="setNullTribuna"
                      >Nueva Tribuna</v-btn
                    >
                  </template>
                  <form @submit.prevent="saveTribuna">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Nueva Tribuna</span>
                      </v-card-title>
                      <v-container>
                        <v-textarea
                          required
                          solo
                          name="nameTribuna"
                          v-model="nameTribuna"
                          label="Escribe aqui la tribuna que desees agregar..."
                          autofocus
                        ></v-textarea>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="default"
                          text
                          @click="modalTribuna = false"
                          >Cancelar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
              </v-flex>
            </v-layout>
            <div
              style="overflow-x: auto"
              class="p-5 text-center bg-white mb-4 mt-3"
            >
              <table
                class="table table-striped table-hover mt-4"
                style="width: 1500px"
              >
                <thead>
                  <tr>
                    <th>Tribuna</th>
                    <th>Localidad</th>
                    <th>Cantidad de Sillas</th>
                    <th>Silleteria</th>
                    <th>Palco</th>
                    <th>puestos por Palco</th>
                    <th>Cantidad de Palcos</th>
                    <th>Puerta de Acceso</th>
                    <th>Impuesto %</th>
                    <th>Precio Full</th>
                    <th>Serv. Tiquete</th>
                    <th>Total</th>
                    <th>Imagen</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(localidad, index) in localidades" :key="index">
                    <td>
                      <v-autocomplete
                        :readonly="localidad.id != ''"
                        v-model="localidad.id_tribuna"
                        no-data-text="No se encontraron resultados"
                        :items="tribunasShow2"
                        item-text="nombre"
                        item-value="id"
                        :rules="[
                          (v) => !!v || 'Tribuna de la Localidad es Requerido',
                        ]"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-text-field
                        :readonly="localidad.id != ''"
                        v-model="localidad.nombre"
                        required
                        :rules="[
                          (v) => !!v || 'Nombre de la Localidad es Requerido',
                        ]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        :readonly="localidad.id != ''"
                        v-model="localidad.aforo"
                        type="number"
                        required
                        :rules="[
                          (v) => !!v || 'Aforo de la Localidad es Requerido',
                          (v) => parseFloat(v) > 0 || 'Solo números positivos',
                        ]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-switch
                        :readonly="localidad.id != ''"
                        v-model="localidad.silleteria"
                        :true-value="1"
                        :false-value="0"
                      ></v-switch>
                    </td>
                    <td>
                      <v-switch
                        :readonly="localidad.id != ''"
                        v-model="localidad.palco"
                        :true-value="1"
                        :false-value="0"
                      ></v-switch>
                    </td>
                    <td>
                      <v-text-field
                        v-if="localidad.palco == 1"
                        :readonly="localidad.id != ''"
                        v-model="localidad.puestosxpalco"
                        type="number"
                        required
                        :rules="[
                          (v) => {
                            if (localidad.palco == 1) {
                              return (
                                !!v ||
                                'Sillas por puestos en palco es Requerido'
                              );
                            }
                          },
                          (v) => {
                            if (localidad.palco == 1) {
                              return (
                                parseFloat(v) > 0 || 'Solo números positivos'
                              );
                            }
                          },
                        ]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="localidad.palco == 1"
                        readonly
                        :value="localidad.aforo / localidad.puestosxpalco"
                        type="number"
                        :rules="[
                          (v) =>
                            /^[1-9]\d*$/.test(v) ||
                            'El aforo y los puestos por palcos deben ser exactos',
                        ]"
                        required
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        :readonly="localidad.id != ''"
                        v-model="localidad.puerta_acceso"
                        :rules="[
                          (v) =>
                            /^[\w]+$/.test(v) ||
                            'Nombre de la Localidad solo debe tener letras y números',
                        ]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="localidadesevento[index].impuesto"
                        type="number"
                        suffix="%"
                        required
                        @change="updateLocalidadesevento(index)"
                        :rules="[
                          (v) => !!v || 'Impuesto de la Localidad es Requerido',
                          (v) => {
                            if (localidad.palco == 1) {
                              return (
                                parseFloat(v) > 0 || 'Solo números positivos'
                              );
                            }
                          },
                        ]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-currency-field
                        :prefix="
                          getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                        "
                        :rules="[
                          (v) => !!v || 'Precio de la Localidad es Requerido',
                          (v) => parseFloat(v) > 0 || 'Solo números positivos',
                        ]"
                        filled
                        v-model="localidadesevento[index].precio_venta"
                        @change="updateLocalidadesevento(index)"
                      />
                    </td>
                    <td>
                      <v-currency-field
                        :prefix="
                          getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                        "
                        :rules="[
                          (v) =>
                            !!v || 'Serv Tiquete de la Localidad es Requerido',
                          (v) => parseFloat(v) > 0 || 'Solo números positivos',
                        ]"
                        filled
                        v-model="localidadesevento[index].precio_servicio"
                        @change="updateLocalidadesevento(index)"
                      />
                    </td>
                    <td>
                      <v-currency-field
                        :prefix="
                          getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                        "
                        filled
                        readonly
                        :value="
                          lasuma(
                            localidadesevento[index].precio_venta,
                            localidadesevento[index].precio_servicio
                          )
                        "
                      />
                    </td>
                    <!-- <td>
                      <v-autocomplete
                        v-model="localidad.codigo_moneda"
                        :rules="[(v) => !!v || 'Moneda de la Localidad es Requerido']"
                        :items="monedas"
                        item-text="descripcion"
                        item-value="codigo_moneda"
                        label="Código de Moneda"
                        placeholder="Seleccionar..."
                        no-data-text="No se encontraron resultados"
                        required
                      ></v-autocomplete>
                    </td> -->
                    <td>
                      <v-layout text-center>
                        <v-flex lg6 md6 sm6 xs12>
                          <label
                            style="cursor: pointer"
                            title="Seleccionar una imagen para la localidad"
                          >
                            <i
                              class="material-icons"
                              style="font-size: 60px; color: #777777"
                              >add_a_photo</i
                            >
                            <input
                              type="file"
                              :id="'imagenLocalidad' + index"
                              @change="getImageLocalidad(index)"
                              accept="image/*"
                              style="display: none"
                              required
                            />
                          </label>
                        </v-flex>
                        <v-flex
                          lg6
                          md6
                          sm6
                          xs12
                          v-if="
                            localidadesevento[index].imagen != '' &&
                            localidadesevento[index].imagen
                          "
                        >
                          <img
                            :src="localidadesevento[index].imagen"
                            width="62px"
                            height="100px"
                          />
                          <!-- <p>Tamaño de la imagen: {{ sizeImage }}</p>
                          <br>
                          <p>Su Ancho: {{ sizeImageWidth }} y Alto: {{ sizeImageHeight }}</p> -->
                        </v-flex>
                        <v-flex
                          lg6
                          md6
                          sm6
                          xs12
                          v-if="
                            !(
                              localidadesevento[index].imagen != '' &&
                              localidadesevento[index].imagen
                            ) &&
                            localidadesevento[index].url_imagen &&
                            localidadesevento[index].url_imagen != ''
                          "
                          width="62px"
                          height="100px"
                        >
                          <img
                            :src="localidadesevento[index].url_imagen"
                            width="62px"
                            height="100px"
                          />
                          <!-- <p>Tamaño de la imagen: {{ sizeImage }}</p>
                          <br>
                          <p>Su Ancho: {{ sizeImageWidth }} y Alto: {{ sizeImageHeight }}</p> -->
                        </v-flex>
                      </v-layout>
                    </td>
                    <td>
                      <v-btn
                        icon
                        v-if="localidad.id == ''"
                        title="Remover localidad"
                        small
                        color="red"
                        @click="removeLocalidad(index)"
                      >
                        <v-icon small color="white">remove</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="mb-4"
              v-if="modalPlantilla == 'new' || modalPlantilla == 'edit'"
            >
              <v-btn color="primary" @click="newLocalidad"
                >Agregar localidad</v-btn
              >
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateSevenTab">
          <v-form ref="ruleForm7">
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Localidades y precios</h4>
              <v-data-table
                :headers="resumheadersLocalidad"
                :items="localidades"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="localidad">
                  <td>{{ localidad.item.id }}</td>
                  <td>{{ getnomtribuna(localidad.item.id_tribuna) }}</td>
                  <td>{{ localidad.item.nombre }}</td>
                  <td>{{ localidad.item.puerta_acceso }}</td>
                  <td>{{ localidad.item.aforo }}</td>
                  <td v-if="localidad.item.silleteria == 1">SI</td>
                  <td v-else>NO</td>
                  <td v-if="localidad.item.palco == 1">SI</td>
                  <td v-else>NO</td>
                  <td>{{ localidad.item.puestosxpalco }}</td>
                  <!-- <td><v-btn small color="primary" @click="addPuestoLocalidad(localidad.item.id)"></v-btn></td> -->
                  <td>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="blue"
                      @click="
                        addPuestoLocalidad(localidad.item.id, localidad.index)
                      "
                    >
                      Ir
                    </v-btn>
                    <v-icon
                      :id="'showsemaforo' + localidad.index"
                      style="display: none"
                      color="green"
                      dark
                      small
                    >
                      done
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </div>

            <br id="puestos" />

            <div v-if="mostrarPuestos == true">
              <Puestos
                :editable="modalPlantilla == 'new' || modalPlantilla == 'edit'"
                :idlocalidad="idLocalidad"
              ></Puestos>
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateEightTab">
          <v-form ref="ruleForm8" v-if="auditorio_mapeado != null">
            <!-- <v-alert
                v-if="(modalPlantilla=='new' || modalPlantilla=='edit') && auditorio_mapeado!=null"
                :value="true"
                color="info"
                icon="info"
                outline
              > -->
            <v-alert
              v-if="
                !auditorio_mapeado.area_mapeada.includes(
                  '/mis-eventos/puestos_localidad/'
                )
              "
              :value="true"
              color="info"
              icon="info"
              outline
            >
              Por favor agregue la id de las localidades en la
              <span>forma 5</span>.
              <br />
              <span>Instrucciones:</span>
              <br />
              en href agregue el id de la localidad
            </v-alert>
            <!-- <center>                
                <v-layout>
                  <v-flex>
                    <v-card>
                      <v-container grid-list-sm fluid>
                        <v-layout row wrap>
                          <v-flex
                            xs6
                            d-flex
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                src="/images/imagenmapeada1.PNG"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-layout
                                    fill-height
                                    align-center
                                    justify-center
                                    ma-0
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-layout>
                                </template>
                              </v-img>
                            </v-card>
                          </v-flex>
                          <v-flex
                            xs6
                            d-flex
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                src="/images/imagenmapeada2.PNG"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-layout
                                    fill-height
                                    align-center
                                    justify-center
                                    ma-0
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-layout>
                                </template>
                              </v-img>
                            </v-card>
                          </v-flex>
                          <v-flex
                            xs6
                            d-flex
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                src="/images/imagenmapeada3.PNG"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-layout
                                    fill-height
                                    align-center
                                    justify-center
                                    ma-0
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-layout>
                                </template>
                              </v-img>
                            </v-card>
                          </v-flex>
                          <v-flex
                            xs6
                            d-flex
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                src="/images/imagenmapeada4.PNG"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-layout
                                    fill-height
                                    align-center
                                    justify-center
                                    ma-0
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-layout>
                                </template>
                              </v-img>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-flex>
                </v-layout>
              </center> -->
            <!-- <center v-if="(modalPlantilla=='new' || modalPlantilla=='edit') && auditorio_mapeado!=null"> -->
            <center v-if="auditorio_mapeado != null">
              <v-alert :value="true" color="info" icon="info" outline>
                Validación
              </v-alert>
              <div class="ValidaAuditorioMapeado">
                <img :src="auditorio_mapeado.imagen" usemap="#map2" />
              </div>
              <br id="boletas" />
              <BoletasxLocalidad
                :pagos="false"
                :fisico="false"
                v-if="idLocalidad2 != ''"
                :idlocalidad="idLocalidad2"
                :idEvento="0"
              ></BoletasxLocalidad>
            </center>
            <!-- <div id="area_mapeadaAuditorio2" v-if="(modalPlantilla=='new' || modalPlantilla=='edit') && auditorio_mapeado!=null"></div> -->
            <div
              id="area_mapeadaAuditorio2"
              v-if="auditorio_mapeado != null"
            ></div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateNineTab">
          <h3>Preventas</h3>
          <v-layout row wrap>
            <v-flex lg6 md6 sm6 xs12 mb-3 mt-4>
              <h6>
                Registre los rangos de fechas para la preventas con sus
                porcentajes de descuentos.
              </h6>
            </v-flex>
            <v-flex lg6 md6 sm6 xs12 mb-4 text-right>
              <v-dialog v-model="modalPreventa" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark v-on="on">Nueva preventa</v-btn>
                </template>
                <form @submit.prevent="savePreventa">
                  <v-card>
                    <v-card-title class="text-center">
                      <span class="headline">Nueva Preventa</span>
                    </v-card-title>
                    <v-container text-center>
                      <div class="form-group">
                        <v-text-field
                          required
                          placeholder="Nombre de Preventa"
                          type="text"
                          label="Nombre de Preventa"
                          v-model="newPreventa.nombre"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-switch
                          label="Evento"
                          v-model="newPreventa.evento"
                          :rules="[
                            () =>
                              !(
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.id_localidad) &&
                                  !!newPreventa.evento) ||
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_localidad) ||
                                ((!!newPreventa.id_localidad ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_tribuna)
                              ) ||
                              'Seleccionar una sola opción entre evento, tribuna y localidad',
                          ]"
                        ></v-switch>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          label="Tribuna"
                          v-model="newPreventa.id_tribuna"
                          v-if="!newPreventa.evento"
                          no-data-text="No se encontraron resultados"
                          :items="tribunaslocalidad"
                          item-text="nombre"
                          item-value="id"
                          :rules="[
                            () =>
                              !(
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.id_localidad) &&
                                  !!newPreventa.evento) ||
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_localidad) ||
                                ((!!newPreventa.id_localidad ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_tribuna)
                              ) ||
                              'Seleccionar una sola opción entre evento, tribuna y localidad',
                          ]"
                          clearable
                        ></v-autocomplete>
                      </div>
                      <div class="form-group">
                        <v-autocomplete
                          label="Localidad"
                          v-model="newPreventa.id_localidad"
                          v-if="!newPreventa.evento"
                          no-data-text="No se encontraron resultados"
                          :items="localidades"
                          item-text="nombre"
                          item-value="id"
                          :rules="[
                            () =>
                              !(
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.id_localidad) &&
                                  !!newPreventa.evento) ||
                                ((!!newPreventa.id_tribuna ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_localidad) ||
                                ((!!newPreventa.id_localidad ||
                                  !!newPreventa.evento) &&
                                  !!newPreventa.id_tribuna)
                              ) ||
                              'Seleccionar una sola opción entre evento, tribuna y localidad',
                          ]"
                          clearable
                        ></v-autocomplete>
                      </div>
                      <div class="form-group">
                        <v-layout row wrap>
                          <v-flex lg6 md6 sm6 xs12>
                            <v-dialog
                              ref="dialogFechaInicioPreventaEvento"
                              v-model="modalFechaInicioPreventaEvento"
                              :return-value.sync="newPreventa.fecha_inicio"
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="newPreventa.fecha_inicio"
                                  label="Fecha Inicial de la preventa del evento"
                                  prepend-icon="event"
                                  readonly
                                  :rules="[
                                    (v) => !!v || 'Fecha Inicial es Requerido',
                                  ]"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="newPreventa.fecha_inicio"
                                scrollable
                                locale="es-ES"
                                :min="hoy"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="
                                    modalFechaInicioPreventaEvento = false
                                  "
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="
                                    $refs.dialogFechaInicioPreventaEvento.save(
                                      newPreventa.fecha_inicio
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex lg6 md6 sm6 xs12>
                            <v-dialog
                              ref="dialogHoraInicioPreventaEvento"
                              v-model="modalHoraInicioPreventaEvento"
                              :return-value.sync="newPreventa.hora_inicio"
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="newPreventa.hora_inicio"
                                  label="Hora de inicio"
                                  prepend-icon="access_time"
                                  readonly
                                  :rules="[
                                    (v) => !!v || 'Hora Inicial es Requerido',
                                  ]"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modalHoraInicioPreventaEvento"
                                v-model="newPreventa.hora_inicio"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="modalHoraInicioPreventaEvento = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="
                                    $refs.dialogHoraInicioPreventaEvento.save(
                                      newPreventa.hora_inicio
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-time-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="form-group">
                        <v-layout row wrap>
                          <v-flex lg6 md6 sm6 xs12>
                            <v-dialog
                              ref="dialogFechaFinPreventaEvento"
                              v-model="modalFechaFinPreventaEvento"
                              :return-value.sync="newPreventa.fecha_fin"
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="newPreventa.fecha_fin"
                                  label="Fecha Final del preventa del evento"
                                  prepend-icon="event"
                                  readonly
                                  :rules="[
                                    (v) => !!v || 'Fecha Final es Requerido',
                                  ]"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="newPreventa.fecha_fin"
                                scrollable
                                locale="es-ES"
                                :min="hoy"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="modalFechaFinPreventaEvento = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="
                                    $refs.dialogFechaFinPreventaEvento.save(
                                      newPreventa.fecha_fin
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex lg6 md6 sm6 xs12>
                            <v-dialog
                              ref="dialogHoraFinPreventaEvento"
                              v-model="modalHoraFinPreventaEvento"
                              :return-value.sync="newPreventa.hora_fin"
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="newPreventa.hora_fin"
                                  label="Hora final"
                                  prepend-icon="access_time"
                                  readonly
                                  :rules="[
                                    (v) => !!v || 'Hora Final es Requerido',
                                  ]"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modalHoraFinPreventaEvento"
                                v-model="newPreventa.hora_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="modalHoraFinPreventaEvento = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="
                                    $refs.dialogHoraFinPreventaEvento.save(
                                      newPreventa.hora_fin
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-time-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="form-group">
                        <v-text-field
                          placeholder="Porcentaje de Descuento en Servicio"
                          suffix="%"
                          type="number"
                          label="Porcentaje de Descuento en Servicio"
                          v-model="newPreventa.porcentaje_descuento_servicio"
                          :rules="[
                            () =>
                              !(
                                !!newPreventa.porcentaje_descuento_servicio &&
                                !!newPreventa.descuento_fijo_servicio
                              ) ||
                              'Seleccionar una sola opción entre Porcentaje de Descuento en Servicio y Valor Fijo en Servicio',
                          ]"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-currency-field
                          label="Valor Fijo en Servicio"
                          placeholder="Valor Fijo en Servicio"
                          :prefix="
                            getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                          "
                          filled
                          :rules="[
                            () =>
                              !(
                                !!newPreventa.porcentaje_descuento_servicio &&
                                !!newPreventa.descuento_fijo_servicio
                              ) ||
                              'Seleccionar una sola opción entre Porcentaje de Descuento en Servicio y Valor Fijo en Servicio',
                          ]"
                          v-model="newPreventa.descuento_fijo_servicio"
                        />
                      </div>
                      <div class="form-group">
                        <v-text-field
                          placeholder="Porcentaje de Descuento en Precio"
                          suffix="%"
                          type="number"
                          label="Porcentaje de Descuento en Precio"
                          v-model="newPreventa.porcentaje_descuento_precio"
                          :rules="[
                            () =>
                              !(
                                !!newPreventa.porcentaje_descuento_precio &&
                                !!newPreventa.descuento_fijo_precio
                              ) ||
                              'Seleccionar una sola opción entre Porcentaje de Descuento en Precio y Valor Fijo en Precio',
                          ]"
                        ></v-text-field>
                      </div>
                      <div class="form-group">
                        <v-currency-field
                          label="Valor Fijo en Precio"
                          placeholder="Valor Fijo en Precio"
                          :prefix="
                            getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                          "
                          filled
                          :rules="[
                            () =>
                              !(
                                !!newPreventa.porcentaje_descuento_precio &&
                                !!newPreventa.descuento_fijo_precio
                              ) ||
                              'Seleccionar una sola opción entre Porcentaje de Descuento en Precio y Valor Fijo en Precio',
                          ]"
                          v-model="newPreventa.descuento_fijo_precio"
                        />
                      </div>
                    </v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="default" text @click="modalPreventa = false"
                        >Cerrar</v-btn
                      >
                      <v-btn color="success" type="submit" text>Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </form>
              </v-dialog>
            </v-flex>
          </v-layout>
          <v-form ref="ruleForm9">
            <div class="p-3 bg-white">
              <v-data-table
                :headers="headersPreventa"
                :items="preventasEvento"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="preventa">
                  <td>{{ preventa.item.nombre }}</td>
                  <td>{{ preventa.item.evento ? "SI" : "NO" }}</td>
                  <td>{{ getnomtribuna(preventa.item.id_tribuna) }}</td>
                  <td>{{ getnomlocalidad(preventa.item.id_localidad) }}</td>
                  <td>{{ preventa.item.fecha_inicio }}</td>
                  <td>{{ preventa.item.hora_inicio }}</td>
                  <td>{{ preventa.item.fecha_fin }}</td>
                  <td>{{ preventa.item.hora_fin }}</td>
                  <td>
                    {{ preventa.item.porcentaje_descuento_servicio + "%" }}
                  </td>
                  <td>
                    {{
                      getSimboloMoneda(evento.codigo_moneda.codigo_moneda) +
                      formatoMoneda(preventa.item.descuento_fijo_servicio)
                    }}
                  </td>
                  <td>{{ preventa.item.porcentaje_descuento_precio + "%" }}</td>
                  <td>
                    {{
                      getSimboloMoneda(evento.codigo_moneda.codigo_moneda) +
                      formatoMoneda(preventa.item.descuento_fijo_precio)
                    }}
                  </td>
                  <td>
                    <v-btn
                      icon
                      title="Remover Preventa"
                      small
                      color="red"
                      @click="removePreventa(preventa.index)"
                    >
                      <v-icon small color="white">remove</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateTenTab">
          <v-form ref="ruleFormNewPuntoVenta">
            <h3>Puntos de venta</h3>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-4>
                <h6>
                  Indique los puntos de venta asociados al evento. Cada uno de
                  ellos recibira un correo electronico por medio del cual podra
                  gestionar sus ventas.
                </h6>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-4 text-right>
                <v-dialog v-model="modalPuntoVenta" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on"
                      >Nuevo punto de venta</v-btn
                    >
                  </template>
                  <form @submit.prevent="savePuntoVenta">
                    <v-card>
                      <v-card-title class="text-center">
                        <span class="headline">Nuevo Punto de venta</span>
                      </v-card-title>
                      <v-container text-center>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Nombre comercial"
                            label="Nombre comercial"
                            v-model="newPuntoventa.nombre_razon"
                            :rules="[
                              (v) => !!v || 'Nombre comercial es requerido',
                            ]"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            label="Tipo de Identificacion"
                            no-data-text="No se encontraron resultados"
                            v-model="newPuntoventa.tipo_identificacion"
                            :items="tipoidentificacion"
                            item-text="descripcion"
                            item-value="id"
                            :rules="[
                              (v) =>
                                !!v || 'Tipo de Identificacion es requerido',
                            ]"
                          ></v-autocomplete>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            type="text"
                            placeholder="Identificación"
                            label="Identificación"
                            v-model="newPuntoventa.identificacion"
                            :rules="[
                              (v) => !!v || 'Identificación es requerido',
                            ]"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Dirección"
                            label="Dirección"
                            v-model="newPuntoventa.direccion"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Telefono"
                            type="tel"
                            label="Telefono"
                            v-model="newPuntoventa.telefono"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Responsable"
                            label="Responsable"
                            v-model="newPuntoventa.responsable"
                            :rules="[(v) => !!v || 'Responsable es requerido']"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Zona"
                            label="Zona"
                            v-model="newPuntoventa.zona"
                            :rules="[(v) => !!v || 'Zona es requerido']"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-text-field
                            placeholder="Correo electronico"
                            type="email"
                            label="Correo electronico"
                            v-model="newPuntoventa.email"
                            :rules="[
                              (v) =>
                                !!v || 'Email del Punto de Venta es Requerido',
                              (v) =>
                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                  v
                                ) ||
                                'E-mail del Punto de Venta debe ser Valido',
                            ]"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            ref="ciudad"
                            v-model="newPuntoventa.id_ciudad"
                            :rules="[(v) => !!v || 'Ciudad es requerido']"
                            :items="ciudades"
                            item-text="descripcion"
                            item-value="id"
                            label="Ciudad"
                            placeholder="Seleccionar..."
                            no-data-text="No se encontraron resultados"
                          ></v-autocomplete>
                        </div>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="default"
                          text
                          @click="modalPuntoVenta = false"
                          >Cerrar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-form>
          <v-form ref="ruleForm10">
            <div class="p-3 bg-white">
              <v-text-field
                solo
                label="Buscar"
                prepend-inner-icon="search"
                class="search"
                v-model="keyPuntoventas"
              ></v-text-field>
            </div>
            <!-- <div class="pa-2 bg-white cont-condiciones"> -->
            <v-data-table
              v-model="selectedPuntoventas"
              :headers="headersPuntoventa"
              :items="puntoventas"
              item-key="id"
              :search="keyPuntoventas"
              select-all
              class="elevation-1"
              rows-per-page-text="Filas por Pag."
              :rows-per-page-items="rowperpageitems"
            >
              <template v-slot:items="puntoventa">
                <td>
                  <v-checkbox
                    v-model="puntoventa.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ puntoventa.item.nombre_razon }}</td>
                <td>{{ puntoventa.item.zona }}</td>
                <td>{{ puntoventa.item.ciudades.descripcion }}</td>
                <td v-if="puntoventa.item.ciudades">
                  {{
                    getDepartamento(puntoventa.item.ciudades.id_departamento)
                  }}
                </td>
                <td>{{ puntoventa.item.responsable }}</td>
              </template>
            </v-data-table>
            <!-- </div> -->
            <v-layout row wrap v-show="selectedPuntoventas.length != 0">
              <v-dialog
                ref="dialogfecha_inicio_venta_puntos"
                v-model="modalfecha_inicio_venta_puntos"
                :return-value.sync="evento.fecha_inicio_venta_puntos"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Fecha de inicio de venta en puntos"
                    v-model="evento.fecha_inicio_venta_puntos"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    :rules="
                      selectedPuntoventas.length == 0
                        ? [true]
                        : [
                            () =>
                              !!'fecha_inicio_venta_puntos' ||
                              'Fecha de inicio de venta en puntos es requerido',
                          ]
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="evento.fecha_inicio_venta_puntos"
                  scrollable
                  locale="es-ES"
                  :min="hoy"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="primary"
                    @click="modalfecha_inicio_venta_puntos = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      $refs.dialogfecha_inicio_venta_puntos.save(
                        evento.fecha_inicio_venta_puntos
                      )
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="dialoghora_inicio_venta_puntos"
                v-model="modalhora_inicio_venta_puntos"
                :return-value.sync="evento.hora_inicio_venta_puntos"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="evento.hora_inicio_venta_puntos"
                    label="Hora de inicio"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalhora_inicio_venta_puntos"
                  v-model="evento.hora_inicio_venta_puntos"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="primary"
                    @click="modalhora_inicio_venta_puntos = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      $refs.dialoghora_inicio_venta_puntos.save(
                        evento.hora_inicio_venta_puntos
                      )
                    "
                    >OK</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </v-layout>
            <v-checkbox
              class="checkFilterCondicionesSolicitud mb-0 font-weight-bold"
              color="primary"
              label="El evento permite ventas en linea"
              v-model="evento.venta_linea"
            ></v-checkbox>
            <v-layout row wrap v-show="evento.venta_linea">
              <v-dialog
                ref="dialogfecha_inicio_venta_internet"
                v-model="modalfecha_inicio_venta_internet"
                :return-value.sync="evento.fecha_inicio_venta_internet"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Fecha de inicio de venta en internet"
                    v-model="evento.fecha_inicio_venta_internet"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    :rules="
                      evento.venta_linea
                        ? [
                            () =>
                              !!'fecha_inicio_venta_internet' ||
                              'Fecha de inicio de venta en internet es requerido',
                          ]
                        : [true]
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="evento.fecha_inicio_venta_internet"
                  scrollable
                  locale="es-ES"
                  :min="hoy"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="primary"
                    @click="modalfecha_inicio_venta_internet = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      $refs.dialogfecha_inicio_venta_internet.save(
                        evento.fecha_inicio_venta_internet
                      )
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="dialoghora_inicio_venta_internet"
                v-model="modalhora_inicio_venta_internet"
                :return-value.sync="evento.hora_inicio_venta_internet"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="evento.hora_inicio_venta_internet"
                    label="Hora de inicio"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalhora_inicio_venta_internet"
                  v-model="evento.hora_inicio_venta_internet"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="primary"
                    @click="modalhora_inicio_venta_internet = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    flat
                    color="primary"
                    @click="
                      $refs.dialoghora_inicio_venta_internet.save(
                        evento.hora_inicio_venta_internet
                      )
                    "
                    >OK</v-btn
                  >
                </v-time-picker>
              </v-dialog>
            </v-layout>
            <v-checkbox
              class="checkFilterCondicionesSolicitud mb-0 font-weight-bold"
              color="primary"
              label="El evento permite entregas a domicilio"
              v-model="evento.domicilios"
            ></v-checkbox>
            <v-layout row wrap>
              <v-text-field
                lg3
                md3
                sm3
                xs6
                label="Boletas o Palcos máximo al día"
                v-model="evento.cant_max_boletas"
                type="number"
              >
              </v-text-field>
            </v-layout>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content :before-change="validateIlevenTab">
          <v-form ref="ruleForm11">
            <h3 class="text-center mb-4">Costos asociados al evento</h3>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3 mt-2>
                <h6>
                  Por favor indique los costos asociados al evento en cada uno
                  de los conceptos que resulten pertinentes.
                </h6>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-3 text-right>
                <v-dialog v-model="modalTipocosto" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on"
                      >Nuevo Tipo de Costo</v-btn
                    >
                  </template>
                  <form @submit.prevent="saveTipoCosto">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Nuevo Tipo de Costo</span>
                      </v-card-title>
                      <v-container>
                        <v-textarea
                          required
                          solo
                          name="nameTipocosto"
                          v-model="nameTipocosto"
                          label="Escribe aqui el tipo de costo que desees agregar..."
                          autofocus
                        ></v-textarea>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="default"
                          text
                          @click="modalTipocosto = false"
                          >Cancelar</v-btn
                        >
                        <v-btn color="success" type="submit" text
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </form>
                </v-dialog>
              </v-flex>
            </v-layout>
            <div
              style="overflow-x: auto"
              class="p-5 text-center bg-white mb-4 mt-3"
            >
              <table class="table table-striped table-hover mt-4">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Descripcion</th>
                    <th>Monto</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(costo, index) in costos" :key="index">
                    <td>
                      <v-autocomplete
                        ref="tipocosto"
                        v-model="costos[index].id_tipo_costo"
                        :rules="[
                          () => !!'tipocosto' || 'Este Campo es requerido',
                        ]"
                        :items="tipocostos"
                        item-text="descripcion"
                        item-value="id"
                        label="Tipo de Costo"
                        placeholder="Seleccionar..."
                        no-data-text="No se encontraron resultados"
                        required
                        @change="updateCostos(index)"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-text-field
                        v-model="costos[index].descripcion"
                        label="Descripción"
                        required
                        @change="updateCostos(index)"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-currency-field
                        label="Valor"
                        :prefix="
                          getSimboloMoneda(evento.codigo_moneda.codigo_moneda)
                        "
                        filled
                        required
                        @change="updateCostos(index)"
                        v-model="costos[index].valor"
                      />
                    </td>
                    <td>
                      <v-btn
                        icon
                        title="Remover costo"
                        small
                        color="red"
                        @click="removeCosto(index)"
                      >
                        <v-icon small color="white">remove</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-4">
              <v-btn color="primary" @click="newCosto">Agregar costo</v-btn>
            </div>
            <v-layout row wrap>
              <v-flex lg4 md4></v-flex>
              <v-flex lg8 md8 text-right mb-5>
                <v-currency-field
                  label="Minimo Garantizado"
                  :prefix="getSimboloMoneda(evento.codigo_moneda.codigo_moneda)"
                  filled
                  required
                  placeholder="Indique el minimo garantizado para la realizacion del evento ($)"
                  v-model="evento.monto_minimo"
                />
              </v-flex>
            </v-layout>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
        <tab-content
          title="Confirmar solicitud"
          icon="ti-receipt"
          :before-change="validateTwelveTab"
        >
          <v-form ref="ruleForm12">
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-4>
                <h3>Resumen de solicitud de evento</h3>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-4 text-right>
                <div class="alert alert-primary">
                  <h6 class="text-left">
                    <i class="ti-eye"></i> Por favor confirme toda la
                    informacion antes de enviarla
                  </h6>
                </div>
              </v-flex>
            </v-layout>
            <h3 class="text-center mb-4">Datos Generales</h3>
            <v-text-field label="Evento" v-model="evento.nombre">
            </v-text-field>
            <v-textarea
              name="descripcionEvento"
              label="Descripción del evento"
              required
              placeholder="Indique la Descripción del evento tal como será mostrado publicamente"
              v-model="evento.descripcion"
              readonly
              hint="Hint text"
            ></v-textarea>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12>
                <v-text-field
                  v-model="evento.fecha_evento"
                  label="Fecha de Inicio del Evento"
                  prepend-icon="access_time"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12>
                <v-text-field
                  v-model="evento.fecha_finalizacion_evento"
                  label="Fecha Final del Evento"
                  prepend-icon="access_time"
                  readonly
                ></v-text-field>
              </v-flex>
              <!-- <v-flex lg4 md4 sm4 xs5 class="pl-3">
                    <v-checkbox color="primary" readonly label="El evento es de un solo dia" v-model="evento.eventoUnSoloDia"></v-checkbox>
                  </v-flex> -->
            </v-layout>
            <v-layout>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  v-if="evento.auditorioevento"
                  label="Lugar del evento"
                  v-model="evento.auditorioevento.nombre"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  v-if="evento.temporada"
                  label="Temporada"
                  v-model="evento.temporada.nombre"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  label="Nombre del Lugar del Evento"
                  v-model="auditorioDatosnombre"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  label="Dirección del Lugar del Evento"
                  v-model="auditorioDatosdireccion"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  label="Departamento"
                  readonly
                  v-if="auditorioDatosid_departamento"
                  :value="getDepartamento(auditorioDatosid_departamento)"
                ></v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  label="Ciudad"
                  readonly
                  v-if="auditorioDatosid_ciudad"
                  :value="getCiudad(auditorioDatosid_ciudad)"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg4 md4 sm4 xs12 class="pr-4">
                <v-text-field
                  label="Longitud"
                  v-model="auditorioDatoslongitud"
                  readonly
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex lg4 md4 sm4 xs12 class="pr-4">
                <v-text-field
                  label="Latitud"
                  v-model="auditorioDatoslatitud"
                  readonly
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex lg4 md4 sm4 xs12 class="pr-4">
                <v-text-field
                  label="Aforo"
                  v-model="auditorioDatosaforo"
                  readonly
                  type="number"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- <v-text-field 
                 label="Dirección"
                 v-model="evento.direccion" 
                 readonly>                   
                 </v-text-field>
                 <v-layout row wrap >
                    <v-flex lg6 md6 sm6 xs12 class="pr-4">
                    <v-text-field
                      v-if="evento.departamento"
                      label="Departamento"
                      v-model="evento.departamento.descripcion"
                      readonly
                    ></v-text-field>
                    </v-flex>
                    <v-flex lg6 md6 sm6 xs12 class="pr-4">
                    <v-text-field
                      v-if="evento.ciudad"
                      label="Ciudad"
                      v-model="evento.ciudad.descripcion"
                      readonly
                      >
                    </v-text-field>
                  </v-flex>
                 </v-layout> -->
            <v-layout row wrap>
              <v-flex lg4 md4 sm4 xs12>
                <v-text-field
                  v-model="evento.hora_inicio"
                  label="Hora de inicio"
                  prepend-icon="access_time"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg4 md4 sm4 xs12>
                <v-text-field
                  v-model="evento.hora_apertura"
                  label="Apertura de puertas"
                  prepend-icon="access_time"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg4 md4 sm4 xs12>
                <v-text-field
                  v-model="evento.hora_finalizacion"
                  label="Hora de finalizacion"
                  prepend-icon="access_time"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg3 md3 sm3 xs12 class="pr-4">
                <v-autocomplete
                  label="Tipo de evento"
                  :items="tiposEventos"
                  v-model="evento.id_tipo_evento"
                  item-text="nombre"
                  item-value="id"
                  no-data-text="No se encontraron resultados"
                  readonly
                ></v-autocomplete>
              </v-flex>
              <v-flex lg3 md3 sm3 xs12>
                <v-text-field
                  required
                  v-model="evento.codigo_pulep"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg3 md3 sm3 xs12>
                <v-text-field
                  required
                  :value="getMoneda(evento.codigo_moneda)"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-spacer></v-spacer>
            <h3 class="text-center mb-4 mt-4">Responsable del evento</h3>
            <v-layout row wrap>
              <v-flex lg8 md8 sm8 xs12>
                <v-text-field
                  label="Responsable"
                  v-model="responsableEventonombreRazonSocial"
                  readonly
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg7 md7 sm7 xs12 class="pr-3">
                <v-text-field
                  label="Identificación"
                  v-model="responsableEventoIdentificacion"
                  readonly
                >
                </v-text-field>
              </v-flex>
              <v-flex lg2 md2 sm2 xs12 class="pr-3">
                <v-text-field
                  required
                  placeholder="TIPO"
                  v-if="cliente == null && responsableEventotipoIdentificacion"
                  v-model="responsableEventotipoIdentificacion.descripcion"
                  readonly
                ></v-text-field>
                <v-text-field
                  required
                  placeholder="TIPO"
                  v-if="cliente != null"
                  :value="
                    responsableEventotipoIdentificacion == 1
                      ? 'CC'
                      : responsableEventotipoIdentificacion == 2
                      ? 'CE'
                      : responsableEventotipoIdentificacion == 3
                      ? 'PA'
                      : ''
                  "
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex lg5 md5 sm5 xs12>
                <v-radio-group
                  v-model="responsableEventotipoCliente"
                  row
                  readonly
                >
                  <v-radio
                    label="Entidad Juridica"
                    :value="true"
                    color="primary"
                  ></v-radio>
                  <v-radio
                    label="Persona Natural"
                    :value="false"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-text-field
              label="Dirección"
              readonly
              v-model="responsableEventodireccion"
            >
            </v-text-field>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  readonly
                  label="Departamento"
                  v-model="responsableEventodepartamento.descripcion"
                  v-if="responsableEventodepartamento"
                ></v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 class="pr-4">
                <v-text-field
                  readonly
                  label="Ciudad"
                  v-model="responsableEventociudad.descripcion"
                  v-if="responsableEventociudad"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 pa-2>
                <v-text-field
                  label="email"
                  v-model="responsableEventoemail"
                  readonly
                >
                </v-text-field>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 pa-2>
                <v-text-field
                  label="Telefono"
                  v-model="responsableEventotelefono"
                  readonly
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de Auditorio</h4>
              <center v-if="auditorio_mapeado != null">
                <div class="ValidaAuditorioMapeado">
                  <img :src="auditorio_mapeado.imagen" usemap="#map3" />
                </div>
                <br id="boletas2" />
                <BoletasxLocalidad
                  :pagos="false"
                  :fisico="false"
                  v-if="idLocalidad2 != ''"
                  :idlocalidad="idLocalidad2"
                  :idEvento="0"
                ></BoletasxLocalidad>
              </center>
              <div
                id="area_mapeadaAuditorio3"
                v-if="auditorio_mapeado != null"
              ></div>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de imagenes del evento</h4>
              <div class="p-3 bg-white">
                <v-container grid-list-md text-xs-center>
                  <v-layout row wrap>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Carrusel</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('carousel')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoCarousel"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Twitter</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('twitter')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoTwitter"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Diseño Boleta</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('disenoboleta')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoDisenoBoleta"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Istagram Stories</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('instagstories')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoInstagStories"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Facebook</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('facebook')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoFacebook"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Vertical</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('vertical')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(eventoImagen, index) in imagenesEventoVert"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Miniatura</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('miniatura')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoMiniatura"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                    <v-flex lg3 md3 sm3 xs3 mb-3 text-center>
                      <v-btn color="primary" dark>Banner</v-btn>
                      <v-item-group multiple>
                        <v-layout row wrap text-center>
                          <v-flex
                            v-for="eventoImagen in eventoimagenes"
                            v-if="eventoImagen.nombre.includes('banner')"
                            :key="eventoimagenes.id"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.url"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.url"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                          <v-flex
                            v-for="(
                              eventoImagen, index
                            ) in imagenesEventoBanner"
                            :key="index"
                            lg12
                            md12
                            sm12
                            xs12
                            class="mb-3"
                          >
                            <v-item
                              :value="eventoImagen.imagen"
                              class="checkFilterImagenesEventoCarouselSolicitud"
                            >
                              <v-img
                                :src="eventoImagen.imagen"
                                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                                height="150"
                                class="text-right pa-2"
                              >
                              </v-img>
                            </v-item>
                          </v-flex>
                        </v-layout>
                      </v-item-group>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h3 class="mt-4 mb-3">Condiciones de admision y restricciones</h3>
              <v-data-table
                :headers="resumheadersCondicion"
                :items="selectedCondiciones"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="condicion">
                  <td>{{ condicion.item.descripcion }}</td>
                </template>
              </v-data-table>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h3 class="mt-3 mb-3">Artistas principales</h3>
              <v-data-table
                :headers="resumheadersArtista"
                :items="artistasShow2"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="artista">
                  <td>{{ artista.item.nombre }}</td>
                  <td>
                    <center>
                      <v-img
                        :src="
                          artista.item.imagens[0] == null
                            ? ''
                            : artista.item.imagens[0].url
                        "
                        lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                        height="100%"
                        class="text-right pa-2"
                      >
                      </v-img>
                    </center>
                  </td>
                </template>
              </v-data-table>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de localidades y precios</h4>
              <v-data-table
                :headers="resumheadersLocalidad2"
                :items="localidades"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="localidad">
                  <td>{{ localidad.item.id }}</td>
                  <td>{{ getnomtribuna(localidad.item.id_tribuna) }}</td>
                  <td>{{ localidad.item.nombre }}</td>
                  <td>{{ localidad.item.puerta_acceso }}</td>
                  <td>{{ localidad.item.aforo }}</td>
                  <td v-if="localidad.item.silleteria == 1">SI</td>
                  <td v-else>NO</td>
                  <td v-if="localidad.item.palco == 1">SI</td>
                  <td v-else>NO</td>
                  <td>{{ localidad.item.puestosxpalco }}</td>
                  <!-- <td><v-btn small color="primary" @click="addPuestoLocalidad(localidad.item.id)"></v-btn></td> -->
                </template>
              </v-data-table>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de preventas</h4>
              <v-data-table
                :headers="headersPreventa"
                :items="preventasEvento"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="preventa">
                  <td>{{ preventa.item.nombre }}</td>
                  <td>{{ preventa.item.evento ? "SI" : "NO" }}</td>
                  <td>{{ getnomtribuna(preventa.item.id_tribuna) }}</td>
                  <td>{{ getnomlocalidad(preventa.item.id_localidad) }}</td>
                  <td>{{ preventa.item.fecha_inicio }}</td>
                  <td>{{ preventa.item.hora_inicio }}</td>
                  <td>{{ preventa.item.fecha_fin }}</td>
                  <td>{{ preventa.item.hora_fin }}</td>
                  <td>
                    {{ preventa.item.porcentaje_descuento_servicio + "%" }}
                  </td>
                  <td>
                    {{
                      getSimboloMoneda(evento.codigo_moneda.codigo_moneda) +
                      formatoMoneda(preventa.item.descuento_fijo_servicio)
                    }}
                  </td>
                  <td>{{ preventa.item.porcentaje_descuento_precio + "%" }}</td>
                  <td>
                    {{
                      getSimboloMoneda(evento.codigo_moneda.codigo_moneda) +
                      formatoMoneda(preventa.item.descuento_fijo_precio)
                    }}
                  </td>
                </template>
              </v-data-table>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de puntos de venta</h4>
              <v-data-table
                :headers="resumheadersPuntoventa"
                :items="selectedPuntoventas"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="puntoventa">
                  <td>{{ puntoventa.item.nombre_razon }}</td>
                  <td>{{ puntoventa.item.zona }}</td>
                  <td v-if="puntoventa.item.id_ciudad">
                    {{ getCiudad(puntoventa.item.id_ciudad) }}
                  </td>
                  <td v-else>{{ puntoventa.item.ciudades.descripcion }}</td>
                  <td v-if="puntoventa.item.id_ciudad">
                    {{ getDepartamentoxCiudad(puntoventa.item.id_ciudad) }}
                  </td>
                  <td v-else></td>
                  <td>{{ puntoventa.item.responsable }}</td>
                </template>
              </v-data-table>
              <v-layout row wrap>
                <v-flex lg6 md6 sm6 xs12>
                  <v-text-field
                    v-show="selectedPuntoventas.length != 0"
                    label="Fecha de inicio de venta en puntos"
                    readonly
                    v-model="evento.fecha_inicio_venta_puntos"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex lg6 md6 sm6 xs12>
                  <v-text-field
                    v-show="selectedPuntoventas.length != 0"
                    label="Hora de inicio de venta en puntos"
                    readonly
                    v-model="evento.hora_inicio_venta_puntos"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 md6 sm6 xs12>
                  <v-text-field
                    v-show="evento.venta_linea"
                    label="Fecha de inicio de ventas en internet"
                    readonly
                    v-model="evento.fecha_inicio_venta_internet"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex lg6 md6 sm6 xs12>
                  <v-text-field
                    v-show="evento.venta_linea"
                    label="Hora de inicio de ventas en internet"
                    readonly
                    v-model="evento.hora_inicio_venta_internet"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg12 md12 sm12 xs12>
                  <v-text-field
                    label="Boletas o Palcos máximo al día"
                    readonly
                    v-model="evento.cant_max_boletas"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <div class="p-5 text-center bg-white mb-4 mt-3">
              <h4>Resumen de costos asociados al evento</h4>
              <v-data-table
                :headers="resumheadersCosto"
                :items="costos"
                item-key="id"
                class="elevation-1"
                rows-per-page-text="Filas por Pag."
                :rows-per-page-items="rowperpageitems"
              >
                <template v-slot:items="costo">
                  <td>
                    {{
                      costo.item.id_tipo_costo != ""
                        ? getTipocosto(costo.item.id_tipo_costo)
                        : ""
                    }}
                  </td>
                  <td>{{ costo.item.descripcion }}</td>
                  <td>
                    {{
                      costo.item.valor != null && costo.item.valor != ""
                        ? getSimboloMoneda(evento.codigo_moneda.codigo_moneda) +
                          formatoMoneda(costo.item.valor)
                        : ""
                    }}
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-form>
          <v-alert
            v-if="errorform.error"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            {{ errorform.msj }}
          </v-alert>
        </tab-content>
      </form-wizard>
    </v-container>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#907b16"
      @click="toTop"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-app>
</template>
<script>
import moment from "moment";
import { configApi } from "../api/configApi";
import Auditorio from "./utils/Escenario.vue";
import Puestos from "./utils/add_puestos.vue";
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import GmapMap from "vue2-google-maps";

moment.locale("es");

export default {
  components: {
    Auditorio,
    Puestos,
    BoletasxLocalidad,
    GmapMap,
  },
  async beforeCreate() {
    document.title = "Solicitud de evento - TicketShop";
    await this.$store.dispatch(
      "auditoriaevento/listAuditoriaeventosEvento",
      this.$route.params.id
    );
    await this.$store.dispatch("evento/getEvento", this.$route.params.id);
    //this.$store.dispatch('evento/getCondicionesEvento',this.$route.params.id);
    //this.$store.dispatch('evento/listPreventasEvento',this.$route.params.id);
    //this.$store.dispatch('evento/getImagenesEvento',this.$route.params.id);
    //this.$store.dispatch('evento/getpuntoventasEvento',this.$route.params.id);
    await this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
      this.$route.params.id
    );
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
      this.$route.params.id
    );
    this.$store.dispatch("tipoevento/listTipoEvento");
    this.$store.dispatch("auditorio/listAuditorios");
    this.$store.dispatch("cliente/listClientes");
    this.$store.dispatch("pais/listPaises");
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    this.$store.dispatch("temporada/listTemporadas");
    this.$store.dispatch("moneda/listMonedas");
  },
  async created() {
    if (this.evento) {
      this.preventas_old = this.evento.preventas;
      this.AuditorioExistente();
      this.DetallesAuditorio = true;
      this.viewCliente();
      this.DetallesResponsableEvento = true;
      this.eventoimagenes = this.evento.imagens;
      this.selectedCondiciones = this.evento.condiciones;
      for (var i = 0; i < this.evento.artists.length; i++) {
        this.artistasCheck.push(this.evento.artists[i].id);
        this.artistasCheckTemp.push(this.evento.artists[i].id);
      }
      await this.PlantillaAuditorio(this.evento.auditorio_mapeado);
      this.localidades.splice(0, this.localidades.length);
      this.localidadesevento.splice(0, this.localidadesevento.length);
      for (var i = 0; i < this.evento.auditorio_mapeado.tribunas.length; i++) {
        for (
          var j = 0;
          j < this.evento.auditorio_mapeado.tribunas[i].localidads.length;
          j++
        ) {
          this.localidades.push(
            this.evento.auditorio_mapeado.tribunas[i].localidads[j]
          );
          this.localidadesevento.push(
            this.evento.auditorio_mapeado.tribunas[i].localidads[j]
              .localidad_evento[0]
          );
        }
      }
      this.selectedPuntoventas.splice(0, this.selectedPuntoventas.length);
      for (var i = 0; i < this.evento.puntoventa_eventos.length; i++) {
        this.selectedPuntoventas.push(
          this.evento.puntoventa_eventos[i].punto_ventum
        );
      }
      this.preventasEvento.splice(0, this.preventasEvento.length);
      for (var i = 0; i < this.evento.preventas.length; i++) {
        this.preventasEvento = this.evento.preventas[i];
      }
      this.$store.dispatch("solicitudevento/costos3", this.evento.costos);
    }
    // var self = this;
    // for (var i = 0; i < this.eventoimagenes.length; i++) {
    //   METODO 1
    //   var httpRequest = new XMLHttpRequest();
    //   httpRequest.onload = function() {
    //     var fileReader = new FileReader();
    //     fileReader.onloadend = function() {
    //       if(self.eventoimagenes[i].nombre.includes('carousel')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoCarousel',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('twitter')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoTwitter',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('instagram')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoInstagram',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('instagstories')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoInstagStories',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('facebook')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoFacebook',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('vertical')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoVert',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('miniatura')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoMiniatura',fileReader.result);
    //       } else if (self.eventoimagenes[i].nombre.includes('banner')) {
    //         self.$store.dispatch('solicitudevento/imagenesEventoBanner',fileReader.result);
    //       }
    //     }
    //     fileReader.readAsDataURL(httpRequest.response);
    //   };
    //   httpRequest.open('GET', self.eventoimagenes[i].url);
    //   httpRequest.responseType = 'blob';
    //   httpRequest.send();

    //   METODO 2
    //   var img = new Image,
    //       canvas = document.createElement("canvas"),
    //       ctx = canvas.getContext("2d");

    //   img.crossOrigin = "Anonymous";

    //   img.onload = function() {
    //       canvas.width = img.width;
    //       canvas.height = img.height;
    //       ctx.drawImage( img, 0, 0 );
    //   }
    //   img.src = self.eventoimagenes[i].url;
    // }
    Emit.$on("fileimg", (file) => {
      this.fileimg = file;
    });
  },
  mounted() {
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgArtistaPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgArtista").change(function () {
      readURL(this);
      $("#imgArtistaPreview").show();
    });
    function readURL2(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoCaruselPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoCarusel").change(function () {
      readURL2(this);
      $("#imgEventoCaruselPreview").show();
    });
    function readURL3(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoTwitterPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoTwitter").change(function () {
      readURL3(this);
      $("#imgEventoTwitterPreview").show();
    });
    function readURL4(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoDisenoBoletaPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoDisenoBoleta").change(function () {
      readURL4(this);
      $("#imgEventoDisenoBoletaPreview").show();
    });
    function readURL5(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoInstagStoriesPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoInstagStories").change(function () {
      readURL5(this);
      $("#imgEventoInstagStoriesPreview").show();
    });
    function readURL6(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoFacebookPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoFacebook").change(function () {
      readURL6(this);
      $("#imgEventoFacebookPreview").show();
    });
    function readURL7(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoVertPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoVert").change(function () {
      readURL7(this);
      $("#imgEventoVertPreview").show();
    });
    function readURL8(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoMiniaturaPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoMiniatura").change(function () {
      readURL8(this);
      $("#imgEventoMiniaturaPreview").show();
    });
    function readURL9(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoBannerPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoBanner").change(function () {
      readURL9(this);
      $("#imgEventoBannerPreview").show();
    });
    function readURL10(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoDesktopPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoDesktop").change(function () {
      readURL10(this);
      $("#imgEventoDesktopPreview").show();
    });
    function readURL11(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoMovilPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoMovil").change(function () {
      readURL11(this);
      $("#imgEventoMovilPreview").show();
    });
    function readURL12(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#imgEventoPreciosPreview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgEventoPrecios").change(function () {
      readURL12(this);
      $("#imgEventoPreciosPreview").show();
    });
    var self = this;
    $(".maparea").click(function (e) {
      e.preventDefault();
      var texthref = e.currentTarget.href;
      var arrayhref = texthref.split("/");
      self.getPuestos(arrayhref[arrayhref.length - 1]);
      window.location.hash = "boletas";
      //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
    });
    if (this.auditorioDatos.id != "") {
      this.DetallesAuditorio = true;
    }
    if (this.responsableEvento.responsable != null) {
      this.DetallesResponsableEvento = true;
    }
    this.sync();
  },
  data() {
    return {
      errorform: {
        error: false,
        msj: "",
      },
      mapCenter: {
        lat: 4.6699538,
        lng: -74.1171362,
      },
      pov: {
        pitch: 0,
        heading: 0,
      },
      imagenAuditorioMapeado: "",
      DetallesAuditorio: false,
      DetallesResponsableEvento: false,
      fileimg: "",
      nameImageC: "",
      sizeImageC: "",
      sizeImageCHeight: "",
      sizeImageCWidth: "",
      nameImageT: "",
      sizeImageT: "",
      sizeImageTHeight: "",
      sizeImageTWidth: "",
      nameImageI: "",
      sizeImageI: "",
      sizeImageIHeight: "",
      sizeImageIWidth: "",
      nameImageIS: "",
      sizeImageIS: "",
      sizeImageISHeight: "",
      sizeImageISWidth: "",
      nameImageF: "",
      sizeImageF: "",
      sizeImageFHeight: "",
      sizeImageFWidth: "",
      nameImageV: "",
      sizeImageV: "",
      sizeImageVHeight: "",
      sizeImageVWidth: "",
      nameImageM: "",
      sizeImageM: "",
      sizeImageMHeight: "",
      sizeImageMWidth: "",
      nameImageB: "",
      sizeImageB: "",
      sizeImageBHeight: "",
      sizeImageBWidth: "",
      nameImageBD: '',
      sizeImageBD: '',
      sizeImageBDHeight: '',
      sizeImageBDWidth: '',
      nameImageBM: '',
      sizeImageBM: '',
      sizeImageBMHeight: '',
      sizeImageBMWidth: '',
      nameImageP: '',
      sizeImageP: '',
      sizeImagePHeight: '',
      sizeImagePWidth: '',
      categoriasArtistas: [{ id: 1, nombre: "Cantantes" }],
      imagenNewArtista: [],
      condicion: null,
      puntoventa: null,
      modalCondicion: false,
      modalTribuna: false,
      modalGenero: false,
      modalTipocosto: false,
      nameTipocosto: null,
      titleModalCondicion: null,
      nameCondicion: null,
      keyCondiciones: "",
      nameTribuna: null,
      nameGenero: null,
      idLocalidad: 0,
      idLocalidad2: "",
      keyPuntoventas: "",
      keyArtistas: "",
      generoSearch: "",
      categoriaSearch: null,
      tipoPersona: false,
      generoNewArtista: null,
      categoriaNewArtista: null,
      managerNewArtista: null,
      nameArtista: null,
      mostrarPuestos: false,
      modalArtista: false,
      modalImagenEventoCarusel: false,
      modalImagenEventoTwitter: false,
      modalImagenEventoDisenoBoleta: false,
      modalImagenEventoInstagStories: false,
      modalImagenEventoFacebook: false,
      modalImagenEventoVert: false,
      modalImagenEventoMiniatura: false,
      modalImagenEventoBanner: false,
      modalImagenEventoDesktop: false,
      modalImagenEventoMovil: false,
      modalImagenEventoPrecios: false,
      modalPuntoVenta: false,
      modalPreventa: false,
      modalFechaInicioPreventaEvento: false,
      modalFechaFinPreventaEvento: false,
      modalHoraInicioPreventaEvento: false,
      modalHoraFinPreventaEvento: false,
      modalFechaEvento: false,
      modalFechaFinEvento: false,
      modalfecha_inicio_venta_puntos: false,
      modalhora_inicio_venta_puntos: false,
      modalfecha_inicio_venta_internet: false,
      modalhora_inicio_venta_internet: false,
      modalHoraInicio: false,
      modalHoraAperturaPuertas: false,
      modalHoraFinalizacion: false,
      newPuntoventa: {
        nombre_razon: null,
        identificacion: null,
        tipo_identificacion: null,
        direccion: null,
        telefono: null,
        responsable: null,
        zona: null,
        email: null,
        id_ciudad: null,
      },
      tipoidentificacion: [
        { id: 1, descripcion: "CC" },
        { id: 2, descripcion: "CE" },
        { id: 3, descripcion: "PA" },
        { id: 4, descripcion: "NIT" },
      ],
      tipodescuento: [
        { id: 1, descripcion: "Servicio" },
        { id: 2, descripcion: "Precio" },
      ],
      newPreventa: {
        nombre: "",
        evento: false,
        id_tribuna: null,
        id_localidad: null,
        fecha_inicio: null,
        hora_inicio: null,
        fecha_fin: null,
        hora_fin: null,
        porcentaje_descuento_servicio: null,
        descuento_fijo_servicio: null,
        porcentaje_descuento_precio: null,
        descuento_fijo_precio: null,
        activo: 1,
      },
      newDialogTemporada: false,
      eventoimagenes: [],
      deleteImagenesEvento: [],
      newTemporada: "",
      headersCondicion: [
        {
          text: "Condición",
          align: "left",
          value: "descripcion",
        },
        {
          text: "Editar",
          sortable: false,
        },
      ],
      headersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "nombre_razon",
        },
        {
          text: "Zona/Region",
          value: "zona",
        },
        {
          text: "Ciudad",
          value: "ciudades.descripcion",
        },
        {
          text: "Departamento",
          value: "ciudades.id_departamento",
        },
        {
          text: "Responsable",
          value: "responsable",
        },
      ],
      resumheadersCondicion: [
        {
          text: "Condición",
          align: "left",
          value: "descripcion",
        },
      ],
      resumheadersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "nombre_razon",
        },
        {
          text: "Zona/Region",
          value: "zona",
        },
        {
          text: "Ciudad",
          value: "ciudades.descripcion",
        },
        {
          text: "Departamento",
          value: "ciudades.id_departamento",
        },
        {
          text: "Responsable",
          value: "responsable",
        },
      ],
      headersPreventa: [
        {
          text: "Nombre",
          align: "left",
          value: "nombre",
        },
        {
          text: "Evento",
          value: "evento",
        },
        {
          text: "Tribuna",
          value: "id_tribuna",
        },
        {
          text: "Localidad",
          value: "id_localidad",
        },
        {
          text: "Fecha Inicial",
          value: "fecha_inicio",
        },
        {
          text: "Hora Inicial",
          value: "hora_inicio",
        },
        {
          text: "Fecha Final",
          value: "fecha_fin",
        },
        {
          text: "Hora Final",
          value: "hora_fin",
        },
        {
          text: "Porcentaje en Servicio",
          value: "porcentaje_descuento_servicio",
        },
        {
          text: "Valor Fijo en Servicio",
          value: "descuento_fijo_servicio",
        },
        {
          text: "Porcentaje en Precio",
          value: "porcentaje_descuento_precio",
        },
        {
          text: "Valor Fijo en Precio",
          value: "descuento_fijo_precio",
        },
      ],
      resumheadersArtista: [
        {
          text: "Nombre",
          align: "left",
          value: "nombre",
        },
        {
          text: "Imagen",
          value: "imagens",
        },
      ],
      resumheadersLocalidad: [
        {
          text: "Id",
        },
        {
          text: "Tribuna",
          align: "left",
        },
        {
          text: "Localidad",
          align: "left",
        },
        {
          text: "Puerta de Acceso",
        },
        {
          text: "Aforo",
        },
        {
          text: "Silleteria",
        },
        {
          text: "Palco",
        },
        {
          text: "Sillas por Puestos en Palco",
        },
        {
          text: "Crear Sillas",
        },
      ],
      resumheadersLocalidad2: [
        {
          text: "Id",
        },
        {
          text: "Tribuna",
          align: "left",
        },
        {
          text: "Localidad",
          align: "left",
        },
        {
          text: "Puerta de Acceso",
        },
        {
          text: "Aforo",
        },
        {
          text: "Silleteria",
        },
        {
          text: "Palco",
        },
        {
          text: "Sillas por Puestos en Palco",
        },
      ],
      resumheadersCosto: [
        {
          text: "Tipo",
          align: "left",
          value: "id_tipo_costo",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Monto",
          value: "valor",
        },
      ],
      resumheadersAuditoriomapeadosauditorio: [
        {
          text: "Auditorio Mapeado",
        },
      ],
      resumheadersLocalidadesauditoriomapeado: [
        {
          text: "Localidad",
        },
      ],
      rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
      rowperpageitems2: [20, { text: "Todos", value: -1 }],
      fab: false,
      logoBase64: `${configApi.apiUrl}img/logo-ticketshop.ca82ecc6.png`,
    };
  },
  watch: {
    evento: {
      handler: function (after, before) {
        if (before) {
          this.$store.dispatch(
            "auditoriaevento/listAuditoriaeventosEvento",
            before.id
          );
        }
      },
      deep: true,
    },
    artistasShow() {
      /*this.artistasCheck=this.artistasCheckTemp;*/
      this.$store.dispatch(
        "solicitudevento/artistasCheck",
        this.artistasCheckTemp
      );
    },
    /*localidades: {
            handler: function (after, before) {
              this.$store.dispatch('solicitudevento/localidades5',before);
            },
            deep: true
          },
          costos: {
            handler: function (after, before) {
              this.$store.dispatch('solicitudevento/costos3',before);
            },
            deep: true
          },*/
  },
  computed: {
    auditoria() {
      var auditoria =
        this.$store.getters["auditoriaevento/listAuditoriaeventos"];
      if (auditoria) {
        var filterauditoria = auditoria.filter((auditoria) => {
          return auditoria.status_2 == 6;
        });
        var sortauditoria = filterauditoria.sort(function (after, before) {
          return new Date(before.date) - new Date(after.date);
        });
        return sortauditoria[0];
      } else {
        return "";
      }
    },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    selectedCondiciones: {
      get() {
        return this.$store.getters["solicitudevento/selectedCondiciones"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/selectedCondiciones", value);
      },
    },
    selectedPuntoventas: {
      get() {
        return this.$store.getters["solicitudevento/selectedPuntoventas"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/selectedPuntoventas", value);
      },
    },
    artistasCheck: {
      get() {
        return this.$store.getters["solicitudevento/artistasCheck"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/artistasCheck", value);
      },
    },
    artistasCheckTemp: {
      get() {
        return this.$store.getters["solicitudevento/artistasCheckTemp"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/artistasCheckTemp", value);
      },
    },
    tribunasShow2: {
      get() {
        return this.$store.getters["solicitudevento/tribunasShow2"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/tribunasShow2", value);
      },
    },
    modalPlantilla: {
      get() {
        return this.$store.getters["solicitudevento/modalPlantilla"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/modalPlantilla", value);
      },
    },
    imagenMapeo: {
      get() {
        return this.$store.getters["solicitudevento/imagenMapeo"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenMapeo", value);
      },
    },
    localidades: {
      get() {
        return this.$store.getters["solicitudevento/localidades"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/localidades", value);
      },
    },
    localidadesevento: {
      get() {
        return this.$store.getters["solicitudevento/localidadesevento"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/localidadesevento", value);
      },
    },
    costos: {
      get() {
        return this.$store.getters["solicitudevento/costos"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/costos", value);
      },
    },
    auditorioDatos: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatos"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatos", value);
      },
    },
    auditorioDatosid: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosid"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosid", value);
      },
    },
    auditorioDatosnombre: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosnombre"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosnombre", value);
      },
    },
    auditorioDatosdireccion: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosdireccion"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosdireccion", value);
      },
    },
    auditorioDatosid_pais: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosid_pais"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosid_pais", value);
      },
    },
    auditorioDatosid_departamento: {
      get() {
        return this.$store.getters[
          "solicitudevento/auditorioDatosid_departamento"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/auditorioDatosid_departamento",
          value
        );
      },
    },
    auditorioDatosid_ciudad: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosid_ciudad"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosid_ciudad", value);
      },
    },
    auditorioDatoslatitud: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatoslatitud"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatoslatitud", value);
      },
    },
    auditorioDatoslongitud: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatoslongitud"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatoslongitud", value);
      },
    },
    auditorioDatosaforo: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosaforo"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosaforo", value);
      },
    },
    auditorioDatosurl_imagen: {
      get() {
        return this.$store.getters["solicitudevento/auditorioDatosurl_imagen"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/auditorioDatosurl_imagen", value);
      },
    },
    auditorioDatoscodigo_mapeado: {
      get() {
        return this.$store.getters[
          "solicitudevento/auditorioDatoscodigo_mapeado"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/auditorioDatoscodigo_mapeado",
          value
        );
      },
    },
    responsableEvento: {
      get() {
        return this.$store.getters["solicitudevento/responsableEvento"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/responsableEvento", value);
      },
    },
    responsableEventoresponsable: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventoresponsable"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventoresponsable",
          value
        );
      },
    },
    responsableEventonombreRazonSocial: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventonombreRazonSocial"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventonombreRazonSocial",
          value
        );
      },
    },
    responsableEventotipoIdentificacion: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventotipoIdentificacion"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventotipoIdentificacion",
          value
        );
      },
    },
    responsableEventoIdentificacion: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventoIdentificacion"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventoIdentificacion",
          value
        );
      },
    },
    responsableEventodireccion: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventodireccion"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventodireccion",
          value
        );
      },
    },
    responsableEventopais: {
      get() {
        return this.$store.getters["solicitudevento/responsableEventopais"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/responsableEventopais", value);
      },
    },
    responsableEventodepartamento: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventodepartamento"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventodepartamento",
          value
        );
      },
    },
    responsableEventociudad: {
      get() {
        return this.$store.getters["solicitudevento/responsableEventociudad"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/responsableEventociudad", value);
      },
    },
    responsableEventotipoCliente: {
      get() {
        return this.$store.getters[
          "solicitudevento/responsableEventotipoCliente"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventotipoCliente",
          value
        );
      },
    },
    responsableEventoemail: {
      get() {
        return this.$store.getters["solicitudevento/responsableEventoemail"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/responsableEventoemail", value);
      },
    },
    responsableEventotelefono: {
      get() {
        return this.$store.getters["solicitudevento/responsableEventotelefono"];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/responsableEventotelefono",
          value
        );
      },
    },
    preventasEvento: {
      get() {
        return this.$store.getters["solicitudevento/preventasEvento"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/preventasEvento", value);
      },
    },
    imagenesEventoCarousel: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoCarousel"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoCarousel", value);
      },
    },
    imagenesEventoTwitter: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoTwitter"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoTwitter", value);
      },
    },
    imagenesEventoDisenoBoleta: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoDisenoBoleta"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoDisenoBoleta", value);
      },
    },
    imagenesEventoLocalidad: {
      get() {
        return this.$store.getters[
          "solicitudevento/imagenesEventoLocalidad"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "solicitudevento/imagenesEventoLocalidad",
          value
        );
      },
    },
    imagenesEventoFacebook: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoFacebook"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoFacebook", value);
      },
    },
    imagenesEventoVert: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoVert"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoVert", value);
      },
    },
    imagenesEventoMiniatura: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoMiniatura"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoMiniatura", value);
      },
    },
    imagenesEventoBanner: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoBanner"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoBanner", value);
      },
    },
    imagenesEventoDesktop: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoDesktop"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoDesktop", value);
      },
    },
    imagenesEventoMovil: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoMovil"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoMovil", value);
      },
    },
    imagenesEventoPrecios: {
      get() {
        return this.$store.getters["solicitudevento/imagenesEventoPrecios"];
      },
      set(value) {
        this.$store.dispatch("solicitudevento/imagenesEventoPrecios", value);
      },
    },
    hoy() {
      return moment().format("Y-MM-DD");
    },
    departamentosShow() {
      let departamentosShow = this.departamentos.filter((departamento) => {
        return departamento.id_pais == this.evento.pais.id;
      });
      return departamentosShow;
    },
    ciudadesShow() {
      let ciudadesShow = this.ciudades.filter((ciudad) => {
        if (this.evento.departamento) {
          return ciudad.id_departamento == this.evento.departamento.id;
        } else {
          return false;
        }
      });
      return ciudadesShow;
    },
    departamentosrespeventoShow() {
      let departamentosShow = this.departamentos.filter((departamento) => {
        return departamento.id_pais == this.responsableEvento.pais.id;
      });
      return departamentosShow;
    },
    ciudadesrespeventoShow() {
      let ciudadesShow = this.ciudades.filter((ciudad) => {
        if (this.responsableEvento.departamento) {
          return (
            ciudad.id_departamento == this.responsableEvento.departamento.id
          );
        } else {
          return false;
        }
      });
      return ciudadesShow;
    },
    departamentosauditorioeventoShow() {
      let departamentosShow = this.departamentos.filter((departamento) => {
        return departamento.id_pais == this.auditorioDatos.id_pais;
      });
      return departamentosShow;
    },
    ciudadesauditorioeventoShow() {
      let ciudadesShow = this.ciudades.filter((ciudad) => {
        return ciudad.id_departamento == this.auditorioDatos.id_departamento;
      });
      return ciudadesShow;
    },
    artistasShow() {
      let artistasShow = this.artistas.filter((artista) => {
        if (this.keyArtistas == "" && this.generoSearch == "") {
          return this.artistasCheckTemp.indexOf(artista.id) !== -1;
        }
        return (
          (artista.nombre
            .toLowerCase()
            .includes(this.keyArtistas.toLowerCase()) &&
            this.keyArtistas != "") ||
          artista.id_genero == this.generoSearch
        );
      });
      return artistasShow;
    },
    artistasShow2() {
      let artistasShow = this.artistas.filter((artista) => {
        return this.artistasCheckTemp.indexOf(artista.id) !== -1;
      });
      return artistasShow;
    },
    tribunasShow() {
      let tribunasShow = this.tribunas.filter((tribuna) => {
        return tribuna.id_auditorio == this.auditorioDatos.id;
      });
      return tribunasShow;
    },
    auditorio_mapeadosauditorioShow() {
      let auditorio_mapeadosauditorioShow =
        this.auditorio_mapeadosauditorio.filter((auditorio_mapeados) => {
          return auditorio_mapeados.status == 1;
        });
      return auditorio_mapeadosauditorioShow;
    },
    tiposEventos() {
      return this.$store.getters["tipoevento/listTipoEvento"];
    },
    auditorios() {
      return this.$store.getters["auditorio/listAuditorios"];
    },
    auditorio() {
      return this.$store.getters["auditorio/auditorio"];
    },
    clientes() {
      return this.$store.getters["cliente/listClientes"];
    },
    cliente() {
      return this.$store.getters["cliente/cliente"];
    },
    paises() {
      return this.$store.getters["pais/listPaises"];
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    condiciones() {
      return this.$store.getters["condicion/listCondiciones"];
    },
    artistas() {
      return this.$store.getters["artista/listArtistas"];
    },
    generos() {
      return this.$store.getters["genero/listGeneros"];
    },
    puntoventas() {
      return this.$store.getters["puntoventa/listPuntoventas"];
    },
    tipocostos() {
      return this.$store.getters["tipocosto/listTipocostos"];
    },
    temporadas() {
      return this.$store.getters["temporada/listTemporadas"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    monedas() {
      return this.$store.getters["moneda/listMonedas"];
    },
    imagen() {
      return this.$store.getters["imagen/imagen"];
    },
    tribunas() {
      return this.$store.getters["tribuna/listTribunas"];
    },
    tribuna() {
      return this.$store.getters["tribuna/tribuna"];
    },
    localidadesauditorio() {
      return this.$store.getters["localidad/listLocalidadesAuditorio"];
    },
    boletas() {
      return this.$store.getters["evento/listEventosBoleta"];
    },
    puestos() {
      return this.$store.getters["evento/listPuestos"];
    },
    localidad() {
      return this.$store.getters["localidad/localidad"];
    },
    auditorio_mapeado() {
      return this.$store.getters["auditorio_mapeado/auditorio_mapeado"];
    },
    auditorio_mapeadosauditorio() {
      return this.$store.getters[
        "auditorio_mapeado/listAuditorio_mapeadosAuditorio"
      ];
    },
    localidadesauditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesAuditorio_mapeado"
      ];
    },
    tribunaslocalidad() {
      var self = this;
      return this.tribunas.filter((tribuna) => {
        for (var i = 0; i < self.localidades.length; i++) {
          if (self.localidades[i].id_tribuna == tribuna.id) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    lasuma(a, b) {
      if (a && !b) {
        return a;
      } else if (!a && b) {
        return b;
      } else if (a && b) {
        return parseInt(a) + parseInt(b);
      } else {
        return "";
      }
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return null;
      }
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    formatHora(hora) {
      if (hora != null && hora != "") {
        return moment(hora, "HH:mm:ss").format("HH:mm:ss");
      } else {
        return null;
      }
    },
    getnomtribuna(id) {
      let filter_tribuna = this.tribunas.filter((tribuna) => tribuna.id == id);
      if (filter_tribuna[0] == "" || filter_tribuna[0] == null) {
        return "";
      } else {
        return filter_tribuna[0].nombre;
      }
    },
    getnomlocalidad(id) {
      let filter_localidad = this.localidades.filter(
        (localidad) => localidad.id == id
      );
      if (filter_localidad[0] == "" || filter_localidad[0] == null) {
        return "";
      } else {
        return filter_localidad[0].nombre;
      }
    },
    getnomtipodescuento(id) {
      let filter_tipodescuento = this.tipodescuento.filter(
        (descuento) => descuento.id == id
      );
      if (filter_tipodescuento[0] == "" || filter_tipodescuento[0] == null) {
        return "";
      } else {
        return filter_tipodescuento[0].descripcion;
      }
    },
    getPais(id) {
      let filter_pais = this.paises.filter((pais) => pais.id == id);
      if (filter_pais[0] == "" || filter_pais[0] == null) {
        return "";
      } else {
        return filter_pais[0].descripcion;
      }
    },
    getDepartamento(id) {
      let filter_departamento = this.departamentos.filter(
        (departamento) => departamento.id == id
      );
      if (filter_departamento[0] == "" || filter_departamento[0] == null) {
        return "";
      } else {
        return filter_departamento[0].descripcion;
      }
    },
    getCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        return filter_ciudad[0].descripcion;
      }
    },
    getDepartamentoxCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        let filter_departamento = this.departamentos.filter(
          (departamento) => departamento.id == filter_ciudad[0].id_departamento
        );
        if (filter_departamento[0] == "" || filter_departamento[0] == null) {
          return "";
        } else {
          return filter_departamento[0].descripcion;
        }
      }
    },
    getTipocosto(id) {
      let filter_tipocosto = this.tipocostos.filter(
        (tipocosto) => tipocosto.id == id
      );
      return filter_tipocosto[0].descripcion;
    },
    getMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0].descripcion;
      }
    },
    getSimboloMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0].simbolo;
      }
    },
    getTipoIdentificacion(identificacion) {
      switch (identificacion) {
        case 1:
          return "CC";
          break;
        case 2:
          return "CE";
          break;
        case 3:
          return "PA";
          break;
        case 4:
          return "NIT";
          break;
      }
    },
    getAuditorioDatos(id) {
      let auditorioDato = this.auditorios.filter(
        (auditorio) => auditorio.id == id
      );
      return auditorioDato[0];
    },
    getResponsableEventoresponsable(id) {
      let cliente = this.clientes.filter((cliente) => cliente.id == id);
      return cliente[0];
    },
    getPuestos(id) {
      this.idLocalidad2 = id;
    },
    updateLocalidadesevento(index) {
      this.$store.dispatch(
        "solicitudevento/localidadesevento3",
        this.localidadesevento[index]
      );
    },
    updateCostos(index) {
      this.$store.dispatch("solicitudevento/costos4", this.costos[index]);
    },
    updateCenter(latLng) {
      this.auditorioDatoslatitud = latLng.lat();
      this.auditorioDatoslongitud = latLng.lng();
    },
    sync() {
      this.mapCenter = {
        lat: this.auditorioDatoslatitud,
        lng: this.auditorioDatoslongitud,
      };
    },
    saveCondicion() {
      let searchCondicion = this.condiciones.filter(
        (condicion) =>
          condicion.descripcion.toLowerCase() ==
          this.nameCondicion.toLowerCase()
      );
      if (
        searchCondicion[0] == null ||
        searchCondicion[0] == "" ||
        searchCondicion[0] == undefined
      ) {
        if (this.condicion) {
          this.$store.dispatch("condicion/updateCondicion", {
            id: this.condicion.id,
            descripcion: this.nameCondicion,
          });
          this.keyCondiciones = this.nameCondicion;
        } else {
          this.$store.dispatch("condicion/newCondicion", {
            descripcion: this.nameCondicion,
          });
        }
      } else {
        swal("Duplicado!", "Condición existente!", "error");
      }
      this.modalCondicion = false;
    },
    saveTribuna() {
      if (this.nameTribuna) {
        this.$store
          .dispatch("tribuna/newTribuna", {
            nombre: this.nameTribuna,
            id_auditorio: this.auditorioDatos.id,
          })
          .then((response) => {
            if ((response = 200)) {
              this.$store.dispatch(
                "solicitudevento/tribunasShow2",
                this.$store.getters["tribuna/tribuna"]
              );
              this.$store.dispatch(
                "auditorio_mapeado_tribuna/newAuditorio_mapeado_tribuna",
                {
                  id_auditorio_mapeado: this.imagenMapeo.id,
                  id_tribuna: this.tribuna.id,
                }
              );
            }
          });
        this.modalTribuna = false;
      }
    },
    saveGenero() {
      if (this.nameGenero) {
        this.$store.dispatch("genero/newGenero", { nombre: this.nameGenero });
        this.modalGenero = false;
      }
    },
    saveTipoCosto() {
      this.$store.dispatch("tipocosto/newTipocosto", {
        descripcion: this.nameTipocosto,
      });
      this.modalTipocosto = false;
    },
    savePuntoVenta() {
      if (this.$refs.ruleFormNewPuntoVenta.validate()) {
        this.$store.dispatch("puntoventa/newPuntoventa", this.newPuntoventa);
        this.modalPuntoVenta = false;
        this.keyPuntoventas = this.newPuntoventa.nombre_razon;
        this.clearPuntoVenta();
      }
    },
    savePreventa() {
      this.$store.dispatch("solicitudevento/preventasEvento", {
        nombre: this.newPreventa.nombre,
        evento: this.newPreventa.evento,
        id_tribuna: this.newPreventa.id_tribuna,
        id_localidad: this.newPreventa.id_localidad,
        fecha_inicio: this.formatFecha(this.newPreventa.fecha_inicio),
        hora_inicio: this.formatHora(this.newPreventa.hora_inicio),
        fecha_fin: this.formatFecha(this.newPreventa.fecha_fin),
        hora_fin: this.formatHora(this.newPreventa.hora_fin),
        porcentaje_descuento_servicio:
          this.newPreventa.porcentaje_descuento_servicio,
        descuento_fijo_servicio: this.newPreventa.descuento_fijo_servicio,
        porcentaje_descuento_precio:
          this.newPreventa.porcentaje_descuento_precio,
        descuento_fijo_precio: this.newPreventa.descuento_fijo_precio,
        activo: 1,
      });
      this.newPreventa = {
        nombre: "",
        evento: false,
        id_tribuna: null,
        id_localidad: null,
        fecha_inicio: null,
        hora_inicio: null,
        fecha_fin: null,
        hora_fin: null,
        porcentaje_descuento_servicio: null,
        descuento_fijo_servicio: null,
        porcentaje_descuento_precio: null,
        descuento_fijo_precio: null,
        activo: 1,
      };
      this.modalPreventa = false;
    },
    async removePreventa(index) {
      this.$store.dispatch("solicitudevento/preventasEvento2", index);
    },
    editCondicion(id) {
      let auxCondicion = this.condiciones.filter(
        (condicion) => condicion.id == id
      );
      this.condicion = auxCondicion[0];
      this.nameCondicion = this.condicion.descripcion;
      this.titleModalCondicion = "Editar Condición";
      this.modalCondicion = true;
      this.$nextTick(() => this.$refs.txtNameCondicion.focus());
    },
    setNullCondicion() {
      this.condicion = null;
      this.nameCondicion = null;
      this.titleModalCondicion = "Nueva Condicion";
    },
    async PlantillaAuditorio(auditoriomapeado) {
      this.modalPlantilla = "set";
      this.idLocalidad2 = "";
      this.$store.dispatch(
        "auditorio_mapeado/setAuditorio_mapeado",
        auditoriomapeado
      );
      this.$store.dispatch("solicitudevento/imagenMapeo", auditoriomapeado);
      await this.$store.dispatch(
        "auditorio_mapeado/listLocalidadesAuditorio_mapeado",
        auditoriomapeado.id
      );
    },
    clearPuntoVenta() {
      this.newPuntoventa = {
        nombre_razon: null,
        identificacion: null,
        tipo_identificacion: null,
        direccion: null,
        telefono: null,
        responsable: null,
        zona: null,
        email: null,
        id_ciudad: null,
      };
    },
    clearPlantilla() {
      this.modalPlantilla = "new";
      this.idLocalidad2 = "";
      this.$store.dispatch("auditorio_mapeado/setAuditorio_mapeado", null);
      this.$store.dispatch("solicitudevento/imagenMapeo", {
        id: "",
        id_auditorio: "",
        area_mapeada: "",
        nombre: "",
        imagen: "",
        status: "",
      });
    },
    setNullTribuna() {
      this.nameTribuna = null;
    },
    setNullGenero() {
      this.nameGenero = null;
    },
    newLocalidad() {
      this.$store.dispatch("solicitudevento/localidades", {
        id: "",
        id_tribuna: "",
        nombre: "",
        impuesto: "",
        precio_venta: "",
        precio_servicio: "",
        puerta_acceso: "",
        url_imagen: "",
        codigo_moneda: "",
        silleteria: "",
        palco: "",
        puestosxpalco: "",
        imagen: "",
      });
      this.$store.dispatch("solicitudevento/localidadesevento", {
        id_localidad: "",
        impuesto: "",
        precio_venta: "",
        precio_servicio: "",
        url_imagen: "",
        codigo_moneda: "",
        imagen: "",
      });
    },
    async removeLocalidad(index) {
      if (
        !(
          this.localidades[index].id == "" || this.localidades[index].id == null
        )
      ) {
        await this.$store
          .dispatch("localidad/deleteLocalidad", this.localidades[index].id)
          .then((response) => {
            if (response != null) {
              if (
                response.response.data.error !=
                "La localidad no se puedo eliminar, es usada en otra tabla"
              ) {
                this.$store.dispatch("solicitudevento/localidades2", index);
                this.$store.dispatch(
                  "solicitudevento/localidadesevento2",
                  index
                );
                /*this.localidades.splice(index,1)*/
              }
            } else {
              this.$store.dispatch("solicitudevento/localidades2", index);
              this.$store.dispatch("solicitudevento/localidadesevento2", index);
              /*this.localidades.splice(index,1)*/
            }
          });
        this.$store.dispatch(
          "localidad/listLocalidadesAuditorio",
          this.auditorioDatos.id
        );
      } else {
        this.$store.dispatch("solicitudevento/localidades2", index);
        this.$store.dispatch("solicitudevento/localidadesevento2", index);
        /*this.localidades.splice(index,1);                */
      }
    },
    addPuestoLocalidad(index, index2) {
      document.getElementById("showsemaforo" + index2).style.display = "block";
      window.location.hash = "puestos";
      this.idLocalidad = index;
      this.mostrarPuestos = true;
    },
    newCosto() {
      /*this.costos.push({
                  id_tipo_costo:'',
                  descripcion: '',
                  valor: '',
                  codigo_moneda: '',
                });*/
      this.$store.dispatch("solicitudevento/costos", {
        id_tipo_costo: "",
        descripcion: "",
        valor: "",
      });
    },
    removeCosto(index) {
      this.$store.dispatch("solicitudevento/costos2", index);
      /*this.costos.splice(index,1);*/
    },
    sendFormSolicitudEvento() {
      this.$store.commit("changeLoader", true);
      if (this.cliente) {
        this.updateEvento();
      } else {
        this.$store
          .dispatch("cliente/newCliente", {
            Identificacion: this.responsableEvento.Identificacion,
            tipo_identificacion: this.responsableEvento.tipoIdentificacion.id,
            nombrerazon: this.responsableEvento.nombreRazonSocial,
            direccion: this.responsableEvento.direccion,
            id_pais: this.responsableEvento.pais.id,
            id_ciudad: this.responsableEvento.ciudad.id,
            id_departamento: this.responsableEvento.departamento.id,
            tipo_cliente: this.responsableEvento.tipoCliente,
            email: this.responsableEvento.email,
            telefono: this.responsableEvento.telefono,
          })
          .then((response) => {
            this.updateEvento();
          });
      }
    },
    async updateEvento() {
      await this.$store.dispatch("evento/updateEvento", {
        fecha_evento: this.evento.fechaEvento,
        fecha_finalizacion_evento: this.evento.fechaFinEvento,
        nombre: this.evento.nombreEvento,
        hora_inicio: this.evento.horaInicio,
        hora_apertura: this.evento.horaAperturaPuertas,
        hora_finalizacion: this.evento.horaFinalizacion,
        codigo_pulep: this.evento.codigoPulep,
        id_tipo_evento: this.evento.tipoEvento,
        domicilios: this.evento.domicilios,
        venta_linea: this.evento.venta_linea,
        id_auditorio: this.auditorioDatos.id,
        id_auditorio_mapeado: this.imagenMapeo.id,
        id_cliente: this.cliente.id,
        id_temporada: this.evento.temporada.id,
        status: 4,
        fecha_inicio_venta_internet: this.evento.fecha_inicio_venta_internet,
        hora_inicio_venta_internet: this.evento.hora_inicio_venta_internet,
        fecha_inicio_venta_puntos: this.evento.fecha_inicio_venta_puntos,
        hora_inicio_venta_puntos: this.evento.hora_inicio_venta_puntos,
        monto_minimo: this.evento.monto_minimo,
        cant_max_boletas: this.evento.cant_max_boletas,
        codigo_moneda: this.evento.codigo_moneda,
      });
      for (var i = 0; i < this.deleteImagenesEvento.length; i++) {
        await this.$store.dispatch("evento/deleteEventoImagen", {
          id_evento: this.evento.id,
          id_imagen: this.deleteImagenesEvento[i],
        });
      }
      for (var i = 0; i < this.imagenesEventoCarousel.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoCarusel(i);
      }
      for (var i = 0; i < this.imagenesEventoDisenoBoleta.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoDisenoBoleta(i);
      }
      for (var i = 0; i < this.imagenesEventoLocalidad.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoLocalidad(i);
      }
      for (var i = 0; i < this.imagenesEventoVert.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoVert(i);
      }
      for (var i = 0; i < this.imagenesEventoMiniatura.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoMiniatura(i);
      }
      for (var i = 0; i < this.imagenesEventoBanner.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoBanner(i);
      }
      for (var i = 0; i < this.imagenesEventoDesktop.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoDesktop(i);
      }
      for (var i = 0; i < this.imagenesEventoMovil.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoMovil(i);
      }
      for (var i = 0; i < this.imagenesEventoPrecios.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setImagenEventoPrecios(i);
      }
      await this.$store.dispatch(
        "evento/deleteEventoCondiciones",
        this.evento.id
      );
      for (var i = 0; i < this.selectedCondiciones.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setEventoCondicion(i);
      }
      await this.$store.dispatch("evento/deleteEventoArtistas", this.evento.id);
      for (var i = 0; i < this.artistasCheck.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setEventoArtista(i);
      }
      await this.$store.dispatch(
        "evento/deleteEventoPuntoVentas",
        this.evento.id
      );
      for (var i = 0; i < this.selectedPuntoventas.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setEventoPuntoVenta(i);
      }
      for (var i = 0; i < this.preventas_old.length; i++) {
        await this.$store.dispatch(
          "evento/deleteEventoPreventa",
          this.preventas_old[i].id
        );
      }
      for (var i = 0; i < this.preventasEvento.length; i++) {
        this.$store.commit("changeLoader", true);
        await this.setEventoPreventa(i);
      }
      await this.$store.dispatch("evento/deleteEventoCostos", this.evento.id);
      for (var i = 0; i < this.costos.length; i++) {
        if (
          !(
            this.costos[i].id_tipo_costo == "" ||
            this.costos[i].descripcion == "" ||
            this.costos[i].valor == "" ||
            this.costos[i].codigo_moneda == ""
          )
        ) {
          this.$store.commit("changeLoader", true);
          await this.setEventocostos(i);
        }
      }
      await this.$store.dispatch(
        "evento/deleteEventoBoletasPalcos",
        this.evento.id
      );
      for (var i = 0; i < this.localidades.length; i++) {
        if (this.localidades.palco == 1) {
          this.$store.commit("changeLoader", true);
          await this.setEventoBoleteriaPalco(i);
        } else {
          this.$store.commit("changeLoader", true);
          await this.setEventoBoleteriaBoleta(i);
        }
      }
      this.$store.commit("changeLoader", false);
      swal(
        "Confirmacion!",
        "Ticketshop se complace en informar que su solicitud ha sido enviada con exito, le responderemos su requerimiento a la brevedad posible.",
        "success"
      );
      await this.clearSolicitudEvento();
      await this.nuevoResponsableEvento();
      this.$router.push("/mis-eventos");
    },
    async setEventoBoleteriaPalco(i) {
      await this.$store.dispatch("evento/palcosxlocalidad", {
        id_localidad: this.localidades[i].id,
        id_evento: this.evento.id,
        precio_venta: this.localidadesevento[i].precio_venta,
        precio_servicio: this.localidadesevento[i].precio_servicio,
        impuesto: this.localidadesevento[i].impuesto,
        status: 1,
        codigo_moneda: this.evento.codigo_moneda,
        imagen: this.localidadesevento[i].imagen,
      });
    },
    async setEventoBoleteriaBoleta(i) {
      await this.$store.dispatch("evento/boletasxlocalidad", {
        id_localidad: this.localidades[i].id,
        id_evento: this.evento.id,
        precio_venta: this.localidadesevento[i].precio_venta,
        precio_servicio: this.localidadesevento[i].precio_servicio,
        impuesto: this.localidadesevento[i].impuesto,
        status: 1,
        codigo_moneda: this.evento.codigo_moneda,
        imagen: this.localidadesevento[i].imagen,
      });
    },
    async setImagenEventoCarusel(i) {
      // const formData = new FormData();
      // formData.append('imagen', this.imagenesEventoCarousel[i]);
      //await this.$store.dispatch('imagen/newImagen',formData);
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoCarousel[i].imagen,
        nombre: "carousel",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoTwitter(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoTwitter[i].imagen,
        nombre: "twitter",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoDisenoBoleta(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoDisenoBoleta[i].imagen,
        nombre: "disenoboleta",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoLocalidad(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoLocalidad[i].imagen,
        nombre: "localidad",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoFacebook(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoFacebook[i].imagen,
        nombre: "facebook",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoVert(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoVert[i].imagen,
        nombre: "vertical",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoMiniatura(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoMiniatura[i].imagen,
        nombre: "miniatura",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoBanner(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoBanner[i].imagen,
        nombre: "banner",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoDesktop(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoBanner[i].imagen,
        nombre: "ban_desktop",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoMovil(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoMovil[i].imagen,
        nombre: "ban_movil",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setImagenEventoPrecios(i) {
      await this.$store.dispatch("imagen/newImagen64", {
        imagen: this.imagenesEventoPrecios[i].imagen,
        nombre: "precios",
      });
      await this.$store.dispatch("evento/newEventoImagen", {
        id_evento: this.evento.id,
        id_imagen: this.imagen.id,
      });
    },
    async setEventoArtista(i) {
      await this.$store.dispatch("evento/newEventoArtista", {
        id_evento: this.evento.id,
        id_artista: this.artistasCheck[i],
      });
    },
    async setEventoCondicion(i) {
      await this.$store.dispatch("evento/newEventoCondicion", {
        id_evento: this.evento.id,
        id_condiciones: this.selectedCondiciones[i].id,
      });
    },
    async setEventoPuntoVenta(i) {
      await this.$store.dispatch("evento/newEventoPuntoVenta", {
        id_evento: this.evento.id,
        id_puntoventa: this.selectedPuntoventas[i].id,
      });
    },
    async setEventoPreventa(i) {
      var eventoPrev = null;
      var payload = {};
      if (this.preventasEvento[i].preventa.evento) {
        eventoPrev = this.evento.id;
      }
      payload = {
        nombre: this.preventasEvento[i].preventa.nombre,
        id_evento_origen: this.evento.id,
        id_evento: eventoPrev,
        id_tribuna: this.preventasEvento[i].preventa.id_tribuna,
        id_localidad: this.preventasEvento[i].preventa.id_localidad,
        fecha_inicio: this.preventasEvento[i].preventa.fecha_inicio,
        hora_inicio: this.preventasEvento[i].preventa.hora_inicio,
        fecha_fin: this.preventasEvento[i].preventa.fecha_fin,
        hora_fin: this.preventasEvento[i].preventa.hora_fin,
        porcentaje_descuento_servicio:
          this.preventasEvento[i].preventa.porcentaje_descuento_servicio,
        descuento_fijo_servicio:
          this.preventasEvento[i].preventa.descuento_fijo_servicio,
        porcentaje_descuento_precio:
          this.preventasEvento[i].preventa.porcentaje_descuento_precio,
        descuento_fijo_precio:
          this.preventasEvento[i].preventa.descuento_fijo_precio,
        activo: this.preventasEvento[i].preventa.activo,
      };
      await this.$store.dispatch("evento/newEventoPreventa", payload);
    },
    async setEventocostos(i) {
      await this.$store.dispatch("evento/newEventoCostos", {
        id_evento: this.evento.id,
        id_tipo_costo: this.costos[i].id_tipo_costo,
        descripcion: this.costos[i].descripcion,
        valor: this.costos[i].valor,
        codigo_moneda: this.evento.codigo_moneda,
      });
    },
    async clearSolicitudEvento() {
      await this.$store.dispatch("solicitudevento/clear");
    },
    viewCliente() {
      this.$store.dispatch(
        "solicitudevento/responsableEventoresponsable",
        this.evento.id_cliente
      );
      this.$store.commit(
        "cliente/viewCliente",
        this.responsableEvento.responsable
      );
      this.responsableEvento.nombreRazonSocial = this.cliente.nombrerazon;
      this.responsableEvento.Identificacion = this.cliente.Identificacion;
      this.responsableEvento.tipoIdentificacion =
        this.cliente.tipo_identificacion;
      this.responsableEvento.ciudad = this.cliente.ciudad;
      this.responsableEvento.departamento = this.cliente.departamento;
      this.responsableEvento.tipoCliente = this.cliente.tipo_cliente;
      this.responsableEvento.direccion = this.cliente.direccion;
      this.responsableEvento.email = this.cliente.email;
      this.responsableEvento.telefono = this.cliente.telefono;
      this.DetallesResponsableEvento = true;
    },
    nuevoResponsableEvento() {
      this.$store.commit("cliente/clearCliente");
      this.responsableEvento.responsable = null;
      this.responsableEvento.nombreRazonSocial = null;
      this.responsableEvento.tipoIdentificacion = false;
      this.responsableEvento.Identificacion = null;
      this.responsableEvento.direccion = null;
      this.responsableEvento.departamento = null;
      this.responsableEvento.ciudad = null;
      this.responsableEvento.tipoCliente = false;
      this.responsableEvento.email = null;
      this.responsableEvento.telefono = null;
      //this.$validator.reset();
      this.$validator.fields.items.forEach((field) => {
        if (field.name == "email") {
          this.$validator.reset(field);
        }
      });
      this.$store.dispatch("solicitudevento/responsableEvento", {
        responsable: null,
        nombreRazonSocial: null,
        tipoIdentificacion: null,
        Identificacion: null,
        direccion: null,
        pais: "",
        departamento: null,
        ciudad: null,
        tipoCliente: false,
        email: null,
        telefono: null,
      });
      this.DetallesResponsableEvento = true;
    },
    AuditorioExistente() {
      this.$store.dispatch(
        "solicitudevento/auditorioDatos",
        this.getAuditorioDatos(this.evento.id_auditorio)
      );
      /*this.$store.dispatch('localidad/listLocalidadesAuditorio',this.auditorioDatos.id);*/
      this.$store.dispatch(
        "auditorio_mapeado/listAuditorio_mapeadosAuditorio",
        this.auditorioDatos.id
      );
      this.idLocalidad2 = "";
      this.DetallesAuditorio = true;
    },
    TemporadaExistente() {
      this.$store.dispatch(
        "temporada/buscarTemporada",
        this.newTemporada.nombre
      );
    },
    nuevoAuditorioEvento() {
      this.auditorioDatos.id = "";
      this.auditorioDatos.nombre = "";
      this.auditorioDatos.direccion = "";
      this.auditorioDatos.id_pais = "";
      this.auditorioDatos.id_departamento = "";
      this.auditorioDatos.id_ciudad = "";
      this.auditorioDatos.latitud = 4.6699538;
      this.auditorioDatos.longitud = -74.1171362;
      this.auditorioDatos.aforo = "";
      this.auditorioDatos.url_imagen = "";
      this.auditorioDatos.codigo_mapeado = "";
      this.$store.dispatch("auditorio/listAuditorios");
      this.$store.dispatch("solicitudevento/auditorioDatos", {
        id: "",
        nombre: "",
        direccion: "",
        id_pais: "",
        id_departamento: "",
        id_ciudad: "",
        latitud: 4.6699538,
        longitud: -74.1171362,
        aforo: "",
        url_imagen: "",
        codigo_mapeado: "",
      });
      this.DetallesAuditorio = true;
    },
    validateFirstTab() {
      if (this.$refs.ruleForm1.validate()) {
        this.$store.dispatch("condicion/listCondiciones");
        if (this.auditorioDatos.id == null || this.auditorioDatos.id == "") {
          this.newAuditorio();
        }
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateSecondTab() {
      if (this.$refs.ruleForm2.validate()) {
        var tieneCarousel = false;
        var tieneTwitter = false;
        var tieneDisenoBoleta = false;
        var tieneInstagstories = false;
        var tieneFacebook = false;
        var tieneVertical = false;
        var tieneMiniatura = false;
        var tieneBanner = false;
        var tieneDesktop = false;
        var tieneMovil = false;
        var tienePrecios = false;
        for (var i = 0; i < this.eventoimagenes.length; i++) {
          if (this.eventoimagenes[i].nombre.includes("carousel")) {
            tieneCarousel = true;
          } else if (this.eventoimagenes[i].nombre.includes("twitter")) {
            tieneTwitter = true;
          } else if (this.eventoimagenes[i].nombre.includes("disenoboleta")) {
            tieneDisenoBoleta = true;
          } else if (this.eventoimagenes[i].nombre.includes("instagstories")) {
            tieneInstagstories = true;
          } else if (this.eventoimagenes[i].nombre.includes("facebook")) {
            tieneFacebook = true;
          } else if (this.eventoimagenes[i].nombre.includes("vertical")) {
            tieneVertical = true;
          } else if (this.eventoimagenes[i].nombre.includes("miniatura")) {
            tieneMiniatura = true;
          } else if (this.eventoimagenes[i].nombre.includes("banner")) {
            tieneBanner = true;
          } else if (this.eventoimagenes[i].nombre.includes("ban_desktop")) {
            tieneDesktop = true;
          } else if (this.eventoimagenes[i].nombre.includes("ban_movil")) {
            tieneMovil = true;
          } else if (this.eventoimagenes[i].nombre.includes("precios")) {
            tienePrecios = true;
          }
        }
        if (this.imagenesEventoCarousel.length == 0 && !tieneCarousel) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Carrusel.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoTwitter.length == 0 && !tieneTwitter) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Twitter.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoDisenoBoleta.length == 0 && !tieneDisenoBoleta) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener el diseño de la boleta.",
            "error"
          );
          return false;
        }
        if (
          this.imagenesEventoInstagStories.length == 0 &&
          !tieneInstagstories
        ) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de InstagStories.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoFacebook.length == 0 && !tieneFacebook) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Facebook.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoVert.length == 0 && !tieneVertical) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos Vertical.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoMiniatura.length == 0 && !tieneMiniatura) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen Miniatura.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoBanner.length == 0 && !tieneBanner) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Banner.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoDesktop.length == 0 && !tieneDesktop) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Banner Desktop.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoMovil.length == 0 && !tieneMovil) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Banner Movil.",
            "error"
          );
          return false;
        }
        if (this.imagenesEventoPrecios.length == 0 && !tienePrecios) {
          swal(
            "Imagenes Evento!",
            "El evento debe tener al menos una imagen de Precios.",
            "error"
          );
          return false;
        }
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateThirtTab() {
      if (this.$refs.ruleForm3.validate()) {
        this.$store.dispatch("artista/listArtistas");
        this.$store.dispatch("genero/listGeneros");
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateFourTab() {
      if (this.artistasCheck.length == 0) {
        swal("Artistas!", "El evento debe tener al menos un artista.", "error");
        return false;
      } else {
        if (this.$refs.ruleForm4.validate()) {
          /*if (this.$store.getters['localidad/listLocalidadesAuditorio']!='') {
                    for (var i = 0; i < this.localidadesauditorio.length; i++) {
                      if (this.localidades[i]=='' || this.localidades[i]==null) {
                        this.$store.dispatch('solicitudevento/localidades',{  
                          id:'',
                          id_tribuna:'',
                          nombre:'',
                          impuesto:'',
                          precio_venta:'',
                          precio_servicio:'',
                          puerta_acceso:'',
                          url_imagen:'',
                          codigo_moneda:'',
                        });
                      }
                        this.$store.dispatch('solicitudevento/localidades3',{
                        localidades:{  
                          id            : this.localidadesauditorio[i].id,
                          nombre        : this.localidadesauditorio[i].nombre,
                          id_tribuna    : this.localidadesauditorio[i].id_tribuna,
                          puerta_acceso : this.localidadesauditorio[i].puerta_acceso,
                          ruta          : this.localidadesauditorio[i].ruta,
                          url_imagen    : this.localidadesauditorio[i].url_imagen
                        },
                        key: i});
                    }
                  }*/
          this.$store.dispatch("tribuna/listTribunas");
          this.errorform.error = false;
          this.errorform.msj = "";
          return true;
        } else {
          this.errorform.error = true;
          this.errorform.msj = "Por favor verifique los datos";
          return false;
        }
      }
    },
    async validateFiveTab() {
      if (this.$refs.ruleForm5.validate()) {
        if (this.modalPlantilla == "set") {
          await this.$store.dispatch(
            "solicitudevento/tribunasShow25",
            this.tribunas
          );
          this.errorform.error = false;
          this.errorform.msj = "";
          return true;
        } else {
          if ($("#img").attr("src") != "" && $("#img").attr("src") != null) {
            if (this.modalPlantilla == "new") {
              this.$store.commit("changeLoader", true);
              await this.newAuditorioMapeado();
              this.modalPlantilla = "edit";
              this.errorform.error = false;
              this.errorform.msj = "";
              return true;
            } else if (this.modalPlantilla == "edit") {
              $("#to_html").trigger("click");
              await this.updateAuditorio_mapeado();
              this.errorform.error = false;
              this.errorform.msj = "";
              return true;
            }
          } else {
            swal("Mapeo!", "El evento debe tener el mapeo.", "error");
            return false;
          }
        }
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    async validateSixTab() {
      if (this.localidades.length != 0) {
        var imagenlocalidades = true;
        for (var j = 0; j < this.localidades.length; j++) {
          if (this.localidadesevento[j].imagen == "") {
            imagenlocalidades = false;
          }
        }
        if (imagenlocalidades) {
          if (this.$refs.ruleForm6.validate()) {
            for (var i = 0; i < this.localidades.length; i++) {
              if (
                this.localidades[i].id == "" ||
                this.localidades[i].id == null
              ) {
                await this.newloc(this.localidades[i]);
                await this.setloc(i);
              }
            }
            /*this.$store.dispatch('localidad/listLocalidadesAuditorio',this.auditorioDatos.id);*/
            this.errorform.error = false;
            this.errorform.msj = "";
            return true;
          } else {
            this.errorform.error = true;
            this.errorform.msj = "Por favor verifique los datos";
            return false;
          }
        } else {
          swal("Localidades!", "Localidades sin imagen", "error");
          return false;
        }
      } else {
        swal(
          "Localidades!",
          "El auditorio debe tener al menos una localidad.",
          "error"
        );
        return false;
      }
    },
    async validateSevenTab() {
      if (this.$refs.ruleForm7.validate()) {
        if (this.localidades.length == 0) {
          this.errorform.error = true;
          this.errorform.msj = "Por favor verifique los datos";
          return false;
        }
        for (var i = 0; i < this.localidades.length; i++) {
          await this.$store.dispatch(
            "fila/listFilasLocalidad",
            this.localidades[i].id
          );
          var filasxlocalidades = await this.$store.getters[
            "fila/listFilasLocalidad"
          ];
          var puestos = 0;
          for (var j = 0; j < filasxlocalidades.length; j++) {
            puestos =
              parseInt(puestos) + parseInt(filasxlocalidades[j].puestos.length);
          }
          if (puestos != this.localidades[i].aforo) {
            swal(
              "Puestos!",
              "La localidad " +
                this.localidades[i].id +
                " no se le ha creado los puestos correspondientes al aforo de esta localidad.",
              "error"
            );
            return false;
          }
        }
        if (this.auditorio_mapeado.area_mapeada != "0 objects") {
          $("#area_mapeadaAuditorio2").html(
            $("<textarea />")
              .html(this.auditorio_mapeado.area_mapeada)
              .text()
              .replace("&nbsp;", "")
              .replace('"map"', '"map2"')
          );
        }
        if ($("#code_content").html() != undefined) {
          $("#area_mapeadaAuditorio2").html(
            $("<textarea />")
              .html($("#code_content").html())
              .text()
              .replace("&nbsp;", "")
              .replace('"map"', '"map2"')
          );
        }
        var self = this;
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1]);
          window.location.hash = "boletas";
          //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
        });
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateEightTab() {
      if (this.$refs.ruleForm8.validate()) {
        this.$store.dispatch("puntoventa/listPuntoventas");
        if (
          !this.auditorio_mapeado.area_mapeada.includes(
            "/mis-eventos/puestos_localidad/"
          )
        ) {
          swal(
            "Auditorio Mapeado!",
            "Por favor introduzca el id de las localidades en la forma 5.",
            "error"
          );
          return false;
        } else {
          this.errorform.error = false;
          this.errorform.msj = "";
          if (this.imagenMapeo.status != 1) {
            this.$store.dispatch("auditorio_mapeado/updateAuditorio_mapeado", {
              id: this.imagenMapeo.id,
              id_auditorio: this.imagenMapeo.id_auditorio,
              nombre: this.imagenMapeo.nombre,
              area_mapeada: this.imagenMapeo.area_mapeada,
              status: 1,
            });
          }
          return true;
        }
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateNineTab() {
      if (this.$refs.ruleForm9.validate()) {
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateTenTab() {
      if (!(this.evento.venta_linea || this.selectedPuntoventas.length != 0)) {
        swal(
          "Punto de Venta!",
          "El evento debe tener servicio de ventas de boleteria.",
          "error"
        );
        return false;
      } else {
        if (
          this.evento.venta_linea &&
          !this.evento.fecha_inicio_venta_internet
        ) {
          swal(
            "Punto de Venta!",
            "El evento debe tener fecha de inicio de ventas en internet.",
            "error"
          );
          return false;
        }
        if (
          this.selectedPuntoventas.length != 0 &&
          !this.evento.fecha_inicio_venta_puntos
        ) {
          swal(
            "Punto de Venta!",
            "El evento debe tener fecha de inicio de ventas en punto de ventas.",
            "error"
          );
          return false;
        }
        if (this.$refs.ruleForm10.validate()) {
          this.$store.dispatch("tipocosto/listTipocostos");
          this.errorform.error = false;
          this.errorform.msj = "";
          return true;
        } else {
          this.errorform.error = true;
          this.errorform.msj = "Por favor verifique los datos";
          return false;
        }
      }
    },
    validateIlevenTab() {
      if (this.$refs.ruleForm11.validate()) {
        this.errorform.error = false;
        this.errorform.msj = "";
        if (this.auditorio_mapeado.area_mapeada != "0 objects") {
          $("#area_mapeadaAuditorio3").html(
            $("<textarea />")
              .html(this.auditorio_mapeado.area_mapeada)
              .text()
              .replace("&nbsp;", "")
              .replace('"map"', '"map3"')
          );
        }
        if ($("#code_content").html() != undefined) {
          $("#area_mapeadaAuditorio3").html(
            $("<textarea />")
              .html($("#code_content").html())
              .text()
              .replace("&nbsp;", "")
              .replace('"map"', '"map3"')
          );
        }
        var self = this;
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1]);
          window.location.hash = "boletas2";
          //$("html, body").animate({ scrollTop: $('#boletas2').offset().top }, 1);
        });
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    validateTwelveTab() {
      if (this.$refs.ruleForm12.validate()) {
        this.errorform.error = false;
        this.errorform.msj = "";
        return true;
      } else {
        this.errorform.error = true;
        this.errorform.msj = "Por favor verifique los datos";
        return false;
      }
    },
    newAuditorioMapeado() {
      /*var base64File=$('#sm_img').attr('src');
              var b64 = base64File.split(',');
              var name = `${Math.random().toString(36).slice(-5)}.png`;
              var file = new File(new Buffer(b64[1]), name, {type: 'image/png'});
              $('#to_html').trigger('click');
              const formData = new FormData();
              formData.append('id_auditorio', this.auditorioDatos.id);
              formData.append('area_mapeada', $('#code_content').html());
              formData.append('imagen', file);
              await this.$store.dispatch('auditorio_mapeado/newAuditorio_mapeado',formData);*/
      $("#to_html").trigger("click");
      const formData = new FormData();
      formData.append("id_auditorio", this.auditorioDatos.id);
      formData.append("nombre", this.imagenMapeo.nombre);
      formData.append("status", 0);
      formData.append("area_mapeada", $("#code_content").html());
      formData.append("imagen", this.fileimg);
      return this.$store.dispatch(
        "auditorio_mapeado/newAuditorio_mapeado",
        formData
      );
    },
    async updateAuditorio_mapeado() {
      return await this.$store
        .dispatch("auditorio_mapeado/updateAuditorio_mapeado", {
          id: this.imagenMapeo.id,
          id_auditorio: this.imagenMapeo.id_auditorio,
          nombre: this.imagenMapeo.nombre,
          area_mapeada: $("#code_content").html(),
          status: this.imagenMapeo.status,
        })
        .then((response) => {
          $("#area_mapeadaAuditorio").html(
            $("<textarea />")
              .html($("#code_content").html())
              .text()
              .replace("&nbsp;", "")
          );
        });
    },
    async newloc(localidad) {
      return await this.$store.dispatch("localidad/newLocalidad", {
        nombre: localidad.nombre,
        id_tribuna: localidad.id_tribuna,
        puerta_acceso: localidad.puerta_acceso,
        ruta: "",
        url_imagen: "",
        aforo: localidad.aforo,
        silleteria: localidad.silleteria,
        palco: localidad.palco,
        puestosxpalco: localidad.puestosxpalco,
        imagen: localidad.imagen,
      });
    },
    async setloc(index) {
      await this.$store.dispatch("solicitudevento/localidades4", {
        id: this.localidad.id,
        key: index,
      });
      return await this.$store.dispatch("solicitudevento/localidadesevento4", {
        id_localidad: this.localidad.id,
        key: index,
      });
    },
    newAuditorio() {
      this.$store
        .dispatch("auditorio/newAuditorio", {
          nombre: this.auditorioDatos.nombre,
          id_ciudad: this.auditorioDatos.id_ciudad,
          id_departamento: this.auditorioDatos.id_departamento,
          id_pais: this.auditorioDatos.id_pais,
          direccion: this.auditorioDatos.direccion,
          latitud: this.auditorioDatos.latitud,
          longitud: this.auditorioDatos.longitud,
          aforo: this.auditorioDatos.aforo,
          url_imagen: this.auditorioDatos.url_imagen,
          codigo_mapeado: this.auditorioDatos.codigo_mapeado,
        })
        .then((response) => {
          this.auditorioDatos.id = this.auditorio.id;
        });
    },
    async saveArtista() {
      let searchArtista = this.artistas.filter(
        (artista) =>
          artista.nombre.toLowerCase() == this.nameArtista.toLowerCase()
      );
      if (
        searchArtista[0] == null ||
        searchArtista[0] == "" ||
        searchArtista[0] == undefined
      ) {
        if (this.artista) {
          if (this.imagenNewArtista[0]) {
            await this.$store.dispatch("artista/updateArtista", {
              id: this.artista.id,
              nombre: this.nameArtista,
            });
          } else {
            swal("Imagen!", "No hay Imagen!", "error");
          }
        } else {
          const formData = new FormData();
          formData.append("nombre", this.nameArtista);
          formData.append("manager", this.managerNewArtista);
          formData.append("id_genero", this.generoNewArtista);
          // for (var i = 0; i < this.imagenNewArtista.length; i++) { formData.append('imagenes[]', this.imagenNewArtista[i]); }
          formData.append(
            "imagenes[]",
            this.imagenNewArtista[this.imagenNewArtista.length - 1]
          );
          await this.$store
            .dispatch("artista/newArtista", formData)
            .then((response) => {
              this.keyArtistas = this.nameArtista;
            });
        }
      } else {
        swal("Duplicado!", "Artista existente!", "error");
      }
      this.modalArtista = false;
    },
    saveTemporada() {
      if (this.newTemporada == "" || this.newTemporada == null) {
        swal("Campo vacío!", "Por favor digite un valor!", "error");
      } else {
        let status = 1;

        this.$store.dispatch("temporada/newTemporada", {
          nombre: this.newTemporada,
          status: status,
        });
      }
      this.newDialogTemporada = false;
      this.newTemporada = "";
    },
    saveImagenEventoCarusel() {
      //this.imagenesEventoCarousel.push(this.imagenEvento[this.imagenEvento.length-1]);
      if (this.nameImageC != "" && this.nameImageC != null) {
        if (this.sizeImageCWidth != 220 || this.sizeImageCHeight != 300) {
          swal(
            "Imagen!",
            "Imagen de carrusel no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoCarousel", {
            imagen: document.getElementById("imgEventoCaruselPreview").src,
          });
          this.modalImagenEventoCarusel = false;
        }
      }
    },
    saveImagenEventoTwitter() {
      if (this.nameImageT != "" && this.nameImageT != null) {
        if (this.sizeImageTWidth != 1024 || this.sizeImageTHeight != 512) {
          swal(
            "Imagen!",
            "Imagen de twitter no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoTwitter", {
            imagen: document.getElementById("imgEventoTwitterPreview").src,
          });
          this.modalImagenEventoTwitter = false;
        }
      }
    },
    saveImagenEventoDisenoBoleta() {
      if (this.nameImageI != "" && this.nameImageI != null) {
        if (this.sizeImageIWidth != 794 || this.sizeImageIHeight != 1124) {
          swal(
            "Imagen!",
            "Imagen de diseño boleta no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoDisenoBoleta", {
            imagen: document.getElementById("imgEventoDisenoBoletaPreview").src,
          });
          this.modalImagenEventoDisenoBoleta = false;
        }
      }
    },
    saveImagenEventoInstagStories() {
      if (this.nameImageIS != "" && this.nameImageIS != null) {
        if (this.sizeImageISWidth != 1127 || this.sizeImageISHeight != 2008) {
          swal(
            "Imagen!",
            "Imagen de Instagram Stories no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoInstagStories", {
            imagen: document.getElementById("imgEventoInstagStoriesPreview")
              .src,
          });
          this.modalImagenEventoInstagStories = false;
        }
      }
    },
    saveImagenEventoFacebook() {
      if (this.nameImageF != "" && this.nameImageF != null) {
        if (this.sizeImageFWidth != 1200 || this.sizeImageFHeight != 630) {
          swal(
            "Imagen!",
            "Imagen de Facebook no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoFacebook", {
            imagen: document.getElementById("imgEventoFacebookPreview").src,
          });
          this.modalImagenEventoFacebook = false;
        }
      }
    },
    saveImagenEventoVert() {
      if (this.nameImageV != "" && this.nameImageV != null) {
        if (this.sizeImageVWidth != 300 || this.sizeImageVHeight != 360) {
          swal(
            "Imagen!",
            "Imagen de evento vertical no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoVert", {
            imagen: document.getElementById("imgEventoVertPreview").src,
          });
          this.modalImagenEventoVert = false;
        }
      }
    },
    saveImagenEventoMiniatura() {
      if (this.nameImageM != "" && this.nameImageM != null) {
        if (this.sizeImageMWidth != 150 || this.sizeImageMHeight != 150) {
          swal(
            "Imagen!",
            "Imagen de evento miniatura no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoMiniatura", {
            imagen: document.getElementById("imgEventoMiniaturaPreview").src,
          });
          this.modalImagenEventoMiniatura = false;
        }
      }
    },
    saveImagenEventoBanner() {
      if (this.nameImageB != "" && this.nameImageB != null) {
        if (this.sizeImageBWidth != 760 || this.sizeImageBHeight != 400) {
          swal(
            "Imagen!",
            "Imagen de banner principal no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoBanner", {
            imagen: document.getElementById("imgEventoBannerPreview").src,
          });
          this.modalImagenEventoBanner = false;
        }
      }
    },
    saveImagenEventoDesktop() {
      if (this.nameImageBD != "" && this.nameImageBD != null) {
        if (this.sizeImageBDWidth!=1000 || this.sizeImageBDHeight!=500) {
          swal(
            "Imagen!",
            "Imagen de banner desktop principal no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoDesktop", {
            imagen: document.getElementById("imgEventoDesktopPreview").src,
          });
          this.modalImagenEventoDesktop = false;
        }
      }
    },
    saveImagenEventoMovil() {
      if (this.nameImageBM != "" && this.nameImageBM != null) {
        if (this.sizeImageBMWidth!=600 || this.sizeImageBMHeight!=500) {
          swal(
            "Imagen!",
            "Imagen de banner movil principal no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoMovil", {
            imagen: document.getElementById("imgEventoMovilPreview").src,
          });
          this.modalImagenEventoMovil = false;
        }
      }
    },
    saveImagenEventoPrecios() {
      if (this.nameImageP != "" && this.nameImageP != null) {
        if (this.sizeImagePWidth!=1280 || this.sizeImagePHeight!=590) {
          swal(
            "Imagen!",
            "Imagen de precios principal no tiene tamaño necesario!",
            "error"
          );
        } else {
          this.$store.dispatch("solicitudevento/imagenesEventoPrecios", {
            imagen: document.getElementById("imgEventoPreciosPreview").src,
          });
          this.modalImagenEventoPrecios = false;
        }
      }
    },
    getImage(event) {
      this.imagenNewArtista.push(event.target.files[0]);
    },
    getImageEventoCarusel(event) {
      //this.imagenesEventoCarusel.push(event.target.files[0]);
      if (event.target.files[0]) {
        this.nameImageC = event.target.files[0].name;
        this.sizeImageC = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageCWidth = this.width;
            self.sizeImageCHeight = this.height;
          };
        };
      }
    },
    getImageEventoTwitter(event) {
      //this.imagenesEventoTwitter.push(event.target.files[0]);
      if (event.target.files[0]) {
        this.nameImageT = event.target.files[0].name;
        this.sizeImageT = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageTWidth = this.width;
            self.sizeImageTHeight = this.height;
          };
        };
      }
    },
    getImageEventoDisenoBoleta(event) {
      //this.imagenesEventoDisenoBoleta.push(event.target.files[0]);
      if (event.target.files[0]) {
        this.nameImageI = event.target.files[0].name;
        this.sizeImageI = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageIWidth = this.width;
            self.sizeImageIHeight = this.height;
          };
        };
      }
    },
    getImageEventoInstagStories(event) {
      if (event.target.files[0]) {
        this.nameImageIS = event.target.files[0].name;
        this.sizeImageIS = parseFloat(
          event.target.files[0].size / 1024
        ).toFixed(2);
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageISWidth = this.width;
            self.sizeImageISHeight = this.height;
          };
        };
      }
    },
    getImageEventoFacebook(event) {
      //this.imagenesEventoFacebook.push(event.target.files[0]);
      if (event.target.files[0]) {
        this.nameImageF = event.target.files[0].name;
        this.sizeImageF = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageFWidth = this.width;
            self.sizeImageFHeight = this.height;
          };
        };
      }
    },
    getImageEventoVert(event) {
      if (event.target.files[0]) {
        this.nameImageV = event.target.files[0].name;
        this.sizeImageV = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageVWidth = this.width;
            self.sizeImageVHeight = this.height;
          };
        };
      }
    },
    getImageEventoMiniatura(event) {
      //this.imagenesEventoMiniatura.push(event.target.files[0]);
      if (event.target.files[0]) {
        this.nameImageM = event.target.files[0].name;
        this.sizeImageM = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageMWidth = this.width;
            self.sizeImageMHeight = this.height;
          };
        };
      }
    },
    getImageEventoBanner(event) {
      if (event.target.files[0]) {
        this.nameImageB = event.target.files[0].name;
        this.sizeImageB = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageBWidth = this.width;
            self.sizeImageBHeight = this.height;
          };
        };
      }
    },
    getImageEventoDesktop(event) {
      if (event.target.files[0]) {
        this.nameImageBD = event.target.files[0].name;
        this.sizeImageBD = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageBDWidth = this.width;
            self.sizeImageBDHeight = this.height;
          };
        };
      }
    },
    getImageEventoMovil(event) {
      if (event.target.files[0]) {
        this.nameImageBM = event.target.files[0].name;
        this.sizeImageBM = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImageBMWidth = this.width;
            self.sizeImageBMHeight = this.height;
          };
        };
      }
    },
    getImageEventoPrecios(event) {
      if (event.target.files[0]) {
        this.nameImageP = event.target.files[0].name;
        this.sizeImageP = parseFloat(event.target.files[0].size / 1024).toFixed(
          2
        );
        var self = this;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            self.sizeImagePWidth = this.width;
            self.sizeImagePHeight = this.height;
          };
        };
      }
    },
    getImageLocalidad(index) {
      var self = this;
      // if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      //   alert('The File APIs are not fully supported in this browser.');
      //   return;
      // }

      var input = document.getElementById("imagenLocalidad" + index);
      // if (!input) {
      //   alert("Um, couldn't find the fileinput element.");
      // }
      // else if (!input.files) {
      //   alert("This browser doesn't seem to support the `files` property of file inputs.");
      // }
      // else
      if (!input.files[0]) {
        // alert("Please select a file before clicking 'Load'");
      } else {
        var file = input.files[0];
        var fr = new FileReader();
        //fr.onload = document.getElementById('editor').appendChild(document.createTextNode(fr.result));;
        //fr.readAsText(file);
        fr.readAsDataURL(file);
        fr.onload = (function (f) {
          return function (e) {
            // Here you can use `e.result` or `this.result`
            // and `f.name`.
            self.localidadesevento[index].imagen = e.target.result;
            self.updateLocalidadesevento(index);
          };
        })(file);
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    updateArtistasCheckTemp(id, active) {
      let artistaIndex = this.artistasCheckTemp.indexOf(id);
      if (active == true) {
        if (artistaIndex !== -1) {
          /*this.artistasCheckTemp.splice(artistaIndex, 1);*/
          this.$store.dispatch(
            "solicitudevento/artistasCheckTemp2",
            artistaIndex
          );
        }
      } else {
        if (artistaIndex === -1) {
          /*this.artistasCheckTemp.push(id);*/
          this.$store.dispatch("solicitudevento/artistasCheckTemp", id);
        }
      }
    },
    updateImagenesEventoCarouselCheckTemp(id) {
      //this.imagenesEventoCarousel.splice(id, 1);
      this.$store.dispatch("solicitudevento/imagenesEventoCarousel2", id);
    },
    updateImagenesEventoTwitterCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoTwitter2", id);
    },
    updateImagenesEventoDisenoBoletaCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoDisenoBoleta2", id);
    },
    updateImagenesEventoInstagStoriesCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoInstagStories2", id);
    },
    updateImagenesEventoFacebookCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoFacebook2", id);
    },
    updateImagenesEventoVertCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoVert2", id);
    },
    updateImagenesEventoMiniaturaCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoMiniatura2", id);
    },
    updateImagenesEventoBannerCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoBanner2", id);
    },
    updateImagenesEventoDesktopCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoDesktop2", id);
    },
    updateImagenesEventoMovilCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoMovil2", id);
    },
    updateImagenesEventoPreciosCheckTemp(id) {
      this.$store.dispatch("solicitudevento/imagenesEventoPrecios2", id);
    },
    deleteImagenesEventoCheck(id) {
      this.deleteImagenesEvento.push(id);
      this.eventoimagenes = this.eventoimagenes.filter((eventoimagen) => {
        return eventoimagen.id != id;
      });
    },
    verTodosArtistas() {
      this.keyArtistas = "";
      this.generoSearch = "";
    },
  },
};
</script>
<style>
.map-container {
  width: 400px;
  height: 400px;
  display: inline-block;
}

.checkFilterArtistasSolicitud {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 200px;
  height: 200px;
  background-size: 100% 100% !important;
  margin: 0 auto;
}
.checkFilterCondicionesSolicitud {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.checkFilterCondicionesSolicitud .v-messages {
  min-height: 0 !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.checkFilterArtistasSolicitud .v-input--selection-controls__ripple {
  width: 200px !important;
  height: 200px !important;
}
.checkFilterArtistasSolicitud
  .v-input--selection-controls__ripple.success--text {
  border-radius: 0px;
  border: 2px #4caf50 solid;
}
.checkFilterArtistasSolicitud i {
  font-size: 50px !important;
}
.checkFilterArtistasSolicitud .v-input__slot {
  margin: 0px !important;
}
.checkFilterImagenesEventoCarouselSolicitud {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 200px;
  height: 200px;
  background-size: 100% 100% !important;
  margin: 0 auto;
}
.checkFilterImagenesEventoCarouselSolicitud
  .v-input--selection-controls__ripple {
  width: 200px !important;
  height: 200px !important;
}
.checkFilterImagenesEventoCarouselSolicitud
  .v-input--selection-controls__ripple.success--text {
  border-radius: 0px;
  border: 2px #4caf50 solid;
}
.checkFilterImagenesEventoCarouselSolicitud i {
  font-size: 50px !important;
}
.checkFilterImagenesEventoCarouselSolicitud .v-input__slot {
  margin: 0px !important;
}
.condicion-name {
  font-weight: bold;
}
.img-artista {
  height: 180px;
  width: 170px;
  cursor: pointer;
}
#imgArtistaPreview {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: none;
}
.img-evento {
  height: 180px;
  width: 170px;
  cursor: pointer;
}
#imgEventoPreview {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: none;
}
.ValidaAuditorioMapeado {
  overflow-y: scroll;
}
</style>