export const mutations = {
	loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
      state.user.accessToken = null;
    },
    changeLoader: (state,status) => {
        state.loader = status;
    },
    validateToken: (state,status) => {
        state.validateToken = status;
    },
    tokenResetPassword: (state,status) => {
        state.tokenResetPassword = status;
    },
    tokenActiveAccount: (state,status) => {
        state.tokenActiveAccount = status;
    },
    sha: (state,sha) => {
        state.sha = sha;
    },
    obtener_refventa: (state,obtener_refventa) => {
        state.obtener_refventa = obtener_refventa;
    },
    get_init_point: (state,init_point) => {
        state.init_point = init_point;
    },
    showFactura: (state,option) => {
        state.modalFactura = option;
    },
     showModalLogin: (state,option) => {
        state.modalLogin = option;
    },
};