import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       departamentos: [],
       departamento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listDepartamentos: (state,getters,rootState) => {
          return state.departamentos;
    },
    departamento: (state,getters,rootState) => {
        return state.departamento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listDepartamentos: (state,departamentos) => {
          state.departamentos = departamentos;
          state.pagination.totalItems = departamentos.total;
          state.pagination.page = departamentos.current_page;
          state.pagination.rowsPerPage = departamentos.per_page;
       },
      listDepartamentosSearch: (state,departamentos) => {
            if(departamentos != null){ 
                state.departamentos = departamentos;
            }else{
              state.departamentos = null;
            }
       },
      viewDepartamento(state,id){
        let auxDepartamento = state.departamentos.filter((item) => item.id == id);
        state.departamento = auxDepartamento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listDepartamentos({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}departamento_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listDepartamentos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los departamentos!", "error");
        console.error(error);
      });

    },
  newDepartamento({state,getters,commit,rootState},payload){
      // id_pais
      // descripcion
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}departamento`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el departamento correctamente!", "success");
        store.dispatch('departamento/listDepartamentos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el departamento!", "error");
        console.error(error);
      });
  },
  updateDepartamento({state,getters,commit,rootState},payload){
      // id_pais
      // descripcion
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}departamento/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el departamento correctamente!", "success");
        store.dispatch('departamento/listDepartamentos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el departamento!", "error");
        console.error(error);
      });
  },
  deleteDepartamento({state,getters,commit,rootState},departamento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}departamento/${departamento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el departamento correctamente!", "success");
        store.dispatch('departamento/listDepartamentos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el departamento!","error");
        //console.log(error);
     });
  },
    searchDepartamentos({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarDepartamento?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listDepartamentosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los departamentos!", "error");
        console.error(error);
      });

    },
  },
}