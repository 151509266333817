<template>
  <v-app>
    <v-container>
      <v-layout>
        <v-flex>
          <v-card>
            <v-card-title class="text-center">
              <h3 class="text-center mt-3 col-md-12">
                TÉRMINOS Y CONDICIONES DE EVENTOS
              </h3>
            </v-card-title>
            <v-card-text>
              <div class="row row-clearfix">
                <div class="col-md col-lg col-sm p-4">
                  <h4>
                    <span>CONDICIONES GENERALES DE COMPRA DE BOLETAS.</span>
                  </h4>
                  <p>
                    Al aceptar los Términos y Condiciones del Servicio de Compraventa de Boletería (en adelante los "T&C"), el Comprador, 
                    Ticketshop y el Productor se obligan en los siguientes términos:
                  </p>
                  <ol class="mt-5">
                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">DECLARACIONES DEL COMPRADOR:</strong><br>
                        El "Comprador", entendido como la persona que adquiere una boleta a través de ticketshop, comprende y acepta que:<br>
                        1.1. Ticketshop es un operador de boletería de espectáculos públicos, conciertos, eventos deportivos, conferencias u otros 
                        (en adelante los "Eventos" o el "Evento" según aplique) legalmente autorizado.<br>
                        1.2. Ticketshop comercializa boletos de Eventos por mandato de sus organizadores (en adelante los "Productores" o el 
                        "Productor" según aplique), cuyo nombre se indica en la información de cada Evento.<br>
                        1.3. Ticketshop únicamente comercializa la boletería del Evento, por lo que es un tercero ajeno a su organización. En 
                        consecuencia, no es responsable por la modificación, aplazamiento, cancelación, cambios de precios, reprogramación, 
                        calidad, contenido, actos o consecuencias que se deriven del Evento, al ser decisiones que dependen del Productor.<br>
                        1.4. Ticketshop presta un servicio de atención al cliente integral, el cual es independiente al desarrollo del Evento. Para 
                        sufragar los costos de atención al cliente, licencias de software, nómina, servicios públicos entre otros, ticketshop cobra un 
                        cargo por servicio, que está sujeto al impuesto sobre las ventas - IVA y se discriminaran según lo establecido en los T&C.<br>
                        1.5. Toda la información del Evento publicada en la página web de ticketshop, como número de boletas, ubicaciones, valor 
                        de la boletería, condiciones de acceso, fechas, horas, y demás características del Evento, ha sido suministrada, por lo que 
                        la decisión de compra del Cliente se ha basado en ello.<br>
                        1.6. La información de los Eventos publicada por ticketshop es suministrada por los Productores, quienes eventualmente 
                        podrán modificarla, caso en el cual ticketshop, en su calidad de mandatario informará en el sitio web y en las taquillas 
                        asignadas estos cambios. Ticketshop no será responsable por la modificación de las condiciones del Evento que sean 
                        ordenadas por el Productor.<br>
                        1.7. La compra de boletería está sujeta a la aprobación y verificación de la transacción por parte del banco, o entidad 
                        financiera, a través de la cual el Comprador realice la compra, así como a la verificación de los datos.<br>
                        1.8. Ticketshop no controla de ninguna forma las páginas de los medios de pago o pasarelas de pagos a través de las que 
                        se realizan las transacciones. En este sentido, ticketshop no será responsable por el tratamiento de la información que el 
                        Comprador suministre a estas páginas, a los medios de pago o a las entidades financieras para la realización de dichas 
                        transacciones. Estos últimos son responsables en los términos y condiciones propios informados en sus páginas.<br>
                        1.9. Cuando la compra de boletería finalice exitosamente, se enviará al correo electrónico aportado por el Comprador la 
                        factura , el comprobante de pago detallado por conceptos y el número de confirmación de compra.<br>
                        1.10. Por razones de seguridad, únicamente pondrán ejercer los derechos establecidos en los T & C y la legislación vigente 
                        aplicable, quienes acrediten que son los titulares de la cuenta ticketshop que generó la compra o el titular del producto 
                        financiero que la realizó.<br>
                        1.11. La cuenta de ticketshop del Cliente estará asociada al correo electrónico validado por el Cliente, el cual no podrá 
                        modificarse salvo que exista una solicitud del Cliente de la cuenta, previa validación de la entidad por parte de ticketshop.<br>
                        1.12. La compra de boletería estará sujeta al pago de los impuestos, tasas y contribuciones según la legislación vigente 
                        aplicable.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">CONDICIONES PARA LA ENTRADA AL EVENTO:</strong><br>
                        2.1. La entrada al Evento está condicionada a la presentación de la boleta (física o digital) válida, y a la revisión de 
                        seguridad del asistente y sus objetos por el personal de seguridad. El personal de seguridad podrá negar el acceso al 
                        Evento cuando tenga fundamento razonable para ello.<br>
                        2.2. El boleto sólo será válido para el Evento en la fecha, ubicación y hora que en ella se indica. El número de boletos, así 
                        como los datos contenidos en cada una de ellas correspondientes al Evento fueron seleccionados directamente por el 
                        Comprador.<br>
                        2.3. El Comprador decidirá su ubicación dentro del Evento, y considerará situaciones tales como condiciones de salud, 
                        embarazo o discapacidad, según aplique, al momento de realizar su compra, asumiendo los riesgos que puedan derivarse 
                        de un evento con afluencia masiva.<br>
                        2.4. Se negará el acceso si el boleto no es válido o si no se cumplen las reglas del evento en relación con los objetos 
                        personales que pueden ingresarse.<br>
                        2.5. El Comprador deberá conservar en todo momento durante el Evento su ticket impreso o hard ticket.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">CARGO POR SERVICIO:</strong><br>
                        3.1. El Comprador entiende y acepta que, para la adquisición del boleto, ticketshop es solo un intermediario que presta el 
                        servicio de generación de boletería y atención al usuario y se obliga a:<br>
                        <ol>
                          a) Procesar en el sistema la compra realizada por el Comprador.<br>
                          b) realizar el proceso de registro del Comprador de manera adecuada.<br>
                          c) Prestar el servicio de conservación y seguridad de las condiciones seleccionadas para cada una de los boletos
                          adquiridos por el Comprador.<br>
                          d) Generar los mecanismos para evitar la suplantación del Comprador.<br>
                          e) Poner a disposición del Comprador todo el soporte de servicio al cliente de manera continua, y todos los canales de 
                          información de ticketshop para mantenerlo enterado de los acontecimientos, condiciones, restricciones, modificaciones, 
                          detalles y datos generales relacionados con el Evento para que pueda acceder a la información completa requerida.<br>
                          f) Atender todas las peticiones, quejas, y reclamos presentados por el Comprador en un término que no podrá superar los 
                          treinta (30) días hábiles. Si el Comprador presenta una petición, queja o reclamo, ticketshop enviará dentro de los tres (3) 
                          días hábiles siguientes una comunicación electrónica informándole el número de su radicado.<br>
                        </ol>
                        3.2. Que la prestación de los anteriores servicios generará un Cargo por Servicio de Boletería como remuneración de 
                        ticketshop, el cual es diferente del importe de acceso al Evento, al servicio de compraventa de boletería y atención al cliente 
                        diferente al Evento, pese a que son servicios que se complementan y son prestados por personas jurídicas independientes.<br>
                        3.3. El Cargo por Servicio de Boletería y su valor se informan al Comprador en el proceso de compra, y en el boleto. Le 
                        corresponde al Comprador de la Boletería decidir de forma libre y voluntaria si acepta estos términos y condiciones de pago 
                        del valor correspondiente con el fin de obtener los servicios a los que ticketshop se obliga.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">OTROS COBROS</strong><br>
                        4.1. El Comprador deberá asumir el costo adicional que se genere si realiza el pago a través de plataformas que cobren 
                        comisión por su servicio.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">POLÍTICA DE DEVOLUCIONES Y CAMBIOS</strong><br>
                        5.1. Garantía Legal: ticketshop se compromete solo en los siguientes casos con el Comprador a devolver el precio pagado 
                        por el Acceso al Evento:<br>
                        <ol>
                          a) En caso de cancelación del Evento (entendiéndose cuando éste no se haya iniciado). El Comprador deberá seguir el 
                          procedimiento que ticketshop señale para solicitar su reembolso.<br>
                          b) En caso de que el Evento sea pospuesto y/o se reprograme su fecha o lugar de realización. El Comprador deberá seguir 
                          el procedimiento que ticketshop señale para solicitar su reembolso en el plazo indicado por ticketshop. Si habiendo 
                          transcurrido el tiempo hasta la nueva fecha programada, sin que se haya solicitado el reembolso, se entenderá que el 
                          Comprador está conforme con la nueva fecha programada, y no procederá el reembolso.<br>
                        </ol>
                        5.2. En ningún otro caso ticketshop realizará cambios o devoluciones.<br>
                        5.3. El Comprador comprende y acepta que no son causales de devolución del dinero:<br>
                        <ol>
                          a) El no ingreso al Evento por razones no atribuibles al Productor o ticketshop.<br>
                          b) Por pérdida, deterioro o destrucción del boleto. Será responsabilidad del Comprador cuidar y resguardar su boleto. 
                          Excepcionalmente, cuando el Comprador pruebe que efectúo la compra de boletería, ninguna persona haya ingresado al 
                          show y sea posible anulación de la boleta pérdida, deteriorada o destruida, se podrá efectuar el cambio.<br>
                        </ol>
                        5.4. Si el Evento se cancela, pospone, o suspende (cuando haya iniciado y no se pueda continuar) por causa de fuerza 
                        mayor, caso fortuito, o impedimentos legales o materiales, no procederá el reembolso.<br>
                        5.5. Son hechos ajenos a ticketshop y no generan devolución del valor del boleto pagado por el usuario:<br>
                        <ol>
                          (i) la enfermedad, incapacidad, cirugías, los conflictos familiares y de pareja, o el fallecimiento del Cliente o un familiar de aquel,<br> 
                          (ii) la cancelación de vuelos o reservas de hoteles,<br>
                          (iii) los Eventos de fuerza mayor que no impidan la realización del Evento.
                        </ol>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">TRANSFERENCIA DE BOLETOS</strong><br>
                        El usuario que recibe un ETICKET declara y acepta los siguiente:<br>
                        6.1. Comprende que la herramienta de transferencia de boletería no es un mecanismo para la reventa de boletería, por lo 
                        que únicamente debe ser utilizada para transferencias entre personas de confianza.<br>
                        6.2. Comprende que el uso de la herramienta de transferencia para actividades de reventa de boletería constituye un uso 
                        no adecuado de la plataforma, el cual anula la garantía legal.<br>
                        6.3. Comprende que, si no se paga el valor de los boletos por cualquier causa, se cancelaran los boletos transferidos por 
                        incumplimiento al contrato de compraventa de boletería.
                      </p>
                    </li>
                  </ol>

                  <!--<p class="mt-5 text-center">
                    Ante cualquier consulta, no dude en comunicarse con nuestro
                    call center (XXX) xxxxxxxx.
                  </p>-->
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "terminoscondiciones",
  beforeCreate() {
    document.title = "Terminos y condiciones - TicketShop";
  },
};
</script>