<template>
  <v-app>
    <v-container>
      <v-layout>
        <v-flex>
          <v-card>
            <v-card-title>
              <h3>Comunicados</h3>
            </v-card-title>
            <v-card-text>
              <ul class="list-group">
                <li
                  v-for="comunicado in comunicados"
                  :key="comunicado.id"
                  class="list-group-item"
                >
                  <a
                    :href="comunicado.url"
                    target="_blank"
                    class="link-comunicado"
                  >
                    {{ comunicado.titulo }}
                  </a>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="text-xs-center pt-2">
        <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  computed: {
    comunicados() {
      return this.$store.getters["comunicados/getComunicados"];
    },
    pagination() {
      return this.$store.getters["comunicados/pagination"];
    },
    pages() {
      return this.$store.getters["comunicados/pages"];
    },
    totalItemCount() {
      return this.$store.getters["comunicados/totalItemCount"];
    },
    loading() {
      return this.$store.getters["comunicados/loading"];
    },
  },
  beforeCreate() {
    document.title = "Comunicados - TicketShop";
  },
  mounted() {
    this.$store.dispatch("comunicados/loadComunicados", {
      page: this.pagination.page,
    });
  },
  watch: {
    pagination: {
      async handler() {
        this.$store.dispatch("comunicados/loadComunicados", {
          page: this.pagination.page,
        });
      },
      deep: true,
    },
  },
};
</script>

<style>
a.link-comunicado {
  color: #000000 !important;
  text-decoration: underline;
  font-weight: bold;
}
</style>